import { Button } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  padding: 0 1rem;
  margin: 0 auto 4rem;
  @media screen and (min-width: 105rem) {
    max-width: 96rem;
  }
`

export const DarkContainer = styled(Container)`
  color: #fff;
  margin: 1rem auto 2rem;

  @media screen and (min-width: 768px) {
    margin: 4rem auto 4rem;
  }

  h1,
  p,
  ul,
  li {
    color: #fff;
  }
`

export const TransparentButton = styled(
  Button as unknown as AnyStyledComponent
)`
  background: rgba(255, 255, 255, 0.15);
  color: #fff;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
  }
`

export const CourseMeta = styled.ul`
  padding: 0;
  margin-bottom: 1.25rem;
  list-style-type: none;

  li {
    display: inline-block;
    margin-right: 1rem;

    .anticon {
      margin-right: 0.35rem;
    }
  }
`

export const CourseTitle = styled.h1`
  font-size: 36px;
  line-height: 125%;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: 42px;
  }
`

export const CourseImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    width: 85%;
    margin: 0;
  }
`

export const StartButton = styled(Button as unknown as AnyStyledComponent)`
  display: block;
`

export const LessonGroupTitle = styled.h4`
  text-transform: uppercase;
  font-weight: bold;
`
export const LessonGroupContent = styled.div`
  padding-top: 1rem;
  padding-left: 0.25rem;
  margin-bottom: 1rem;
`

export const HoverMenu = styled((props) => <div {...props} />)`
  overflow: hidden;
  border-radius: 24px;
  background-color: var(--secondary-color);
  ${({ inline = false }: { inline?: boolean }) =>
    inline ? `margin-bottom: 1rem;` : `position: absolute; margin: 0 auto;`}
  width: fit-content;
  z-index: 5;

  ${({ verticalalign = 'bottom' }: { verticalalign?: 'bottom' | 'top' }) => [
    verticalalign === 'bottom' && `bottom: -18px;`,
    verticalalign === 'top' && `top: -18px;`,
  ]}

  ${({ align = 'center' }: { align?: 'center' | 'left' | 'right' }) => [
    align === 'center' && `left: 0; right: 0;`,
    align === 'left' && `left: 1rem; right: auto;`,
    align === 'right' && `left: auto; right: 1rem;`,
  ]}

  button {
    color: #fff !important;
    border: none;
    border-radius: 0;
    padding: 0.625rem 0.925rem !important;
    height: auto;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  button:first-child,
  button + button:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
  }

  
    
    ${({ verticalalign = 'bottom' }: { verticalalign?: 'bottom' | 'top' }) => [
      verticalalign === 'bottom' &&
        `@media screen and (max-width: 767px) {
          position: relative;
          max-width: 95vw;
          text-align: center;
          bottom: -1.5rem;
        }`,
    ]}}
  }
`
export const TabContent = styled.div`
  position: relative;
  z-index: 0;
  margin-top: -16px;
  padding: 1.5rem 1rem 1rem;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 4px 4px;
`
