import { Alert } from 'antd'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'

type StatusBarData = {
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
}

export const StatusBar = () => {
  const posthog = usePostHog()
  const [statusBar, setStatusBar] = useState<StatusBarData | undefined>()

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled('StatusBar')) {
        setStatusBar(
          posthog.getFeatureFlagPayload('StatusBar') as StatusBarData
        )
      }
    })
  }, [])

  return statusBar && APP_ENV === 'production' ? (
    <Alert
      message={statusBar.message}
      type={
        ['success', 'info', 'warning', 'error'].includes(statusBar.type)
          ? statusBar.type
          : 'info'
      }
      banner
      closable
      style={{ textAlign: 'center' }}
    />
  ) : null
}
