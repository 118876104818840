/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  MailOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { Key, SortOrder, SorterResult } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { formatDuration } from '@lms-shared-patterns/utils'
import {
  AssignCourseMutation,
  DeleteAssignmentMutation,
  UpdateAssignmentMutation,
  UserCourseActivity,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { LoadSection } from '../../../core/components/LoadScreen'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { CourseAssignmentModal } from '../../../courses/components/course-assignment-modal/CourseAssignmentModal'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { ExportButton } from '../../../shared/components/export-button/ExportButton'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { UserCoursesReport } from '../../components/UserCoursesReport'

import ASSIGN_COURSE_MUTATION from './../../../courses/mutations/assign-course.graphql'
import DELETE_ASSIGNMENT_MUTATION from './../../mutations/delete-assignment.graphql'
import UPDATE_ASSIGNMENT_MUTATION from './../../mutations/update-assignment.graphql'
import { Table } from './Assignments.style'

interface AssignmentReminder {
  date: Date
  time_amount: number
  time_unit: 'hours' | 'days' | 'weeks'
}

interface Assignee {
  _id: string
  name: string
}

interface Course {
  _id: string
  name: string
  duration: number
}
interface Assignment {
  _id: string
  key: string
  created: string
  assignee: { _id: string; name: string; branch_id: string }
  assignor: { _id: string; name: string }
  course: {
    _id: string
    name: string
  }
  message: string
  mandatory: boolean
  deadline: string
  activity?: UserCourseActivity
  reminders?: AssignmentReminder[]
}

interface TablePagination {
  current?: number | undefined
  pageSize?: number
}
interface TableFilters {
  'assignee._id': string | null
  'course._id': string | null
  mandatory: string[] | null
  activity: string[] | null
}
interface TableSorter {
  field: Key | readonly Key[]
  order: 'ascend' | 'descend' | ''
  columnKey: Key | undefined
}

export const AssignmentsReport = () => {
  const [form] = useForm()

  const [tableParams, setTableParams] = useState<{
    pagination: TablePagination
    filters: TableFilters
    sorter: TableSorter
  }>({
    pagination: { current: 1, pageSize: 10 },
    filters: {
      'assignee._id': null,
      'course._id': null,
      mandatory: null,
      activity: null,
    },
    sorter: { field: [], order: '', columnKey: '' },
  })

  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)

  const [updateSubject, setUpdateSubject] = useState<Assignment>()
  const [assignmentModalVisible, setAssignmentModalVisible] = useState(false)

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const ability = useContext(AbilityContext)

  const [assignCourse, { loading: assigning }] =
    useMutation<AssignCourseMutation>(ASSIGN_COURSE_MUTATION)

  const [deleteAssignment, { loading: deleting }] =
    useMutation<DeleteAssignmentMutation>(DELETE_ASSIGNMENT_MUTATION, {
      variables: {
        id: deletePopConfirmIndex,
      },
    })

  const [updateAssignment, { loading: updating }] =
    useMutation<UpdateAssignmentMutation>(UPDATE_ASSIGNMENT_MUTATION)

  const [{ data, loading }, refetch] = useAxios<{
    assignments: Assignment[]
    assignees: Assignee[]
    courses: Course[]
    total: number
  }>({
    url: `/api/activity/assignments`,
    params: {
      section_id: hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
      status: JSON.stringify([]),
      courseNames: JSON.stringify([]),
      assigneeNames: JSON.stringify([]),
      mandatory: JSON.stringify([]),
      sort_by: 'created',
      sort_order: -1,
      page: 1,
      pageSize: 10,
    },
  })

  useEffect(() => {
    if (updateSubject) {
      setAssignmentModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  useEffect(() => {
    setTableParams({
      pagination: { current: 1, pageSize: 10 },
      filters: {
        'assignee._id': null,
        'course._id': null,
        mandatory: null,
        activity: null,
      },
      sorter: { field: [], order: '', columnKey: '' },
    })
  }, [hierarchyFilter])

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: TableFilters,
    sorter: SorterResult<Assignment>
  ) => {
    setTableParams({
      pagination: pagination || { current: 1, pageSize: 10 },
      filters,
      ...(sorter.field && sorter.order
        ? {
            sorter: {
              field: sorter.field,
              order: sorter.order,
              columnKey: sorter.columnKey,
            },
          }
        : { sorter: { field: '', order: '', columnKey: '' } }),
    })

    refetch({
      params: {
        section_id:
          hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
        pagination: pagination.current,
        status: JSON.stringify(filters.activity),
        courseNames: JSON.stringify(filters['course._id']),
        assigneeNames: JSON.stringify(filters ? filters['assignee._id'] : []),
        mandatory: JSON.stringify(filters.mandatory),
        sort_by: Array.isArray(sorter?.field)
          ? sorter?.field?.join('.')
          : sorter.field,
        sort_order: getSortBoolean(sorter),
        page: pagination?.current,
        pageSize: pagination?.pageSize,
      },
    })
  }

  const getSortBoolean = (sorter) =>
    sorter.order === 'ascend' ? 1 : sorter.order === 'descend' ? -1 : undefined

  const columns: ColumnsType<Assignment> = [
    {
      title: t({
        id: 'reports.assignments.table.created',
        message: 'Toegewezen op',
      }),
      dataIndex: 'created',
      key: 'created',
      render: (date: string) => (
        <Tooltip title={dayjs(date).format('DD/MM/YYYY HH:mm')}>
          {dayjs(date).format('DD/MM/YYYY')}
        </Tooltip>
      ),
      sortOrder:
        tableParams?.sorter?.columnKey === 'created'
          ? (tableParams.sorter.order as SortOrder)
          : null,
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
    },
    {
      title: t({
        id: 'reports.assignments.table.assignee',
        message: 'Toegewezen aan',
      }),
      dataIndex: ['assignee', 'name'],
      key: 'assignee._id',
      render: (name: string, record: Assignment) => (
        <>
          {name}
          {record.message && (
            <span style={{ marginLeft: 6 }}>
              <Tooltip title={record.message}>
                <MailOutlined style={{ fontSize: 12 }} />
              </Tooltip>
            </span>
          )}
        </>
      ),
      filters:
        data && data?.assignees
          ? data.assignees
              .map(({ name, _id }) => ({ text: name, value: _id }))
              .sort((a, b) => a.text.localeCompare(b.text))
          : [],

      filteredValue: tableParams.filters['assignee._id']
        ? [...tableParams.filters['assignee._id']]
        : null,
      sorter: (a, b) => a.assignee.name.localeCompare(b.assignee.name),
      sortOrder:
        tableParams?.sorter?.columnKey === 'assignee._id'
          ? (tableParams.sorter.order as SortOrder)
          : null,
    },
    {
      title: t({
        id: 'reports.assignments.table.course',
        message: 'Opleiding',
      }),
      children: [
        {
          title: t({
            id: 'reports.assignments.table.course_name',
            message: 'Naam',
          }),
          dataIndex: ['course', 'name'],
          key: 'course._id',
          filters: data
            ? data.courses
                .map(({ name, _id }) => ({ text: name, value: _id }))
                .sort((a, b) => a.text.localeCompare(b.text))
            : [],
          filteredValue: tableParams.filters['course._id']
            ? [...tableParams.filters['course._id']]
            : null,
          sorter: (a, b) => a.course.name.localeCompare(b.course.name),
          sortOrder:
            tableParams?.sorter?.columnKey === 'course._id'
              ? (tableParams.sorter.order as SortOrder)
              : null,
        },
        {
          title: t({
            id: 'reports.assignments.table.course_duration',
            message: 'Duurtijd',
          }),
          dataIndex: ['course', 'duration'],
          align: 'center',
          render: (duration) => {
            return <>{formatDuration(duration * 60)}</>
          },
          key: 'course._id',
        },
      ],
    },
    {
      title: t({
        id: 'reports.assignments.table.mandatory',
        message: 'Verplicht',
      }),
      dataIndex: 'mandatory',
      key: 'mandatory',
      align: 'center',
      render: (mandatory: boolean) => {
        return mandatory ? <CheckOutlined /> : <CloseOutlined />
      },
      filters: [
        {
          text: t({
            id: 'reports.assignments.table.mandatory',
            message: 'Verplicht',
          }),
          value: 'mandatory',
        },
        {
          text: t({
            id: 'reports.assignments.table.optional',
            message: 'Optioneel',
          }),
          value: 'optional',
        },
      ],
      filteredValue: tableParams.filters.mandatory || null,
    },
    {
      title: t({
        id: 'reports.assignments.table.deadline',
        message: 'Deadline',
      }),
      dataIndex: 'deadline',
      key: 'deadline',
      align: 'center',
      render: (_: string, record) => {
        const { deadline, activity } = record
        if (!deadline) return null
        if (activity?.completed) return dayjs(deadline).format('DD/MM/YYYY')
        return (
          <Tooltip title={dayjs(deadline).format('DD/MM/YYYY')}>
            {deadline
              ? dayjs().diff(deadline) > 0
                ? t({
                    id: 'reports.assignments.table.expired',
                    message: `${dayjs().to(deadline, true)} over tijd`,
                  })
                : t({
                    id: 'reports.assignments.table.expiring',
                    message: `nog ${dayjs().to(deadline, true)}`,
                  })
              : null}
          </Tooltip>
        )
      },
      sorter: (a, b) => {
        if (!a.deadline) return 1
        if (!b.deadline) return -1
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
      },
      sortOrder:
        tableParams?.sorter?.columnKey === 'deadline'
          ? (tableParams.sorter.order as SortOrder)
          : null,
    },
    {
      title: t({
        id: 'reports.assignments.table.status',
        message: 'Status',
      }),
      dataIndex: ['activity'],
      key: 'activity',
      align: 'center',
      render: (_: string, record) => {
        const { activity } = record

        if (!activity) {
          return (
            <span>
              <Trans id="reports.assignments.table.status.not_started">
                Nog niet gestart
              </Trans>
            </span>
          )
        }

        if (activity.completed)
          return (
            <span>
              <Trans id="reports.assignments.table.status.completed">
                Voltooid
              </Trans>
            </span>
          )

        return (
          <span>
            <Trans id="reports.assignments.table.status.in_progress">
              In uitvoering
            </Trans>
          </span>
        )
      },
      filters: [
        {
          text: t({
            id: 'reports.assignments.table.status.not_started',
          }),
          value: 'not_started',
        },
        {
          text: t({
            id: 'reports.assignments.table.status.in_progress',
          }),
          value: 'in_progress',
        },
        {
          text: t({
            id: 'reports.assignments.table.status.completed',
          }),
          value: 'completed',
        },
      ],
      filteredValue: tableParams.filters.activity || null,
      sorter: (a, b) => {
        if (!a.activity && !b.activity) return 0
        if (!a.activity) return -1
        if (!b.activity) return 1
        if (a.activity.completed && !b.activity.completed) return 1
        if (!a.activity.completed && b.activity.completed) return -1
        return 0
      },
      sortOrder:
        tableParams.sorter?.columnKey === 'activity'
          ? (tableParams.sorter.order as SortOrder)
          : undefined,
    },
    {
      title: t({
        id: 'reports.assignments.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 60,
      render: (_, record: Assignment) => (
        <ActionButtonWrapper onClick={(e) => e.stopPropagation()}>
          <Can I={PermissionAction.UPDATE} an={PermissionObjectType.ASSIGNMENT}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.assignments.find((a) =>
                      new ObjectId(a._id).equals(record._id)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} an={PermissionObjectType.ASSIGNMENT}>
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'reports.assignments.action.delete.title',
                  message:
                    'Ben je zeker dat je deze toewijzing wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record._id &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onCancel={() => setDeletePopConfirmVisible(false)}
                onConfirm={() => {
                  deleteAssignment({
                    variables: {
                      id: record._id,
                    },
                    onCompleted: () =>
                      refetch({
                        params: {
                          section_id:
                            hierarchyFilter ||
                            sessionStorage.getItem('aa_report_filter'),
                          pagination: tableParams.pagination.current,
                          status: JSON.stringify(tableParams.filters.activity),
                          courseNames: JSON.stringify(
                            tableParams.filters['course._id']
                          ),
                          assigneeNames: JSON.stringify(
                            tableParams.filters
                              ? tableParams.filters['assignee._id']
                              : []
                          ),
                          mandatory: JSON.stringify(
                            tableParams.filters.mandatory
                          ),
                          sort_by: Array.isArray(tableParams.sorter.field)
                            ? tableParams.sorter.field.join('.')
                            : tableParams.sorter.field,
                          sort_order: getSortBoolean(tableParams.sorter),
                          page: tableParams.pagination?.current,
                          pageSize: tableParams.pagination?.pageSize,
                        },
                      }),
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'reports.assignments.action.delete.success',
                          message: 'Toewijzing succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
              >
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    setDeletePopConfirmIndex(record._id)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  return (
    <>
      <Content style={{ backgroundColor: '#FFF' }}>
        <PageHeader
          ghost={false}
          className="site-page-header"
          title={t({
            id: 'reports.assignments.title',
            message: 'Toegewezen opleidingen',
          })}
          extra={
            <Space style={{ marginBottom: 24 }}>
              {treeData.length > 0 && (
                <TreeSelect
                  placeholder={t({
                    id: 'reports.filter.hierarchy',
                    message: 'Filter op afdeling',
                  })}
                  treeDefaultExpandAll={true}
                  treeLine={true}
                  showSearch
                  treeDataSimpleMode
                  style={{ width: 250 }}
                  dropdownMatchSelectWidth={false}
                  filterTreeNode={(input, option) =>
                    (option.title as string)
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={treeData}
                  loading={treeLoading}
                  allowClear={ability.cannot(
                    PermissionAction.READ,
                    PermissionObjectType.REPORT
                  )}
                  treeNodeLabelProp="label"
                  value={hierarchyFilter}
                  onChange={(value) => {
                    setSessionStorageItem('aa_report_filter', value)
                    setHierarchyFilter(value)
                  }}
                />
              )}
              <Can
                I={PermissionAction.CREATE}
                an={PermissionObjectType.ASSIGNMENT}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setUpdateSubject(undefined)
                    setAssignmentModalVisible(true)
                  }}
                >
                  <PlusOutlined />{' '}
                  <Trans id="reports.assignments.action.assign">
                    Opleidingen toewijzen
                  </Trans>
                </Button>
              </Can>
            </Space>
          }
        ></PageHeader>
        <Content>
          {!data && <LoadSection />}
          {data && (
            <Table
              bordered
              pagination={
                {
                  ...tableParams.pagination,
                  total: data.total,
                } as TablePaginationConfig
              }
              onChange={handleTableChange}
              size="small"
              loading={loading}
              dataSource={data.assignments}
              rowKey="_id"
              expandable={{
                expandedRowRender: (record) => {
                  const { assignee, course } = record as Assignment
                  return (
                    <UserCoursesReport
                      userId={assignee._id}
                      branchId={assignee.branch_id}
                      courseId={course._id}
                    />
                  )
                },
                expandRowByClick: true,
                rowExpandable(record) {
                  const { activity } = record as Assignment
                  return !!activity
                },
              }}
              locale={{
                emptyText: t({
                  id: 'reports.assignments.table.empty',
                  message: 'Geen toegewezen opleidingen gevonden',
                }),
                filterConfirm: 'Filter',
                filterReset: 'Resetten',
              }}
              onRow={(record) => {
                const { activity, deadline } = record as Assignment
                if (!activity?.completed && dayjs().diff(deadline) > 0)
                  return {
                    style: {
                      borderLeft: '3px solid var(--ant-error-color)',
                    },
                    className: 'ant-table-row-error',
                  }

                if (activity?.completed)
                  return {
                    style: {
                      borderLeft: '3px solid var(--ant-success-color)',
                    },
                    className: 'ant-table-row-success',
                  }

                return {
                  style: { borderLeft: '3px solid var(--ant-warning-color)' },
                }
              }}
              // @ts-ignore
              columns={columns}
              footer={() => {
                return (
                  <div style={{ textAlign: 'right' }}>
                    <Space>
                      <span>
                        <Trans id="reports.courses_report.action.export">
                          Exporteer naar:
                        </Trans>{' '}
                      </span>
                      {['xlsx', 'csv', 'json'].map((type, index) => (
                        <ExportButton
                          key={index}
                          url={`/api/export/assignments`}
                          params={{
                            section_id: hierarchyFilter,

                            pagination: tableParams.pagination.current,
                            status: JSON.stringify(
                              tableParams.filters.activity
                            ),
                            courseNames: JSON.stringify(
                              tableParams.filters['course._id']
                            ),
                            assigneeNames: JSON.stringify(
                              tableParams.filters
                                ? tableParams.filters['assignee._id']
                                : []
                            ),
                            mandatory: JSON.stringify(
                              tableParams.filters.mandatory
                            ),
                            sort_by: Array.isArray(tableParams.sorter.field)
                              ? tableParams.sorter.field.join('.')
                              : tableParams.sorter.field,
                            sort_order: getSortBoolean(tableParams.sorter),
                            page: 1,
                            pageSize: 100000,
                          }}
                          type={type}
                          name="assignments"
                          dates={[
                            'created',
                            'deadline',
                            'started',
                            'last_activity',
                            'completed',
                          ]}
                        />
                      ))}
                    </Space>
                  </div>
                )
              }}
            />
          )}
        </Content>
      </Content>
      <CourseAssignmentModal
        key={updateSubject?._id}
        subject={
          updateSubject
            ? {
                mandatory: updateSubject.mandatory,
                deadline: updateSubject.deadline
                  ? dayjs(updateSubject.deadline)
                  : undefined,
                message: updateSubject.message,
                reminders: updateSubject.reminders?.map((reminder) => ({
                  ...reminder,
                  date: dayjs(reminder.date),
                })),
              }
            : undefined
        }
        assigning={updating || assigning}
        visible={assignmentModalVisible}
        onVisibilityChange={(state) => {
          setAssignmentModalVisible(state)
          if (state) {
            form.resetFields()
          } else {
            setUpdateSubject(undefined)
          }
        }}
        onAssign={(variables) => {
          return assignCourse({
            variables,
          }).then(() => {
            return refetch({
              params: {
                section_id:
                  hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
                pagination: tableParams.pagination.current,
                status: JSON.stringify(tableParams.filters.activity),
                courseNames: JSON.stringify(tableParams.filters['course._id']),
                assigneeNames: JSON.stringify(
                  tableParams.filters ? tableParams.filters['assignee._id'] : []
                ),
                mandatory: JSON.stringify(tableParams.filters.mandatory),
                sort_by: Array.isArray(tableParams.sorter.field)
                  ? tableParams.sorter.field.join('.')
                  : tableParams.sorter.field,
                sort_order: getSortBoolean(tableParams.sorter),
                page: tableParams.pagination?.current,
                pageSize: tableParams.pagination?.pageSize,
              },
            })
          })
        }}
        onUpdate={(variables) => {
          return updateAssignment({
            variables: {
              ...variables,
              id: updateSubject!._id,
            },
          }).then(() => {
            return refetch({
              params: {
                section_id:
                  hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
                pagination: tableParams.pagination.current,
                status: JSON.stringify(tableParams.filters.activity),
                courseNames: JSON.stringify(tableParams.filters['course._id']),
                assigneeNames: JSON.stringify(
                  tableParams.filters ? tableParams.filters['assignee._id'] : []
                ),
                mandatory: JSON.stringify(tableParams.filters.mandatory),
                sort_by: Array.isArray(tableParams.sorter.field)
                  ? tableParams.sorter.field.join('.')
                  : tableParams.sorter.field,
                sort_order: getSortBoolean(tableParams.sorter),
                page: tableParams.pagination?.current,
                pageSize: tableParams.pagination?.pageSize,
              },
            })
          })
        }}
      />
    </>
  )
}
