/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import { EyeOutlined, EditOutlined, TagOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import {
  Button,
  Form,
  Modal,
  PageHeader,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType, CompareFn } from 'antd/lib/table/interface'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { BaseSelectRef } from 'rc-select'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import {
  BranchCourseAttributesQuery,
  CourseTag,
  CourseTagsQuery,
  Image,
  UpdateBranchCourseAttributesMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { arraySort, defaultSort } from '../../../shared/utils/sort'
import BRANCH_COURSE_ATTRIBUTES_QUERY from '../../queries/branch-course-attributes.graphql'

import COURSE_TAGS_QUERY from './../../../courses/queries/course-tags.graphql'
import UPDATE_BRANCH_COURSE_ATTRIBUTES_MUTATION from './../../mutations/update-branch-course-attributes.graphql'
import { CourseThumbnail } from './BranchCourses.style'

extend(utc)
extend(timezone)
extend(relativeTime)

interface BranchCourseRow {
  key: string
  slug: string
  thumbnail?: Partial<Image>
  name: string
  lecturer?: string
  tags: CourseTag[]
}

export const BranchCourses = () => {
  const [attributesModalVisible, setAttributesModalVisible] =
    useState<boolean>(false)
  const [attributesModalSubject, setAttributesModalSubject] = useState<string>()
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [pageSize, setPageSize] = useQueryParam('size', NumberParam)
  const [searchTerm, setSearchTerm] = useQueryParam('q', StringParam)
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const [attributesForm] = useForm()
  const tagSelector = useRef<BaseSelectRef | null>(null)
  const [tagSelectorOpen, setTagSelectorOpen] = useState(false)

  const { data: courseTags } = useQuery<CourseTagsQuery>(COURSE_TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { data, loading } = useQuery<BranchCourseAttributesQuery>(
    BRANCH_COURSE_ATTRIBUTES_QUERY,
    {
      variables: {
        page,
        limit: pageSize || 10,
        q: searchTerm,
      },
    }
  )

  const [update, { loading: updating }] =
    useMutation<UpdateBranchCourseAttributesMutation>(
      UPDATE_BRANCH_COURSE_ATTRIBUTES_MUTATION,
      {
        variables: {
          course_id: attributesModalSubject,
        },
        refetchQueries: ['branchCourseAttributes', 'courseTags'],
      }
    )

  const courses = useMemo(() => {
    return data?.fetchBranchCourseAttributes.results.map<BranchCourseRow>(
      (item) => ({
        key: item._id,
        slug: item.slug,
        thumbnail: item.image || undefined,
        name: item.name,
        lecturer: item.lecturer || undefined,
        tags: item.tags || [],
      })
    )
  }, [data])

  const selectedCourse = useMemo(() => {
    return courses?.find((c) => c.key === attributesModalSubject)
  }, [attributesModalSubject, courses])

  useEffect(() => {
    if (attributesModalSubject) {
      setAttributesModalVisible(true)
    }
  }, [attributesModalSubject])

  useEffect(() => {
    if (attributesModalVisible) attributesForm.resetFields()
  }, [attributesModalVisible, attributesForm])

  const columns: ColumnsType<BranchCourseRow> = [
    {
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (thumbnail: Partial<Image>) =>
        thumbnail ? (
          <CourseThumbnail
            src={thumbnail?.url || ''}
            alt={thumbnail?.alt || ''}
            width={thumbnail.width}
            height={thumbnail.height}
          />
        ) : null,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.courses.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.courses.table.lecturer',
        message: 'Docent',
      }),
      dataIndex: 'lecturer',
      key: 'lecturer',
      sorter: defaultSort('lecturer') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.courses.table.tags',
        message: 'Tags',
      }),
      dataIndex: 'tags',
      key: 'tags',
      render: (tags: CourseTag[]) =>
        tags.map((tag) => <Tag key={tag._id}>{tag.name}</Tag>),
      sorter: arraySort('tags') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.roles.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      width: 125,
      render: (_: string, record: BranchCourseRow) => {
        const course = data?.fetchBranchCourseAttributes.results.find(
          (c) => c._id === record.key
        )

        if (!course) return false

        const canUpdate =
          ability.can(PermissionAction.UPDATE, PermissionObjectType.COURSE) ||
          (ability.can(
            PermissionAction.UPDATE,
            subject(PermissionObjectType.BRANCH_COURSE, course)
          ) &&
            !course.readonly) ||
          (ability.can(
            PermissionAction.UPDATE,
            subject(PermissionObjectType.OWN_COURSE, course)
          ) &&
            !course.readonly)

        return (
          <ActionButtonWrapper>
            <Can
              I={PermissionAction.UPDATE}
              a={PermissionObjectType.BRANCH_COURSE_ATTRIBUTES}
            >
              <Tooltip
                title={t({
                  id: 'actions.tag',
                  message: 'Taggen',
                })}
              >
                <Button
                  onClick={() => {
                    setAttributesModalSubject(record.key)
                  }}
                  shape="circle"
                  icon={<TagOutlined />}
                />
              </Tooltip>
            </Can>
            <Can I={PermissionAction.READ} a={PermissionObjectType.COURSE}>
              <Tooltip
                title={t({
                  id: 'actions.show',
                  message: 'Weergeven',
                })}
              >
                <Button
                  onClick={() =>
                    navigate(`/courses/${encodeURIComponent(record.slug)}`)
                  }
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </Can>
            {canUpdate && (
              <Tooltip
                title={t({
                  id: 'actions.edit',
                  message: 'Bewerken',
                })}
              >
                <Button
                  onClick={() => navigate(`/courses/${record.key}/edit`)}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
          </ActionButtonWrapper>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.courses.heading',
          message: 'Opleidingen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.courses.search',
              message: 'Zoeken',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            defaultValue={searchTerm || undefined}
            style={{ width: 200 }}
          />,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.courses.table.empty',
            message: 'Geen opleidingen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        dataSource={courses}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page || 1,
          pageSize: pageSize || 10,
          onChange: (page: number) => {
            setPage(page)
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
          },
          onShowSizeChange(_, size) {
            setPage(1)
            setPageSize(size)
          },
          total: data?.fetchBranchCourseAttributes.count,
        }}
      />
      <Modal
        forceRender
        okText={t({
          id: 'action.update',
          message: 'Wijzigen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        open={!!attributesModalVisible}
        title={selectedCourse?.name || ''}
        onOk={() => attributesForm.submit()}
        onCancel={() => setAttributesModalVisible(false)}
        okButtonProps={{ loading: updating }}
        afterClose={() => {
          setAttributesModalSubject(undefined)
          attributesForm.resetFields()
        }}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          key={selectedCourse?.key}
          disabled={updating}
          form={attributesForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ tags: selectedCourse?.tags.map((tag) => tag.name) }}
          onFinish={async (values) => {
            await update({
              variables: {
                attributes: {
                  ...values,
                  tags: values.tags.map(
                    (tag: string) =>
                      courseTags?.fetchCourseTags.find((t) => t.name === tag)
                        ?._id || tag
                  ),
                },
              },
            })
            setAttributesModalVisible(false)
          }}
        >
          <Form.Item
            name={'tags'}
            label={t({
              id: 'courses.course_edit.form.label.tags',
              message: 'Tags',
            })}
          >
            <Select
              ref={tagSelector}
              mode="tags"
              allowClear
              options={courseTags?.fetchCourseTags?.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))}
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase()) ||
                false
              }
              onDropdownVisibleChange={(flag) => setTagSelectorOpen(flag)}
              open={tagSelectorOpen}
              notFoundContent={null}
              onSelect={() => setTagSelectorOpen(false)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
