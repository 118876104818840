import { useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Space,
  Table,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import DOMPurify from 'dompurify'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  ArticleQuery,
  AutoTranslateArticleMutation,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { useLanguages } from '../../../shared/hooks/use-languages'
import AUTO_TRANSLATE_ARTICLE_MUTATION from '../../mutations/auto-translate-article.graphql'
import TRANSLATE_ARTICLE_MUTATION from '../../mutations/translate-article.graphql'
import ARTICLE_QUERY from '../../queries/article.graphql'

const ArticleTranslationFormConfig = [
  {
    name: 'title',
    label: t({
      id: 'article.title',
      message: 'Title',
    }),
  },
  {
    name: 'intro',
    label: t({
      id: 'article.intro',
      message: 'Article intro',
    }),
    type: 'textarea',
  },
  {
    name: 'content',
    label: t({
      id: 'article.content',
      message: 'Content',
    }),
    type: 'richtext',
  },
]

export const ArticleTranslation = () => {
  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const { id, lang } = useParams()
  const { data: article, loading } = useQuery<ArticleQuery>(ARTICLE_QUERY, {
    variables: {
      id,
    },
    onCompleted: () => {
      form.resetFields()
    },
  })
  const { languages } = useLanguages()
  const [translate, { loading: translating }] = useMutation(
    TRANSLATE_ARTICLE_MUTATION,
    {
      variables: {
        article_id: id,
        language: lang,
      },
    }
  )
  const [autoTranslate, { loading: autoTranslating }] =
    useMutation<AutoTranslateArticleMutation>(AUTO_TRANSLATE_ARTICLE_MUTATION, {
      variables: {
        article_id: id,
        language: lang,
      },
      refetchQueries: ['article'],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'settings.article.action.translate.auto.success',
            message: 'Automatische vertaling succesvol opgeslagen',
          }),
        })
        setFormDirty(false)
      },
      onError: () => {
        notification.error({
          message: t({
            id: 'settings.article.action.translate.auto.error',
            message: 'Er liep iets fout bij het automatisch vertalen',
          }),
        })
      },
    })

  const handleTranslate = async () => {
    const fields = await form.validateFields()
    await translate({
      variables: {
        translation: fields,
      },
    })
      .then(() => {
        notification.success({
          message: t({
            id: 'settings.article.action.translate.success',
            message: 'Vertaling succesvol opgeslagen',
          }),
        })
        setFormDirty(false)
      })
      .catch((error) => {
        notification.error({
          message: t({
            id: 'settings.article.action.translate.error',
            message: 'Er liep iets fout bij het opslaan van de vertaling',
          }),
        })
        console.error(error)
      })
  }

  const translation = article?.fetchArticleById.translations?.find(
    (t) => t.language === lang
  )

  useEffect(() => {
    form.resetFields()
  }, [form, translation])

  if (!article || loading) return <LoadScreen />

  const columns = [
    {
      title: t({
        id: 'settings.courses.table.field',
        message: 'Veld',
      }),
      dataIndex: 'field',
      key: 'field',
      width: '20%',
    },
    {
      title:
        languages.find((l) => article.fetchArticleById.language === l.code)
          ?.name ||
        t({
          id: 'translation.original',
          message: 'Origineel',
        }),
      dataIndex: 'original',
      key: 'original',
      width: '40%',
      render: (field, record) => {
        if (record.type === 'richtext') {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(field) }}
            />
          )
        }
        return field
      },
    },
    {
      title: languages.find((l) => lang === l.code)?.name,
      dataIndex: 'translation',
      key: 'translation',
      render: (field, record) => {
        if (record.type === 'textarea') {
          return (
            <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
              <Input.TextArea />
            </Form.Item>
          )
        }
        if (record.type === 'richtext') {
          return (
            <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
              <RichEditor
                disableTextStyles
                onChange={(value) => {
                  form.setFieldsValue({ [field]: value })
                  setFormDirty(true)
                }}
              />
            </Form.Item>
          )
        }
        return (
          <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
            <Input />
          </Form.Item>
        )
      },
      width: '40%',
    },
  ]

  const data = ArticleTranslationFormConfig.map((field) => ({
    key: field.name,
    field: field.label,
    original: article.fetchArticleById[field.name],
    translation: field.name,
    type: field.type,
  }))

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleTranslate}
      autoComplete="off"
      initialValues={translation || {}}
      onChange={() => setFormDirty(true)}
    >
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          <Trans id="settings.courses.title.translate">
            Vertaling van &ldquo;{article.fetchArticleById.title}&rdquo; naar
            &ldquo;
            {languages.find((l) => lang === l.code)?.name}&rdquo;
          </Trans>
        }
        extra={
          <Space>
            <Button
              onClick={() => {
                window.history.back()
              }}
            >
              <Trans id="actions.go_back">Ga terug</Trans>
            </Button>
            <Button
              loading={autoTranslating}
              onClick={() => {
                autoTranslate()
              }}
            >
              <Trans id="actions.auto_translate">Automatisch vertalen</Trans>
            </Button>
            <Button
              loading={translating}
              disabled={!formDirty}
              type="primary"
              htmlType={'submit'}
            >
              <Trans id="actions.save">Opslaan</Trans>
            </Button>
          </Space>
        }
      />
      <Table
        className="translation-table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Form>
  )
}
