import { i18n } from '@lingui/core'

import { UserLanguage } from '@lms-shared-patterns/models'
export const locales = {
  'nl-BE': 'Nederlands (België)',
  en: 'English',
  fr: 'Français',
}
export const defaultLocale =
  (sessionStorage.getItem('aa_lms_lang') as UserLanguage) || 'nl-BE'

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(
    `./locales/${locale === 'dev' ? 'nl-BE' : locale}.po`
  )

  if (locale === 'dev') {
    const testMessages = {}
    for (const key in messages) {
      testMessages[key] = key
    }
    i18n.load(locale, testMessages)
  } else {
    i18n.load(locale, messages)
  }
  i18n.activate(locale)
}
