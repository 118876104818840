export const formatDuration = (duration: number) => {
  let formatted = ''
  const seconds = Math.floor(duration % 60)
  const minutes = Math.floor(duration / 60) % 60
  const hours = Math.floor(duration / 3600)
  if (hours) {
    formatted = `${hours} u`
  }
  if (minutes) {
    formatted = `${formatted} ${minutes} min`
  }
  if (!hours && !minutes) {
    formatted = seconds ? `${seconds} sec` : `0 min`
  }
  return formatted.trim()
}
