/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default */
/* eslint-disable unicorn/no-useless-undefined */
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import Tus from '@uppy/tus'
import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  JobContractType,
  JobEducationLevel,
  JobAccessibilityOptions,
  JobBenefits,
  JobOtherCriteria,
  JobSchedule,
  JobType,
  StudentJobSchedule,
} from '@lms-shared-patterns/models/job-type.model'
import { ImageMimeType } from '@lms-shared-patterns/models/mime-types.model'
import {
  BranchJobOpportunitiesQuery,
  CloudflareVideo,
  CloudflareVideosQuery,
  CreateBranchFirstJobOpportunityMutation,
  CreateBranchInternshipOpportunityMutation,
  CreateBranchStudentJobOpportunityMutation,
  DeleteBranchJobOpportunityMutation,
  UpdateBranchFirstJobOpportunityMutation,
  UpdateBranchInternshipOpportunityMutation,
  UpdateBranchStudentJobOpportunityMutation,
  ImageInput,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { Can } from '../../../auth/components/Can'
import { renderJobType } from '../../../jobs/utils/render-job-type'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { CharacterLimitHelper } from '../../../shared/components/character-limit-helper/CharacterLimitHelper'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { getBase64 } from '../../../shared/utils/get-base64'
import { dateSort, defaultSort } from '../../../shared/utils/sort'
import { characterLimitValidator } from '../../../shared/validators/character-limit-validator'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'
import { urlFreeFieldValidator } from '../../../shared/validators/url-free-field.validator'
import CREATE_FIRST_JOB_OPPORTUNITY_MUTATION from '../../mutations/create-branch-first-job-opportunity.graphql'
import CREATE_INTERNSHIP_OPPORTUNITY_MUTATION from '../../mutations/create-branch-internship-opportunity.graphql'
import CREATE_STUDENT_JOB_OPPORTUNITY_MUTATION from '../../mutations/create-branch-student-job-opportunity.graphql'

import CLOUDFLARE_VIDEOS_QUERY from './../../../units/queries/cloudflare-videos.graphql'
import DELETE_JOB_OPPORTUNITY_MUTATION from './../../mutations/delete-branch-job-opportunity.graphql'
import UPDATE_FIRST_JOB_OPPORTUNITY_MUTATION from './../../mutations/update-branch-first-job-opportunity.graphql'
import UPDATE_HIRING_ORGANISATION_MUTATION from './../../mutations/update-branch-hiring-organisation.graphql'
import UPDATE_INTERNSHIP_OPPORTUNITY_MUTATION from './../../mutations/update-branch-internship-opportunity.graphql'
import UPDATE_STUDENT_JOB_OPPORTUNITY_MUTATION from './../../mutations/update-branch-student-job-opportunity.graphql'
import JOB_OPPORTUNITIES_QUERY from './../../queries/branch-job-opportunities.graphql'
import { HiringOrganisationForm } from './HiringOrganisationForm'

extend(utc)
extend(timezone)
extend(relativeTime)

interface JobRow {
  key: string
  published: Date
  title: string
  city: string
  updated?: Date
}

const RICH_EDITOR_MAX_CHARACTERS = 1000

export const Jobs = () => {
  const [imageField, setImageField] = useState<ImageInput | null>()
  const [page, setPage] = useState(1)
  const [searchTerm] = useState('')
  const [jobModalVisible, setJobModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<BranchJobOpportunitiesQuery['fetchBranchJobOpportunities'][0]>()
  const [duplicateValues, setDuplicateValues] =
    useState<BranchJobOpportunitiesQuery['fetchBranchJobOpportunities'][0]>()

  const branch = useBranch()
  const navigate = useNavigate()

  const [jobForm] = useForm()

  /** Video uploading */
  const [uppyClient, setUppyClient] = useState<Uppy>()
  const [videoUploaderVisible, setVideoUploaderVisible] = useState(false)
  const [libraryVideo, setLibraryVideo] = useState<
    CloudflareVideo | undefined
  >()

  /** Image uploading */
  const [uploading, setUploading] = useState<boolean>(false)

  /** Watched form fields */
  const title: JobType = Form.useWatch('title', jobForm)
  const tasks = Form.useWatch('tasks', jobForm)
  const competencies = Form.useWatch('competencies', jobForm)
  const why = Form.useWatch('why', jobForm)
  const published = Form.useWatch('published', jobForm)
  const videoExternalUrl = Form.useWatch('external_url', jobForm)

  useEffect(() => {
    const getUppyClient = async () => {
      return new Uppy({ debug: true, autoProceed: true }).use(Tus, {
        endpoint: `${
          import.meta.env.NX_BACKEND_URL
        }/api/files/generateCloudflareTusURL`,
        chunkSize: 150 * 1024 * 1024,
        async onBeforeRequest(req) {
          if (req.getURL().includes('generateCloudflareTusURL'))
            req.setHeader('Upload-Creator', branch?._id)
        },
        async onAfterResponse(_, res) {
          if (res.getHeader('stream-media-id')) {
            const cf_stream_id = res.getHeader('stream-media-id')
            jobForm.setFieldsValue({ cf_stream_id })
            setLibraryVideo(
              (v) =>
                ({
                  ...v,
                  uid: cf_stream_id,
                }) as CloudflareVideo
            )
          }
        },
      })
    }
    getUppyClient().then((client) => {
      client.on('file-added', (file) => {
        setLibraryVideo(
          (v) =>
            ({
              ...v,
              meta: { name: file.name },
            }) as CloudflareVideo
        )
      }),
        client.on('upload-success', () => {
          setVideoUploaderVisible(false)
          message.info(
            t({
              id: 'settings.jobs.form.upload.video.success',
              message: 'Video succesvol geüploadet.',
            })
          )
        })
      setUppyClient(client)
    })
    return () => uppyClient?.close({ reason: 'unmount' })
  }, [])

  const { data, loading } = useQuery<BranchJobOpportunitiesQuery>(
    JOB_OPPORTUNITIES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const [loadCloudflareVideos] = useLazyQuery<CloudflareVideosQuery>(
    CLOUDFLARE_VIDEOS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  )

  const [updateHiringOrganisation, { loading: updatingHiringOrganisation }] =
    useMutation(UPDATE_HIRING_ORGANISATION_MUTATION, {
      variables: {
        id: branch!._id,
      },
    })

  const [createFirstJob, { loading: creatingFirstJob }] =
    useMutation<CreateBranchFirstJobOpportunityMutation>(
      CREATE_FIRST_JOB_OPPORTUNITY_MUTATION
    )
  const [createStudentJob, { loading: creatingStudentJob }] =
    useMutation<CreateBranchStudentJobOpportunityMutation>(
      CREATE_STUDENT_JOB_OPPORTUNITY_MUTATION
    )
  const [createInternship, { loading: creatingInternship }] =
    useMutation<CreateBranchInternshipOpportunityMutation>(
      CREATE_INTERNSHIP_OPPORTUNITY_MUTATION
    )

  const [updateFirstJob, { loading: updatingFirstJob }] =
    useMutation<UpdateBranchFirstJobOpportunityMutation>(
      UPDATE_FIRST_JOB_OPPORTUNITY_MUTATION
    )

  const [updateStudentJob, { loading: updatingStudentJob }] =
    useMutation<UpdateBranchStudentJobOpportunityMutation>(
      UPDATE_STUDENT_JOB_OPPORTUNITY_MUTATION
    )

  const [updateInternship, { loading: updatingInternship }] =
    useMutation<UpdateBranchInternshipOpportunityMutation>(
      UPDATE_INTERNSHIP_OPPORTUNITY_MUTATION
    )

  const [deleteJob, { loading: deleting }] =
    useMutation<DeleteBranchJobOpportunityMutation>(
      DELETE_JOB_OPPORTUNITY_MUTATION
    )

  useEffect(() => {
    if (updateSubject) {
      setDuplicateValues(undefined)
      setJobModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (jobModalVisible) {
      jobForm.resetFields()
      setDuplicateValues(undefined)
    }
  }, [jobModalVisible, jobForm])

  const jobs = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranchJobOpportunities || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranchJobOpportunities || []
    ).map<JobRow>(
      (
        item: BranchJobOpportunitiesQuery['fetchBranchJobOpportunities'][0]
      ) => ({
        key: item._id,
        title: item.title,
        position: renderJobType(
          item.title,
          'position' in item ? item.position || undefined : undefined
        ),
        updated: item.updated,
        published: item.published,
        city: item.city,
      })
    )
  }, [data, searchTerm])

  const image = imageField || updateSubject?.image

  const handleUpload = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploading(true)
    } else if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setUploading(false)

        // Set to state here
        setImageField(info.file.response)
      })
    } else {
      setUploading(false)
    }
  }

  useEffect(() => {
    if (updateSubject?.cf_stream?.uid)
      setLibraryVideo({
        uid: updateSubject.cf_stream.uid,
        thumbnail: updateSubject.cf_stream.thumbnail,
        duration: updateSubject.cf_stream.duration,
        meta: { name: updateSubject.cf_stream.meta.name },
      } as CloudflareVideo)
    else if (duplicateValues?.cf_stream?.uid)
      setLibraryVideo({
        uid: duplicateValues.cf_stream.uid,
        thumbnail: duplicateValues.cf_stream.thumbnail,
        duration: duplicateValues.cf_stream.duration,
        meta: { name: duplicateValues.cf_stream.meta.name },
      } as CloudflareVideo)
    jobForm.setFieldValue(
      'cf_stream_id',
      updateSubject?.cf_stream?.uid || duplicateValues?.cf_stream?.uid
    )
  }, [updateSubject, duplicateValues, loadCloudflareVideos, jobForm])

  const columns: ColumnsType<JobRow> = [
    {
      title: t({
        id: 'settings.jobs.table.published',
        message: 'Publicatiedatum',
      }),
      dataIndex: 'published',
      key: 'published',
      width: 200,
      sorter: dateSort('published') as CompareFn<unknown>,
      render: (text: string) =>
        text ? dayjs(text).format('DD/MM/YYYY') : '(concept)',
    },
    {
      title: t({
        id: 'settings.jobs.table.title',
        message: 'Functie',
      }),
      dataIndex: 'title',
      key: 'title',
      sorter: defaultSort('title') as CompareFn<unknown>,
      fixed: 'left',
      render: (val, record) =>
        renderJobType(
          val,
          'position' in record ? String(record.position) : undefined
        ),
    },
    {
      title: t({
        id: 'settings.jobs.table.city',
        message: 'Gemeente',
      }),
      dataIndex: 'city',
      key: 'city',
      sorter: defaultSort('city') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.jobs.table.updated',
        message: 'Laatst gewijzigd',
      }),
      dataIndex: 'updated',
      key: 'updated',
      sorter: dateSort('updated') as CompareFn<unknown>,
      render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.jobs.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 200,
      render: (_, record: JobRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.READ}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            <Tooltip title="Preview">
              <Button
                onClick={() => navigate(`/branch/jobs/${record.key}`)}
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            <Tooltip title="Bewerken">
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranchJobOpportunities.find((jobOpportunity) =>
                      new ObjectId(jobOpportunity._id as string).equals(
                        record.key
                      )
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            <Tooltip
              title={t({
                id: 'actions.duplicate',
                message: 'Dupliceren',
              })}
            >
              <Button
                onClick={() => {
                  const job = data?.fetchBranchJobOpportunities.find(
                    (jobOpportunity) =>
                      new ObjectId(jobOpportunity._id as string).equals(
                        record.key
                      )
                  )
                  if (!job) return
                  const { image, published, ...jobProps } = job
                  if (image) {
                    const { __typename, mimetype, ...properties } = image
                    if (__typename === 'Image')
                      setImageField({
                        mimetype: ImageMimeType[mimetype],
                        ...properties,
                      })
                  }
                  setUpdateSubject(undefined)
                  setDuplicateValues(jobProps)
                  setJobModalVisible(true)
                }}
                shape="circle"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            <Tooltip title={'Verwijderen'}>
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.jobs.action.delete.confirm',
                  message: 'Ben je zeker dat je deze vacature wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteJob({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchJobOpportunities'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.jobs.action.delete.success',
                          message: 'Vacature succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const create = {
    [JobType.FirstJob]: createFirstJob,
    [JobType.StudentJob]: createStudentJob,
    [JobType.Internship]: createInternship,
  }

  const update = {
    [JobType.FirstJob]: updateFirstJob,
    [JobType.StudentJob]: updateStudentJob,
    [JobType.Internship]: updateInternship,
  }

  const mutating =
    creatingFirstJob ||
    creatingInternship ||
    creatingStudentJob ||
    updatingFirstJob ||
    updatingInternship ||
    updatingStudentJob

  const handleCreation = async () => {
    try {
      const { publication_date, cf_stream_id, external_url, ...fields } =
        await jobForm.validateFields()
      create[title]({
        variables: {
          input: {
            ...fields,
            published: fields.published ? publication_date : null,
            image: imageField,
            video:
              cf_stream_id !== undefined || external_url !== undefined
                ? { cf_stream_id, external_url }
                : undefined,
          },
        },
        refetchQueries: ['branchJobOpportunities'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.jobs.action.create.success',
              message: 'Vacature succesvol aangemaakt',
            }),
          })
          setDuplicateValues(undefined)
          setImageField(undefined)
          setJobModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const { publication_date, title, cf_stream_id, external_url, ...fields } =
        await jobForm.validateFields()
      update[title]({
        variables: {
          input: {
            _id: updateSubject?._id,
            ...fields,
            image: imageField,
            published: fields.published ? publication_date : null,
            video:
              cf_stream_id !== undefined || external_url !== undefined
                ? { cf_stream_id, external_url }
                : undefined,
          },
        },
        refetchQueries: ['branchJobOpportunities'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.jobs.action.update.success',
              message: 'Vacature succesvol gewijzigd',
            }),
          })
          setJobModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {!data?.branch.hiringOrganisation &&
          data?.fetchBranchJobOpportunities?.some((j) => j.published) && (
            <Alert
              type="warning"
              message={
                <Trans id="settings.jobs.alert.organisation_info_missing.title">
                  Gepubliceerde vacatures worden niet getoond.
                </Trans>
              }
              description={
                <Trans id="settings.jobs.alert.organisation_info_missing.description">
                  Vooraleer onderstaande vacatures getoond kunnen worden aan
                  studenten, dienen de gegevens van je organisatie aangevuld te
                  worden.
                </Trans>
              }
            />
          )}
        <HiringOrganisationForm
          loading={updatingHiringOrganisation}
          active={data && !data.branch.hiringOrganisation}
          data={data?.branch.hiringOrganisation || undefined}
          onChange={(data) => updateHiringOrganisation({ variables: data })}
        />
      </Space>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.jobs.title',
          message: 'Vacatures',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setJobModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [],
              }}
            >
              <Trans id="settings.jobs.action.create">Vacature aanmaken</Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.jobs.table.empty',
            message: 'Geen vacatures gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={jobs}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        destroyOnClose
        title={
          updateSubject
            ? t({
                id: 'settings.jobs.action.update.title',
                message: 'Vacature wijzigen',
              })
            : t({
                id: 'settings.jobs.action.create.title',
                message: 'Vacature aanmaken',
              })
        }
        open={jobModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={mutating}
        onCancel={() => {
          setJobModalVisible(false)
          setLibraryVideo(undefined)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          disabled: uploading,
          loading: mutating,
        }}
      >
        <Form
          key={updateSubject?._id}
          form={jobForm}
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={
            updateSubject
              ? {
                  ...updateSubject,
                  schedule:
                    updateSubject.__typename === JobType.FirstJob
                      ? updateSubject.schedule
                      : updateSubject.__typename === JobType.StudentJob
                      ? updateSubject.studentSchedule
                      : undefined,
                  cf_stream_id: updateSubject?.video?.cf_stream_id,
                  external_url: updateSubject?.video?.external_url,
                }
              : duplicateValues
              ? {
                  ...duplicateValues,
                  schedule:
                    duplicateValues.__typename === JobType.FirstJob
                      ? duplicateValues.schedule
                      : duplicateValues.__typename === JobType.StudentJob
                      ? duplicateValues.studentSchedule
                      : undefined,
                  cf_stream_id: duplicateValues?.video?.cf_stream_id,
                  external_url: duplicateValues?.video?.external_url,
                }
              : {
                  tasks: '',
                  competencies: '',
                  why: '',
                  wage: [1000, 3000],
                }
          }
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.jobs.form.label.title',
              message: 'Functie',
            })}
            name="title"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.jobs.form.validation.title',
                  message: 'Gelieve een functietitel te selecteren.',
                }),
              },
            ]}
          >
            <Select
              disabled={updateSubject !== undefined}
              placeholder={t({
                id: 'settings.jobs.form.placeholder.title',
                message: 'Selecteer een functie',
              })}
              options={[
                {
                  label: 'Vakantiejob',
                  value: JobType.StudentJob,
                },
                {
                  label: 'Stage',
                  value: JobType.Internship,
                },
                {
                  label: 'Eerste werkervaring',
                  value: JobType.FirstJob,
                },
              ]}
            />
          </Form.Item>
          {title === JobType.FirstJob && (
            <Form.Item
              label={t({
                id: 'settings.jobs.form.label.position',
                message: 'Jobtitel',
              })}
              name="position"
              rules={[
                {
                  required: true,
                  message: t({
                    id: 'settings.jobs.form.validation.position',
                    message: 'Gelieve een jobtitel in te vullen.',
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item wrapperCol={{ span: 24 }}>
            <Card
              title={
                <Trans id="settings.jobs.form.label.image">
                  Headerafbeelding
                </Trans>
              }
              size="small"
              extra={
                <Button
                  hidden={!image}
                  onClick={() => {
                    setImageField(null)
                    setUpdateSubject((s) => s && { ...s, image: null })
                  }}
                  type="text"
                  size="small"
                >
                  <Tooltip
                    title={<Trans id="action.delete">Verwijderen</Trans>}
                  >
                    <CloseOutlined />
                  </Tooltip>
                </Button>
              }
            >
              <Space
                direction="vertical"
                size={'middle'}
                style={{ width: '100%' }}
              >
                {!image && (
                  <Card.Meta
                    description={t({
                      id: 'settings.jobs.form.label.image.description',
                      message:
                        'Klik hieronder om een afbeelding op te laden en weer te geven bij de vacature.',
                    })}
                  />
                )}
                <Dragger
                  name="file"
                  listType="picture-card"
                  className="file-uploader"
                  showUploadList={false}
                  action={`${
                    import.meta.env.NX_BACKEND_URL
                  }/api/files/upload/image`}
                  beforeUpload={uploadValidator(5, [
                    FileType.jpg,
                    FileType.png,
                    FileType.gif,
                  ])}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(
                      'aa_lms_at'
                    )}`,
                    'x-academy-host': window.location.hostname,
                  }}
                  accept=".jpg,.jpeg,.png,.gif"
                  onChange={handleUpload}
                >
                  {image && !uploading ? (
                    <img
                      src={image.url}
                      alt={image.alt || ''}
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    <div>
                      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>
                        {uploading ? (
                          <Trans id="action.uploading">Uploading</Trans>
                        ) : (
                          <Trans id="action.upload">Upload</Trans>
                        )}
                      </div>
                    </div>
                  )}
                </Dragger>
              </Space>
            </Card>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Card
              title={<Trans id="settings.jobs.form.label.video">Video</Trans>}
              size="small"
              extra={
                <Button
                  hidden={!libraryVideo}
                  onClick={() => {
                    setLibraryVideo(undefined)
                    jobForm.setFieldsValue({ cf_stream_id: null })
                  }}
                  type="text"
                  size="small"
                >
                  <Tooltip
                    title={<Trans id="action.delete">Verwijderen</Trans>}
                  >
                    <CloseOutlined />
                  </Tooltip>
                </Button>
              }
            >
              <Space
                direction="vertical"
                size={'small'}
                style={{ width: '100%' }}
              >
                {
                  <Card.Meta
                    description={t({
                      id: 'settings.job.form.validation.video',
                      message:
                        'Vul een externe link (YouTube/Vimeo) in of laad een videobestand op.',
                    })}
                  />
                }
                <Form.Item
                  hidden
                  label={t({
                    id: 'course.unit.form.label.cf_stream_id',
                    message: 'Cloudflare Stream ID',
                  })}
                  name="cf_stream_id"
                  initialValue={updateSubject?.video?.cf_stream_id}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  hidden={!!libraryVideo}
                  label={t({
                    id: 'course.unit.form.label.external_video_url',
                    message: 'Video URL',
                  })}
                  name="external_url"
                  style={{ marginBottom: 12 }}
                  rules={[
                    {
                      type: 'url',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {!videoExternalUrl && (
                  <Form.Item
                    wrapperCol={{ sm: { span: 24 } }}
                    style={{ marginBottom: 0 }}
                  >
                    {libraryVideo && (
                      <Card style={{ marginBottom: 24 }}>
                        <Meta
                          avatar={
                            libraryVideo.thumbnail ? (
                              <Avatar
                                shape="square"
                                src={`${libraryVideo.thumbnail}?time=5s`}
                                style={{
                                  boxShadow: '5px 5px 40px rgba(0,0,0,0.12)',
                                }}
                              />
                            ) : undefined
                          }
                          title={libraryVideo.meta.name}
                          description={
                            libraryVideo.duration
                              ? dayjs
                                  .duration(libraryVideo.duration, 'seconds')
                                  .humanize()
                              : undefined
                          }
                        />
                      </Card>
                    )}
                    {!libraryVideo && (
                      <div className="ant-upload ant-upload-drag file-uploader">
                        <span
                          className="ant-upload ant-upload-btn"
                          onClick={() => {
                            setVideoUploaderVisible(true)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setVideoUploaderVisible(true)
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div>
                            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }}>
                              {uploading ? (
                                <Trans id="action.uploading">Uploading</Trans>
                              ) : (
                                <Trans id="action.upload">Upload</Trans>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    )}
                  </Form.Item>
                )}
              </Space>
            </Card>
          </Form.Item>
          <Row gutter={24}>
            <Col xs={{ span: 24 }}>
              <h3>
                <Trans id="settings.jobs.form.label.location">
                  Werklocatie
                </Trans>
              </h3>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 16 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.street',
                  message: 'Straat',
                })}
                name="street"
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.house_no',
                  message: 'Huisnr',
                })}
                name="house_no"
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.zip',
                  message: 'Postcode',
                })}
                name="zipCode"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.city',
                  message: 'Gemeente',
                })}
                name="city"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={35} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={{ span: 24 }}>
              <h3>
                <Trans id="settings.jobs.form.label.contact">
                  Contactpersoon
                </Trans>
              </h3>
            </Col>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.name',
                  message: 'Naam',
                })}
                name="contactPerson"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={
                  <span style={{ direction: 'ltr' }}>
                    {t({
                      id: 'settings.jobs.form.label.phone',
                      message: 'Telefoonnr.',
                    })}
                  </span>
                }
                name="phoneNumber"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="tel" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.email',
                  message: 'E-mailadres',
                })}
                name="email"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }}>
              <h3>
                <Trans id="settings.jobs.form.label.team">Team</Trans>
              </h3>
            </Col>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.team_size',
                  message: 'Grootte van het team',
                })}
                name="teamSize"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }}>
              <h3>
                <Trans id="settings.jobs.form.label.details">Details</Trans>
              </h3>
            </Col>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.tasks',
                  message: 'Takenpakket',
                })}
                name="tasks"
                extra={
                  <CharacterLimitHelper
                    content={tasks}
                    max={RICH_EDITOR_MAX_CHARACTERS}
                    html
                  />
                }
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    validator: characterLimitValidator(
                      RICH_EDITOR_MAX_CHARACTERS,
                      {
                        html: true,
                      }
                    ),
                    validateTrigger: 'onFinish',
                  },
                  {
                    validator: urlFreeFieldValidator,
                    message: t({
                      id: 'settings.job.form.validation.url_free',
                      message: 'Dit veld mag geen URLs bevatten.',
                    }),
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <RichEditor
                  disableLinks
                  disableHistory
                  disableClearMarkup
                  disableHorizontalRuler
                  disableTextStyles
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.competencies',
                  message: 'Vereiste competenties',
                })}
                name="competencies"
                extra={
                  <CharacterLimitHelper
                    content={competencies}
                    max={RICH_EDITOR_MAX_CHARACTERS}
                    html
                  />
                }
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    validator: characterLimitValidator(
                      RICH_EDITOR_MAX_CHARACTERS,
                      {
                        html: true,
                      }
                    ),
                    validateTrigger: 'onFinish',
                  },
                  {
                    validator: urlFreeFieldValidator,
                    message: t({
                      id: 'settings.job.form.validation.url_free',
                      message: 'Dit veld mag geen URLs bevatten.',
                    }),
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <RichEditor
                  disableLinks
                  disableHistory
                  disableClearMarkup
                  disableHorizontalRuler
                  disableTextStyles
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label={
                  <span style={{ direction: 'ltr' }}>
                    {t({
                      id: 'settings.jobs.form.label.why',
                      message: 'Waarom bij ons solliciteren?',
                    })}
                  </span>
                }
                name="why"
                extra={
                  <CharacterLimitHelper
                    content={why}
                    max={RICH_EDITOR_MAX_CHARACTERS}
                    html
                  />
                }
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    validator: characterLimitValidator(
                      RICH_EDITOR_MAX_CHARACTERS,
                      {
                        html: true,
                      }
                    ),
                    validateTrigger: 'onFinish',
                  },
                  {
                    validator: urlFreeFieldValidator,
                    message: t({
                      id: 'settings.job.form.validation.url_free',
                      message: 'Dit veld mag geen URLs bevatten.',
                    }),
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <RichEditor
                  disableLinks
                  disableHistory
                  disableClearMarkup
                  disableHorizontalRuler
                  disableTextStyles
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }}>
              <h3>
                <Trans id="settings.jobs.form.label.criteria">Criteria</Trans>
              </h3>
            </Col>
          </Row>
          {title === JobType.FirstJob && (
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.education_level',
                    message: 'Gewenst opleidingsniveau',
                  })}
                  name="educationLevel"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobEducationLevel.Master}>
                        Master
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Bachelor}>
                        Bachelor
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Graduate}>
                        Graduaat
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.None}>
                        Geen diploma
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 14 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.contract',
                    message: 'Contract',
                  })}
                  name="contract"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobContractType.Permanent}>
                        Vast contract
                      </Checkbox>
                      <Checkbox value={JobContractType.Temporary}>
                        Tijdelijk contract
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.schedule',
                    message: 'Werkschema',
                  })}
                  name="schedule"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobSchedule.FullTime}>Voltijds</Checkbox>
                      <Checkbox value={JobSchedule.PartTime}>
                        Part-time
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.wage',
                    message: 'Loonvork',
                  })}
                  extra={t({
                    id: 'settings.jobs.form.label.wage.extra',
                    message: '= Bruto bedrag per maand – fulltime',
                  })}
                  name="wage"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Slider
                    tooltip={{
                      formatter: (value) => `€ ${value}`,
                    }}
                    range={{
                      draggableTrack: true,
                    }}
                    min={0}
                    max={5000}
                    step={250}
                    marks={{
                      0: '€ 0',
                      500: '500',
                      1000: '1000',
                      1500: '1500',
                      2000: '2000',
                      2500: '2500',
                      3000: '3000',
                      3500: '3500',
                      4000: '4000',
                      4500: '4500',
                      5000: '5000',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.benefits',
                    message: 'Extralegale voordelen',
                  })}
                  name="benefits"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                        <Row style={{ marginBottom: -8 }}>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.CompanyCar}>
                              Bedrijfswagen
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.Smartphone}>
                              Smartphone
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.Laptop}>
                              Laptop
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.MealVouchers}>
                              Maaltijdcheques
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.CafetariaPlan}>
                              Cafetariaplan
                            </Checkbox>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Row style={{ marginBottom: -8 }}>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.HealthInsurance}>
                              Hospitalisatie- en groepsverzekering
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.MobilityBudget}>
                              Mobiliteitsbudget
                            </Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.Bonus}>Bonus</Checkbox>
                          </Col>
                          <Col span={24} style={{ marginBottom: 8 }}>
                            <Checkbox value={JobBenefits.Other}>
                              Andere
                            </Checkbox>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.accessibility',
                    message: 'Bereikbaarheid',
                  })}
                  name="accessibility"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobAccessibilityOptions.Parking}>
                        Wagen – parking aanwezig
                      </Checkbox>
                      <Checkbox value={JobAccessibilityOptions.PublicTransport}>
                        Makkelijk bereikbaar via het openbaar vervoer
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.other',
                    message: 'Andere',
                  })}
                  name="other"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobOtherCriteria.FlexibleHours}>
                        Flexibele werkuren
                      </Checkbox>
                      <Checkbox value={JobOtherCriteria.RemoteWork}>
                        Thuiswerk toegelaten
                      </Checkbox>
                      <Checkbox value={JobOtherCriteria.ItaaInternship}>
                        Mogelijkheid tot ITAA-stage
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          {title === JobType.Internship && (
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.education',
                    message: 'Opleidingsrichting',
                  })}
                  name="educationLevel"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobEducationLevel.Graduate}>
                        Graduaat
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Bachelor}>
                        Bachelor
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Master}>
                        Master
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Either}>
                        Geen voorkeur
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 14 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.accessibility',
                    message: 'Bereikbaarheid',
                  })}
                  name="accessibility"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobAccessibilityOptions.Parking}>
                        Wagen – parking aanwezig
                      </Checkbox>
                      <Checkbox value={JobAccessibilityOptions.PublicTransport}>
                        Makkelijk bereikbaar via het openbaar vervoer
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.other',
                    message: 'Andere',
                  })}
                  name="other"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobOtherCriteria.FlexibleHours}>
                        Flexibele werkuren
                      </Checkbox>
                      <Checkbox value={JobOtherCriteria.RemoteWork}>
                        Thuiswerk toegelaten
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          {title === JobType.StudentJob && (
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.schedule',
                    message: 'Werkschema',
                  })}
                  name="schedule"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={StudentJobSchedule.FixedDays}>
                        Vaste dag(en) per week
                      </Checkbox>
                      <Checkbox value={StudentJobSchedule.Vacations}>
                        Schoolvakanties
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.education',
                    message: 'Opleidingsrichting',
                  })}
                  name="educationLevel"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobEducationLevel.Graduate}>
                        Graduaat
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Bachelor}>
                        Bachelor
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Master}>
                        Master
                      </Checkbox>
                      <Checkbox value={JobEducationLevel.Either}>
                        Geen voorkeur
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.accessibility',
                    message: 'Bereikbaarheid',
                  })}
                  name="accessibility"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobAccessibilityOptions.Parking}>
                        Wagen – parking aanwezig
                      </Checkbox>
                      <Checkbox value={JobAccessibilityOptions.PublicTransport}>
                        Makkelijk bereikbaar via het openbaar vervoer
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  label={t({
                    id: 'settings.jobs.form.label.other',
                    message: 'Andere',
                  })}
                  name="other"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Checkbox.Group>
                    <Space direction="vertical">
                      <Checkbox value={JobOtherCriteria.FlexibleHours}>
                        Flexibele werkuren
                      </Checkbox>
                      <Checkbox value={JobOtherCriteria.RemoteWork}>
                        Thuiswerk toegelaten
                      </Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            label={<Trans id="settings.job.form.label.status">Status</Trans>}
            name="published"
            valuePropName="checked"
          >
            <Switch
              unCheckedChildren={<Trans id="job.tag.draft">Concept</Trans>}
              checkedChildren={
                <Trans id="job.tag.published">Gepubliceerd</Trans>
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Trans id="settings.job.form.label.publication_date">
                Publicatiedatum
              </Trans>
            }
            name="publication_date"
            hidden={!published}
            initialValue={dayjs().startOf('day')}
            required={!!published}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{ width: '100%' }}
              allowClear={false}
            />
          </Form.Item>
          <Button
            hidden
            disabled={mutating || uploading}
            type="primary"
            htmlType={'submit'}
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
      <Modal
        open={videoUploaderVisible}
        maskClosable={false}
        closable={false}
        okText={t({
          id: 'action.upload',
          message: 'Upload',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ hidden: true }}
        onCancel={() => {
          uppyClient?.cancelAll({
            reason: 'user',
          })
          jobForm.setFieldsValue({ cf_stream_id: null })
          setLibraryVideo(undefined)
          setVideoUploaderVisible(false)
        }}
      >
        {uppyClient && <Dashboard uppy={uppyClient} />}
      </Modal>
    </>
  )
}
