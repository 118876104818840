/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Col, Form, Input, notification, PageHeader, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  CreateContentUnitMutation,
  CreateContentUnitMutationVariables,
  ContentUnit,
  ContentUnitInput,
} from 'apps/lms-front/src/generated/graphql'
import { RichEditor } from 'apps/lms-front/src/modules/shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import CREATE_CONTENT_UNIT_MUTATION from './../../../mutations/create-content-unit.graphql'
import UPDATE_CONTENT_UNIT_MUTATION from './../../../mutations/update-content-unit.graphql'

interface Props extends PageProps {
  className?: string
  unit?: ContentUnit
}

export const CourseEditContentUnit = ({ route, unit }: Props) => {
  const navigate = useNavigate()

  const [createUnit, { loading: creating }] =
    useMutation<CreateContentUnitMutation>(CREATE_CONTENT_UNIT_MUTATION)
  const [updateUnit, { loading: updating }] =
    useMutation<CreateContentUnitMutation>(UPDATE_CONTENT_UNIT_MUTATION, {
      variables: {
        id: unit?._id,
      },
    })

  const { id, section } = useParams()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const MAX_CHARACTERS = {
    NAME: 100,
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          unit ? (
            <Button
              key="3"
              onClick={() =>
                navigate(`/courses/${unit.course_id}/edit-content`)
              }
            >
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          ) : (
            <Button onClick={() => navigate(-1)} key="2">
              <Trans id="action.cancel">Annuleren</Trans>
            </Button>
          ),
          <Button
            disabled={!formDirty || creating || updating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={unit ? (unit as Store) : undefined}
              onFinish={(variables: ContentUnitInput) => {
                if (unit) {
                  updateUnit({
                    variables: {
                      ...variables,
                    },
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'course.unit.edit.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                } else {
                  createUnit({
                    variables: {
                      name: variables.name,
                      content: variables.content,
                      courseId: id,
                      sectionId: section,
                    } as CreateContentUnitMutationVariables,
                  })
                    .then((result) => {
                      notification.success({
                        message: t({
                          id: 'course.unit.create.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      const id = result.data?.addContentUnit.course_id
                      navigate(`/courses/${id}/edit-content`, { replace: true })
                    })
                    .catch(errorNotifierFn)
                }
              }}
              onFieldsChange={() => setFormDirty(true)}
              autoComplete="off"
            >
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.name',
                  message: 'Naam',
                })}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t({
                      id: 'course.unit.form.validation.name',
                      message:
                        'Gelieve een naam voor dit onderdeel in te vullen',
                    }),
                  },
                  {
                    max: MAX_CHARACTERS.NAME,
                    message: t({
                      id: 'course.unit.form.validation.max_characters',
                      message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                    }),
                  },
                ]}
              >
                <Input tabIndex={0} />
              </Form.Item>
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.content',
                  message: 'Inhoud',
                })}
                name="content"
                required={true}
              >
                <RichEditor />
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                <Button
                  disabled={!formDirty || updating || creating}
                  type="primary"
                  htmlType={'submit'}
                >
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  )
}
