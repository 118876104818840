import { useMutation, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { PageHeader, Transfer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useEffect, useMemo, useState } from 'react'

import {
  BranchMetaQuery,
  FindBranchesByMetaQuery,
  UpdateBranchMetaMutation,
} from 'apps/lms-front/src/generated/graphql'
import { LoadScreen } from 'apps/lms-front/src/modules/core/components/LoadScreen'

import { useBranch } from '../../../auth/hooks/use-branch'

import BRANCHES from './../../../courses/queries/branches.graphql'
import UPDATE_BRANCH_META from './../../mutations/update-branch-meta.graphql'
import BRANCH_META from './../../queries/branch-meta.graphql'

export const ConfigureSoftware = () => {
  const branch = useBranch()
  const { data: branchMeta } = useQuery<BranchMetaQuery>(BRANCH_META)
  const { data: branches } = useQuery<FindBranchesByMetaQuery>(BRANCHES, {
    variables: {
      meta: 'include_in_tools',
    },
  })
  const [selected, setSelected] = useState<string[]>([])

  const [saveSettings] = useMutation<UpdateBranchMetaMutation>(
    UPDATE_BRANCH_META,
    {
      variables: {
        id: branch?._id,
      },
    }
  )

  const initialKeys = useMemo(() => {
    const settings = branchMeta?.branchMeta.meta?.disabled_tools || '[]'
    return JSON.parse(settings)
  }, [branchMeta])

  useEffect(() => {
    setSelected(initialKeys)
  }, [initialKeys])

  if (!branches || !branchMeta) return <LoadScreen />

  return (
    <Content>
      <PageHeader
        ghost={false}
        title={t({
          id: 'branch.software.header.title',
          message: 'Softwaretools',
        })}
        className="site-page-header"
        subTitle={t({
          id: 'branch.software.header.subtitle',
          message: 'Verberg specifieke softwaretools voor je kantoor',
        })}
      ></PageHeader>

      <Transfer
        dataSource={branches?.findBranchesByMeta?.map((branch) => ({
          key: branch._id,
          title: branch.name,
        }))}
        titles={[
          t({
            id: 'branch.software.transfer.active',
            message: 'Softwaretools',
          }),
          t({
            id: 'branch.software.transfer.disabled',
            message: 'Gedeactiveerde softwaretools',
          }),
        ]}
        render={(item) => item.title}
        style={{ marginBottom: 16, width: '100%' }}
        listStyle={{ flex: 1, height: '70vh' }}
        locale={{
          notFoundContent: t({
            id: 'branch.software.transfer.none_disabled',
            message: 'Geen softwaretools gedeactiveerd',
          }),
        }}
        targetKeys={selected}
        onChange={(keys) => {
          setSelected(keys)
          saveSettings({
            variables: {
              meta: {
                disabled_tools: JSON.stringify(keys),
              },
            },
          })
        }}
      />
    </Content>
  )
}
