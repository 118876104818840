import styled from 'styled-components'

export const HeaderTitle = styled.h1`
  line-height: 105%;
  color: #fff;
  margin: 0;
`

export const HeaderTitleText = styled.span`
  @media screen and (max-width: 768px) {
    display: inline-block;
    font-size: 1.125rem;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
