export enum RuleType {
  VisibilityRule = 'VisibilityRule',
  TriggerRule = 'TriggerRule',
}

export enum VisibilityActionTarget {
  Course = 'Course',
  Category = 'Category',
  Article = 'Article',
}

export enum VisibilityActionType {
  Show = 'Show',
  Hide = 'Hide',
}

export enum ConditionType {
  StringCondition = 'StringCondition',
  BooleanCondition = 'BooleanCondition',
  NumberCondition = 'NumberCondition',
  DateCondition = 'DateCondition',
  SelectCondition = 'SelectCondition',
}

export enum ConditionSubject {
  User = 'User',
  UserRole = 'UserRole',
  Section = 'Section',
  Course = 'Course',
  CourseTag = 'CourseTag',
  CourseOwnerBranch = 'CourseOwnerBranch',
}

export enum StringConditionOperator {
  Equals = 'Equals',
  DoesNotEqual = 'DoesNotEqual',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
}

export enum BooleanConditionOperator {
  IsTrue = 'IsTrue',
  IsFalse = 'IsFalse',
}

export enum NumberConditionOperator {
  Equals = 'Equals',
  DoesNotEqual = 'DoesNotEqual',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  GreaterThanOrEqaualTo = 'GreaterThanOrEqaualTo',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
}

export enum DateConditionOperator {
  Equals = 'Equals',
  DoesNotEqual = 'DoesNotEqual',
  IsBefore = 'IsBefore',
  IsAfter = 'IsAfter',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
}

export enum SimpleSelectConditionOperator {
  IsIn = 'IsIn',
  IsNotIn = 'IsNotIn',
}

export enum SelectConditionOperator {
  Equals = 'Equals',
  DoesNotEqual = 'DoesNotEqual',
  IsIn = 'IsIn',
  IsNotIn = 'IsNotIn',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
}
