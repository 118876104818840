import { Table as T } from 'antd'
import styled from 'styled-components'

export const Table = styled((props) => <T {...props} />)`
  .ant-table table {
    border-collapse: collapse;
  }

  .ant-table-row-success {
    background-color: #f6ffed;

    td {
      background-color: #f6ffed;
    }

    &:hover td {
      background-color: #e6f7de !important;
    }
  }

  .ant-table-row-error {
    background-color: #fff1f0;

    td {
      background-color: #fff1f0;
    }

    &:hover td {
      background-color: #ffe7e6 !important;
    }
  }
`
// slightly darker variant of #fff1f0 is #ffe7e6
