import { ApolloQueryResult } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Button, Col } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { PublicUnitQuery } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'
import { useAxios } from 'apps/lms-front/src/modules/shared/hooks/use-axios'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

import { ConnectionStatus } from '../../../components/connection-status/ConnectionStatus'
import { EventResults } from '../../../components/event-results/EventResults'
import { PageHeader } from '../UnitViewer.style'
import { QuizViewer, QuizViewerRef } from '../video-unit-viewer/QuizViewer'

import { Question } from './QuizUnitViewer.styles'

interface Props {
  unit: PublicUnitQuery['fetchUnitById'] & {
    __typename: 'QuizUnit'
  }
  refetchUnit?: () => Promise<ApolloQueryResult<PublicUnitQuery>>
  handleUnitCompletion: () => void
}

interface RemoteProps extends Omit<Props, 'refetchUnit'> {
  event_id: string
}

export const QuizUnitViewer = ({
  unit,
  refetchUnit,
  handleUnitCompletion,
}: Props) => {
  const quizRef = useRef<QuizViewerRef>(null)
  const [current, setCurrent] = useState(0)
  const [loading, setLoading] = useState(false)

  return (
    <Col md={20} style={{ textAlign: 'center' }}>
      <PageHeader
        title={
          <small>
            {unit.my_activity?.completed ? (
              <Trans id="unit.viewer.quiz_results.title">Jouw resultaten</Trans>
            ) : (
              <Trans id="unit.viewer.quiz.title">
                Vraag {current + 1} van{' '}
                {unit.quizUnitTranslation.questions.length}
              </Trans>
            )}
          </small>
        }
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <QuizViewer
        ref={quizRef}
        questionIndex={current}
        onQuestionIndexChange={setCurrent}
        unit={unit}
        refetchUnit={refetchUnit}
        onLoadingStateChange={(loading) => setLoading(loading)}
        finishButton={
          unit.my_activity?.completed ? (
            <Button type="primary" onClick={handleUnitCompletion}>
              {unit.next ? (
                <Trans id="unit.viewer.action.continue">Doorgaan</Trans>
              ) : (
                <Trans id="unit.viewer.action.complete_course">
                  Opleiding voltooien
                </Trans>
              )}
            </Button>
          ) : (
            <Button
              type="primary"
              loading={loading}
              onClick={() => quizRef.current?.submit()}
            >
              <Trans id="unit.viewer.quiz.submit">Antwoord indienen</Trans>
            </Button>
          )
        }
      />
    </Col>
  )
}

export const QuizRemoteUnitViewer = ({
  unit,
  event_id,
  handleUnitCompletion,
}: RemoteProps) => {
  const { token } = useAuth()

  const [{ data: results }, refetchResults] = useAxios<
    { total: number; completed: number } | undefined
  >({
    url: `/api/activity/event/${event_id}/unit/${unit._id}`,
  })

  const { connected: eventConnected, emit } = useSocket('/api/event/ws', {
    token,
    events: {
      eventActionFinished: () => refetchResults(),
    },
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    /**
     * Trigger the event unit action to show the questions
     */
    emit('triggerEventUnitAction', {
      event_id,
      unit_id: unit._id,
    })
  }, [event_id, unit._id])

  return (
    <Col md={20} style={{ textAlign: 'center' }}>
      <PageHeader
        title={unit.name}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <ConnectionStatus connected={eventConnected} />
      <Question>
        <Trans id="unit.viewer.video.answer_questions_with_companion">
          Beantwoord de tussentijdse vragen op je telefoon
        </Trans>
      </Question>
      {results && (
        <EventResults total={results.total} completed={results.completed} />
      )}
      <div
        className="steps-action"
        style={{
          marginTop: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true)
            emit('expireEventAction', {
              event_id,
              unit_id: unit._id,
            })
            await logActivity({
              unit_id: unit._id,
              event_id,
              completed: true,
            })
              .then(() => {
                handleUnitCompletion()
              })
              .catch(errorNotifierFn)
              .finally(() => setLoading(false))
          }}
        >
          {unit.next ? (
            <Trans id="unit.viewer.action.continue">Doorgaan</Trans>
          ) : (
            <Trans id="unit.viewer.action.complete_course">
              Opleiding voltooien
            </Trans>
          )}
        </Button>
      </div>
    </Col>
  )
}
