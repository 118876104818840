import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Modal,
  Form,
  Input,
  Switch,
  InputNumber,
  Popconfirm,
  Button,
  Tabs,
  Row,
  Col,
  Radio,
  Card,
  Tooltip,
  notification,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import {
  CreatePathSelfStudyStepInput,
  SelfStudyStep,
  StepCategory,
  StepReferenceType,
  StepUnitKind,
} from '../../../generated/graphql'
import DatePicker from '../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../shared/components/rich-editor/RichEditor'
import {
  errorNotifierFn,
  validationErrorNotifierFn,
} from '../../shared/helpers/error-notifier'
import { LinkedUnitPicker } from '../components/LinkedUnitPicker'
import { StepEditContentUnit } from '../components/StepEditContentUnit'
import { StepEditPDFUnit } from '../components/StepEditPDFUnit'
import { StepEditVideoUnit } from '../components/StepEditVideoUnit'
import { usePath } from '../contexts/PathContext'
import DELETE_PATH_STEP from '../mutations/delete-path-step.graphql'
import UPDATE_PATH_SELF_STUDY_STEP from '../mutations/update-path-self-study-step.graphql'

export const EditSelfStudyStepModal = ({
  open,
  onClose,
  onSubmit,
  step,
}: {
  open: boolean
  onClose: () => void
  onSubmit?: (values: CreatePathSelfStudyStepInput) => void
  step?: SelfStudyStep
  moduleId?: string
}) => {
  const { isDateWithinAvailableRange } = usePath()
  const { isDateBeforeTodayOrStartDate } = usePath()
  const [form] = Form.useForm()
  const unit_kind = Form.useWatch('unit_kind', form)
  const reference_type = Form.useWatch('reference_type', form)
  const optional = Form.useWatch('optional', form)

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        optional: false,
        category: StepCategory.SelfStudyStep,
        ...step,
        deadline: step?.deadline ? dayjs(step?.deadline) : null,
        available_from: step?.available_from
          ? dayjs(step?.available_from)
          : null,
      })
    } else {
      form.resetFields()
    }
  }, [open, form, step])

  const [updateSelfStudyStep] = useMutation(UPDATE_PATH_SELF_STUDY_STEP, {
    refetchQueries: ['path'],
  })
  const [deleteStep] = useMutation(DELETE_PATH_STEP, {
    refetchQueries: ['path'],
  })

  const handleSubmit = async () => {
    const values = await form.validateFields().catch(validationErrorNotifierFn)

    if (!values) {
      return
    }

    if (values.linked_unit_duration) {
      values.duration = Math.round(values.linked_unit_duration / 60)
      delete values.linked_unit_duration
    }

    const input = {
      ...values,
      category: StepCategory.SelfStudyStep,
      deadline: values.optional ? null : values.deadline,
    }

    if (step?._id) {
      await updateSelfStudyStep({
        variables: { step_id: step._id, input },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.self-study.edit.success',
              message: 'Zelfstudie succesvol bijgewerkt',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    } else if (onSubmit) {
      onSubmit(input)
    }
  }

  const handleDelete = async () => {
    if (step?._id) {
      await deleteStep({
        variables: { step_id: step._id },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.self-study.delete.success',
              message: 'Zelfstudie succesvol verwijderd',
            }),
          })
          onClose()
        })
        .catch(errorNotifierFn)
    }
  }

  return (
    <Modal
      title={
        step ? (
          <Trans id="step.self-study.edit.title">Stap bewerken</Trans>
        ) : (
          <Trans id="step.self-study.add.title">Zelfstudie toevoegen</Trans>
        )
      }
      bodyStyle={{ paddingTop: 16 }}
      maskClosable={false}
      destroyOnClose={true}
      width={800}
      open={open}
      onCancel={() => {
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        step && (
          <Popconfirm
            key="delete"
            title={
              <Trans id="step.self-study.delete.confirm">
                Weet je zeker dat je deze stap wilt verwijderen?
              </Trans>
            }
            okText={<Trans id="action.yes">Ja</Trans>}
            cancelText={<Trans id="action.no">Nee</Trans>}
            onConfirm={handleDelete}
          >
            <Button danger>
              <Trans id="action.delete">Verwijderen</Trans>
            </Button>
          </Popconfirm>
        ),
        !step && (
          <Button key="cancel" onClick={onClose}>
            <Trans id="action.cancel">Annuleren</Trans>
          </Button>
        ),
        <Button key="submit" type="primary" onClick={handleSubmit}>
          <Trans id="action.save">Opslaan</Trans>
        </Button>,
      ].filter(Boolean)}
    >
      <Form form={form} layout="vertical">
        <Tabs
          type="card"
          destroyInactiveTabPane={false}
          items={[
            {
              key: 'details',
              label: <Trans id="step.self-study.details.title">Details</Trans>,
              children: (
                <>
                  <Row gutter={16}>
                    <Col span={20}>
                      <Form.Item
                        name="name"
                        label={
                          <Trans id="step.self-study.field.name">Naam</Trans>
                        }
                        rules={[
                          {
                            required: true,
                            message: t({
                              id: 'step.self-study.field.validation.name',
                              message: 'Gelieve een naam in te vullen',
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name="optional"
                        valuePropName="checked"
                        label={
                          <Trans id="step.self-study.field.optional">
                            Optioneel
                          </Trans>
                        }
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="description"
                    hidden
                    label={
                      <Trans id="step.self-study.field.description">
                        Beschrijving
                      </Trans>
                    }
                  >
                    <RichEditor disableTextStyles />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="duration"
                        label={
                          <Trans id="step.self-study.field.duration">
                            Duurtijd
                          </Trans>
                        }
                      >
                        <InputNumber
                          min={1}
                          style={{ width: '100%' }}
                          addonAfter={<Trans id="time.minutes">minuten</Trans>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="available_from"
                        label={
                          <Trans id="step.self-study.field.available_from">
                            Beschikbaar vanaf
                          </Trans>
                        }
                      >
                        <DatePicker
                          disabledDate={(currentDate) => {
                            if (!dayjs.isDayjs(currentDate)) return true

                            return !isDateWithinAvailableRange(
                              currentDate,
                              form.getFieldValue('deadline')
                            )
                          }}
                          showTime
                          showSecond={false}
                          format="D MMMM YYYY - HH:mm"
                          minuteStep={15}
                          secondStep={60}
                          style={{ width: '100%' }}
                          onSelect={(value) => {
                            form.setFieldValue('available_from', value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        hidden={optional}
                        name="deadline"
                        label={
                          <Trans id="step.self-study.field.deadline">
                            Deadline
                          </Trans>
                        }
                      >
                        <DatePicker
                          disabledDate={isDateBeforeTodayOrStartDate}
                          showTime
                          showSecond={false}
                          format="D MMMM YYYY - HH:mm"
                          minuteStep={15}
                          secondStep={60}
                          style={{ width: '100%' }}
                          onSelect={(value) => {
                            form.setFieldValue('deadline', value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              key: 'content',
              label: <Trans id="step.self-study.content.title">Inhoud</Trans>,
              children: (
                <>
                  <Form.Item
                    hidden={
                      reference_type === StepReferenceType.CustomUnit &&
                      !!unit_kind
                    }
                    name="reference_type"
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'step.self-study.field.validation.reference_type',
                          message: 'Selecteer een type',
                        }),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        form.setFieldValue('reference_type', e.target.value)
                        form.setFieldValue('unit_kind', undefined)
                        form.setFieldValue('linked_unit_id', undefined)
                        form.setFieldValue('duration', undefined)
                      }}
                    >
                      <Radio value={StepReferenceType.LinkedUnit}>
                        <Trans id="step.self-study.reference_type.linked">
                          Bestaand onderdeel koppelen
                        </Trans>
                      </Radio>
                      <Radio value={StepReferenceType.CustomUnit}>
                        <Trans id="step.self-study.reference_type.custom">
                          Nieuw onderdeel
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    hidden={
                      reference_type !== StepReferenceType.CustomUnit ||
                      !!unit_kind
                    }
                    name="unit_kind"
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'step.self-study.field.validation.unit_kind',
                          message: 'Selecteer een type',
                        }),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={StepUnitKind.ContentUnit}>
                        <Trans id="step.self-study.unit_kind.content">
                          Tekst
                        </Trans>
                      </Radio>
                      <Radio value={StepUnitKind.PdfUnit}>
                        <Trans id="step.self-study.unit_kind.pdf">PDF</Trans>
                      </Radio>
                      <Radio value={StepUnitKind.VideoUnit}>
                        <Trans id="step.self-study.unit_kind.video">
                          Video
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {reference_type === StepReferenceType.CustomUnit && (
                    <>
                      {unit_kind === StepUnitKind.ContentUnit &&
                        reference_type === StepReferenceType.CustomUnit && (
                          <Card
                            title={t({
                              id: 'step.self-study.unit_kind.content',
                              message: 'Tekst',
                            })}
                            size="small"
                            extra={
                              <Tooltip
                                placement="left"
                                title={t({
                                  id: 'action.delete',
                                  message: 'Verwijderen',
                                })}
                              >
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      unit_kind: undefined,
                                      reference_type: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <StepEditContentUnit />
                          </Card>
                        )}
                      {unit_kind === StepUnitKind.PdfUnit &&
                        reference_type === StepReferenceType.CustomUnit && (
                          <Card
                            title={t({
                              id: 'step.self-study.unit_kind.pdf',
                              message: 'PDF',
                            })}
                            size="small"
                            extra={
                              <Tooltip
                                placement="left"
                                title={t({
                                  id: 'action.delete',
                                  message: 'Verwijderen',
                                })}
                              >
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      unit_kind: undefined,
                                      reference_type: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <StepEditPDFUnit
                              form={form}
                              step={step as SelfStudyStep}
                            />
                          </Card>
                        )}
                      {unit_kind === StepUnitKind.VideoUnit &&
                        reference_type === StepReferenceType.CustomUnit && (
                          <Card
                            title={t({
                              id: 'step.self-study.unit_kind.video',
                              message: 'Video',
                            })}
                            size="small"
                            extra={
                              <Tooltip
                                placement="left"
                                title={t({
                                  id: 'action.delete',
                                  message: 'Verwijderen',
                                })}
                              >
                                <Button
                                  type="text"
                                  icon={<DeleteOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    form.setFieldsValue({
                                      unit_kind: undefined,
                                      reference_type: undefined,
                                    })
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <StepEditVideoUnit
                              form={form}
                              step={step as SelfStudyStep}
                            />
                          </Card>
                        )}
                    </>
                  )}
                  {reference_type === StepReferenceType.LinkedUnit && (
                    <LinkedUnitPicker
                      form={form}
                      step={step as SelfStudyStep}
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  )
}
