import { CheckOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Descriptions, PageHeader, Pagination, Popover, Spin } from 'antd'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext } from '../../../auth/components/Can'
import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'

import {
  CenteredTD,
  Done,
  InProgress,
  RotatedTH,
  Table,
  TableCellLink,
  TBody,
  TD,
  THead,
  TR,
} from './Matrix.style'

export const MatrixReport = () => {
  const ability = useContext(AbilityContext)

  const [coursePage, setCoursePage] = useState(1)
  const [coursePageSize, setCoursePageSize] = useState(10)
  const [courseQuery, setCourseQuery] = useState<string>('')
  const [userPage, setUserPage] = useState(1)
  const [userPageSize, setUserPageSize] = useState(10)
  const [userQuery, setUserQuery] = useState<string>('')

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
  })

  const [{ data, loading }] = useAxios({
    url: `/api/activity/matrix`,
    params: {
      section_id: hierarchyFilter || sessionStorage.getItem('aa_report_filter'),
      course_page: coursePage,
      course_page_size: coursePageSize,
      course_query: courseQuery,
      user_page: userPage,
      user_page_size: userPageSize,
      user_query: userQuery,
    },
  })

  useEffect(() => {
    if (
      !hierarchyFilter &&
      (ability.can(PermissionAction.READ, PermissionObjectType.REPORT) ||
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_REPORT))
    )
      setHierarchyFilter(sessionStorage.getItem('aa_report_filter'))
  }, [])

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.matrix.title',
          message: 'Trainingsmatrix',
        })}
        extra={
          treeData.length > 0 && (
            <TreeSelect
              placeholder={t({
                id: 'reports.filter.hierarchy',
                message: 'Filter op afdeling',
              })}
              treeDefaultExpandAll={true}
              treeLine={true}
              showSearch
              treeDataSimpleMode
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              filterTreeNode={(input, option) =>
                (option.title as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeData={treeData}
              loading={treeLoading}
              allowClear={ability.cannot(
                PermissionAction.READ,
                PermissionObjectType.REPORT
              )}
              treeNodeLabelProp="label"
              value={hierarchyFilter}
              onChange={(value) => {
                setSessionStorageItem('aa_report_filter', value)
                setHierarchyFilter(value)
                setUserQuery('')
                setCoursePage(1)
                setUserPage(1)
              }}
            />
          )
        }
      ></PageHeader>
      <Content>
        {data && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1rem',
            }}
          >
            <InputSearch
              placeholder={t({
                id: 'reports.matrix.search_course',
                message: 'Zoeken op opleiding',
              })}
              onSearch={(query) => {
                setCourseQuery(query)
                setCoursePage(1)
              }}
              style={{ width: '12rem', marginRight: 16 }}
            />
            <Pagination
              showSizeChanger
              onChange={(page) => setCoursePage(page)}
              onShowSizeChange={(_, size) => setCoursePageSize(size)}
              current={coursePage}
              pageSize={coursePageSize}
              total={data.pageInfo.coursesSize}
            />
          </div>
        )}
        {data && (
          <div
            style={
              loading
                ? {
                    pointerEvents: 'none',
                    cursor: 'wait',
                    opacity: 0.5,
                    overflow: 'hidden',
                  }
                : { overflowX: 'auto' }
            }
          >
            <Table>
              <THead>
                <TD style={{ borderBottom: 'none' }}>&nbsp;</TD>
                {data.columns.map((column: { _id: string; name: string }) => (
                  <RotatedTH key={column._id}>
                    <div>
                      <span>
                        <TableCellLink
                          to={`/reports/courses?query=${encodeURIComponent(
                            column.name
                          )}&expand=${column._id}`}
                        >
                          {column.name}
                        </TableCellLink>
                      </span>
                    </div>
                  </RotatedTH>
                ))}
              </THead>
              <TBody>
                {data.matrix.map(
                  (row: {
                    _id: string
                    name: string
                    email: string
                    courses: [
                      {
                        _id: string
                        name: string
                        activity: {
                          _id: string
                          last_activity: string
                          completed: string
                          started: string
                        }
                      },
                    ]
                  }) => (
                    <TR key={row._id}>
                      <TD>
                        <Popover
                          placement="right"
                          title={row.name}
                          content={
                            <>
                              <span style={{ fontWeight: 700 }}>
                                <Trans id="reports.matrix.email">
                                  E-mailadres
                                </Trans>
                              </span>
                              :<br />
                              {row.email}
                            </>
                          }
                        >
                          <TableCellLink
                            to={`/reports/users?query=${encodeURIComponent(
                              row.name
                            )}&expand=${row._id}`}
                          >
                            {row.name}
                          </TableCellLink>
                        </Popover>
                      </TD>
                      {data.columns.map(
                        (column: { _id: string; name: string }) => {
                          const activity = row.courses.find((course) =>
                            new ObjectId(course._id).equals(column._id)
                          )?.activity

                          if (!activity) return <CenteredTD>&nbsp;</CenteredTD>
                          if (activity.completed)
                            return (
                              <CenteredTD
                                key={activity?._id}
                                style={{
                                  borderColor: '#FFF',
                                }}
                              >
                                <Popover
                                  overlayClassName={'no-padding'}
                                  showArrow={false}
                                  placement={'right'}
                                  content={
                                    <Descriptions
                                      size={'small'}
                                      column={1}
                                      bordered
                                    >
                                      <Descriptions.Item
                                        label={t({
                                          id: 'reports.matrix.started',
                                          message: 'Gestart',
                                        })}
                                      >
                                        {dayjs(activity?.started).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </Descriptions.Item>
                                      <Descriptions.Item
                                        label={t({
                                          id: 'reports.matrix.completed',
                                          message: 'Voltooid',
                                        })}
                                      >
                                        {dayjs(activity?.completed).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </Descriptions.Item>
                                    </Descriptions>
                                  }
                                  trigger={'click'}
                                >
                                  <Done>
                                    <CheckOutlined />
                                  </Done>
                                </Popover>
                              </CenteredTD>
                            )
                          return (
                            <CenteredTD
                              key={activity?._id}
                              style={{
                                borderColor: '#FFF',
                              }}
                            >
                              <Popover
                                overlayClassName={'no-padding'}
                                showArrow={false}
                                placement={'right'}
                                content={
                                  <Descriptions
                                    size={'small'}
                                    column={1}
                                    bordered
                                  >
                                    <Descriptions.Item
                                      label={t({
                                        id: 'reports.matrix.started',
                                        message: 'Gestart',
                                      })}
                                    >
                                      {dayjs(activity?.started).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                      label={t({
                                        id: 'reports.matrix.last_activity',
                                        message: 'Laatste activiteit',
                                      })}
                                    >
                                      {dayjs(activity?.last_activity).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                      label={t({
                                        id: 'reports.matrix.progress',
                                        message: 'Voortgang',
                                      })}
                                    >
                                      <ProgressPercentage
                                        activity={activity._id}
                                      ></ProgressPercentage>
                                    </Descriptions.Item>
                                  </Descriptions>
                                }
                                trigger={'click'}
                              >
                                <InProgress>&nbsp;</InProgress>
                              </Popover>
                            </CenteredTD>
                          )
                        }
                      )}
                    </TR>
                  )
                )}
              </TBody>
            </Table>
          </div>
        )}
        {data && (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Pagination
              showSizeChanger
              onShowSizeChange={(_, size) => setUserPageSize(size)}
              onChange={(page) => setUserPage(page)}
              current={userPage}
              pageSize={userPageSize}
              total={data.pageInfo.usersSize}
            />
            <InputSearch
              placeholder={t({
                id: 'reports.matrix.search_user',
                message: 'Zoeken op gebruiker',
              })}
              onSearch={(query) => {
                setUserQuery(query)
                setUserPage(1)
              }}
              style={{ width: '12rem', marginLeft: 16 }}
            />
          </div>
        )}
      </Content>
    </Content>
  )
}

const ProgressPercentage = ({ activity }: { activity: string }) => {
  const [{ data }] = useAxios<number>({
    url: `/api/activity/progress`,
    params: {
      id: activity,
    },
  })
  if (data === undefined || data === null)
    return (
      <div>
        <Spin />
      </div>
    )
  return <>{data}%</>
}
