import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { NavItemExtension } from '../../generated/graphql'
import { PageProps } from '../shared/interfaces/page.interface'

import { Frame } from './components/frame/Frame'

export const Extension = ({ setPageTitle }: PageProps) => {
  const [frameKey, setFrameKey] = useState<number>(0)
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()
  const { key } = useParams()
  const branch = useBranch()
  const extension = branch?.extensions?.find((e) => 'key' in e && e.key === key)

  const path = useMemo(
    () =>
      hash.startsWith('#/')
        ? hash.slice(1)
        : localStorage.getItem('lastPath') || '/',
    [hash]
  )

  useEffect(() => {
    if (`/x/${key}` === pathname) setFrameKey(Date.now())
  }, [pathname, key])

  useEffect(() => {
    if (hash && hash !== '' && hash !== '#/')
      localStorage.setItem('lastPath', hash.slice(1))
    else if (hash === '#/') localStorage.removeItem('lastPath')
  }, [hash])

  if (!extension) {
    navigate('/')
    return null
  }

  if (extension.__typename === 'NavItemExtension') {
    return (
      <Frame
        key={frameKey + hash}
        extension={extension as NavItemExtension}
        path={path}
        setPageTitle={setPageTitle}
      />
    )
  }

  return null
}
