/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */

import { useContext } from 'react'

import { AuthContext } from '../context/auth.context'

export function useAuth() {
  return useContext(AuthContext)
}
