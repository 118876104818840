import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Input, Button, Row, Col, notification, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ResetPasswordMutation } from 'apps/lms-front/src/generated/graphql'

import PASSWORD_RESET_MUTATION from './../../mutations/reset-password.graphql'
import { H1, AuthForm } from './../login/Login.style'

export const AlignVertically = styled.div`
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ResetPassword = () => {
  const [reset, { loading }] = useMutation<ResetPasswordMutation>(
    PASSWORD_RESET_MUTATION
  )

  const navigate = useNavigate()

  return (
    <AlignVertically>
      <div>
        <H1>
          <Trans id="forgot_password.heading">Wachtwoord resetten</Trans>
        </H1>
        <Row justify="center" align="middle" style={{ flex: 1 }}>
          <Col xs={24} lg={12}>
            <p style={{ textAlign: 'center' }}>
              <Trans id="forgot_password.instructions">
                Vul hieronder je e-mailadres in en we versturen je een link
                waarmee je tijdelijk kan inloggen om je wachtwoord te
                veranderen.
              </Trans>
            </p>
            <AuthForm>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ email: localStorage.getItem('aa_email') }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onFinish={(data: any) => {
                  reset({
                    variables: {
                      email: data.email,
                    },
                  }).then(() => {
                    navigate('/')
                    notification.info({
                      message: t({
                        id: 'forgot_password.email_sent',
                        message:
                          'Als je e-mailadres in ons systeem gevonden werd, ontvang je binnen de 5 minuten een link om je wachtwoord te resetten.',
                      }),
                    })
                  })
                }}
                autoComplete="off"
              >
                <Form.Item
                  label={t({
                    id: 'forgot_password.form.label.email',
                    message: 'E-mailadres',
                  })}
                  labelAlign="left"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: t({
                        id: 'forgot_password.form.validation.email',
                        message: 'Gelieve je e-mailadres in te geven',
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{ margin: '0 auto', display: 'block' }}
                  >
                    <Trans id="forgot_password.form.submit">
                      Verstuur me een link
                    </Trans>
                  </Button>
                </Form.Item>
              </Form>
            </AuthForm>
          </Col>
        </Row>
      </div>
    </AlignVertically>
  )
}
