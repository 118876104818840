import axios from 'axios'

import { axiosClient } from '../hooks/use-axios'

export const logActivity = async (any: unknown) => {
  if (!any) return
  const token = localStorage.getItem('aa_lms_at')
  return axiosClient.post(`/api/activity`, any, {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
}

export const logArticleActivity = async (any: unknown) => {
  if (!any) return
  const token = localStorage.getItem('aa_lms_at')
  return axios.post(
    `${import.meta.env.NX_BACKEND_URL}/api/activity/article`,
    any,
    {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
