import { Card as AntCard } from 'antd'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

export const Card = styled((props) => <AntCard {...props} />)`
  border: 1px solid #e5e5e5;
  height: 100%;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 16px;
`

export const Link = styled((props) => <RouterLink {...props} />)`
  text-decoration: none;
  img {
    transition: opacity 0.2s ease;
  }
  &:hover img {
    opacity: 0.8;
  }
`

export const Title = styled.h2`
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.2;
`

export const Description = styled.p`
  flex: 1;
  color: #555;
`

export const Author = styled.h3`
  margin-bottom: 0;
  font-weight: 500;
`

export const Meta = styled.p`
  margin-bottom: 0;
  color: #555;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
`
