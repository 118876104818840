import { Card } from 'antd'
import styled from 'styled-components'

export const BookmarkButton = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 1.25rem;
`

export const JobCardWrapper = styled((props) => <Card {...props} />)`
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  .ant-card-meta-description {
    color: var(--text-color);
  }
  padding-right: 1.5rem;

  .ant-card-meta-title {
    overflow: auto !important;
    white-space: normal !important;
  }
`
