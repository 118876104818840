import { useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { notification } from 'antd'
import { useCallback } from 'react'

import COPY_MODULE_MUTATION from '../mutations/copy-path-module.graphql'
import COPY_STEP_MUTATION from '../mutations/copy-path-step.graphql'

interface ClipboardContent {
  id: string
  type: 'PathModule' | 'PathStep'
  targetModuleStepId?: string
}

export function useClipboardPaste(
  targetModuleId: string,
  refetchQueries: string[] = [],
  targetModuleStepId?: string
) {
  const [copyModule] = useMutation(COPY_MODULE_MUTATION, {
    refetchQueries,
    onCompleted: () => {
      notification.success({
        message: t({
          id: 'paths.module.paste.success',
          message: 'Module succesvol geplakt',
        }),
      })
    },
    onError: () => {
      notification.error({
        message: t({
          id: 'paths.module.paste.error',
          message: 'Fout bij plakken van module',
        }),
      })
    },
  })

  const [copyStep] = useMutation(COPY_STEP_MUTATION, {
    refetchQueries,
    onCompleted: () => {
      notification.success({
        message: t({
          id: 'paths.step.paste.success',
          message: 'Stap succesvol geplakt',
        }),
      })
    },
    onError: () => {
      notification.error({
        message: t({
          id: 'paths.step.paste.error',
          message: 'Fout bij plakken van deze stap',
        }),
      })
    },
  })

  return useCallback(async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText()

      const parsed = JSON.parse(clipboardContent)

      if (
        !parsed ||
        typeof parsed !== 'object' ||
        !('id' in parsed) ||
        !('type' in parsed)
      ) {
        throw new Error('Invalid clipboard content format')
      }

      const { id, type } = parsed as ClipboardContent

      if (type !== 'PathModule' && type !== 'PathStep') {
        throw new Error('Unsupported content type')
      }

      if (type === 'PathModule') {
        await copyModule({
          variables: {
            sourceId: id,
            targetModuleId: targetModuleId,
          },
        })
      } else if (type === 'PathStep') {
        await copyStep({
          variables: {
            sourceId: id,
            targetModuleId: targetModuleId,
            targetModuleStepId: targetModuleStepId ?? null,
          },
        })
      }
    } catch (error) {
      console.error('Failed to paste:', error)
    }
  }, [copyModule, copyStep, targetModuleId, targetModuleStepId])
}
