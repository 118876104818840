import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { usePostHog } from 'posthog-js/react'
import { ReactNode, Suspense, useContext, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation, Params } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { AbilityContext } from '../../auth/components/Can'
import { AnonGuard, AuthGuard } from '../../auth/guards/auth.guard'
import { Layout as DefaultLayout } from '../../shared/layout'
import { LoadScreen } from '../components/LoadScreen'

import { Route as RouteType } from './route.interface'
import { flattenRoutes, buildPaths, setupParents } from './router-utils'
import { routes } from './routes'

const loadingMessage = t({ id: 'action.loading', message: 'Aan het laden' })

const ScrollToTop = (props: { children: ReactNode }) => {
  const posthog = usePostHog()
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    posthog.capture('$pageview')
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{props.children}</>
}

export const Router = () => {
  const { loading: validatingAuth, user } = useAuth()
  useLingui()

  const ability = useContext(AbilityContext)

  if (ability.rules.length === 0 && user) {
    return (
      <DefaultLayout
        route={{
          path: '/',
          component: LoadScreen,
          label: loadingMessage,
          description: loadingMessage,
        }}
        loading
      />
    )
  }

  return (
    <ScrollToTop>
      <Suspense
        fallback={
          <DefaultLayout
            route={{
              path: '/',
              component: LoadScreen,
              label: loadingMessage,
              description: loadingMessage,
            }}
            loading
          />
        }
      >
        <Routes>
          {flattenRoutes(setupParents(buildPaths(routes()))).map((route) => {
            const Layout = route.layout || DefaultLayout

            const access_granted = route.permission
              ? 'or' in route.permission
                ? route.permission.or.some((or) =>
                    ability.can(or.action, or.subject)
                  )
                : ability.can(
                    route.permission?.action,
                    route.permission?.subject
                  )
              : true

            const element = route.redirect ? (
              <Navigate to={route.redirect} />
            ) : access_granted ? (
              <Layout route={route} />
            ) : (
              <Navigate to={'/r'} />
            )

            const anonElement = route.redirect ? (
              <Navigate to={route.redirect} />
            ) : (
              <Layout anon route={route} />
            )

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.anonOnly ? (
                    <AnonGuard>{anonElement}</AnonGuard>
                  ) : validatingAuth ? (
                    <Layout
                      route={{
                        path: '/',
                        component: LoadScreen,
                        label: loadingMessage,
                        description: loadingMessage,
                      }}
                      loading
                    />
                  ) : (
                    <AuthGuard>{element}</AuthGuard>
                  )
                }
              />
            )
          })}
          <Route
            key={'wildcard'}
            path={'*'}
            element={
              <AuthGuard>
                <Navigate
                  to={
                    ability.can(
                      PermissionAction.READ,
                      PermissionObjectType.DASHBOARD
                    )
                      ? '/'
                      : '/courses'
                  }
                />
              </AuthGuard>
            }
          ></Route>
        </Routes>
      </Suspense>
    </ScrollToTop>
  )
}

export const getParentRoute = (
  route: RouteType | undefined,
  params: Readonly<Params<string>>,
  levels = 1
) => {
  if (!route) {
    return '..'
  }

  const { path } = route

  let fullPath = path.replaceAll(/:(\w+)/g, (match, paramName) => {
    const value = params[paramName]
    return value ? encodeURIComponent(value) : match
  })

  while (levels > 0) {
    const lastIndex = fullPath.lastIndexOf('/')
    fullPath = fullPath.slice(0, Math.max(1, lastIndex))
    levels--
  }

  return fullPath
}
