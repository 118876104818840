import { Tabs } from 'antd'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #fff;
`

export const CustomTabs = styled(Tabs)`
  .ant-tabs-content {
    margin-top: -16px;
  }
  & > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-nav-list > .ant-tabs-tab,
  [data-theme='compact'] .ant-tabs-nav-list > .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-nav-list > .ant-tabs-tab-active,
  [data-theme='compact'] .ant-tabs-nav-list > .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  [data-theme='compact'] .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
`
