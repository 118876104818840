import styled from 'styled-components'

export const ControlPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  padding: 10px;
  max-width: 100%;
  overflow-x: auto;
  height: auto; // Ensure it only takes the height it needs
`
export const ControlButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  min-width: 60px;

  &:hover {
    transform: scale(1.1);
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
  }
`
