const urlRegex =
  /^((https|http):\/\/.)[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)$/

export function isValidUrl(_, url) {
  if (urlRegex.test(url)) {
    return Promise.resolve()
  }

  return Promise.reject()
}
