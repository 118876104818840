import { TranslationOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Dropdown, Button, Space, Badge } from 'antd'

import { LanguagesQuery } from 'apps/lms-front/src/generated/graphql'

export interface TranslateableRecord {
  key: string
  language: string
  translations: { language: string; [key: string]: string }[]
  [key: string]: unknown
}

interface TranslateButtonProps {
  languages: LanguagesQuery['fetchLanguages']
  record: TranslateableRecord
  onClick?: (key: string, language: string) => void
  isInlineButton?: boolean
  children?: React.ReactNode
}

export const TranslateButton = ({
  languages,
  record,
  onClick,
  isInlineButton = false,
  children,
}: TranslateButtonProps) => {
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: languages
          .filter((lang) => lang.code !== record.language)
          .map((language) => ({
            key: language.code,
            label: (
              <Space>
                {language.name}
                {record.translations?.some(
                  (t) => t.language === language.code
                ) && <Badge status="success" />}
              </Space>
            ),
            onClick: () => {
              onClick?.(record.key, language.code)
            },
          })),
      }}
    >
      {children ?? (
        <Button
          {...(isInlineButton && { type: 'text' })}
          shape="circle"
          icon={<TranslationOutlined />}
        >
          {isInlineButton &&
            t({ id: 'actions.translate', message: 'Vertalen' })}
        </Button>
      )}
    </Dropdown>
  )
}
