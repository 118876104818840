/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  ControlOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
  Transfer,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { test } from 'fuzzyjs'
import { useEffect, useMemo, useState } from 'react'

import {
  LimitAccessType,
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchUsersQuery,
  BranchGroupsQuery,
  CreateBranchGroupMutation,
  UpdateBranchGroupMutation,
  DeleteBranchGroupMutation,
  BranchAccessibleCoursesQuery,
  AssignCourseMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { CourseAssignmentModal } from '../../../courses/components/course-assignment-modal/CourseAssignmentModal'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort } from '../../../shared/utils/sort'

import ASSIGN_COURSE_MUTATION from './../../../courses/mutations/assign-course.graphql'
import CREATE_BRANCH_GROUP_MUTATION from './../../../settings/mutations/create-branch-group.graphql'
import DELETE_BRANCH_GROUP_MUTATION from './../../../settings/mutations/delete-branch-group.graphql'
import UPDATE_BRANCH_GROUP_MUTATION from './../../../settings/mutations/update-branch-group.graphql'
import BRANCH_GROUPS_QUERY from './../../../settings/queries/branch-groups.graphql'
import BRANCH_USERS_QUERY from './../../../settings/queries/branch-users.graphql'
import BRANCH_ACCESSIBLE_COURSES_QUERY from './../../queries/branch-accessible-courses.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface GroupRow {
  key: string
  name: string
  created?: Date
}

type TransferItem = BranchUsersQuery['fetchBranchUsers']['results'][0]

export const BranchGroups = () => {
  const [assignModalVisible, setAssignModalVisible] = useState<boolean>(false)

  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const [groupModalVisible, setGroupModalVisible] = useState(false)
  const [groupAccessModalVisible, setGroupAccessModalVisible] = useState(false)

  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<BranchGroupsQuery['fetchBranchGroups'][0]>()

  const [updateAccessSubject, setUpdateAccessSubject] =
    useState<BranchGroupsQuery['fetchBranchGroups'][0]>()

  const [form] = useForm()
  const [rulesForm] = useForm()

  const [limitAccessType, setLimitAccessType] = useState<LimitAccessType>(
    LimitAccessType.NONE
  )

  const { data, loading } = useQuery<BranchGroupsQuery>(BRANCH_GROUPS_QUERY)

  const { data: courses } = useQuery<BranchAccessibleCoursesQuery>(
    BRANCH_ACCESSIBLE_COURSES_QUERY
  )

  const [assignCourse, { loading: assigning }] =
    useMutation<AssignCourseMutation>(ASSIGN_COURSE_MUTATION)

  const [createGroup, { loading: creating }] =
    useMutation<CreateBranchGroupMutation>(CREATE_BRANCH_GROUP_MUTATION)

  const [updateGroup, { loading: updating }] =
    useMutation<UpdateBranchGroupMutation>(UPDATE_BRANCH_GROUP_MUTATION)

  const [deleteGroup, { loading: deleting }] =
    useMutation<DeleteBranchGroupMutation>(DELETE_BRANCH_GROUP_MUTATION)

  const { data: userData } = useQuery<BranchUsersQuery>(BRANCH_USERS_QUERY)

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])

  const filterOption = (
    inputValue: string,
    option: { _id: string; name: string }
  ) =>
    test(inputValue, option.name, {
      caseSensitive: false,
    })

  const userFilterOption = (inputValue: string, option: TransferItem) =>
    test(inputValue, `${option.firstName} ${option.lastName} ${option.email}`, {
      caseSensitive: false,
    })

  useEffect(() => {
    if (updateSubject) {
      setGroupModalVisible(true)
      if (updateSubject.users) setSelectedUsers(updateSubject.users)
    } else {
      setSelectedUsers([])
    }
  }, [updateSubject])

  useEffect(() => {
    if (updateAccessSubject) {
      setGroupAccessModalVisible(true)
      setLimitAccessType(
        updateAccessSubject.limit_access_type as unknown as LimitAccessType
      )
      setSelectedCourses(updateAccessSubject.selected_courses)
    }
  }, [updateAccessSubject, rulesForm])

  useEffect(() => {
    if (groupModalVisible) form.resetFields()
  }, [groupModalVisible, form])

  const groups = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranchGroups || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranchGroups || []
    ).map<GroupRow>((item: BranchGroupsQuery['fetchBranchGroups'][0]) => ({
      key: item._id,
      name: item.name,
      users: item.users,
      created: item.created,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<GroupRow> = [
    {
      title: t({
        id: 'branch.groups.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'branch.groups.table.users',
        message: 'Gebruikers',
      }),
      dataIndex: 'users',
      key: 'users',
      render: (users: string[]) => users.length,
    },
    {
      title: t({
        id: 'branch.groups.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      align: 'center',
      fixed: 'right',
      width: 215,
      render: (_, record: GroupRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_USER_GROUP}
          >
            <Tooltip
              title={t({
                id: 'action.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranchGroups.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.CREATE} an={PermissionObjectType.ASSIGNMENT}>
            <Tooltip
              title={t({
                id: 'branch.groups.table.actions.assign',
                message: 'Opleidingen toewijzen',
              })}
            >
              <Button
                onClick={() => {
                  setAssignModalVisible(true)
                }}
                shape="circle"
                icon={<SolutionOutlined />}
              />
              <CourseAssignmentModal
                users={[record.key]}
                assigning={assigning}
                visible={assignModalVisible}
                onVisibilityChange={(state) => setAssignModalVisible(state)}
                onAssign={(variables) => {
                  return assignCourse({
                    variables,
                  })
                }}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_USER_GROUP}
          >
            <Tooltip
              title={t({
                id: 'branch.groups.table.actions.manage_access',
                message: 'Opleidingen verbergen',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateAccessSubject(
                    data?.fetchBranchGroups.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<ControlOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={PermissionObjectType.BRANCH_USER_GROUP}
          >
            <Tooltip
              title={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'branch.groups.delete.title',
                  message: 'Ben je zeker dat je deze groep wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteGroup({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchGroups'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'branch.groups.delete.success',
                          message: 'Groep succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleChange = (newUsers: string[]) => {
    setSelectedUsers(newUsers)
  }

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createGroup({
        variables: {
          ...fields,
          users: selectedUsers,
        },
        refetchQueries: ['branchGroups'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'branch.groups.create.success',
              message: 'Groep succesvol aangemaakt',
            }),
          })
          setGroupModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateGroup({
        variables: {
          id: updateSubject?._id,
          ...fields,
          users: selectedUsers,
        },
        refetchQueries: ['branchGroups'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'branch.groups.update.success',
              message: 'Groep succesvol gewijzigd',
            }),
          })
          setGroupModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleRulesUpdate = async () => {
    try {
      const fields = await rulesForm.validateFields()
      updateGroup({
        variables: {
          id: updateAccessSubject?._id,
          ...fields,
          limit_access_type: limitAccessType,
          selected_courses:
            limitAccessType === LimitAccessType.ALL_BUT_SELECTED ||
            limitAccessType === LimitAccessType.SELECTED
              ? selectedCourses
              : [],
        },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'branch.groups.rules_update.success',
              message: 'Succesvol toegepast',
            }),
          })
          setGroupAccessModalVisible(false)
          setUpdateAccessSubject(undefined)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.groups.title',
          message: 'Groepen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'branch.groups.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_USER_GROUP}
          >
            <DropdownButton
              onClick={() => setGroupModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-branch-groups',
                    label: t({
                      id: 'branch.groups.import',
                      message: 'Groepen importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="branch.groups.create">Groep aanmaken</Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'branch.groups.table.empty',
            message: 'Geen groepen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={groups}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'branch.groups.update.title',
                message: 'Groep wijzigen',
              })
            : t({
                id: 'branch.groups.create.title',
                message: 'Nieuwe groep aanmaken',
              })
        }
        open={groupModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setGroupModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'branch.groups.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'branch.groups.form.validation.name',
                  message: 'Gelieve de naam van de groep in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Transfer
              style={{ height: 300 }}
              listStyle={{ width: 275, height: 300 }}
              showSearch
              // @ts-ignore
              dataSource={userData?.fetchBranchUsers.results}
              // @ts-ignore
              filterOption={userFilterOption}
              targetKeys={selectedUsers}
              // @ts-ignore
              render={(record: TransferItem) =>
                `${record.firstName} ${record.lastName} (${record.email})`
              }
              onChange={handleChange}
              // @ts-ignore
              rowKey={(record: TransferItem) => record._id}
              titles={[
                t({
                  id: 'branch.groups.form.transfer.users',
                  message: 'Alle gebruikers',
                }),
                t({
                  id: 'branch.groups.form.transfer.group',
                  message: 'Groep',
                }),
              ]}
            />
          </div>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t({
          id: 'branch.groups.manage_access.title',
          message: 'Opleidingen verbergen voor groep',
        })}
        open={groupAccessModalVisible}
        onOk={handleRulesUpdate}
        confirmLoading={updating}
        onCancel={() => {
          setGroupAccessModalVisible(false)
          setUpdateAccessSubject(undefined)
        }}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={t({
          id: 'action.apply',
          message: 'Toepassen',
        })}
        width={800}
      >
        <Form
          key={`${updateAccessSubject?._id}-access`}
          form={rulesForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleRulesUpdate}
          initialValues={updateAccessSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'branch.groups.manage_access.form.label.mode',
              message: 'Welke opleidingen wil je voor deze groep verbergen?',
            })}
            labelCol={{ span: 24 }}
            required={true}
          >
            <Radio.Group
              value={limitAccessType}
              onChange={(e) => {
                setLimitAccessType(e.target.value)
                setSelectedCourses([])
              }}
            >
              <Space direction="vertical">
                <Radio value={LimitAccessType.ALL_BUT_SELECTED}>
                  <Trans id="branch.groups.manage_access.form.mode.all_but_selected">
                    Alle opleidingen behalve...
                  </Trans>
                </Radio>
                <Radio value={LimitAccessType.SELECTED}>
                  <Trans id="branch.groups.manage_access.form.mode.selected">
                    Geselecteerde opleidingen
                  </Trans>
                </Radio>
                <Radio value={LimitAccessType.NONE}>
                  <Trans id="branch.groups.manage_access.form.mode.none">
                    Geen opleidingen verbergen
                  </Trans>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {limitAccessType === LimitAccessType.ALL_BUT_SELECTED ||
          limitAccessType === LimitAccessType.SELECTED ? (
            <Transfer
              style={{ height: 300 }}
              listStyle={{ width: 360, height: 300 }}
              showSearch
              // @ts-ignore
              filterOption={filterOption}
              dataSource={courses?.fetchBranchAccessibleCourses?.results || []}
              rowKey={(record) => record._id}
              titles={[
                t({
                  id: 'branch.groups.manage_access.form.transfer.courses',
                  message: 'Opleidingen',
                }),
                t({
                  id: 'branch.groups.manage_access.form.transfer.selected',
                  message: 'Geselecteerd',
                }),
              ]}
              locale={{
                itemUnit: t({
                  id: 'branch.groups.manage_access.form.transfer.item_unit',
                  message: 'opleiding',
                }),
                itemsUnit: t({
                  id: 'branch.groups.manage_access.form.transfer.items_unit',
                  message: 'opleidingen',
                }),
              }}
              targetKeys={selectedCourses}
              onChange={(newSelectedCourses) =>
                setSelectedCourses(newSelectedCourses)
              }
              render={(item) => item.name}
            />
          ) : null}
        </Form>
      </Modal>
    </>
  )
}
