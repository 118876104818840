/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */

import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

export function AuthGuard({ children }: { children: JSX.Element }) {
  const auth = useAuth()
  const location = useLocation()
  if (!auth?.user) {
    if (location.pathname === '/') {
      return <Navigate to="/welcome" state={{ from: location }} replace />
    } else {
      sessionStorage.setItem('aa_session_end', location.pathname)
      return <Navigate to="/login" state={{ from: location }} replace />
    }
  }

  return children
}

export function AnonGuard({ children }: { children: JSX.Element }) {
  const auth = useAuth()

  if (auth?.user) {
    return <Navigate to="/dashboard" replace />
  }

  return children
}
