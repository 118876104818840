import { useState, useCallback } from 'react'

export function useClipboardCheck() {
  const [canPaste, setCanPaste] = useState(false)
  const [clipboardContent, setClipboardContent] = useState(null)

  const checkClipboard = useCallback(async () => {
    try {
      if (!navigator.clipboard) {
        console.warn('Clipboard API not available in this environment.')
        return false
      }

      if (!document.hasFocus()) {
        console.warn('Clipboard access requires the document to be focused.')
        return false
      }

      const text = await navigator.clipboard.readText()

      if (text.includes('<script>') || text.includes('javascript:')) {
        console.warn('Potentially malicious content detected')
        return false
      }

      if (text.trim() === '') {
        setCanPaste(false)
        setClipboardContent(null)
        return false
      }

      let clipboardObject
      try {
        clipboardObject = JSON.parse(text)
      } catch {
        setCanPaste(false)
        setClipboardContent(null)
        return false
      }

      // Validate the structure of the clipboard content
      if (
        clipboardObject &&
        typeof clipboardObject === 'object' &&
        'id' in clipboardObject &&
        'type' in clipboardObject
      ) {
        setCanPaste(true)
        setClipboardContent(clipboardObject)
        return clipboardObject
      } else {
        setCanPaste(false)
        setClipboardContent(null)
        return false
      }
    } catch (error) {
      setCanPaste(false)
      setClipboardContent(null)
      console.error('Failed to read clipboard:', error)
      return false
    }
  }, [])

  return { canPaste, clipboardContent, checkClipboard }
}
