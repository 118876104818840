import { classNames } from '@react-pdf-viewer/core'
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout'
import { ReactElement } from 'react'

export const renderToolbar = (
  Toolbar: (props: ToolbarProps) => ReactElement
) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots

      return (
        <div
          data-testid="toolbar"
          className={classNames({
            'rpv-toolbar': true,
          })}
          role="toolbar"
          aria-orientation="horizontal"
        >
          <div className="rpv-toolbar__left">
            <div className="rpv-toolbar__item">
              <ShowSearchPopover />
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToPreviousPage />
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <CurrentPageInput />
              <span className="rpv-toolbar__label">
                <NumberOfPages />
              </span>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <GoToNextPage />
              </div>
            </div>
          </div>
          <div className="rpv-toolbar__center">
            <div className="rpv-toolbar__item">
              <ZoomOut />
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-small">
              <div className="rpv-toolbar__item">
                <Zoom />
              </div>
            </div>
            <div className="rpv-toolbar__item">
              <ZoomIn />
            </div>
          </div>
          <div className="rpv-toolbar__right">
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <Print />
              </div>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <Download />
              </div>
            </div>
            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
              <div className="rpv-toolbar__item">
                <EnterFullScreen />
              </div>
            </div>
          </div>
        </div>
      )
    }}
  </Toolbar>
)
