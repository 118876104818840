import { Trans, t } from '@lingui/macro'
import { Button, PageHeader, message } from 'antd'

import { exporter } from 'apps/lms-front/src/modules/reports/helpers/export'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'

export const ExportUsers = ({ route }: PageProps) => {
  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={
          <Button
            type="primary"
            onClick={() => {
              const hideExportLoader = message.loading(
                t({
                  id: 'export.preparing',
                  message: 'Data klaarmaken voor export...',
                }),
                0
              )
              exporter({
                url: '/api/export/all-users',
                type: 'csv',
                name: 'users',
                dates: [],
              })
                .then(() => {
                  message.success(
                    t({
                      id: 'export.ready',
                      message: 'Data klaar voor export!',
                    }),
                    1
                  )
                })
                .finally(hideExportLoader)
            }}
          >
            <Trans id="settings.users.export.form.submit">
              Exporteer overzicht van alle gebruikers
            </Trans>
          </Button>
        }
      />
    </>
  )
}
