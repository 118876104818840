import { InfoCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Plural, Trans, t } from '@lingui/macro'
import {
  Button,
  Dropdown,
  PageHeader,
  Space,
  Tree,
  Input,
  notification,
  Modal,
  Form,
  Tag,
  Affix,
  Alert,
} from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { DataNode, EventDataNode } from 'antd/lib/tree'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  HierarchyUserCountQuery,
  type CreateHierarchySectionMutation,
  type DeleteHierarchySectionMutation,
  type ReorderHierarchySectionsMutation,
  type UpdateHierarchyMutation,
  AssignUsersToSectionMutation,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadSection } from '../../../core/components/LoadScreen'
import { UserSelect } from '../../../shared/form-fields/user-select/UserSelect'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { UpdateHierarchySectionModal } from '../../components/hierarchy/UpdateHierarchySectionModal'
import {
  TreeItem,
  convertSimpleTreeDataToTree,
  useHierarchyTree,
} from '../../hooks/use-hierarchy-tree'
import ASSIGN_USERS_TO_SECTION_MUTATION from '../../mutations/assign-users-to-section.graphql'
import CREATE_HIERARCHY_SECTION_MUTATION from '../../mutations/create-hierarchy-section.graphql'
import DELETE_HIERARCHY_SECTION_MUTATION from '../../mutations/delete-hierarchy-section.graphql'
import REORDER_HIERARCHY_SECTIONS_MUTATION from '../../mutations/reorder-hierarchy-sections.graphql'
import UPDATE_HIERARCHY_SECTION_MUTATION from '../../mutations/update-hierarchy-section.graphql'
import HIERARCHY_USER_COUNT_QUERY from '../../queries/hierarchy-user-count.graphql'

type OrganisationProps = {
  onNavigate: (section: string) => void
}

export const Organisation = ({
  onNavigate: navigateToSection,
}: OrganisationProps) => {
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const [createForm] = Form.useForm()
  const [addUserForm] = Form.useForm()

  const {
    data,
    refetch,
    loading: loadingHierarchy,
  } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_USER,
    },
    noBranches: true,
  })

  const [createSection, { loading: creating }] =
    useMutation<CreateHierarchySectionMutation>(
      CREATE_HIERARCHY_SECTION_MUTATION,
      {
        refetchQueries: ['hierarchy'],
      }
    )

  const [deleteSection, { loading: deleting }] =
    useMutation<DeleteHierarchySectionMutation>(
      DELETE_HIERARCHY_SECTION_MUTATION,
      {
        refetchQueries: ['hierarchy'],
      }
    )

  const [updateSection, { loading: updating }] =
    useMutation<UpdateHierarchyMutation>(UPDATE_HIERARCHY_SECTION_MUTATION, {
      refetchQueries: ['hierarchy'],
    })

  const [reorderSections, { loading: reordering }] =
    useMutation<ReorderHierarchySectionsMutation>(
      REORDER_HIERARCHY_SECTIONS_MUTATION,
      {
        refetchQueries: ['hierarchy'],
      }
    )

  const [assignUsersToSection, { loading: assigningUsersToSection }] =
    useMutation<AssignUsersToSectionMutation>(ASSIGN_USERS_TO_SECTION_MUTATION)

  const [treeData, setTreeData] = useState<TreeItem[]>()
  const [updateSubject, setUpdateSubject] = useState<string | null>(null)
  const [renameSubject, setRenameSubject] = useState<string | null>(null)
  const [createSubject, setCreateSubject] = useState<string | null>(null)
  const [deleteSubject, setDeleteSubject] = useState<string | null>(null)
  const [addUserModalOpen, setAddUserModalOpen] = useState<TreeItem | null>(
    null
  )
  const [movingMode, setMovingMode] = useState<boolean>(false)

  const [expandedKeys, setExpandedKeys] = useState<string[]>([])

  const onDrop = useCallback(
    (info) => {
      if (!treeData) return

      const dropKey = info.node.key as string
      const dragKey = info.dragNode.key as string
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos.at(-1))

      const loop = (
        data: Array<TreeItem>,
        key: string,
        callback: (item: TreeItem, index: number, arr: Array<TreeItem>) => void
      ) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].key === key) {
            return callback(data[i], i, data)
          }
          if (data[i].children) {
            loop(data[i].children, key, callback)
          }
        }
      }

      const data: Array<TreeItem> = [...treeData] // Clone your current tree data

      // Find dragObject
      let dragObj: TreeItem | null = null
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })

      if (!dragObj) {
        return
      }

      // Drop outside the tree
      if (info.dropToGap) {
        let ar: Array<TreeItem> = []
        let i: number | undefined
        loop(data, dropKey, (_, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i!, 0, dragObj) // Insert at the position directly before the drop target
        } else {
          ar.splice(i! + 1, 0, dragObj) // Corrects position calculation
        }
      } else {
        loop(data, dropKey, (item) => {
          item.children = item.children || []
          if (item.children.length === 0 || dropPosition <= 0) {
            item.children.unshift(dragObj as TreeItem) // Add to the start if dropping on an empty node or as first child
          } else {
            item.children.push(dragObj as TreeItem) // Add to the end if not specified
          }
        })
      }

      setTreeData(data)
      setMovingMode(true)
    },
    [treeData, setTreeData]
  )

  const loading = useMemo(() => {
    return (
      !treeData ||
      reordering ||
      creating ||
      deleting ||
      updating ||
      loadingHierarchy
    )
  }, [treeData, reordering, creating, deleting, updating, loadingHierarchy])

  const expandMethod = (arr: typeof treeData, levels, keys) => {
    arr?.forEach((data) => {
      keys.push(data.key)
      if (data.children && levels > 0) {
        expandMethod(data.children, levels - 1, keys)
      }
    })
  }

  useEffect(() => {
    const keys: string[] = []
    // Convert the simple tree data to a complex tree data
    if (data) {
      const complex = convertSimpleTreeDataToTree(data)
      setTreeData(complex)

      if (expandedKeys.length === 0) {
        // Expand the tree to level 2
        expandMethod(complex, 2, keys)
        setExpandedKeys(keys)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getDropDownItems = (node: TreeItem): ItemType[] =>
    [
      {
        key: 'new-section',
        label: t({
          id: 'branch.organisation.context_menu.add_new_section',
          message: 'Nieuwe entiteit toevoegen',
        }),
        onClick: () => {
          setCreateSubject(node.id)
        },
      },
      {
        key: 'add_users',
        label: t({
          id: 'branch.organisation.context_menu.add_users',
          message: 'Voeg gebruikers toe',
        }),
        onClick: () => setAddUserModalOpen(node),
      },
      {
        type: 'divider',
      },
      {
        key: 'see_users',
        label: t({
          id: 'branch.organisation.context_menu.see_users',
          message: 'Bekijk gebruikers',
        }),
        onClick: () => {
          navigate(`/branch/users?section=${node.id}`)
          navigateToSection('users')
        },
      },
      ability.can(
        PermissionAction.CONFIGURE,
        PermissionObjectType.PLATFORM_SETTINGS
      ) && {
        key: 'edit',

        label: t({
          id: 'branch.organisation.context_menu.edit_info',
          message: 'Info bewerken',
        }),
        onClick: () =>
          ability.can(
            PermissionAction.CONFIGURE,
            PermissionObjectType.PLATFORM_SETTINGS
          ) && setUpdateSubject(node.id),
      },
      {
        type: 'divider',
      },
      {
        key: 'expand',
        label: t({
          id: 'branch.organisation.context_menu.expand',
          message: 'Volledig openklappen',
        }),
        onClick: () => {
          const keys = []
          expandMethod([node], 100, keys)
          setExpandedKeys((k) => [...new Set([...k, ...keys])])
        },
      },
      {
        key: 'collapse',
        label: t({
          id: 'branch.organisation.context_menu.collapse',
          message: 'Volledig dichtklappen',
        }),
        onClick: () => {
          const keys = extractAllIds([node])
          setExpandedKeys((k) => k.filter((key) => !keys.includes(key)))
        },
      },
      {
        type: 'divider',
      },
      {
        key: 'rename',
        disabled: !node.pId,
        label: t({
          id: 'branch.organisation.context_menu.rename',
          message: 'Naam wijzigen',
        }),
        onClick: () => !!node.pId && setRenameSubject(node.id),
      },
      {
        key: 'delete',
        disabled: !node.pId,
        label: t({
          id: 'branch.organisation.context_menu.delete',
          message: 'Verwijderen',
        }),
        onClick: () => !!node.pId && setDeleteSubject(node.id),
      },
    ].filter(Boolean) as ItemType[]

  return (
    <>
      <div style={{ padding: 32, position: 'relative' }}>
        {movingMode && (
          <Alert
            style={{
              position: 'absolute',
              inset: 0,
              bottom: 'auto',
              zIndex: 10,
            }}
            icon={<InfoCircleOutlined />}
            showIcon={true}
            type="warning"
            message={t({
              id: 'branch.organisation.moving_mode',
              message:
                "Je bent momenteel in de modus om entiteiten te verplaatsen. Klik op 'Opslaan' om je wijzigingen door te voeren.",
            })}
            banner={true}
          />
        )}
        <PageHeader
          ghost={false}
          className="site-page-header"
          title={t({
            id: 'branch.organisation.heading',
            message: 'Organisatie',
          })}
          style={{ backgroundColor: '#FFF' }}
          extra={
            <Affix offsetTop={50}>
              <Space hidden={!movingMode}>
                <Button
                  disabled={reordering}
                  key={'cancel'}
                  onClick={() => {
                    setTreeData(convertSimpleTreeDataToTree(data))
                    setMovingMode(false)
                  }}
                >
                  <Trans id="action.cancel">Annuleren</Trans>
                </Button>
                <Button
                  key={'save'}
                  type={'primary'}
                  loading={reordering}
                  onClick={() =>
                    reorderSections({
                      variables: {
                        sections: extractHierarchy(treeData),
                      },
                    })
                      .then(() => {
                        notification.success({
                          message: t({
                            id: 'branch.organisation.reorder.success',
                            message: 'Entiteiten succesvol opgeslagen.',
                          }),
                        })
                        setMovingMode(false)
                      })
                      .catch(errorNotifierFn)
                  }
                >
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Space>
            </Affix>
          }
        />
        {treeData && (
          <Tree
            style={
              loading
                ? {
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }
                : {}
            }
            blockNode
            showLine
            selectable={false}
            allowDrop={(item) => {
              return !!item.dragNode.pId && !!item.dropNode.pId
            }}
            draggable={{
              icon: false,
              nodeDraggable: (node: DataNode & { pId?: string }) =>
                !!node.pId &&
                !renameSubject &&
                !loading &&
                ability.can(
                  PermissionAction.UPDATE,
                  PermissionObjectType.BRANCH_HIERARCHY
                ),
            }}
            onDragStart={(info) => {
              info.event.dataTransfer.effectAllowed = 'move'
              const img = new Image()
              img.src =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
              info.event.dataTransfer.setDragImage(img, 0, 0)
            }}
            onDragOver={(info) => {
              info.event.dataTransfer.dropEffect = 'move'
            }}
            onDoubleClick={(
              _,
              node: EventDataNode<DataNode & { pId?: string }>
            ) =>
              !!node.pId &&
              ability.can(
                PermissionAction.UPDATE,
                PermissionObjectType.BRANCH_HIERARCHY
              ) &&
              setRenameSubject(node.key as string)
            }
            onDrop={onDrop}
            expandedKeys={expandedKeys}
            onExpand={(key) => setExpandedKeys(key.map(String))}
            treeData={treeData}
            titleRender={(node) =>
              node.id === renameSubject ? (
                <Input
                  defaultValue={node.title}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={true}
                  size="small"
                  onBlur={(item) => {
                    if (item.target.value !== node.title) {
                      updateSection({
                        variables: {
                          id: node.id,
                          data: {
                            name: item.target.value,
                          },
                        },
                      }).then(() =>
                        notification.success({
                          message: t({
                            id: 'branch.organisation.rename.success',
                            message: 'Entiteit succesvol hernoemd.',
                          }),
                        })
                      )
                    }
                    setRenameSubject(null)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.currentTarget.blur()
                  }}
                />
              ) : (
                <Dropdown
                  menu={{
                    items: movingMode
                      ? [
                          {
                            disabled: true,
                            key: 'moving',
                            label: t({
                              id: 'branch.organisation.context_menu.move_warning',
                              message: 'Sla je wijzigingen op om door te gaan.',
                            }),
                          },
                        ]
                      : getDropDownItems(node),
                  }}
                  trigger={['contextMenu']}
                  disabled={
                    loading ||
                    ability.cannot(
                      PermissionAction.UPDATE,
                      PermissionObjectType.BRANCH_HIERARCHY
                    )
                  }
                >
                  <div>
                    <Space>
                      {node.title}
                      {node?.meta?.invoiceable &&
                        ability.can(
                          PermissionAction.CONFIGURE,
                          PermissionObjectType.PLATFORM_SETTINGS
                        ) && <Tag color="blue">Factureerbare entiteit</Tag>}
                    </Space>
                  </div>
                </Dropdown>
              )
            }
            virtual
          />
        )}
        {!!updateSubject && (
          <UpdateHierarchySectionModal
            section_id={updateSubject}
            onCancel={() => setUpdateSubject(null)}
            onUpdateComplete={() => refetch()}
          />
        )}
        <Modal
          afterClose={() => createForm.resetFields()}
          title={t({
            id: 'branch.organisation.context_menu.add_new_section',
            message: 'Nieuwe entiteit toevoegen',
          })}
          open={!!createSubject}
          okText={t({ id: 'action.create' })}
          cancelText={t({ id: 'action.cancel' })}
          onCancel={() => setCreateSubject(null)}
          onOk={() => createForm.submit()}
        >
          <Form
            form={createForm}
            layout="vertical"
            onFinish={({ name }) =>
              createSection({
                variables: {
                  parent_id: createSubject,
                  name,
                },
              })
                .then(() => {
                  notification.success({
                    message: t({
                      id: 'branch.organisation.create_section.success',
                      message: 'Entiteit succesvol aangemaakt.',
                    }),
                  })
                  setCreateSubject(null)
                })
                .catch(errorNotifierFn)
            }
          >
            <Form.Item
              label={t({
                id: 'branch.organisation.create_section.name',
                message: 'Naam',
              })}
              name={'name'}
            >
              <Input required />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          afterClose={() => addUserForm.resetFields()}
          title={
            <Trans id="branch.organisation.add_users.title">
              Voeg gebruikers toe aan{' '}
              {addUserModalOpen?.label
                ? `'${addUserModalOpen?.label}'`
                : 'entiteit'}
            </Trans>
          }
          open={!!addUserModalOpen}
          okText={t({ id: 'action.add' })}
          okButtonProps={{ loading: assigningUsersToSection }}
          cancelText={t({ id: 'action.cancel' })}
          onCancel={() => setAddUserModalOpen(null)}
          onOk={() => addUserForm.submit()}
        >
          <Form
            disabled={assigningUsersToSection}
            form={addUserForm}
            layout="vertical"
            onFinish={(values) =>
              addUserModalOpen?.id &&
              assignUsersToSection({
                variables: {
                  section_id: addUserModalOpen.id,
                  ...values,
                },
              })
                .then(() => {
                  notification.success({
                    message: t({
                      id: 'branch.organisation.add_users.success',
                      message: 'Gebruikers succesvol toegevoegd.',
                    }),
                  })
                  setAddUserModalOpen(null)
                })
                .catch(errorNotifierFn)
            }
          >
            <Form.Item
              label={t({
                id: 'branch.organisation.add_users.users.label',
                message: 'Gebruikers',
              })}
              name={'users'}
            >
              <UserSelect mode={'multiple'} type={'branch'} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={t({
            id: 'branch.organisation.context_menu.delete.confirm',
            message: 'Ben je zeker dat je deze entiteit wilt verwijderen?',
          })}
          open={!!deleteSubject}
          okText={t({ id: 'action.delete' })}
          cancelText={t({ id: 'action.cancel' })}
          onCancel={() => setDeleteSubject(null)}
          onOk={() =>
            deleteSection({
              variables: {
                id: deleteSubject,
              },
            })
              .then(() =>
                notification.success({
                  message: t({
                    id: 'branch.organisation.context_menu.delete.success',
                    message: 'Entiteit succesvol verwijderd.',
                  }),
                })
              )
              .catch(errorNotifierFn)
              .finally(() => setDeleteSubject(null))
          }
        >
          <DeleteModalContent section={deleteSubject} />
        </Modal>
      </div>
    </>
  )
}

function DeleteModalContent({ section }: { section: string | null }) {
  const { data: count, loading } = useQuery<HierarchyUserCountQuery>(
    HIERARCHY_USER_COUNT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        section_id: section,
      },
    }
  )

  if (!section) return null
  if (loading) return <LoadSection />

  if (count?.countUsersInSection === 0)
    return (
      <Trans id="branch.organisation.delete_modal.content.no_users">
        Er zijn momenteel geen gebruikers toegewezen onder deze entiteit.
      </Trans>
    )

  if (!count?.countUsersInSection)
    return (
      <Trans id="branch.organisation.delete_modal.content.error">
        Er zijn mogelijks nog gebruikers toegewezen onder deze entiteit.
      </Trans>
    )

  return (
    <Plural
      id={'branch.organisation.delete_modal.content'}
      value={count?.countUsersInSection}
      one={`Er is momenteel # gebruiker toegewezen onder deze entiteit.`}
      other={`Er zijn momenteel # gebruikers toegewezen onder deze entiteit.`}
    />
  )
}

export function extractHierarchy(nodes, parentPath = ',') {
  if (!nodes || nodes.length === 0) return []

  let result: { _id: string; path: string }[] = []

  nodes.forEach((node) => {
    const currentPath = `${parentPath}${node.id},`
    result.push({ _id: node.id, path: currentPath })

    if (node.children && node.children.length > 0) {
      result = [...result, ...extractHierarchy(node.children, currentPath)]
    }
  })

  return result
}

export type BasicTreeItem = {
  id: string
  children: BasicTreeItem[]
  [key: string]: unknown
}

export function extractAllIds(nodes: BasicTreeItem[]) {
  let ids: string[] = []

  nodes.forEach((node) => {
    ids.push(node.id) // Assuming 'id' is the field you refer to as '_id'
    if (node.children && node.children.length > 0) {
      ids = [...ids, ...extractAllIds(node.children)]
    }
  })

  return ids
}
