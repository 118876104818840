import { t } from '@lingui/macro'
import { message } from 'antd'

export enum FileType {
  jpg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  svg = 'image/svg+xml',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt = 'text/plain',
  csv = 'text/csv',
  ico = 'image/x-icon',
  msico = 'image/vnd.microsoft.icon',
}

export const uploadValidator =
  (max_file_size: number, allowed_mime_types: FileType[]) => (file: File) => {
    const isImage = allowed_mime_types.includes(file.type as FileType)
    if (!isImage) {
      message.error(
        t({
          id: 'error.image_upload_only',
          message: 'Je kan enkel afbeeldingen uploaden.',
        })
      )
    }
    const FILE_SIZE_LIMIT = max_file_size
    const isLt2M = file.size / 1024 / 1024 < max_file_size
    if (!isLt2M) {
      message.error(
        t({
          id: 'error.upload_file_size',
          message: `Het gekozen bestand mag de limiet van ${FILE_SIZE_LIMIT} MB niet overschrijden.`,
        })
      )
    }
    return isImage && isLt2M
  }
