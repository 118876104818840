import { Checkbox } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const StepWrapper = styled.div`
  padding: 1rem;
`

export const StepsWrapper = styled.div`
  .ant-col {
    min-height: auto;
  }
  .steps-content {
    margin-top: 16px;
    padding-top: 2rem;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }

  .steps-action {
    margin-top: 24px;
    display: flex;
  }

  .ant-steps-item-title {
    line-height: 32px;
  }

  .ant-form-item-explain {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1023px) {
    .ant-form-vertical .ant-form-item-required {
      direction: ltr !important;

      &::before {
        position: absolute;
        right: -0.75rem;
      }
    }
  }

  .ant-radio-button-checked {
    font-weight: 700;
  }
`

export const CheckBoxGroup = styled(
  Checkbox.Group as unknown as AnyStyledComponent
)`
  display: flex;
  flex-direction: column;
  text-align: left;

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`
