import styled from 'styled-components'

export const LearningPathCardWrapper = styled.div`
  height: 100%;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border-radius: 5px;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.25);
  }
`

export const Title = styled.h3`
  font-size: 1.35rem;
  line-height: 130%;
  margin-bottom: 0;
`

export const Intro = styled.p`
  font-size: 1rem;
  line-height: 135%;
  margin-top: 0.5rem;
  margin-bottom: 0;
`

export const CardBody = styled.div`
  padding: 1.5rem;
`
