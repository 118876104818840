/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import TipTapImage from '@tiptap/extension-image'
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Switch,
  Tag,
  notification,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import Upload, { UploadChangeParam, UploadFile } from 'antd/lib/upload'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import slugify from 'slugify'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  ArticleQuery,
  BranchUsersNamesQuery,
  CheckArticleSlugQuery,
  CreateArticleInput,
  CreateArticleMutation,
  UpdateArticleMutation,
  UsersNamesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { Content } from '../../../courses/pages/courses/Courses.style'
import { CharacterLimitHelper } from '../../../shared/components/character-limit-helper/CharacterLimitHelper'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { RichEditor } from '../../../shared/components/rich-editor/RichEditor'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { getBase64 } from '../../../shared/utils/get-base64'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'

import CREATE_ARTICLE_MUTATION from './../../mutations/create-article.graphql'
import UPDATE_ARTICLE_MUTATION from './../../mutations/update-article.graphql'
import ARTICLE_QUERY from './../../queries/article.graphql'
import BRANCH_USERS_NAMES_QUERY from './../../queries/branch-users-names.graphql'
import CHECK_SLUG from './../../queries/check-article-slug.graphql'
import USERS_NAMES_QUERY from './../../queries/users-names.graphql'

TipTapImage.configure({
  allowBase64: true,
})

export const ArticleEdit = ({ route }: PageProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  const [form] = useForm()
  const published = Form.useWatch('published', form)
  const content = Form.useWatch('content', form)

  const [formDirty, setFormDirty] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const { data: usersNames } = useQuery<UsersNamesQuery>(USERS_NAMES_QUERY, {
    skip: !ability.can(PermissionAction.READ, PermissionObjectType.USER),
  })

  const { data: branchUsersNames } = useQuery<BranchUsersNamesQuery>(
    BRANCH_USERS_NAMES_QUERY,
    {
      skip: !ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_USER
      ),
    }
  )

  const [author, setAuthor] = useState<string>()

  const [fetchArticleData, { data: article, refetch }] =
    useLazyQuery<ArticleQuery>(ARTICLE_QUERY, {
      variables: { id },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.fetchArticleById.author) {
          setAuthor(data.fetchArticleById.author._id)
        } else if (data.fetchArticleById.externalAuthor) {
          setAuthor(data.fetchArticleById.externalAuthor.name || '')
        }
      },
    })

  const [checkSlug] = useLazyQuery<CheckArticleSlugQuery>(CHECK_SLUG, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    id && fetchArticleData()
  }, [id, fetchArticleData])

  const [updateArticle, { loading: updating }] =
    useMutation<UpdateArticleMutation>(UPDATE_ARTICLE_MUTATION)
  const [createArticle, { loading: creating }] =
    useMutation<CreateArticleMutation>(CREATE_ARTICLE_MUTATION)

  const client = useApolloClient()

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setUploading(false)
        client.refetchQueries({ include: ['articles', 'article'] })
      })
    }
  }

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploading ? (
          <Trans id="action.uploading">Uploading</Trans>
        ) : (
          <Trans id="action.upload">Upload</Trans>
        )}
      </div>
    </div>
  )

  const authorIsUser = useMemo(() => {
    return (
      usersNames?.fetchUsers.results ||
      branchUsersNames?.fetchBranchUsers.results
    )?.find((user) => new ObjectId(user._id).equals(author || ''))
  }, [usersNames, branchUsersNames, author])

  const MAX_CHARACTERS = {
    TITLE: 100,
    SLUG: 100,
    INTRO: 500,
    AUTHOR: 500,
    CONTENT: 7500,
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        tags={
          article?.fetchArticleById ? (
            article?.fetchArticleById.published ? (
              <Tag color="blue">
                <Trans id="article.tag.published">Gepubliceerd</Trans>
              </Tag>
            ) : (
              <Tag color="orange">
                <Trans id="article.tag.draft">Concept</Trans>
              </Tag>
            )
          ) : (
            <></>
          )
        }
        extra={[
          <Button
            onClick={() =>
              navigate(
                '/articles/' +
                  encodeURIComponent(article?.fetchArticleById.slug || '')
              )
            }
            key="2"
          >
            <Trans id="articles.article_edit.go_back">Ga terug</Trans>
          </Button>,
          <Button
            disabled={!formDirty}
            loading={creating || updating}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            {!id || article ? (
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={
                  id
                    ? {
                        ...(article?.fetchArticleById as Store),
                        author:
                          article?.fetchArticleById?.author?._id ||
                          article?.fetchArticleById.externalAuthor?.name ||
                          '',
                        meta: undefined,
                        publication_date: article?.fetchArticleById.published
                          ? dayjs(article?.fetchArticleById.published)
                          : undefined,
                      }
                    : undefined
                }
                onFinish={(
                  variables: CreateArticleInput & { publication_date: Date }
                ) => {
                  if (id) {
                    updateArticle({
                      variables: {
                        id,
                        ...variables,
                        author: authorIsUser ? variables.author : undefined,
                        externalAuthor: authorIsUser
                          ? undefined
                          : { name: variables.author },
                        published: variables.published
                          ? variables.publication_date
                          : null,
                      },
                      update(cache) {
                        cache.evict({
                          id: 'ROOT_QUERY',
                          fieldName: 'fetchArticles',
                        })
                        cache.gc()
                      },
                    })
                      .then(() => {
                        refetch()
                        notification.success({
                          message: t({
                            id: 'articles.article_edit.success',
                            message: 'Bericht succesvol opgeslagen.',
                          }),
                        })
                        navigate('/articles/' + id)
                      })
                      .catch(errorNotifierFn)
                  } else {
                    createArticle({
                      variables: {
                        ...variables,
                        author: authorIsUser ? variables.author : undefined,
                        externalAuthor: authorIsUser
                          ? undefined
                          : { name: variables.author },
                        published: variables.published
                          ? variables.publication_date
                          : null,
                      },
                      update(cache) {
                        cache.evict({
                          id: 'ROOT_QUERY',
                          fieldName: 'fetchArticles',
                        })
                        cache.gc()
                      },
                    })
                      .then((result) => {
                        notification.success({
                          message: t({
                            id: 'articles.article_edit.success',
                            message: 'Bericht succesvol opgeslagen.',
                          }),
                        })
                        const slug = result.data?.createArticle.slug
                        return navigate(`/articles/${slug}`, { replace: true })
                      })
                      .catch(errorNotifierFn)
                  }
                }}
                onFieldsChange={() => setFormDirty(true)}
                autoComplete="off"
              >
                {id && (
                  <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="article-image-uploader"
                      showUploadList={false}
                      action={`${
                        import.meta.env.NX_BACKEND_URL
                      }/api/files/uploadArticleImage/${id}`}
                      beforeUpload={uploadValidator(3, [
                        FileType.jpg,
                        FileType.png,
                        FileType.gif,
                      ])}
                      onChange={handleChange}
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          'aa_lms_at'
                        )}`,
                        'x-academy-host': window.location.hostname,
                      }}
                    >
                      {article?.fetchArticleById.thumbnail?.url &&
                      !uploading ? (
                        <img
                          src={article?.fetchArticleById.thumbnail?.url}
                          alt={article?.fetchArticleById.thumbnail?.alt || ''}
                          style={{
                            width: '100%',
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                )}
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.title">
                      Titel
                    </Trans>
                  }
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'articles.article_edit.form.validation.title',
                        message:
                          'Gelieve een titel voor dit bericht in te vullen',
                      }),
                    },
                    {
                      max: MAX_CHARACTERS.TITLE,
                      message: t({
                        id: 'articles.article_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.TITLE} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    onChange={debounce(async (e) => {
                      const slug = slugify(e.target.value, {
                        lower: true,
                        remove: /[!"'()*+,.:?@~]/g,
                      })
                      const { data } = await checkSlug({
                        variables: {
                          slug,
                        },
                      })
                      form.setFieldsValue({
                        slug: data?.checkArticleSlug || slug,
                      })
                    }, 250)}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.slug">
                      URL-vriendelijke naam
                    </Trans>
                  }
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'articles.article_edit.form.validation.slug',
                        message:
                          'Gelieve een URL-vriendelijke naam voor dit bericht in te vullen',
                      }),
                    },
                    {
                      max: MAX_CHARACTERS.SLUG,
                      message: t({
                        id: 'articles.article_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.SLUG} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input
                    onBlur={async (e) => {
                      const slug = slugify(e.target.value, {
                        lower: true,
                        remove: /[!"'()*+,.:?@~]/g,
                      })
                      const { data } = await checkSlug({
                        variables: {
                          slug,
                        },
                      })
                      form.setFieldsValue({
                        slug: data?.checkArticleSlug || slug,
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.intro">
                      Intro
                    </Trans>
                  }
                  name="intro"
                  rules={[
                    {
                      max: MAX_CHARACTERS.INTRO,
                      message: t({
                        id: 'articles.article_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.INTRO} tekens te blijven`,
                      }),
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.content">
                      Inhoud
                    </Trans>
                  }
                  help={
                    <CharacterLimitHelper
                      content={content}
                      max={MAX_CHARACTERS.CONTENT}
                    />
                  }
                  name="content"
                  rules={[
                    {
                      max: MAX_CHARACTERS.CONTENT,
                      message: t({
                        id: 'articles.article_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.CONTENT} tekens te blijven`,
                      }),
                    },
                    {
                      required: true,
                      message: t({
                        id: 'articles.article_edit.form.validation.content',
                        message: 'Gelieve inhoud voor dit bericht in te vullen',
                      }),
                    },
                  ]}
                >
                  <RichEditor boldHeadings Extensions={[TipTapImage]} />
                </Form.Item>
                <Divider plain />
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.author">
                      Auteur
                    </Trans>
                  }
                  name="author"
                  rules={[
                    {
                      max: MAX_CHARACTERS.AUTHOR,
                      message: t({
                        id: 'articles.article_edit.form.validation.max_characters',
                        message: `Gelieve onder de ${MAX_CHARACTERS.AUTHOR} tekens te blijven`,
                      }),
                    },
                    {
                      required: true,
                      message: t({
                        id: 'articles.article_edit.form.validation.author',
                        message:
                          'Gelieve een auteur voor dit bericht in te vullen',
                      }),
                    },
                  ]}
                >
                  {authorIsUser ? (
                    <Select
                      options={[
                        {
                          label: `${authorIsUser.firstName} ${authorIsUser.lastName}`,
                          value: authorIsUser._id,
                        },
                      ]}
                      value={author}
                      onChange={(value) => setAuthor(value)}
                      allowClear
                    />
                  ) : (
                    <AutoComplete
                      options={
                        (
                          usersNames?.fetchUsers.results ||
                          branchUsersNames?.fetchBranchUsers.results
                        )?.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user._id,
                        })) || []
                      }
                      onSelect={(value) => setAuthor(value)}
                      value={author}
                      filterOption={(inputValue, option) =>
                        option?.label
                          ?.toUpperCase()
                          .includes(inputValue.toUpperCase()) || false
                      }
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.status">
                      Status
                    </Trans>
                  }
                  name="published"
                  valuePropName="checked"
                >
                  <Switch
                    unCheckedChildren={
                      <Trans id="article.tag.draft">Concept</Trans>
                    }
                    checkedChildren={
                      <Trans id="article.tag.published">Gepubliceerd</Trans>
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="articles.article_edit.form.label.publication_date">
                      Publicatiedatum
                    </Trans>
                  }
                  name="publication_date"
                  hidden={!published}
                  initialValue={dayjs().startOf('day')}
                  required={!!published}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                  <Button
                    disabled={!formDirty}
                    loading={creating || updating}
                    type="primary"
                    htmlType={'submit'}
                  >
                    <Trans id="articles.article_edit.form.submit">
                      Artikel opslaan
                    </Trans>
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <LoadScreen />
            )}
          </Col>
        </Row>
      </Content>
    </>
  )
}
