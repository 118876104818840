import styled from 'styled-components'

export const VendorCardWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2rem;
  border-bottom: solid 5px ${(props) => props.color};
`

export const VendorCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const VendorLogoWrapper = styled.div`
  img {
    max-width: 9rem;
    max-height: 2.5rem;
  }
`

export const VerticalDivider = styled.div`
  background: #e8e8e8;
  width: 2px;
  height: 2.5rem;
`

export const VendorContact = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 500;
    margin: 0;
    line-height: 1.25;
    padding-left: 0.75rem;
    vertical-align: middle;
  }
`

export const VendorCTA = styled.div`
  cursor: pointer;
  font-weight: 600;
  * {
    color: var(--primary-color);
  }
`

export const UnderlineCTA = styled.div`
  font-size: 0.875rem;
  line-height: 1.1;
  border-bottom: solid 1px;
`

export const VendorCoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.75rem;
  margin-top: 2rem;
`
