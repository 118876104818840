import { CheckCircleTwoTone } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Scanner } from '@yudiel/react-qr-scanner'
import { Button, Result, Select, Space, notification } from 'antd'
import { ObjectId } from 'bson'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { URL } from 'url'
import { useWindowSize } from 'usehooks-ts'

import {
  CourseCertificationTypesQuery,
  VodEventMetaQuery,
  JoinVodEventMutation,
} from 'apps/lms-front/src/generated/graphql'
import { LoadScreen } from 'apps/lms-front/src/modules/core/components/LoadScreen'

import JOIN_EVENT_MUTATION from '../../mutations/join-vod-event.graphql'
import COURSE_CERTIFICATION_TYPES_QUERY from '../../queries/course-certification-type.graphql'
import EVENT_META_QUERY from '../../queries/vod-event-meta.graphql'

const ConfirmJoin = ({ event }: { event: VodEventMetaQuery }) => {
  const navigate = useNavigate()
  const [selectedCertificationType, setSelectedCertificationType] =
    useState<string>()

  const [joinEvent, { loading }] = useMutation<JoinVodEventMutation>(
    JOIN_EVENT_MUTATION,
    {
      variables: {
        id: event.fetchVODEventMeta._id,
        certification_id: selectedCertificationType,
      },
      refetchQueries: ['myActiveVODEvents'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate('/remote')
      },
      onError: (error) => {
        navigate('/')
        notification.error({
          message: error.message,
        })
      },
    }
  )

  const { data: certificationTypes, loading: certificationTypesLoading } =
    useQuery<CourseCertificationTypesQuery>(COURSE_CERTIFICATION_TYPES_QUERY, {
      variables: {
        id: event.fetchVODEventMeta.course?._id,
      },
      onCompleted: (data) => {
        if (data.fetchCourseById.certificationType?.[0]._id)
          setSelectedCertificationType(
            data.fetchCourseById.certificationType[0]._id
          )
      },
    })

  if (event.fetchVODEventMeta.my_activity?.completed) {
    return (
      <Result
        icon={<CheckCircleTwoTone />}
        title={t({
          id: 'routes.label.already-completed',
          message: 'Je hebt deze sessie al voltooid',
        })}
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Terug
          </Button>
        }
      ></Result>
    )
  }

  if (
    event.fetchVODEventMeta.my_activity?.started &&
    !event.fetchVODEventMeta.my_activity?.left
  ) {
    return (
      <Result
        icon={<CheckCircleTwoTone />}
        title={t({
          id: 'routes.label.already-joined',
          message: 'Je bent al lid van deze sessie!',
        })}
        extra={
          <Button type="primary" onClick={() => navigate('/remote')}>
            Terug
          </Button>
        }
      ></Result>
    )
  }

  return (
    <Result
      icon={
        <img
          src={event?.fetchVODEventMeta.course?.image?.url}
          alt=""
          style={{ width: 480, maxWidth: '100%' }}
        />
      }
      title={t({
        id: 'routes.label.join',
        message: 'Deelnemen aan groepsessie',
      })}
      subTitle={
        <Space direction="vertical" size="large">
          <Trans id="event.join.confirm.title">
            Bevestig je deelname aan de opleiding: &quot;
            {event?.fetchVODEventMeta.course?.name}&quot;
          </Trans>
          {!event.fetchVODEventMeta.my_activity?.started &&
          certificationTypes?.fetchCourseById.certificationType?.length ? (
            <Select
              allowClear={true}
              size="large"
              loading={certificationTypesLoading}
              style={{ width: '20rem', maxWidth: '80vw' }}
              placeholder={
                <Trans id="event.join.select.certification_type">
                  Selecteer een certificeringstype
                </Trans>
              }
              options={
                certificationTypes?.fetchCourseById.certificationType?.map(
                  (cert) => ({
                    label: cert.name,
                    value: cert._id,
                  })
                ) || []
              }
              value={selectedCertificationType}
              onChange={(value) => setSelectedCertificationType(value)}
            />
          ) : null}
        </Space>
      }
      extra={
        <Space>
          <Button loading={loading} type="primary" onClick={() => joinEvent()}>
            <Trans id="action.confirm">Bevestigen</Trans>
          </Button>
          <Button disabled={loading} onClick={() => navigate('/')}>
            <Trans id="action.cancel">Annuleren</Trans>
          </Button>
        </Space>
      }
    ></Result>
  )
}

export const JoinVodEventPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { width, height } = useWindowSize()

  const { data: event, loading } = useQuery<VodEventMetaQuery>(
    EVENT_META_QUERY,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    }
  )

  const handleQRCodeScanned = (data: string) => {
    const url = new URL(data)
    if (url.pathname.startsWith('/join/')) {
      const eventId = data.split('/').pop()
      if (eventId && ObjectId.isValid(eventId))
        return navigate(`/join/${eventId}`)
    }

    notification.error({
      message: t({
        id: 'qr-code.invalid',
        message: 'Ongeldige QR-code voor deze applicatie',
      }),
    })
  }

  if (loading) {
    return <LoadScreen />
  }

  if (event) return <ConfirmJoin event={event} />

  const size = Math.min(height - 144, width - 64)

  return (
    <>
      <center>
        <Scanner
          enabled
          onResult={handleQRCodeScanned}
          styles={{ container: { width: size, paddingTop: size } }}
        />
      </center>
    </>
  )
}
