import styled from 'styled-components'

export const ApiKey = styled.div`
  margin: 0 auto;
  font-family: monospace;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #f0f0f0;
  cursor: pointer;
  user-select: none;
`
