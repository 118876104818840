import styled from 'styled-components'

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
  width: 100%;
  min-height: 600px;

  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`

export const MainScreen = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;

  & > div {
    width: 100%;
    height: auto;
  }

  & .lk-focus-toggle-button {
    display: none;
  }
`

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`
export const SideBarTiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  flex: 1;
  height: 100%; // Use full height of the parent
  padding-right: 10px; // Add some padding for the scrollbar

  /* Show scrollbar for all browsers */
  &::-webkit-scrollbar {
    width: 6px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
    max-height: 30vh; // Maintain the mobile view adjustment
  }
`

export const SideBarTile = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  cursor: pointer;
  min-height: 150px; // Set a minimum height
  flex-shrink: 0; // Prevent tiles from shrinking

  // hide the focus toggle button
  & .lk-focus-toggle-button {
    display: none !important;
  }
`
export const ControlsOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  border-radius: 4px;

  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
  }
`
export const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  font-size: 14px;
  text-align: center;

    margin-top: 10px;
  }
`

export const ControlPanelWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  height: auto !important;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
`

export const ChatContainer = styled.div`
  height: 300px;
  overflow-y: auto;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
`
