import { t } from '@lingui/macro'
import { ObjectId } from 'bson'
import { useMemo } from 'react'

import {
  SurveyQuestion,
  UserSurveyUnitActivity,
} from 'apps/lms-front/src/generated/graphql'

import { LoadSection } from '../../../core/components/LoadScreen'
import { Table } from '../Table'

export const UserCourseActivitySurveyReport = ({
  questions,
  answers,
}: {
  questions: SurveyQuestion[]
  answers: UserSurveyUnitActivity[]
}) => {
  const data = useMemo(() => {
    return questions.map((question) => {
      const match = answers?.find((answer) =>
        new ObjectId(answer.question_id).equals(question._id)
      )
      return {
        ...question,
        user_answers: match?.user_answers.join(', '),
      }
    })
  }, [questions, answers])

  if (!data?.length) return <LoadSection />
  return (
    <Table
      columns={[
        {
          title: t({
            id: 'reports.survey_report.table.question',
            message: 'Vraag',
          }),
          dataIndex: 'question',
          key: 'question',
        },
        {
          title: t({
            id: 'reports.survey_report.table.user_answers',
            message: 'Gegeven antwoord',
          }),
          dataIndex: 'user_answers',
          key: 'user_answers',
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
    />
  )
}
