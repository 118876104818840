import { ApolloQueryResult } from '@apollo/client'
import { createContext } from 'react'

import {
  LoginInput,
  MeQuery,
  UserProfile,
} from 'apps/lms-front/src/generated/graphql'

interface AuthContextType {
  user?: UserProfile | MeQuery['me'] | null
  login: (loginInput: LoginInput) => Promise<void | ApolloQueryResult<MeQuery>>
  loginWithMicrosoftAzureAd: () => void
  loginWithMagicLinkToken: (
    token: string,
    callback: VoidFunction,
    catchFn?: (reason: { message?: string }) => unknown
  ) => void
  verifyMagicCode: (
    email: string,
    code: string,
    callback: VoidFunction,
    catchFn?: (reason: { message?: string }) => unknown
  ) => void
  logout: (callback?: VoidFunction) => void
  loading: boolean
  token: string
}

export const AuthContext = createContext<AuthContextType>(null!)
