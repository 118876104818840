import { DeleteOutlined, EditOutlined, HolderOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverEvent,
  DragOverlay,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Form,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Tooltip,
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { useForm } from 'antd/lib/form/Form'
import { ObjectId } from 'bson'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
  CourseContentActionKind,
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import {
  CourseQuery,
  CourseSection,
  DispatchCourseContentActionMutation,
  Unit,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import { Prompt } from '../../../shared/components/prompt/Prompt'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Content } from '../../../shared/layout/Layout.style'
import { EditSectionContents } from '../../components/edit-section-contents'

import DISPATCH_COURSE_CONTENT_ACTION_MUTATION from './../../mutations/dispatch-course-content-action.graphql'
import COURSE_QUERY from './../../queries/course.graphql'
import {
  PresentationalSortableTimelineItem,
  SortableSectionItem,
} from './SortableSectionItem'

export const CourseEditContents = ({ route }: PageProps) => {
  const params = useParams()
  const { id } = params
  const parent = getParentRoute(route, params)
  const navigate = useNavigate()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const [draggable, setDraggable] = useState<boolean>(false)

  const [order, setOrder] = useState<string[]>([])
  const [orderSaved, setOrderSaved] = useState<boolean>(true)
  const [contents, setContents] = useState<
    CourseQuery['fetchCourseById']['contents']
  >([])

  const [addSectionForm] = useForm()

  const ability = useContext(AbilityContext)

  const { data: course, loading } = useQuery<CourseQuery>(COURSE_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!course) return

    const canUpdate =
      ability.can(PermissionAction.UPDATE, PermissionObjectType.COURSE) ||
      (ability.can(
        PermissionAction.UPDATE,
        subject(PermissionObjectType.BRANCH_COURSE, course.fetchCourseById)
      ) &&
        !course.fetchCourseById.readonly) ||
      (ability.can(
        PermissionAction.UPDATE,
        subject(PermissionObjectType.OWN_COURSE, course.fetchCourseById)
      ) &&
        !course.fetchCourseById.readonly)

    if (!canUpdate) {
      navigate(parent)
    }
  }, [course, ability, navigate, parent])

  useEffect(() => {
    if (course && course.fetchCourseById.contents) {
      setOrder(
        [...course.fetchCourseById.contents]
          .sort((a, b) => a.order - b.order)
          .map((item) => item._id)
      )
      setContents(course.fetchCourseById.contents)
    }
  }, [course])

  const [dispatch] = useMutation<DispatchCourseContentActionMutation>(
    DISPATCH_COURSE_CONTENT_ACTION_MUTATION,
    { variables: { id } }
  )

  if (!id) return null

  if (loading || !course) return <LoadScreen />

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event
    const { id } = active
    const overId = over?.id

    if (!overId || id === overId) return

    setContents((contents) => {
      const activeItemsSection = contents.find((section) =>
        section.units.find((unit) =>
          new ObjectId((unit as Unit)._id).equals(id)
        )
      )
      const activeItems = activeItemsSection?.units
      const activeItemsIds = activeItems?.map((unit) => (unit as Unit)._id)
      const overItemsSection =
        contents.find((section) =>
          section.units.find((unit) =>
            new ObjectId((unit as Unit)._id).equals(overId)
          )
        ) ||
        contents.find((section) => new ObjectId(section._id).equals(overId))

      const overItems = overItemsSection?.units
      const overItemsIds = overItems?.map((unit) => (unit as Unit)._id)

      if (!overItems || !activeItems) return contents

      const activeIndex = activeItemsIds?.indexOf(id)
      const overIndex = overItemsIds?.indexOf(overId) || 0

      if (overIndex === undefined || activeIndex === undefined) return contents

      let newIndex: number

      if (contents.some((section) => new ObjectId(section._id).equals(id))) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1
      } else {
        newIndex = overIndex >= 0 ? overIndex : overItems.length + 1
      }
      return contents.map((section) => {
        if (
          new ObjectId(section._id).equals(activeItemsSection?._id) &&
          new ObjectId(section._id).equals(overItemsSection?._id)
        ) {
          return {
            ...section,
            units: [
              ...section.units
                .filter((unit) => (unit as Unit)._id !== id)
                .slice(0, newIndex)
                .map((unit, i) => ({ ...unit, order: i })),
              {
                ...activeItems.find((unit) =>
                  new ObjectId((unit as Unit)._id).equals(id)
                ),
                order: newIndex,
              },
              ...section.units
                .filter((unit) => (unit as Unit)._id !== id)
                .slice(newIndex)
                .map((unit, i) => ({ ...unit, order: i + newIndex + 1 })),
            ]
              .filter(Boolean)
              .map(
                (unit) =>
                  unit as CourseQuery['fetchCourseById']['contents'][0]['units'][0]
              ),
          }
        } else if (new ObjectId(section._id).equals(activeItemsSection?._id)) {
          return {
            ...section,
            units: section.units
              .filter((unit) => (unit as Unit)._id !== id)
              .map((unit, i) => ({ ...unit, order: i })),
          }
        } else if (new ObjectId(section._id).equals(overItemsSection?._id)) {
          return {
            ...section,
            units: [
              ...section.units
                .slice(0, newIndex)
                .map((unit, i) => ({ ...unit, order: i })),
              {
                ...activeItems.find((unit) =>
                  new ObjectId((unit as Unit)._id).equals(id)
                ),
                order: newIndex,
              },
              ...section.units
                .slice(newIndex)
                .map((unit, i) => ({ ...unit, order: i + newIndex + 1 })),
            ]
              .filter(Boolean)
              .map(
                (unit) =>
                  unit as CourseQuery['fetchCourseById']['contents'][0]['units'][0]
              ),
          }
        }
        return section
      })
    })
  }

  return (
    <>
      <Content>
        <PageHeader
          ghost={false}
          className="site-page-header"
          title={
            draggable ? (
              <Trans id="course.edit_contents.change_unit_order.title">
                Onderdelen verplaatsen voor ${course?.fetchCourseById.name}
              </Trans>
            ) : (
              <span>
                <Trans id="course.edit_contents.header">
                  Inhoud bewerken voor{' '}
                  <Link to={parent}>{course?.fetchCourseById.name}</Link>
                </Trans>
              </span>
            )
          }
          extra={
            <Button onClick={() => navigate(parent)}>
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          }
          style={{ padding: 0, marginBottom: 12 }}
        />
        <Row gutter={16}>
          <Col xs={24} md={16} style={{ marginBottom: 16 }}>
            {contents.length === 0 && (
              <Empty
                description={t({
                  id: 'course.edit_contents.empty',
                  message: 'Deze opleiding bevat nog geen inhoud',
                })}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
            {contents.length > 0 && (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragOver={handleDragOver}
              >
                <DragOverlay>
                  <PresentationalSortableTimelineItem />
                </DragOverlay>
                <Collapse defaultActiveKey={contents.map((_) => _._id)}>
                  {[...contents]
                    .sort((a, b) => a.order - b.order)
                    .map((section) => (
                      <CollapsePanel
                        header={
                          <span>
                            <strong>
                              <Trans id="course.edit_contents.section">
                                Sectie
                              </Trans>
                              :
                            </strong>{' '}
                            {section.title}
                          </span>
                        }
                        key={section._id}
                        extra={
                          <>
                            {!draggable && (
                              <div
                                role={'none'}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Space>
                                  <Prompt
                                    trigger={(triggerProps) => (
                                      <Tooltip
                                        title={t({
                                          id: 'course.edit_contents.edit_section',
                                          message: 'Sectie bewerken',
                                        })}
                                      >
                                        <EditOutlined {...triggerProps} />
                                      </Tooltip>
                                    )}
                                    defaultValue={section.title}
                                    onSubmit={(value) =>
                                      dispatch({
                                        variables: {
                                          type: CourseContentActionKind.UPDATE_SECTION_TITLE,
                                          id: section._id,
                                          value,
                                        },
                                      })
                                    }
                                  >
                                    <Trans id="course.edit_contents.edit_section">
                                      Sectie bewerken
                                    </Trans>
                                  </Prompt>

                                  <Tooltip
                                    title={t({
                                      id: 'course.edit_contents.remove_section',
                                      message: 'Sectie verwijderen',
                                    })}
                                  >
                                    <Popconfirm
                                      disabled={section.units.length > 0}
                                      title={t({
                                        id: 'course.edit_contents.remove_section.title',
                                        message:
                                          'Ben je zeker dat je deze sectie wil verwijderen?',
                                      })}
                                      onConfirm={(e) => {
                                        e?.stopPropagation()
                                        dispatch({
                                          variables: {
                                            type: CourseContentActionKind.REMOVE_SECTION,
                                            id: section._id,
                                          },
                                        })
                                      }}
                                      onCancel={() => {
                                        return false
                                      }}
                                      okText={t({
                                        id: 'action.confirm',
                                        message: 'Bevestigen',
                                      })}
                                      cancelText={t({
                                        id: 'action.cancel',
                                        message: 'Annuleren',
                                      })}
                                    >
                                      <DeleteOutlined
                                        disabled={section.units.length > 0}
                                        onClick={(event) => {
                                          event.stopPropagation()
                                          if (section.units.length > 0)
                                            message.error(
                                              t({
                                                id: 'error.cannot_remove_section_with_units',
                                                message:
                                                  'Je kan geen sectie verwijderen die nog onderdelen bevat.',
                                              })
                                            )
                                        }}
                                      />
                                    </Popconfirm>
                                  </Tooltip>
                                </Space>
                              </div>
                            )}
                          </>
                        }
                      >
                        <EditSectionContents
                          courseId={id}
                          section={section as CourseSection}
                          draggable={draggable}
                        />
                      </CollapsePanel>
                    ))}
                </Collapse>
              </DndContext>
            )}
          </Col>
          <Col xs={24} md={8}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              {!draggable && (
                <Card
                  title={t({
                    id: 'course.edit_contents.add_section.title',
                    message: 'Sectie toevoegen',
                  })}
                >
                  <Form
                    form={addSectionForm}
                    name="add-section"
                    autoComplete="off"
                    onFinish={(form) => {
                      dispatch({
                        variables: {
                          type: CourseContentActionKind.ADD_SECTION,
                          value:
                            form.name ||
                            t({
                              id: 'course.edit_contents.add_section.default_name',
                              message: 'Nieuwe sectie',
                            }),
                        },
                      })
                      addSectionForm.resetFields()
                    }}
                  >
                    <Form.Item name="name">
                      <Input
                        placeholder={t({
                          id: 'course.edit_contents.add_section.placeholder',
                          message: 'Vul de naam van de sectie in',
                        })}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        <Trans id="course.edit_contents.add_section.submit">
                          Nieuwe sectie toevoegen
                        </Trans>
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              )}
              <Card>
                {draggable && (
                  <Button
                    type={draggable ? 'primary' : 'default'}
                    onClick={() => {
                      const order = contents.map((section) => ({
                        _id: section._id,
                        units: section.units.map((unit) => ({
                          _id: (unit as Unit)._id,
                          order: (unit as Unit).order,
                        })),
                      }))
                      dispatch({
                        variables: {
                          type: CourseContentActionKind.CHANGE_CONTENT_ORDER,
                          value: JSON.stringify(order),
                        },
                      })
                      setDraggable((val) => !val)
                    }}
                  >
                    <Trans id="course.edit_contents.change_unit_order">
                      Volgorde lesonderdelen opslaan
                    </Trans>
                  </Button>
                )}
                {!draggable && (
                  <Button
                    type={draggable ? 'primary' : 'default'}
                    onClick={() => {
                      setDraggable((val) => !val)
                    }}
                  >
                    <Trans id="course.edit_contents.change_unit_order.submit">
                      Lesonderdelen verplaatsen
                    </Trans>
                  </Button>
                )}
              </Card>
              {contents.length > 1 && !draggable && (
                <Card
                  title={t({
                    id: 'course.edit_contents.change_section_order.title',
                    message: 'Volgorde secties wijzigen',
                  })}
                  bodyStyle={{ padding: 0 }}
                >
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(event) => {
                      const { active, over } = event

                      if (over && active.id !== over.id) {
                        setOrder((order) => {
                          const oldIndex = order.indexOf(active.id)
                          const newIndex = order.indexOf(over.id)

                          return arrayMove(order, oldIndex, newIndex)
                        })
                        setOrderSaved(false)
                      }
                    }}
                  >
                    <SortableContext
                      items={order}
                      strategy={verticalListSortingStrategy}
                    >
                      {[...contents]
                        .sort(
                          (a, b) => order.indexOf(a._id) - order.indexOf(b._id)
                        )
                        .map((item) => (
                          <SortableSectionItem key={item._id} id={item._id}>
                            <HolderOutlined style={{ marginRight: 8 }} />
                            {item.title}
                          </SortableSectionItem>
                        ))}
                    </SortableContext>
                  </DndContext>
                  <Button
                    type="primary"
                    disabled={orderSaved}
                    style={{ margin: 24, marginTop: 16 }}
                    onClick={() => {
                      setOrderSaved(true)
                      dispatch({
                        variables: {
                          type: CourseContentActionKind.CHANGE_SECTION_ORDER,
                          value: JSON.stringify(order),
                        },
                      }).catch(() => setOrderSaved(false))
                    }}
                  >
                    <Trans id="course.edit_contents.change_section_order.submit">
                      Volgorde opslaan
                    </Trans>
                  </Button>
                </Card>
              )}
            </Space>
          </Col>
        </Row>
      </Content>
    </>
  )
}
