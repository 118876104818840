// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import { ApolloQueryResult } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Alert, Checkbox, Form, Input, Radio, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ObjectId } from 'bson'
import { forwardRef, useImperativeHandle, useMemo } from 'react'

import {
  PublicUnitQuery,
  QuizAnswer,
  QuizQuestion,
  SurveyQuestionType,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'

import { Question } from '../quiz-unit-viewer/QuizUnitViewer.styles'

export type QuizViewerRef = {
  submit: () => void
}

type QuizViewerProps = {
  unit: PublicUnitQuery['fetchUnitById'] & {
    __typename: 'QuizUnit' | 'VideoUnit' | 'SurveyUnit'
  }
  refetchUnit: (() => Promise<ApolloQueryResult<PublicUnitQuery>>) | undefined
  questionIndex: number
  onQuestionIndexChange: (index: number) => void
  onLoadingStateChange?: (loading: boolean) => void
  finishButton?: React.ReactNode
}

export const QuizViewer = forwardRef<QuizViewerRef, QuizViewerProps>(
  (
    {
      unit,
      refetchUnit,
      onLoadingStateChange,
      questionIndex,
      onQuestionIndexChange,
      finishButton,
    }: QuizViewerProps,
    ref
  ) => {
    const questions = useMemo<
      (
        | (PublicUnitQuery['fetchUnitById'] & {
            __typename: 'QuizUnit'
          })['quizUnitTranslation']['questions'][0]
        | (PublicUnitQuery['fetchUnitById'] & {
            __typename: 'SurveyUnit'
          })['surveyUnitTranslation']['questions'][0]
      )[]
    >(() => {
      if (unit.__typename === 'QuizUnit')
        return unit.quizUnitTranslation.questions

      if (unit.__typename === 'SurveyUnit')
        return unit.surveyUnitTranslation.questions

      if (unit.__typename === 'VideoUnit')
        return [
          ...unit.videoUnitTranslation.questions,
          ...unit.videoUnitTranslation.survey_questions.map((q) => ({
            ...q,
            answer_type: SurveyQuestionType.MultipleChoice,
          })),
        ]

      return []
    }, [unit])

    const question = questions[questionIndex]

    const quizComplete = useMemo(() => {
      switch (unit.__typename) {
        case 'QuizUnit':
        case 'SurveyUnit': {
          return unit.my_activity?.completed
        }
        case 'VideoUnit': {
          return (
            unit.videoUnitTranslation.questions.length +
              unit.videoUnitTranslation.survey_questions.length ===
            questionIndex
          )
        }
        default: {
          return false
        }
      }
    }, [unit, questionIndex])

    const [form] = useForm()

    const goToNextQuestion = () => {
      onQuestionIndexChange(questionIndex + 1)
    }

    useImperativeHandle(ref, () => ({
      submit: form.submit,
    }))

    if (
      quizComplete &&
      (unit.__typename === 'QuizUnit' ||
        (unit.__typename === 'VideoUnit' &&
          unit.videoUnitTranslation.questions.some(
            (q) => q.__typename === 'QuizQuestion'
          )))
    )
      return (
        <article className="quiz-unit">
          <p>
            <Trans id="unit.viewer.video_quiz_results.description">
              Je diende deze toets in en behaalde een score van{' '}
              <strong>{Math.round((unit.my_score || 0) * 100)}%</strong>.
              Hieronder een overzicht van je antwoorden.
            </Trans>
          </p>
          <div className="review-results" style={{ padding: '1.5rem' }}>
            <Space direction="vertical" size="large">
              {questions.map((question) => {
                const quiz_answer = unit.my_activity?.quiz_answers?.find(
                  (answer) =>
                    new ObjectId(answer.question_id).equals(question._id)
                )
                if (!quiz_answer) return

                return (
                  <div key={'question:' + question._id}>
                    <h3 style={{ marginBottom: 24 }}>{question.question}</h3>
                    <p style={{ marginBottom: 16 }}>
                      <strong>
                        <Trans id="unit.viewer.quiz_results.your_answer">
                          Jouw antwoord
                        </Trans>
                        :
                      </strong>{' '}
                      {quiz_answer.user_answers
                        .map((answer) => {
                          const selected_answer = question.answers.find(
                            (a) => a.answer === answer
                          )
                          return (
                            selected_answer?.translation?.answer ??
                            selected_answer?.answer
                          )
                        })
                        .join(', ')}
                      {quiz_answer.correct ? (
                        <span> ✅</span>
                      ) : (
                        <span> ❌</span>
                      )}
                    </p>
                    {!quiz_answer.correct && (
                      <p>
                        <strong>
                          <Trans id="unit.viewer.quiz_results.correct_answer">
                            Correct antwoord
                          </Trans>
                          :
                        </strong>{' '}
                        {quiz_answer.correct_answers
                          .map((answer) => {
                            const correct_answer = question.answers.find(
                              (a) => a.answer === answer
                            )
                            return (
                              correct_answer?.translation?.answer ??
                              correct_answer?.answer
                            )
                          })
                          .join(', ')}
                      </p>
                    )}
                    {'explanation' in question && question.explanation && (
                      <p>
                        <strong>
                          <Trans id="unit.viewer.quiz_results.explanation">
                            Uitleg
                          </Trans>
                          :
                        </strong>{' '}
                        <span>{question.explanation}</span>
                      </p>
                    )}
                  </div>
                )
              })}
              {finishButton}
            </Space>
          </div>
        </article>
      )
    if (
      quizComplete &&
      (unit.__typename === 'SurveyUnit' ||
        (unit.__typename === 'VideoUnit' &&
          unit.videoUnitTranslation.questions.every(
            (q) => q.__typename !== 'QuizQuestion'
          )))
    ) {
      return (
        <Space
          direction="vertical"
          size="large"
          style={{ width: '100%', alignItems: 'center' }}
        >
          <Alert
            type="info"
            message={
              unit.my_activity?.completed ? (
                <Trans id="unit.viewer.survey.already_completed">
                  Je hebt deze enquête succesvol ingediend op{' '}
                  {new Date(unit.my_activity?.completed).toLocaleDateString()}{' '}
                  om{' '}
                  {new Date(unit.my_activity?.completed).toLocaleTimeString()}.
                </Trans>
              ) : (
                <Trans id="unit.viewer.survey.already_finished">
                  Je hebt deze enquête succesvol ingediend.
                </Trans>
              )
            }
          />
          {finishButton}
        </Space>
      )
    }
    return (
      <article className="quiz-unit">
        {questionIndex < questions.length && (
          <div className="steps-content" style={{ padding: '1.5rem' }}>
            <Question>{question.question}</Question>
            <Form
              form={form}
              onFinish={async (result) => {
                onLoadingStateChange?.(true)
                const given_answer = questions
                  .map((question, i) => {
                    const user_answer = result[`userAnswers[${i}]`]
                    return question.__typename === 'QuizQuestion'
                      ? {
                          unit_id: unit._id,
                          quiz_progress: {
                            question_id: question._id,
                            user_answers: Array.isArray(user_answer)
                              ? user_answer
                              : user_answer
                              ? [user_answer]
                              : null,
                          },
                        }
                      : {
                          unit_id: unit._id,
                          survey_progress: {
                            question_id: question._id,
                            user_answers: Array.isArray(user_answer)
                              ? user_answer
                              : user_answer
                              ? [user_answer]
                              : null,
                          },
                        }
                  })
                  .find(
                    (i) => (i.quiz_progress || i.survey_progress).user_answers
                  )

                await logActivity({ ...given_answer }).catch(errorNotifierFn)

                if (
                  questionIndex === questions.length - 1 &&
                  unit.__typename === 'VideoUnit'
                ) {
                  await refetchUnit?.()
                    .then(() => {
                      goToNextQuestion()
                    })
                    .catch(errorNotifierFn)
                    .finally(() => onLoadingStateChange?.(false))
                  return
                }

                if (
                  questionIndex === questions.length - 1 &&
                  unit.__typename !== 'VideoUnit'
                ) {
                  await logActivity({ unit_id: unit._id, completed: true })
                    .then(async () => await refetchUnit?.())
                    .then(() => goToNextQuestion())
                    .catch(errorNotifierFn)
                    .finally(() => onLoadingStateChange?.(false))
                  return
                }

                onLoadingStateChange?.(false)
                goToNextQuestion()
              }}
            >
              {question.__typename === 'QuizQuestion' && (
                <Form.Item
                  name={`userAnswers[${questionIndex}]`}
                  required
                  rules={[
                    {
                      required: true,
                      message: t({
                        id: 'unit.viewer.video.quiz.validation.answer',
                        message: 'Gelieve een antwoord te selecteren',
                      }),
                    },
                  ]}
                >
                  {(question as QuizQuestion).answers.filter(
                    (answer: QuizAnswer) => answer.is_correct
                  ).length > 1 ? (
                    <Checkbox.Group style={{ textAlign: 'left', fontSize: 18 }}>
                      <Space direction="vertical">
                        {question.answers.map((answer) => (
                          <Checkbox
                            key={questionIndex + answer.answer}
                            value={answer.answer}
                          >
                            {answer.translation?.answer ?? answer.answer}
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  ) : (
                    <Radio.Group style={{ textAlign: 'left', fontSize: 18 }}>
                      <Space direction="vertical">
                        {question.answers.map((answer) => (
                          <Radio
                            key={questionIndex + answer.answer}
                            value={answer.answer}
                          >
                            {answer.translation?.answer ?? answer.answer}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  )}
                </Form.Item>
              )}
              {question.__typename === 'SurveyQuestion' &&
                question.answer_type === SurveyQuestionType.OpenEnded && (
                  <Form.Item
                    name={`userAnswers[${questionIndex}]`}
                    required
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'unit.viewer.survey.validation.open_ended_answer',
                          message: 'Gelieve een antwoord in te vullen',
                        }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{
                        marginLeft: 24,
                        marginRight: 24,
                        width: 'calc(100% - 48px)',
                        height: 100,
                      }}
                    />
                  </Form.Item>
                )}
              {question.__typename === 'SurveyQuestion' &&
                question.answer_type === SurveyQuestionType.MultipleChoice && (
                  <Form.Item
                    name={`userAnswers[${questionIndex}]`}
                    required
                    rules={[
                      {
                        required: true,
                        message: t({
                          id: 'unit.viewer.survey.validation.multiple_choice_answer',
                          message: 'Gelieve een antwoord te selecteren',
                        }),
                      },
                    ]}
                  >
                    <Radio.Group style={{ textAlign: 'left', fontSize: 18 }}>
                      <Space direction="vertical">
                        {question.answers.map((answer) => (
                          <Radio
                            key={questionIndex + answer.answer}
                            value={answer.answer}
                          >
                            {answer.translation?.answer ?? answer.answer}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}

              <div
                className="steps-action"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {finishButton}
              </div>
            </Form>
          </div>
        )}
      </article>
    )
  }
)
