import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { cleanHostname } from '../utils/branch'

export const HostGuard = ({ children }: { children: React.ReactNode }) => {
  const branch = useBranch()

  if (
    !(
      branch?.hostnames.includes(cleanHostname(window.location.hostname)) ||
      branch?.hostnames.includes('*')
    )
  )
    return null
  return <>{children}</>
}
