import { Layout, Avatar as AntAvatar, Menu as AntMenu } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

const { Header: AntHeader, Content: AntContent } = Layout

export const Logo = styled.img`
  height: 2.5rem;
  width: auto;
  margin-right: 1rem;
`

export const Header = styled(AntHeader as unknown as AnyStyledComponent)`
  box-sizing: border-box;
  display: flex;
  height: 4.5rem;
  line-height: 4.5rem;
  padding: 1rem;
  @media screen and (max-width: 991px) {
    .user-menu,
    .desktop-nav-menu {
      display: none;
    }
  }
  @media screen and (min-width: 992px) {
    .menu-button {
      display: none;
    }
  }
`

export const Container = styled(AntContent as unknown as AnyStyledComponent)`
  width: 100%;
  max-width: 80rem;
  padding: 0 1rem;
  margin: 0 auto;

  @media screen and (min-width: 105rem) {
    max-width: 96rem;
  }
`

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1279px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Menu = styled(AntMenu as unknown as AnyStyledComponent)`
  flex: 1;
`

export const NavMenu = styled(AntMenu as unknown as AnyStyledComponent)`
  flex: 1;
  box-sizing: border-box;
  height: 4.5rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`

export const Avatar = styled(AntAvatar as unknown as AnyStyledComponent)`
  cursor: pointer;
`

export const AvatarLabel = styled.div`
  color: #fff;
  margin-left: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
`

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
