import {
  MinusCircleOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  FocusLayout,
  LayoutContextProvider,
  ParticipantTile,
  TrackReference,
  TrackReferenceOrPlaceholder,
  VideoTrack,
  useLocalParticipant,
  useLocalParticipantPermissions,
  useParticipants,
  useRoomContext,
  useTracks,
} from '@livekit/components-react'
import '@livekit/components-styles' // Add default styles for LiveKit components
import { Avatar, Button, Space } from 'antd'
import { Participant, Track } from 'livekit-client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'

import { useStream } from '../../../../contexts/StreamContext'
import { AttentionCheckModal } from '../attention-modal/AttentionModal'
import { Chat } from '../chat/Chat'
import { ControlPanel } from '../control-panel/ControlPanel'
import ParticipantList from '../participants-list/ParticipantList'

import {
  ChatContainer,
  ControlPanelWrapper,
  ControlsOverlay,
  LayoutContainer,
  MainScreen,
  PlaceholderContainer,
  SideBar,
  SideBarTile,
  SideBarTiles,
} from './VideoConference.style'

export const VideoConference = ({
  isHostUser = false,
  roomData,
}: {
  isHostUser?: boolean
  roomData: FetchMeetingDetailsQuery
}) => {
  const { localParticipant } = useLocalParticipant()
  const participants = useParticipants()

  const room = useRoomContext()

  const [isScreenSharing, setIsScreenSharing] = useState(false)
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.Microphone, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ])

  const [isAttentionCheckVisible, setIsAttentionCheckVisible] = useState(false)
  const [attentionCheckTimeLeft, setAttentionCheckTimeLeft] = useState(30)
  const attentionCheckInterval = 10 * 60 * 1000 // 10 minutes between checks
  const attentionCheckDuration = 30 // 30 seconds for attention check
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null)

  const { stream_details, call_id } = roomData.fetchLiveEventByCallId

  const [isChatVisible, setIsChatVisible] = useState(false)
  const [unreadChatCount, setUnreadChatCount] = useState(0)
  const permission = useLocalParticipantPermissions()

  const {
    emit,
    connected,
    setMainParticipant,
    mainParticipant,
    pendingMainParticipantId,
    setPendingMainParticipantId,
    leaveRoom,
    chatParticipants,
    setCanEnableCamera,
    setCanEnableMicrophone,
  } = useStream()

  const findParticipantById = useCallback(
    (participantId: string) => {
      return (
        participants.find((p) => p.identity === participantId) ||
        (localParticipant.identity === participantId ? localParticipant : null)
      )
    },
    [participants, localParticipant]
  )

  const handleAttentionConfirm = useCallback(() => {
    setIsAttentionCheckVisible(false)
    setAttentionCheckTimeLeft(attentionCheckDuration)
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
    }
  }, [attentionCheckDuration])

  const kickUser = useCallback(() => {
    emit('leaveStream', {
      call_id,
    })
    leaveRoom()
    room?.disconnect()
    // Redirect user to a "kicked" page or show a message
  }, [emit, room, roomData])

  const startAttentionCheck = useCallback(() => {
    setIsAttentionCheckVisible(true)
    setAttentionCheckTimeLeft(attentionCheckDuration)

    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current)
    }

    countdownTimerRef.current = setInterval(() => {
      setAttentionCheckTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          if (countdownTimerRef.current) {
            clearInterval(countdownTimerRef.current)
          }
          setIsAttentionCheckVisible(false)
          kickUser()
          return attentionCheckDuration
        }
        return prevTime - 1
      })
    }, 1000)
  }, [attentionCheckDuration, kickUser])

  useEffect(() => {
    if (isHostUser) return // Don't run attention checks for hosts

    const attentionCheckTimer = setInterval(
      startAttentionCheck,
      attentionCheckInterval
    )

    return () => {
      clearInterval(attentionCheckTimer)
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current)
      }
    }
  }, [isHostUser, startAttentionCheck, attentionCheckInterval])

  useEffect(() => {
    if (room) {
      setCanEnableCamera(!!permission?.canPublish)
      setCanEnableMicrophone(!!permission?.canPublish)
    }
  }, [permission])

  const setHostAsMainParticipant = () => {
    const hostParticipant = participants.find(
      (p) => JSON.parse(p?.metadata as string)?.isHost
    )
    if (hostParticipant) setMainParticipant(hostParticipant)
  }

  const setDefaultHostAsMainParticipant = () => {
    const hostParticipant = chatParticipants.find(
      (p) => p._id === stream_details?.host_ids?.[0]
    )
    if (hostParticipant) {
      setMainParticipant(
        findParticipantById(
          `${hostParticipant.firstName} ${hostParticipant.lastName}`
        )
      )
    }
  }

  useEffect(() => {
    if (pendingMainParticipantId) {
      const pendingParticipant = findParticipantById(pendingMainParticipantId)

      if (pendingParticipant) {
        setMainParticipant(pendingParticipant)
        setPendingMainParticipantId(null)
      }
    } else if (
      !mainParticipant &&
      participants.length > 0 &&
      localParticipant?.identity &&
      stream_details
    ) {
      if (localParticipant?.metadata) {
        const isParticipantHost = JSON.parse(localParticipant?.metadata).isHost
        if (isParticipantHost) {
          setMainParticipant(localParticipant)
          emit('setMainParticipant', {
            call_id,
            participantId: localParticipant.identity,
          })
        } else {
          setHostAsMainParticipant()
        }
      } else {
        setDefaultHostAsMainParticipant()
      }
    }
  }, [
    tracks,
    mainParticipant,
    isScreenSharing,
    localParticipant,
    participants,
    pendingMainParticipantId,
    findParticipantById,
    chatParticipants,
    roomData,
    call_id,
    emit,
  ])

  const handleSetMainParticipant = useCallback(
    (participant: Participant) => {
      if (participant !== mainParticipant) {
        setMainParticipant(participant) // Update local state immediately for all users
        if (isHostUser && connected) {
          emit('setMainParticipant', {
            call_id,
            participantId: participant.identity,
          })
        }
      }
    },
    [mainParticipant, isHostUser, connected, call_id, emit]
  )

  useEffect(() => {
    const setupLocalParticipant = async () => {
      if (!isHostUser) {
        await localParticipant.setCameraEnabled(false)
        await localParticipant.setMicrophoneEnabled(false)
      }
    }

    setupLocalParticipant()
  }, [localParticipant, isHostUser, setCanEnableCamera, setCanEnableMicrophone])

  const sidebarTracks = useMemo(() => {
    const participantMap = new Map<Participant, TrackReference>()

    for (const track of tracks) {
      if (
        (track.participant.identity !== mainParticipant?.identity ||
          (isScreenSharing && track.source === Track.Source.Camera)) &&
        !participantMap.has(track.participant) &&
        track.source === Track.Source.Camera
      ) {
        participantMap.set(track.participant, track as TrackReference)
      }
    }

    return [...participantMap.values()]
  }, [tracks, mainParticipant, isScreenSharing])

  useEffect(() => {
    const screenShareTrack = tracks.find(
      (track) => track.source === Track.Source.ScreenShare
    )

    if (screenShareTrack) {
      setIsScreenSharing(true)
    } else {
      setIsScreenSharing(false)
    }
  }, [tracks])

  const renderMainContent = () => {
    const screenShareTrack = tracks.find(
      (track) => track.source === Track.Source.ScreenShare
    )

    if (screenShareTrack) {
      return (
        <>
          <FocusLayout
            trackRef={screenShareTrack as TrackReferenceOrPlaceholder}
          />
          <ParticipantTile
            key={`screenshare-${screenShareTrack.participant.identity}`}
            disableSpeakingIndicator
            style={{ width: '100%', height: '100%' }}
            trackRef={screenShareTrack}
          >
            <VideoTrack trackRef={screenShareTrack as TrackReference} />
          </ParticipantTile>
        </>
      )
    }

    if (mainParticipant && !isScreenSharing) {
      const videoTrack = tracks.find(
        (track) =>
          track.participant.identity === mainParticipant.identity &&
          track.source === Track.Source.Camera
      )

      if (!videoTrack) {
        return null
      }
      return (
        <>
          <FocusLayout trackRef={videoTrack as TrackReferenceOrPlaceholder} />
          <ParticipantTile
            key={`main-${videoTrack?.participant.identity}`}
            style={{ width: '100%', height: '100%' }}
            disableSpeakingIndicator={false}
            trackRef={videoTrack}
          >
            {videoTrack ? (
              <VideoTrack trackRef={videoTrack as TrackReference} />
            ) : (
              <PlaceholderContainer>
                <Avatar size={64} icon={<UserOutlined />} />
                <div>{mainParticipant.identity}</div>
              </PlaceholderContainer>
            )}
          </ParticipantTile>
        </>
      )
    }

    return null
  }

  const renderVideoOrPlaceholder = (trackRef: TrackReferenceOrPlaceholder) => {
    return trackRef.publication?.isSubscribed &&
      !trackRef.publication.isMuted ? (
      <VideoTrack trackRef={trackRef} />
    ) : (
      <PlaceholderContainer>
        <Avatar size={64} icon={<UserOutlined />} />
        <div>{trackRef.participant.identity}</div>
      </PlaceholderContainer>
    )
  }

  useEffect(() => {
    const audioElements: HTMLAudioElement[] = []
    const audioTracks = tracks.filter(
      (track) => track.source === Track.Source.Microphone
    )

    audioTracks.forEach((track) => {
      if (
        track.publication?.isSubscribed &&
        !track.publication.isMuted &&
        track.participant.identity !== localParticipant?.identity
      ) {
        const mediaStream = track?.publication?.track?.mediaStream
        if (mediaStream) {
          const audioElement = new Audio()
          audioElement.srcObject = mediaStream
          audioElement.autoplay = true
          audioElements.push(audioElement)
          audioElement
            .play()
            .catch((error) => console.error('Audio playback error:', error))
        }
      }
    })
    return () => {
      audioElements.forEach((element) => {
        element.srcObject = null
        element.remove()
      })
    }
  }, [tracks])

  useEffect(() => {
    async function setupAudio() {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
      } catch (error) {
        console.error('Failed to get audio permissions:', error)
      }
    }

    setupAudio()
  }, [])

  const togglePermission = (participant: Participant) => {
    emit('toggleParticipantPermission', {
      participantId: participant.identity,
      call_id,
    })
  }
  const renderParticipantControls = (participant: Participant) => {
    if (participant === localParticipant) return null

    return (
      <ControlsOverlay onClick={(e) => e.stopPropagation()}>
        <Space>
          <Button
            type="link"
            style={{ fontSize: '1rem', color: 'white' }}
            onClick={() => togglePermission(participant)}
          >
            {participant?.permissions?.canPublish ? (
              <MinusCircleOutlined />
            ) : (
              <SoundOutlined />
            )}
          </Button>
        </Space>
      </ControlsOverlay>
    )
  }

  return (
    <LayoutContextProvider>
      <LayoutContainer>
        <MainScreen>
          {renderMainContent()}

          <ControlPanelWrapper>
            <ControlPanel
              isHostUser={isHostUser}
              onToggleChat={() => setIsChatVisible(!isChatVisible)}
              isChatVisible={isChatVisible}
              unreadChatCount={unreadChatCount}
            />
          </ControlPanelWrapper>
        </MainScreen>
        <SideBar>
          <SideBarTiles>
            {sidebarTracks.slice(0, 4).map((track) => (
              <SideBarTile key={track.participant.identity}>
                <ParticipantTile
                  disableSpeakingIndicator={false}
                  {...(isHostUser && {
                    onClick: () => handleSetMainParticipant(track.participant),
                  })}
                  trackRef={track}
                >
                  {renderVideoOrPlaceholder(track)}
                  {isHostUser && renderParticipantControls(track.participant)}
                </ParticipantTile>
              </SideBarTile>
            ))}
          </SideBarTiles>
          {sidebarTracks.length > 1 && (
            <ParticipantList
              participants={participants.filter(
                (participant) =>
                  participant.identity !== localParticipant.identity
              )}
              togglePermission={togglePermission}
            />
          )}
          <ChatContainer style={{ display: isChatVisible ? 'block' : 'none' }}>
            <Chat
              setVisibility={setIsChatVisible}
              isVisible={isChatVisible}
              onUnreadCountChange={setUnreadChatCount}
            />
          </ChatContainer>
        </SideBar>
        <AttentionCheckModal
          isVisible={isAttentionCheckVisible}
          timeLeft={attentionCheckTimeLeft}
          totalTime={30}
          onConfirm={handleAttentionConfirm}
        />
      </LayoutContainer>
    </LayoutContextProvider>
  )
}
