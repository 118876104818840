import styled from 'styled-components'

export const SoftwareCourseCardWrapper = styled.div`
  cursor: pointer;
  background: linear-gradient(
    114.9deg,
    ${(props: { left: string; right: string | null }) => props.left} 0%,
    ${(props: { left: string; right: string | null }) => props.right} 172.35%
  );
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1rem;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #fff;
  }

  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.25);
  }
`

export const SoftwareCourseCardMeta = styled.span`
  font-size: 0.875rem;
  svg {
    font-size: 0.75rem;
    vertical-align: baseline;
    margin-right: 0.25rem;
    position: relative;
  }
  display: inline-block;
`

export const SoftwareCourseCardTitle = styled.h2`
  font-weight: 700;
  margin: 0;
`
