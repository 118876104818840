import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Popconfirm,
  Row,
  Space,
  Tag,
} from 'antd'
import dayjs from 'dayjs'
import { throttle } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import { ArticleBySlugQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { HoverMenu } from '../../../courses/pages/course-viewer/CourseViewer.style'
import {
  TranslateableRecord,
  TranslateButton,
} from '../../../shared/components/translate-button/TranslateButton'
import { logArticleActivity } from '../../../shared/helpers/log-activity'
import { useLanguages } from '../../../shared/hooks/use-languages'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Author, Meta } from '../../components/article-card/ArticleCard.style'
import { ArticleSharingModal } from '../../components/article-sharing-modal'

import DELETE_ARTICLE_MUTATION from './../../mutations/delete-article.graphql'
import ARTICLE_BY_SLUG_QUERY from './../../queries/article-by-slug.graphql'
import { Container, Image, Intro, Paragraph, Title } from './Article.style'

export const ArticlePage = ({ setPageTitle }: PageProps) => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const [showSharingOptions, setShowSharingOptions] = useState<boolean>(false)

  const { data: article, error } = useQuery<ArticleBySlugQuery>(
    ARTICLE_BY_SLUG_QUERY,
    {
      variables: { slug },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data?.fetchArticleBySlug.translation.title && setPageTitle)
          setPageTitle(data?.fetchArticleBySlug.translation.title)
      },
    }
  )

  const logActivity = useMemo(
    () =>
      throttle(
        ({
          article_id,
          last_activity,
          session_start,
        }: {
          article_id: string
          last_activity: Date
          session_start?: boolean
        }) => {
          logArticleActivity({ article_id, last_activity, session_start })
        },
        5000
      ),
    []
  )

  const { languages } = useLanguages()

  const canTranslate =
    ability.can(PermissionAction.TRANSLATE, PermissionObjectType.ARTICLE) ||
    ability.can(
      PermissionAction.TRANSLATE,
      subject(PermissionObjectType.BRANCH_ARTICLE, article?.fetchArticleBySlug)
    ) ||
    ability.can(
      PermissionAction.TRANSLATE,
      subject(PermissionObjectType.OWN_ARTICLE, article?.fetchArticleBySlug)
    )

  useEffect(() => {
    if (!article?.fetchArticleBySlug?._id) return

    const action = () =>
      logActivity({
        article_id: article?.fetchArticleBySlug._id,
        last_activity: new Date(),
      })

    // Initial action
    logActivity({
      article_id: article?.fetchArticleBySlug._id,
      last_activity: new Date(),
      session_start: true,
    })

    // Add event listeners on mount
    window.addEventListener('mousemove', action)
    window.addEventListener('mousedown', action)
    window.addEventListener('keypress', action)
    window.addEventListener('scroll', action)
    window.addEventListener('touchmove', action)

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('mousemove', action)
      window.removeEventListener('mousedown', action)
      window.removeEventListener('keypress', action)
      window.removeEventListener('scroll', action)
      window.removeEventListener('touchmove', action)
    }
  }, [logActivity, article])

  const [deleteArticle] = useMutation(DELETE_ARTICLE_MUTATION, {
    variables: { id: article?.fetchArticleBySlug._id },
    refetchQueries: ['articles'],
    onCompleted: () => navigate('/articles'),
  })

  if (!article && !error) {
    return <LoadScreen />
  }

  if (!article) {
    return (
      <Container style={{ padding: '36px 0' }}>
        <Empty
          description={
            <p>
              <Trans id="articles.detail.not_found">
                Bericht niet gevonden. Ben je zeker dat je over de nodige
                rechten beschikt om deze te bekijken?
              </Trans>
            </p>
          }
        >
          <Button type="primary" onClick={() => navigate('/articles')}>
            <Trans id="articles.detail.back_to_overview">
              Terug naar overzicht
            </Trans>
          </Button>
        </Empty>
      </Container>
    )
  }

  const canUpdate =
    ability.can(PermissionAction.UPDATE, PermissionObjectType.ARTICLE) ||
    ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.BRANCH_ARTICLE, article.fetchArticleBySlug)
    ) ||
    ability.can(
      PermissionAction.UPDATE,
      subject(PermissionObjectType.OWN_ARTICLE, article.fetchArticleBySlug)
    )

  const canShare =
    ability.can(PermissionAction.SHARE, PermissionObjectType.ARTICLE) ||
    ability.can(
      PermissionAction.SHARE,
      subject(PermissionObjectType.BRANCH_ARTICLE, article.fetchArticleBySlug)
    ) ||
    ability.can(
      PermissionAction.SHARE,
      subject(PermissionObjectType.OWN_ARTICLE, article.fetchArticleBySlug)
    )

  const canDelete =
    ability.can(PermissionAction.DELETE, PermissionObjectType.ARTICLE) ||
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.BRANCH_ARTICLE, article.fetchArticleBySlug)
    ) ||
    ability.can(
      PermissionAction.DELETE,
      subject(PermissionObjectType.OWN_ARTICLE, article.fetchArticleBySlug)
    )

  return (
    <>
      <Container>
        <Row>
          <Col lg={{ span: 16, offset: 4 }} style={{ width: '100%' }}>
            <Space
              style={{
                justifyContent: 'space-between',
                width: '100%',
                position: 'relative',
              }}
            >
              <Link to={'/articles'}>
                <Space style={{ marginBottom: 8 }}>
                  <ArrowLeftOutlined style={{ fontSize: 12 }} />
                  <Trans id="articles.detail.back_to_overview">
                    Terug naar overzicht
                  </Trans>
                </Space>
              </Link>
              <HoverMenu align="right">
                <Button
                  type="text"
                  onClick={() =>
                    navigate(`/articles/${article.fetchArticleBySlug._id}/edit`)
                  }
                  icon={<EditOutlined />}
                  hidden={!canUpdate}
                >
                  {' '}
                  <Trans id="articles.detail.action.edit">Bewerken</Trans>
                </Button>

                {canTranslate && (
                  <TranslateButton
                    isInlineButton
                    languages={languages}
                    record={
                      {
                        key: article.fetchArticleBySlug._id,
                        ...article.fetchArticleBySlug,
                      } as unknown as TranslateableRecord
                    }
                    onClick={(key, lang) => {
                      navigate(`/articles/translate/${key}/${lang}`)
                    }}
                  ></TranslateButton>
                )}

                <Button
                  type="text"
                  hidden={!canShare}
                  onClick={() => setShowSharingOptions(true)}
                >
                  <ShareAltOutlined />{' '}
                  <Trans id="articles.detail.action.share">Delen</Trans>
                </Button>
                <Popconfirm
                  title={
                    <Trans id="articles.detail.delete.title">
                      Ben je zeker dat je dit bericht wilt verwijderen?
                    </Trans>
                  }
                  onConfirm={() => deleteArticle()}
                  okText={<Trans id="action.delete">Verwijderen</Trans>}
                  cancelText={<Trans id="action.cancel">Annuleren</Trans>}
                >
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    hidden={!canDelete}
                  >
                    {' '}
                    <Trans id="articles.detail.action.delete">
                      Verwijderen
                    </Trans>
                  </Button>
                </Popconfirm>
              </HoverMenu>
            </Space>
            <Card>
              <Space direction="vertical">
                <Space size={'middle'} style={{ marginBottom: 8 }}>
                  <Avatar
                    src={article.fetchArticleBySlug.author?.picture?.url}
                    icon={(
                      article.fetchArticleBySlug.author?.firstName ||
                      article.fetchArticleBySlug.externalAuthor?.name ||
                      ' '
                    ).slice(0, 1)}
                    size={'large'}
                  />
                  <div>
                    <Author>
                      {article.fetchArticleBySlug.author
                        ? `${article.fetchArticleBySlug.author.firstName} ${article.fetchArticleBySlug.author.lastName}`
                        : article.fetchArticleBySlug.externalAuthor?.name}
                    </Author>
                    <Meta>
                      {article.fetchArticleBySlug.published ? (
                        dayjs(article.fetchArticleBySlug.published).format(
                          'DD MMMM YYYY'
                        )
                      ) : (
                        <Tag color="blue">
                          <Trans id="article.tag.draft">Concept</Trans>
                        </Tag>
                      )}
                    </Meta>
                  </div>
                </Space>
                <Title>{article.fetchArticleBySlug.translation.title}</Title>
                <Intro>{article.fetchArticleBySlug.translation.intro}</Intro>
              </Space>
              {article.fetchArticleBySlug.thumbnail && (
                <Image
                  width={article.fetchArticleBySlug.thumbnail?.width || 720}
                  height={article.fetchArticleBySlug.thumbnail?.width || 360}
                  loading="lazy"
                  src={article.fetchArticleBySlug.thumbnail.url}
                  alt={article.fetchArticleBySlug.thumbnail.alt || ''}
                  style={{ marginTop: '0.75rem' }}
                />
              )}
              {
                ReactHtmlParser(
                  article.fetchArticleBySlug.translation.content || '',
                  {
                    transform: (node, index) => {
                      if (node.type === 'tag' && node.name === 'p') {
                        return (
                          <Paragraph key={`content-${node.name}-${index}`}>
                            {node.children.map((child: unknown, i: number) =>
                              convertNodeToElement(child, i, () => undefined)
                            )}
                          </Paragraph>
                        )
                      }
                      if (node.type === 'tag' && node.name === 'img') {
                        return (
                          <Image
                            key={`content-${node.name}-${index}`}
                            {...node.attribs}
                          />
                        )
                      }
                      return undefined
                    },
                  }
                ) as React.ReactNode
              }
            </Card>
          </Col>
        </Row>
        {showSharingOptions && (
          <ArticleSharingModal
            id={article.fetchArticleBySlug._id}
            options={article.fetchArticleBySlug.sharing}
            visible={showSharingOptions}
            setVisible={setShowSharingOptions}
          />
        )}
      </Container>
    </>
  )
}
