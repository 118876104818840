import { useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Button, PageHeader } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'

import { PublicUnitQuery } from 'apps/lms-front/src/generated/graphql'
import { LoadScreen } from 'apps/lms-front/src/modules/core/components/LoadScreen'
import {
  QuizViewer,
  QuizViewerRef,
} from 'apps/lms-front/src/modules/units/pages/unit-viewer/video-unit-viewer/QuizViewer'
import PUBLIC_UNIT_QUERY from 'apps/lms-front/src/modules/units/queries/public-unit.graphql'

import { PageProps } from '../../../shared/interfaces/page.interface'
import { Action } from '../event-remote-viewer/EventRemoteViewer'

export const ActionPage = ({
  action,
  setPageProgressBar,
  onFinish,
  emitEvent,
}: {
  action: Action
  onFinish: () => void
  emitEvent?: (ev: string, ...args: unknown[]) => void
} & PageProps) => {
  const [current, setCurrent] = useState(0)
  const [finished, setFinished] = useState(false)

  const {
    data: unit,
    loading,
    refetch: refetchUnit,
  } = useQuery<PublicUnitQuery>(PUBLIC_UNIT_QUERY, {
    variables: { id: action.unit_id },
    onCompleted: ({ fetchUnitById }) => {
      if (
        fetchUnitById.__typename === 'QuizUnit' &&
        fetchUnitById.my_activity?.completed
      ) {
        const no_of_questions =
          fetchUnitById.quizUnitTranslation.questions.length
        setCurrent(no_of_questions)
      }

      if (
        fetchUnitById.__typename === 'SurveyUnit' &&
        fetchUnitById.my_activity?.completed
      ) {
        const no_of_questions =
          fetchUnitById.surveyUnitTranslation.questions.length
        setCurrent(no_of_questions)
      }

      if (fetchUnitById.__typename === 'VideoUnit') {
        const no_of_questions =
          fetchUnitById.videoUnitTranslation.questions.length +
          fetchUnitById.videoUnitTranslation.survey_questions.length
        if (
          fetchUnitById.my_activity?.quiz_answers?.filter(
            (answer) => !!answer.submitted
          ).length === fetchUnitById.videoUnitTranslation.questions.length
        )
          setCurrent(no_of_questions)
      }
    },
  })

  const quizRef = useRef<QuizViewerRef>(null)

  const { secondsLeft } = useCountDown({ expiration: action.expires })

  const actionComplete = useMemo(() => {
    if (
      unit?.fetchUnitById.__typename === 'QuizUnit' ||
      unit?.fetchUnitById.__typename === 'SurveyUnit'
    ) {
      return unit?.fetchUnitById.my_activity?.completed
    }
    if (unit?.fetchUnitById.__typename === 'VideoUnit') {
      return (
        unit?.fetchUnitById.videoUnitTranslation.questions.length +
          unit?.fetchUnitById.videoUnitTranslation.survey_questions.length ===
        current
      )
    }
  }, [unit?.fetchUnitById, current])

  const resultPageActive = useMemo(() => {
    return (
      (unit?.fetchUnitById.__typename === 'QuizUnit' &&
        current ===
          unit?.fetchUnitById?.quizUnitTranslation.questions.length) ||
      (unit?.fetchUnitById.__typename === 'SurveyUnit' &&
        current ===
          unit?.fetchUnitById?.surveyUnitTranslation.questions.length) ||
      (unit?.fetchUnitById.__typename === 'VideoUnit' &&
        current ===
          unit?.fetchUnitById?.videoUnitTranslation.questions.length +
            unit?.fetchUnitById?.videoUnitTranslation.survey_questions.length)
    )
  }, [unit?.fetchUnitById, current])

  useEffect(() => {
    if (resultPageActive) {
      setPageProgressBar?.(0)
      if (!finished) {
        emitEvent?.('finishEventUnitAction', {
          event_id: action.event_id,
          unit_id: action.unit_id,
        })
        setFinished(true)
      }
    } else {
      setPageProgressBar?.(secondsLeft / (action.duration || 60))
    }
  }, [
    secondsLeft,
    setPageProgressBar,
    current,
    resultPageActive,
    action,
    finished,
    setFinished,
  ])

  if (loading) return <LoadScreen />

  if (
    !unit?.fetchUnitById ||
    (unit?.fetchUnitById.__typename !== 'VideoUnit' &&
      unit?.fetchUnitById.__typename !== 'QuizUnit' &&
      unit?.fetchUnitById.__typename !== 'SurveyUnit')
  )
    return (
      <PageHeader
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title="Error"
        extra={<Button onClick={() => refetchUnit()}>Probeer opnieuw</Button>}
      />
    )

  return (
    <>
      <QuizViewer
        key={unit?.fetchUnitById._id}
        ref={quizRef}
        unit={unit?.fetchUnitById}
        refetchUnit={refetchUnit}
        questionIndex={current}
        onQuestionIndexChange={(index) => setCurrent(index)}
        finishButton={
          actionComplete ? (
            <Button type="primary" onClick={() => onFinish()}>
              <Trans id="unit.viewer.action.continue">Doorgaan</Trans>
            </Button>
          ) : (
            <Button type="primary" onClick={() => quizRef.current?.submit()}>
              <Trans id="unit.viewer.quiz.submit">Antwoord indienen</Trans>
            </Button>
          )
        }
      />
    </>
  )
}

export const useCountDown = ({ expiration }: { expiration: Date }) => {
  const [secondsLeft, setSecondsLeft] = useState(() =>
    calculateSecondsLeft(expiration)
  )

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateSecondsLeft(expiration)
      setSecondsLeft(updatedTimeLeft)

      if (updatedTimeLeft <= 0) {
        clearInterval(timer)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [expiration])

  return { secondsLeft }
}

const calculateSecondsLeft = (targetDate) => {
  const difference = +new Date(targetDate) - Date.now()
  return Math.floor(difference / 1000)
}
