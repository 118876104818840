import { Trans } from '@lingui/macro'
import { Layout as AntLayout } from 'antd'
import { FC, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { TitleContext } from '../../core/context/Title.context'
import { LayoutProps } from '../layout'

const { Footer } = AntLayout

export const FocusLayout: FC<LayoutProps> = ({ route }: LayoutProps) => {
  const { component: Component, label } = route
  const { title: pageTitle, setTitle: setPageTitle } = useContext(TitleContext)
  const branch = useBranch()
  const location = useLocation()

  useEffect(() => {
    label && setPageTitle(label)
  }, [location, label, setPageTitle])

  return (
    <AntLayout className="layout" style={{ minHeight: '100vh' }}>
      <Helmet>
        <title>
          {pageTitle || ''}
          {branch ? ` | ${branch?.name}` : ''}
        </title>
      </Helmet>
      {Component && (
        <Component
          route={route}
          setPageTitle={(title) => {
            setPageTitle(title)
          }}
        />
      )}
      <Footer style={{ textAlign: 'center' }}>
        &copy; {new Date().getFullYear()} {branch?.name} -{' '}
        <Trans id="footer.copyright">Alle rechten voorbehouden</Trans>
      </Footer>
    </AntLayout>
  )
}
