import styled from 'styled-components'

export const ALink = styled.p`
  opacity: 0.7;
  transition: opacity 144ms ease;
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`
