import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Table,
  PageHeader,
  Popconfirm,
  Tooltip,
  Modal,
  Form,
  Input,
  Select,
  notification,
  Switch,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useContext, useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CreateWebhookMutation,
  WebhookEventType,
  FetchWebhooksQuery,
  MutationDeleteWebhookArgs,
  MutationUpdateWebhookArgs,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort } from '../../../shared/utils/sort'
import { isValidUrl } from '../../../shared/validators/url-validator'
import CREATE_WEBHOOK_MUTATION from '../../mutations/create-webhook.graphql'
import DELETE_WEBHOOKS from '../../mutations/delete-webhook.graphql'
import UPDATE_WEBHOOKS from '../../mutations/update-webhook.graphql'
import FETCH_WEBHOOKS from '../../queries/webhooks.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface Webhooks {
  _id: string
  name: string
  url: string
  created?: Date
  event_type: WebhookEventType
  enabled?: boolean
}

export function formatEventsString(str: string): string {
  return str.toLowerCase().replace('_', '.')
}

const EventsTypeOptions = Object.values(WebhookEventType)
  .filter((value) =>
    [
      'USER_UPDATED',
      'USER_CREATED',
      'USER_DELETED',
      'COURSE_COMPLETED',
    ].includes(value)
  )
  .map((value) => ({
    label: formatEventsString(value),
    value,
  }))

export const Webhooks = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [modalVisible, setModalVisibility] = useState(false)
  const [updateSubject, setUpdateSubject] = useState<Webhooks | undefined>()
  const ability = useContext(AbilityContext)

  const { data, loading } = useQuery<FetchWebhooksQuery>(FETCH_WEBHOOKS)

  const [createWebhook, { loading: creating }] =
    useMutation<CreateWebhookMutation>(CREATE_WEBHOOK_MUTATION)

  const [deleteWebhook, { loading: deleting }] =
    useMutation<MutationDeleteWebhookArgs>(DELETE_WEBHOOKS)

  const [updateWebhook, { loading: updating }] =
    useMutation<MutationUpdateWebhookArgs>(UPDATE_WEBHOOKS)

  const [form] = useForm()

  useEffect(() => {
    if (updateSubject) {
      setModalVisibility(true)
    }
  }, [updateSubject])
  useEffect(() => {
    if (modalVisible) form.resetFields()
  }, [modalVisible, form])

  const hasEditRights =
    ability.can(PermissionAction.UPDATE, PermissionObjectType.WEBHOOK) &&
    ability.can(PermissionAction.DELETE, PermissionObjectType.WEBHOOK)

  const handleCreation = async () => {
    try {
      const formValues = await form.validateFields()
      createWebhook({
        variables: { input: { ...formValues } },
        refetchQueries: ['fetchWebhooks'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.webhooks.action.create.success',
              message: 'Webhook succesvol aangemaakt.',
            }),
          })
          setModalVisibility(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }
  const handleUpdate = async () => {
    try {
      const formValues = await form.validateFields()
      updateWebhook({
        variables: {
          input: { ...formValues, _id: updateSubject?._id },
        },
        refetchQueries: ['fetchWebhooks'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.webhooks.action.update.success',
              message: 'Webhook succesvol gewijzigd.',
            }),
          })
          setModalVisibility(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const onChange = (checked: boolean, webhook_id: string) => {
    updateWebhook({
      variables: { input: { enabled: checked, _id: webhook_id } },
    })
  }

  const webhooks = useMemo(() => {
    const fuse = new Fuse(data?.webhooks || [], {
      keys: ['name', 'url', 'event_type', 'enabled'],
      findAllMatches: true,
    })
    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.webhooks || []
    ).map<Webhooks>((item) => ({
      _id: item._id,
      url: item.url,
      name: item.name,
      event_type: item.event_type,
      enabled: item.enabled,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<Webhooks> = [
    {
      dataIndex: 'enabled',
      key: 'enabled',
      width: 60,
      render: (_, webhook: Webhooks) => {
        return (
          <Switch
            checked={webhook.enabled}
            onChange={(checked) => onChange(checked, webhook._id)}
          />
        )
      },
    },
    {
      title: t({
        id: 'settings.webhooks.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.webhooks.table.url',
        message: 'URL',
      }),
      dataIndex: 'url',
      key: 'url',
      sorter: defaultSort('url') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.webhooks.table.event_type',
        message: 'Type',
      }),
      dataIndex: 'event_type',
      key: 'event_type',
      render: (_, webhook: Webhooks) => {
        return formatEventsString(webhook.event_type)
      },
      filters: data
        ? [
            ...data.webhooks.reduce((acc, { event_type }) => {
              acc.add(event_type)
              return acc
            }, new Set<string>()),
          ]
            .sort((a, b) => a.localeCompare(b))
            .map((name) => ({ text: formatEventsString(name), value: name }))
        : [],
      onFilter: (value, record) => record.event_type === value,
      sorter: defaultSort('event_type') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.webhook.table.actions',
        message: 'Acties',
      }),
      key: 'actions',
      width: 110,
      render: (_, record: Webhooks) => {
        return (
          <ActionButtonWrapper>
            <Can I={PermissionAction.UPDATE} a={PermissionObjectType.WEBHOOK}>
              <Tooltip
                title={t({
                  id: 'action.edit',
                  message: 'Bewerken',
                })}
              >
                <Button
                  onClick={() => {
                    setUpdateSubject(record)
                  }}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            </Can>

            <Can I={PermissionAction.DELETE} a={PermissionObjectType.WEBHOOK}>
              <Tooltip
                title={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
              >
                <Popconfirm
                  placement={'left'}
                  title={t({
                    id: 'settings.webhooks.action.delete.confirm',
                    message:
                      'Ben je zeker dat je deze webhook wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.',
                  })}
                  okType="danger"
                  okText={t({
                    id: 'action.delete',
                    message: 'Verwijderen',
                  })}
                  cancelText={t({
                    id: 'action.cancel',
                    message: 'Annuleren',
                  })}
                  okButtonProps={{ loading: deleting }}
                  onConfirm={() => {
                    deleteWebhook({
                      variables: { id: record._id },
                      refetchQueries: ['fetchWebhooks'],
                    })
                      .then(() =>
                        notification.success({
                          message: t({
                            id: 'settings.webhooks.action.delete.success',
                            message: 'Webhook succesvol verwijderd.',
                          }),
                        })
                      )
                      .catch(errorNotifierFn)
                  }}
                >
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Can>
          </ActionButtonWrapper>
        )
      },
    },
  ].filter((column) => hasEditRights || column.key !== 'actions')

  return (
    <>
      <PageHeader
        key="page-header"
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.webhooks.title',
          message: 'Webhooks',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.webhooks.search',
              message: 'Zoeken...',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.WEBHOOK}
          >
            <Button onClick={() => setModalVisibility(true)} type="primary">
              <Trans id="settings.webhooks.action.create">
                Webhook aanmaken
              </Trans>
            </Button>
          </Can>,
        ]}
      />
      <Table
        key="table"
        locale={{
          emptyText: t({
            id: 'settings.webhooks.table.empty',
            message: 'Geen webhooks gevonden',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={webhooks}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.webhooks.action.update.title',
                message: 'Webhook updaten',
              })
            : t({
                id: 'settings.webhooks.action.create.title',
                message: 'Webhook aanmaken',
              })
        }
        open={modalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setModalVisibility(false)
        }}
        afterClose={() => {
          setUpdateSubject(undefined)
        }}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.webhooks.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.webhook.form.validation.name',
                  message: 'Vul een naam in voor deze webhook.',
                }),
              },
            ]}
          >
            <Input
              placeholder={t({
                id: 'settings.webhooks.form.placeholder.name',
                message: 'Vul een naam in',
              })}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.webhooks.form.label.url',
              message: 'URL',
            })}
            name="url"
            rules={[
              {
                required: true,
                validator: isValidUrl,
                message: t({
                  id: 'settings.webhook.form.validation.url',
                  message: 'Vul een geldige URL in.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.webhooks.form.label.event_type',
              message: 'Type',
            })}
            name="event_type"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.webhook.form.validation.event_type',
                  message: 'Selecteer een type gebeurtenis voor deze webhook.',
                }),
              },
            ]}
          >
            <Select
              allowClear={true}
              placeholder="Selecteer een type gebeurtenis"
              options={EventsTypeOptions}
            />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
