export enum JobType {
  All = 'All',
  FirstJob = 'FirstJob',
  StudentJob = 'StudentJob',
  Internship = 'Internship',
}

export enum JobEducationLevel {
  Bachelor = 'Bachelor',
  Master = 'Master',
  Graduate = 'Graduate',
  None = 'None',
  Either = 'Either',
}

export enum JobContractType {
  Permanent = 'Permanent',
  Temporary = 'Temporary',
}

export enum JobSchedule {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum StudentJobSchedule {
  FixedDays = 'FixedDays',
  Vacations = 'Vacations',
}

export enum JobBenefits {
  CompanyCar = 'CompanyCar',
  Smartphone = 'Smartphone',
  Laptop = 'Laptop',
  MealVouchers = 'MealVouchers',
  CafetariaPlan = 'CafetariaPlan',
  HealthInsurance = 'HealthInsurance',
  MobilityBudget = 'MobilityBudget',
  Bonus = 'Bonus',
  Other = 'Other',
}

export enum JobAccessibilityOptions {
  Parking = 'Parking',
  PublicTransport = 'PublicTransport',
}

export enum JobOtherCriteria {
  FlexibleHours = 'FlexibleHours',
  RemoteWork = 'RemoteWork',
  ItaaInternship = 'ItaaInternship',
}
