import { Trans } from '@lingui/macro'
import { Progress, Space, Statistic } from 'antd'

export const EventResults = (results: { total: number; completed: number }) => {
  return (
    <Space
      direction="vertical"
      align="center"
      style={{ alignItems: 'center', width: '100%', marginTop: 24 }}
    >
      <Progress
        type="dashboard"
        percent={(results.completed / results.total) * 100}
        steps={results.total}
        format={() => (
          <Statistic
            valueStyle={{ textAlign: 'center' }}
            title={<Trans id="statistic.submitted">Ingediend</Trans>}
            value={results.completed}
            suffix={`/ ${results.total}`}
          />
        )}
      />
    </Space>
  )
}
