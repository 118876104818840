import { useCallback, useEffect, useRef, useState } from 'react'
import { Socket, io } from 'socket.io-client'

export const useSocket = (
  path: string,
  options?: {
    token?: string
    skip?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    events?: { [event: string]: (...args: any[]) => void }
    transports?: ('websocket' | 'polling')[]
  }
) => {
  const [connected, setConnected] = useState<boolean>(false)
  const [queue, setQueue] = useState<[ev: string, ...args: unknown[]][]>([])
  const [socket, setSocket] = useState<Socket | null>(null)
  const eventHandlers = useRef(options?.events)

  useEffect(() => {
    if (options?.skip) return

    /**
     * Create socket
     */
    const socket = io(import.meta.env.NX_SOCKET_HOST, {
      autoConnect: false,
      path,
      transports: options?.transports || ['websocket', 'polling'],
      auth: { token: options?.token },
    })
    setSocket(socket)

    /**
     * Socket events
     */
    socket.on('connect', () => setConnected(true))
    socket.on('disconnect', () => setConnected(false))
    socket.on('error', (error) => console.error(error))

    // Registering event listeners
    Object.entries(eventHandlers.current || {}).forEach(([event, handler]) => {
      socket.on(event, handler)
    })

    /**
     * Connect to socket
     */
    socket.connect()

    /**
     * Cleanup effect
     */
    return () => {
      socket.disconnect()
      socket.removeAllListeners()
      setSocket(null)
    }
  }, [options?.token, path, options?.skip])

  useEffect(() => {
    if (!options?.skip && socket && connected && queue.length > 0) {
      setQueue((prevQueue) => {
        while (prevQueue.length > 0 && connected) {
          const item = prevQueue.shift()
          if (!item) break

          const [event, ...data] = item
          if (event) socket.emit(event, ...data)
        }
        return []
      })
    }
  }, [connected, queue, socket, options?.skip])

  const emit = useCallback(
    (ev: string, ...args: unknown[]) => {
      if (connected && socket && ev) {
        socket.emit(ev, ...args)
      } else if (ev) {
        setQueue((prevQueue) => [...prevQueue, [ev, ...args]])
      }
    },
    [connected, socket]
  )

  const disconnect = useCallback(() => {
    if (socket) {
      socket.disconnect()
      socket.removeAllListeners()
      setSocket(null)
      setConnected(false)
      setQueue([])
    }
  }, [socket])

  return {
    connected,
    emit,
    disconnect,
  }
}
