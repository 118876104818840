import { Space } from 'antd'
import { posthog } from 'posthog-js'
import { useEffect } from 'react'

import {
  CoursesQuery,
  FindBranchesByMetaQuery,
} from 'apps/lms-front/src/generated/graphql'

import { VendorCard } from '../vendor-card/VendorCard'

import { SoftwareCourseCard } from './SoftwareCourseCard'

type SoftwareCoursesProps = {
  courses: CoursesQuery['fetchCourses']['results'] | undefined
  vendors?: FindBranchesByMetaQuery['findBranchesByMeta']
}

export const SoftwareCourses = ({ courses, vendors }: SoftwareCoursesProps) => {
  posthog.capture('software_academy_view', {
    software_branch: vendors?.map((vendor) => vendor.name),
    software_branch_id: vendors?.map((vendor) => vendor._id),
  })
  useEffect(() => {
    if (window && sessionStorage.getItem('aa_software_scrolly')) {
      const scrollY = Number(sessionStorage.getItem('aa_software_scrolly'))
      window.scrollTo(0, scrollY)
      sessionStorage.removeItem('aa_software_scrolly')
    }
  }, [])

  return (
    <>
      {vendors && (
        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          {vendors
            .filter(
              (vendor) =>
                courses?.some((course) => course.ownerBranch === vendor._id)
            )
            .map((vendor) => {
              return (
                <VendorCard key={vendor._id} vendor={vendor}>
                  {courses &&
                    courses
                      .filter((course) => course.ownerBranch === vendor._id)
                      .sort((a, b) => {
                        const order = [
                          'ontdek',
                          'onboarding',
                          'masterclass',
                          'updates',
                        ]
                        return (
                          order.indexOf(a.name.toLowerCase()) -
                          order.indexOf(b.name.toLowerCase())
                        )
                      })
                      .map((course) => (
                        <SoftwareCourseCard key={course._id} course={course} />
                      ))}
                </VendorCard>
              )
            })}
        </Space>
      )}
    </>
  )
}
