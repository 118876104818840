import { useQuery, useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Space,
  Table,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  AutoTranslateCustomFieldMutation,
  FieldQuery,
  TranslateCustomFieldMutation,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useLanguages } from '../../../shared/hooks/use-languages'

import AUTO_TRANSLATE_FIELD_MUTATION from './../../mutations/auto-translate-custom-field.graphql'
import TRANSLATE_FIELD_MUTATION from './../../mutations/translate-custom-field.graphql'
import FIELD_QUERY from './../../queries/field.graphql'

const fieldTranslationFormConfig = [
  {
    label: t({
      id: 'settings.fields.form.label.name',
      message: 'Naam',
    }),
    name: 'name',
  },
]

export const FieldTranslation = () => {
  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const { id, lang } = useParams()

  const { data: customField, loading } = useQuery<FieldQuery>(FIELD_QUERY, {
    variables: {
      id,
    },
  })

  const { languages } = useLanguages()
  const [translate, { loading: translating }] =
    useMutation<TranslateCustomFieldMutation>(TRANSLATE_FIELD_MUTATION, {
      variables: {
        field_id: id,
        language: lang,
      },
    })
  const [autoTranslate, { loading: autoTranslating }] =
    useMutation<AutoTranslateCustomFieldMutation>(
      AUTO_TRANSLATE_FIELD_MUTATION,
      {
        variables: {
          field_id: id,
          language: lang,
        },
        onCompleted: () => {
          form.resetFields()
          notification.success({
            message: t({
              id: 'settings.fields.action.translate.auto.success',
              message: 'Automatische vertaling succesvol opgeslagen',
            }),
          })
          setFormDirty(false)
        },
        onError: () => {
          notification.error({
            message: t({
              id: 'settings.fields.action.translate.auto.error',
              message: 'Er liep iets fout bij het automatisch vertalen',
            }),
          })
        },
      }
    )

  const handleTranslate = async () => {
    const fields = await form.validateFields()
    await translate({
      variables: {
        translation: fields,
      },
    })
      .then(() => {
        notification.success({
          message: t({
            id: 'settings.fields.action.translate.success',
            message: 'Vertaling succesvol opgeslagen',
          }),
        })
        setFormDirty(false)
      })
      .catch(errorNotifierFn)
  }

  const translation = customField?.fetchField.translations?.find(
    (t) => t.language === lang
  )

  const columns = [
    {
      title: t({
        id: 'translation.field',
        message: 'Veld',
      }),
      dataIndex: 'field',
      key: 'field',
    },
    {
      title:
        languages.find((l) => customField?.fetchField.language === l.code)
          ?.name ||
        t({
          id: 'translation.original',
          message: 'Origineel',
        }),
      dataIndex: 'original',
      key: 'original',
    },
    {
      title: languages.find((l) => lang === l.code)?.name,
      dataIndex: 'translation',
      key: 'translation',
      render: (field) => {
        return (
          <Form.Item key={field} style={{ marginBottom: 0 }} name={field}>
            <Input />
          </Form.Item>
        )
      },
    },
  ]

  const data = fieldTranslationFormConfig.map((field) => ({
    key: field.name,
    field: field.label,
    original: customField?.fetchField[field.name],
    translation: field.name,
  }))

  if (!customField || loading) return <LoadScreen />

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleTranslate}
      autoComplete="off"
      initialValues={translation || {}}
      onChange={() => setFormDirty(true)}
    >
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          <Trans id="settings.fields.title.translate">
            Vertaling van &ldquo;{customField.fetchField.name}&rdquo; naar{' '}
            &ldquo;{languages.find((l) => lang === l.code)?.name}&rdquo;
          </Trans>
        }
        extra={
          <Space>
            <Button
              onClick={() => {
                window.history.back()
              }}
            >
              <Trans id="actions.go_back">Ga terug</Trans>
            </Button>
            <Button
              loading={autoTranslating}
              onClick={() => {
                autoTranslate()
              }}
            >
              <Trans id="actions.auto_translate">Automatisch vertalen</Trans>
            </Button>
            <Button
              loading={translating}
              disabled={!formDirty}
              type="primary"
              htmlType={'submit'}
            >
              <Trans id="actions.save">Opslaan</Trans>
            </Button>
          </Space>
        }
      />
      <Table columns={columns} dataSource={data} pagination={false} />
    </Form>
  )
}
