import { Input } from 'antd'
import { SearchProps } from 'antd/lib/input'

export const InputSearch = (props: SearchProps) => {
  return (
    <Input.Search
      onChange={(event) => {
        if (event.target.value === '') props.onSearch?.('')
      }}
      {...props}
    />
  )
}
