import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React, { createContext, useContext, useState } from 'react'

import { PathQuery } from 'apps/lms-front/src/generated/graphql'

dayjs.extend(isBetween)

type PathContextType = {
  path: PathQuery['fetchLearningPathById'] | null
  setPath: React.Dispatch<
    React.SetStateAction<PathQuery['fetchLearningPathById'] | null>
  >
  isDateBeforeTodayOrStartDate: (date: Dayjs) => boolean
  isDateWithinAvailableRange: (date: Dayjs, deadline?: Dayjs) => boolean
  isTodayBeforeAvailabilityDate: (availabilityDate?: Dayjs) => boolean
}

const PathContext = createContext<PathContextType | undefined>(undefined)

interface PathProviderProps {
  children: React.ReactNode
}

export const PathProvider: React.FC<PathProviderProps> = ({ children }) => {
  const [path, setPath] = useState<PathQuery['fetchLearningPathById'] | null>(
    null
  )

  const pathStartDate = path?.start ? dayjs(path.start) : undefined

  const isDateBeforeTodayOrStartDate = (date: Dayjs) => {
    const todayEnd = dayjs().endOf('day')
    if (pathStartDate) {
      return date.isBefore(todayEnd) || date.isBefore(pathStartDate)
    }
    return date.isBefore(todayEnd)
  }

  const isDateWithinAvailableRange = (
    date: Dayjs,
    deadline?: Dayjs
  ): boolean => {
    const todayEnd = dayjs().endOf('day')

    // If deadline is not available, only check if the date is after todayEnd and after pathStartDate
    if (!deadline) {
      return (
        date.isAfter(todayEnd) &&
        (!pathStartDate || date.isAfter(pathStartDate))
      )
    }

    // If deadline is available, use isBetween for range validation
    return (
      date.isAfter(todayEnd) &&
      (pathStartDate
        ? date.isBetween(pathStartDate, deadline, undefined, '[)')
        : true)
    )
  }

  const isTodayBeforeAvailabilityDate = (availabilityDate?: Dayjs) => {
    if (!availabilityDate) {
      return false
    }

    const todayEnd = dayjs().endOf('day')
    return todayEnd.isBefore(availabilityDate)
  }

  return (
    <PathContext.Provider
      value={{
        path,
        setPath,
        isDateBeforeTodayOrStartDate,
        isDateWithinAvailableRange,
        isTodayBeforeAvailabilityDate,
      }}
    >
      {children}
    </PathContext.Provider>
  )
}

export const usePath = () => {
  const context = useContext(PathContext)
  if (context === undefined) {
    throw new Error('usePath must be used within a PathProvider')
  }
  return context
}
