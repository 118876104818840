import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Form, Modal, notification, Radio } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  LiveEventsQuery,
  RegisterParticipantsForLiveEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { UserTreeSelect } from '../../shared/form-fields/user-tree-select/UserTreeSelect'
import { useEventStatus } from '../hooks/use-event-status.hook'
import REGISTER_PARTICIPANTS_FOR_LIVE_EVENT_MUTATION from '../mutations/register-participants-for-live-event.graphql'
import LIVE_EVENT_PARTICIPANTS_QUERY from '../queries/live-event-participants.graphql'

export const AddEventParticipantsModal = ({
  event,
  open,
  onClose,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
  open: boolean
  onClose: () => void
}) => {
  const [form] = useForm()
  const participants = Form.useWatch('participants', form)

  const { hasReachedCapacity, remaining } = useEventStatus(event)

  const [addParticipants, { loading: adding }] =
    useMutation<RegisterParticipantsForLiveEventMutation>(
      REGISTER_PARTICIPANTS_FOR_LIVE_EVENT_MUTATION,
      {
        variables: {
          event_id: event._id,
          user_ids: participants,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
        onCompleted: () => {
          onClose()
          form.resetFields()
          notification.success({
            message: t({
              id: 'events.participants.add_participants.success',
              message:
                'De deelnemers worden op de achtergrond toegevoegd. Dit kan enkele minuten duren.',
            }),
          })
        },
      }
    )

  return (
    <Modal
      title={
        <Trans id="events.participants.add_participants">
          Deelnemers toevoegen
        </Trans>
      }
      open={open}
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
      onOk={() =>
        addParticipants({
          variables: {
            type: form.getFieldValue('type'),
          },
        })
      }
      okButtonProps={{
        loading: adding,
      }}
      okText={t({
        id: 'action.add',
        message: 'Toevoegen',
      })}
    >
      <Form form={form}>
        <Form.Item
          name="participants"
          label={t({
            id: 'events.form.label.participants',
            message: 'Deelnemers',
          })}
        >
          <UserTreeSelect />
        </Form.Item>
        <Form.Item
          hidden={event.location_type.length === 1}
          name="type"
          label={t({
            id: 'events.participant-form.label.location_type',
            message: 'Locatietype',
          })}
          initialValue={event.location_type[0]}
        >
          <Radio.Group>
            {hasReachedCapacity ||
            !!(
              remaining &&
              participants &&
              remaining < participants.length
            ) ? undefined : (
              <Radio value={EventLocationType.Physical}>
                <Trans id="events.participant-form.label.physical">
                  Fysiek
                </Trans>
              </Radio>
            )}
            <Radio value={EventLocationType.Online}>
              <Trans id="events.participant-form.label.online">Online</Trans>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
