import { google, outlook, office365, yahoo, CalendarEvent } from 'calendar-link'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import ical from 'ical-generator'

type CalendarLinks =
  | 'Google Calendar'
  | 'Apple'
  | 'Outlook (Personal)'
  | 'Outlook365'
  | 'Yahoo'
  | 'ics'

type CalendarValue = {
  value: string
  isLink: boolean
}

interface GetCalendarLinksArguments {
  start?: Date
  duration: number
  title: string
  description?: string
  url?: string
}

type GetCalendarLinks = {
  [key in CalendarLinks]: CalendarValue
}

/**
 * There is an issue with office365 links on the Microsoft side of things. This is a workaround
 * ref: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/discussions/47
 * @param link link that needs to be fixed
 * @returns fixed link
 */
const fixOffice365Link = (link: string) => {
  return link.replace('/0/', '/')
}

const createIcal = (eventData: CalendarEvent) => {
  const cal = ical()

  if (!eventData.start) {
    return
  }

  cal.createEvent({
    start: new Date(eventData.start),
    end: dayjs(eventData.start).add(1, 'hour').toDate(),
    summary: eventData.title,
    description: eventData.description,
    organizer: 'Accountants Academy <info@accountantsacademy.be>',
    url: eventData.url,
    location: eventData.url,
  })

  return cal.toString()
}

export const getCalendarLinks = ({
  duration,
  title,
  start,
  description,
  url,
}: GetCalendarLinksArguments) => {
  const calendarEvent: CalendarEvent = {
    title,
    description: description,
    start,
    duration: [duration, 'minutes'],
    url,
  }

  const googleLink = {
    value: google(calendarEvent),
    isLink: true,
  }
  const outlookLink = {
    value: outlook(calendarEvent),
    isLink: true,
  }
  const officeLink = {
    value: fixOffice365Link(office365(calendarEvent)),
    isLink: true,
  }
  const yahooLink = {
    value: yahoo(calendarEvent),
    isLink: true,
  }
  const icsData = {
    value: createIcal(calendarEvent) || '',
    isLink: false,
  }

  const response: GetCalendarLinks = {
    'Google Calendar': googleLink,
    Apple: icsData,
    'Outlook (Personal)': outlookLink,
    Outlook365: officeLink,
    Yahoo: yahooLink,
    ics: icsData,
  }

  return response
}

export const openOrDownloadCalendar = (link: CalendarValue, title: string) => {
  if (link.isLink) {
    window.open(link.value, '_blank')
  } else {
    const blob = new Blob([link.value], {
      type: 'text/plain;charset=utf-8',
    })
    saveAs(blob, title + '.ics')
  }
}
