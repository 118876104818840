import { Trans } from '@lingui/macro'
import { PdfJs, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Button, Col, Space } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PdfUnit } from 'apps/lms-front/src/generated/graphql'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'

import { PageHeader } from '../UnitViewer.style'

import { renderToolbar } from './PdfViewerToolbar'

interface Props {
  unit: PdfUnit
  handleUnitCompletion: () => void
}

export const PDFUnitViewer = ({ unit, handleUnitCompletion }: Props) => {
  const { event_id } = useParams()
  const [loading, setLoading] = useState(false)
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
    toolbarPlugin: {
      openPlugin: {
        enableShortcuts: false,
      },
      getFilePlugin: {
        fileNameGenerator: () => {
          return unit.file?.originalName || 'download.pdf'
        },
      },
    },
  })
  return (
    <Col xs={24} style={{ textAlign: 'center' }}>
      <PageHeader
        title={unit.name}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <Space direction="vertical" size={'large'} style={{ width: '100%' }}>
        {unit.file && (
          <div style={{ height: '700px', maxHeight: '80vh' }}>
            <Viewer
              transformGetDocumentParams={(options: PdfJs.GetDocumentParams) =>
                Object.assign({}, options, {
                  isEvalSupported: false,
                })
              }
              fileUrl={unit.file.url}
              defaultScale={SpecialZoomLevel.PageFit}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        )}
        {unit.next ? (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true)
              logActivity({ unit_id: unit._id, event_id, completed: true })
                .then(() => handleUnitCompletion())
                .finally(() => setLoading(false))
            }}
          >
            <Trans id="unit.viewer.action.complete_and_continue">
              Voltooien en doorgaan
            </Trans>
          </Button>
        ) : (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true)
              logActivity({ unit_id: unit._id, event_id, completed: true })
                .then(() => handleUnitCompletion())
                .finally(() => setLoading(false))
            }}
          >
            <Trans id="unit.viewer.action.complete_course">
              Opleiding voltooien
            </Trans>
          </Button>
        )}
      </Space>
    </Col>
  )
}
