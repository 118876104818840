import { LiveEventsQuery } from 'apps/lms-front/src/generated/graphql'

import { useEventStatus } from '../hooks/use-event-status.hook'
import { useUserEventRegistrationStatus } from '../hooks/use-user-event-registration-status.hook'

export const EventStatus = ({
  event,
}: {
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  const { alert: userAlert } = useUserEventRegistrationStatus(event)
  const { alert: eventAlert } = useEventStatus(event)
  return userAlert ?? eventAlert ?? null
}
