/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UploadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
  UploadFile,
  notification,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType, CompareFn } from 'antd/lib/table/interface'
import Upload, { UploadChangeParam } from 'antd/lib/upload'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchCertificationTypesQuery,
  CreateCertificationTypeMutation,
  DeleteCertificationTypeMutation,
  UpdateCertificationTypeMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { getBase64 } from '../../../shared/utils/get-base64'
import { dateSort, defaultSort } from '../../../shared/utils/sort'
import {
  FileType,
  uploadValidator,
} from '../../../shared/validators/upload-file-validator'

import CREATE_CERTIFICATION_TYPE_MUTATION from './../../mutations/create-branch-certification-type.graphql'
import DELETE_CERTIFICATION_TYPE_MUTATION from './../../mutations/delete-branch-certification-type.graphql'
import UPDATE_CERTIFICATION_TYPE_MUTATION from './../../mutations/update-branch-certification-type.graphql'
import BRANCH_CERTIFICATION_TYPES_QUERY from './../../queries/branch-certification-types.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface CertificationTypeRow {
  key: string
  name: string
  sequentiality: boolean
  disable_playbackrate?: boolean | null
  created?: Date
}

export const BranchCertificationTypes = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [certificationTypeModalVisible, setCertificationTypeModalVisible] =
    useState(false)
  const [certificateBackgroundUploading, setCertificateBackgroundUploading] =
    useState<boolean>(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<
      BranchCertificationTypesQuery['fetchBranchCertificationTypes'][0]
    >()
  const [form] = useForm()

  const { data, loading } = useQuery<BranchCertificationTypesQuery>(
    BRANCH_CERTIFICATION_TYPES_QUERY
  )

  const [createCertificationType, { loading: creating }] =
    useMutation<CreateCertificationTypeMutation>(
      CREATE_CERTIFICATION_TYPE_MUTATION
    )

  const [updateCertificationType, { loading: updating }] =
    useMutation<UpdateCertificationTypeMutation>(
      UPDATE_CERTIFICATION_TYPE_MUTATION
    )

  const [deleteCertificationType, { loading: deleting }] =
    useMutation<DeleteCertificationTypeMutation>(
      DELETE_CERTIFICATION_TYPE_MUTATION
    )

  useEffect(() => {
    if (updateSubject) {
      setCertificationTypeModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (certificationTypeModalVisible) form.resetFields()
  }, [certificationTypeModalVisible, form])

  const certificationTypes = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranchCertificationTypes || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranchCertificationTypes || []
    ).map<CertificationTypeRow>(
      (
        item: BranchCertificationTypesQuery['fetchBranchCertificationTypes'][0]
      ) => ({
        key: item._id,
        name: item.name,
        sequentiality: item.sequentiality,
        disable_playbackrate: item.disable_playbackrate,
        created: item.created,
      })
    )
  }, [data, searchTerm])

  const columns: ColumnsType<CertificationTypeRow> = [
    {
      title: t({
        id: 'settings.certification_types.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.certification_types.table.created',
        message: 'Aangemaakt',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: dateSort('created') as CompareFn<unknown>,
      render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.certification_types.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: CertificationTypeRow) => (
        <ActionButtonWrapper>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
          >
            <Tooltip
              title={t({
                id: 'action.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchBranchCertificationTypes.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can
            I={PermissionAction.DELETE}
            a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
          >
            <Tooltip
              title={t({
                id: 'action.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.certification_types.aciton.delete.confirm',
                  message:
                    'Ben je zeker dat je deze certificeringstype wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteCertificationType({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchCertificationTypes'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.certification_types.action.delete.success',
                          message: 'Certificeringstype succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const client = useApolloClient()

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createCertificationType({
        variables: {
          ...fields,
        },
        refetchQueries: ['branchCertificationTypes'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.certification_types.action.create.success',
              message: 'Certificeringstype succesvol aangemaakt',
            }),
          })
          setCertificationTypeModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateCertificationType({
        variables: {
          id: updateSubject?._id,
          ...fields,
        },
        refetchQueries: ['branchCertificationTypes'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.certification_types.action.update.success',
              message: 'Certificeringstype succesvol gewijzigd',
            }),
          })
          setCertificationTypeModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleCertificateBackgroundChange = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setCertificateBackgroundUploading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setCertificateBackgroundUploading(false)
        client.refetchQueries({ include: ['branches'] })
        setCertificationTypeModalVisible(false)
      })
    }
  }
  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.certification-types.heading',
          message: 'Certificeringstypes',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.certification_types.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setCertificationTypeModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-certification-type',
                    label: t({
                      id: 'settings.certification_types.action.import',
                      message: 'Certificeringstypes importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="settings.certification_types.action.create">
                Certificeringstype aanmaken
              </Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.certification_types.table.empty',
            message: 'Geen certificeringstypes gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={certificationTypes}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.certification_types.action.update.title',
                message: 'Certificeringstype wijzigen',
              })
            : t({
                id: 'settings.certification_types.action.create.title',
                message: 'Nieuw certificeringstype aanmaken',
              })
        }
        open={certificationTypeModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setCertificationTypeModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.certification_types.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.certification_types.form.validation.name',
                  message:
                    'Gelieve de naam van de certificeringstype in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="sequentiality"
            valuePropName="checked"
            wrapperCol={{ sm: { offset: 8, span: 16 } }}
            initialValue={false}
          >
            <Checkbox>
              <Trans id="settings.certification_types.form.label.sequentiality">
                Volgtijdelijkheid
              </Trans>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="disable_playbackrate"
            valuePropName="checked"
            wrapperCol={{ sm: { offset: 8, span: 16 } }}
            initialValue={false}
          >
            <Checkbox>
              <Trans id="settings.certification_types.form.label.disable_playbackrate">
                Afspeelsnelheid blokkeren
              </Trans>
            </Checkbox>
          </Form.Item>
          {updateSubject && (
            <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
              {updateSubject?.certificateBackground && (
                <a
                  href={updateSubject?.certificateBackground.url}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    margin: '6px 0',
                  }}
                  rel="noreferrer"
                >
                  <PaperClipOutlined />{' '}
                  {updateSubject?.certificateBackground?.name.split('/').at(-1)}
                </a>
              )}
              <Upload
                name="file"
                className="certification-type-certificate-background-uploader"
                showUploadList={false}
                action={`${
                  import.meta.env.NX_BACKEND_URL
                }/api/files/uploadCertificationTypeCertificateBackground/${
                  updateSubject._id
                }`}
                beforeUpload={uploadValidator(2, [FileType.jpg, FileType.png])}
                onChange={handleCertificateBackgroundChange}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
                  'x-academy-host': window.location.hostname,
                }}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={certificateBackgroundUploading}
                >
                  {certificateBackgroundUploading
                    ? t({
                        id: 'action.uploading',
                        message: 'Uploading',
                      })
                    : t({
                        id: 'settings.certification_types.form.label.certificate_background',
                        message: 'Achtergrondafbeelding attest',
                      })}
                </Button>
              </Upload>
            </Form.Item>
          )}
          <Form.Item
            label={t({
              id: 'settings.certification_types.form.label.certificate_info',
              message: 'Extra attest informatie',
            })}
            name="certificateInfo"
          >
            <Input.TextArea cols={2} />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
