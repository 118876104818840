import { useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Button, Empty, PageHeader, Row } from 'antd'
import Fuse from 'fuse.js'
import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { LikedCoursesQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Content } from '../../../shared/layout/Layout.style'
import { CourseCard } from '../../components/course-card/CourseCard'

import LIKED_COURSES_QUERY from './../../queries/liked-courses.graphql'

export const Likes = ({ route }: PageProps) => {
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { data, loading } = useQuery<LikedCoursesQuery>(LIKED_COURSES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const courses = useMemo(() => {
    const fuse = new Fuse(data?.fetchLikedCourses || [], {
      keys: ['name', 'description', 'lecturer'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return searchTerm.length > 1
      ? result.map((result) => result.item)
      : data?.fetchLikedCourses || []
  }, [data, searchTerm])

  if (loading) return <LoadScreen />

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={
          <Trans id="courses.likes.heading">
            Kijk naar jouw <strong>opgeslagen opleidingen</strong>
          </Trans>
        }
        subTitle={route.description}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'courses.likes.search.placeholder',
              message: 'Zoeken',
            })}
            onSearch={(value) => setSearchTerm(value)}
            style={{ width: 200 }}
          />,
        ]}
      />
      <Content>
        <Row gutter={16}>
          {[...courses]
            .sort(
              (a, b) =>
                new Date(b.liked).getTime() - new Date(a.liked).getTime()
            )
            .map((course) => (
              <CourseCard
                key={course._id}
                card
                progress
                course={course}
                draft={!course.published}
                likes={ability.can(
                  PermissionAction.READ,
                  PermissionObjectType.LIKE
                )}
                addToCalendar={ability.can(
                  PermissionAction.ADD_TO_CALENDAR,
                  PermissionObjectType.COURSE
                )}
                onClick={(course) =>
                  navigate(`/courses/${encodeURIComponent(course.slug)}`)
                }
                archived={course.category?.some(
                  (cat) => cat.name === 'Archief'
                )}
              />
            ))}
        </Row>
        {courses.length === 0 && (
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={t({
              id: 'courses.likes.empty',
              message:
                'Je hebt momenteel geen opleidingen toegevoegd aan je lijst.',
            })}
          >
            <Button type="primary" onClick={() => navigate('/courses')}>
              <Trans id="courses.likes.explore">Verken alle opleidingen</Trans>
            </Button>
          </Empty>
        )}
      </Content>
    </>
  )
}
