import { notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

export const AlignVertically = styled.div`
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TokenHandler = () => {
  const { loginWithMagicLinkToken } = useAuth()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  if (!params.get('token')) navigate('/login')

  useEffect(() => {
    loginWithMagicLinkToken(
      params.get('token') || '',
      () => navigate(params.get('path') || '/'),
      ({ message }) => {
        notification.error({
          message,
        })
        navigate('/')
      }
    )
  }, [params, loginWithMagicLinkToken])

  return null
}
