import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { UnitKind } from '@lms-shared-patterns/models'
import {
  ContentUnit,
  PdfUnit,
  QuizUnit,
  SurveyUnit,
  UnitQuery,
  VideoUnit,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { PageProps } from '../../../shared/interfaces/page.interface'

import UNIT_QUERY from './../../queries/unit.graphql'
import { CourseEditContentUnit } from './content/CourseEditContentUnit'
import { CourseEditPDFUnit } from './pdf/CourseEditPdfUnit'
import { CourseEditQuizUnit } from './quiz/CourseEditQuizUnit'
import { CourseEditSurveyUnit } from './survey/CourseEditSurveyUnit'
import { CourseEditVideoUnit } from './video/CourseEditVideoUnit'

export const EditUnit = (props: PageProps) => {
  const navigate = useNavigate()
  const { unit: unitId } = useParams()

  const { data: unit } = useQuery<UnitQuery>(UNIT_QUERY, {
    variables: { id: unitId },
    fetchPolicy: 'network-only',
  })

  if (!unit?.fetchUnitById) return <LoadScreen />

  switch (unit.fetchUnitById.__typename) {
    case UnitKind.PDF: {
      return (
        <CourseEditPDFUnit unit={unit.fetchUnitById as PdfUnit} {...props} />
      )
    }
    case UnitKind.VIDEO: {
      return (
        <CourseEditVideoUnit
          unit={unit.fetchUnitById as VideoUnit}
          {...props}
        />
      )
    }
    case UnitKind.CONTENT: {
      return (
        <CourseEditContentUnit
          unit={unit.fetchUnitById as ContentUnit}
          {...props}
        />
      )
    }
    case UnitKind.QUIZ: {
      return (
        <CourseEditQuizUnit unit={unit.fetchUnitById as QuizUnit} {...props} />
      )
    }
    case UnitKind.SURVEY: {
      return (
        <CourseEditSurveyUnit
          unit={unit.fetchUnitById as SurveyUnit}
          {...props}
        />
      )
    }
    default: {
      navigate(`/courses`)
      break
    }
  }

  return null
}
