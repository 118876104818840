import { useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { Input, Modal, message } from 'antd'
import { useState } from 'react'

import { ReportSubtitleErrorMutation } from 'apps/lms-front/src/generated/graphql'

import REPORT_SUBTITLE_ERROR_MUTATION from '../../../../mutations/report-subtitle-error.graphql'

type ReportSubtitleErrorModalProps = {
  open: boolean
  onClose: () => unknown
  video_id: string
}

export const ReportSubtitleErrorModal = ({
  open,
  onClose,
  video_id,
}: ReportSubtitleErrorModalProps) => {
  const [note, setNote] = useState<string>('')
  const [submitError, { loading: submittingError }] =
    useMutation<ReportSubtitleErrorMutation>(REPORT_SUBTITLE_ERROR_MUTATION, {
      notifyOnNetworkStatusChange: true,
    })

  return (
    <Modal
      title={t({
        id: 'unit.viewer.video.report_subtitle_error_modal.title',
        message: 'Een fout in de ondertiteling melden',
      })}
      centered
      open={open}
      onOk={() => {
        submitError({
          variables: {
            video_id,
            note,
          },
        })
          .then(() => {
            message.info(
              t({
                id: 'unit.viewer.video.report_subtitle_error_modal.success',
                message: 'Bedankt! We bekijken dit spoedig.',
              })
            )
            setNote('')
            onClose()
          })
          .catch((error) => {
            console.error(error)
            message.error(
              t({
                id: 'unit.viewer.video.report_subtitle_error_modal.failed',
                message:
                  'Er ging iets mis bij het doorsturen van je bericht. Probeer het later opnieuw.',
              })
            )
          })
      }}
      okText={t({
        id: 'unit.viewer.video.report_subtitle_error_modal.submit',
        message: 'Fout melden',
      })}
      onCancel={() => onClose()}
      okButtonProps={{
        disabled: !note || note === '',
        loading: submittingError,
      }}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
    >
      <Input.TextArea
        autoSize
        placeholder={t({
          id: 'unit.viewer.video.report_subtitle_error_modal.placeholder',
          message: 'Beschrijf de fout',
        })}
        onChange={(evt) => setNote(evt.target.value)}
        value={note}
        style={{ minHeight: 100 }}
      />
    </Modal>
  )
}
