import { UploadOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  message,
  notification,
  PageHeader,
  Progress,
  Row,
  Steps,
  Table,
  Upload,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { RcFile } from 'antd/lib/upload'
import { parse as papaparse } from 'papaparse'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const { Step } = Steps

import {
  CreateBranchMutation,
  CreateBranchMutationVariables,
} from 'apps/lms-front/src/generated/graphql'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'

import { Content } from '../../../../shared/layout/Layout.style'
import { arraysEqual } from '../../../../shared/utils/arrays-equal'

import CREATE_BRANCH_MUTATION from './../../../mutations/create-branch.graphql'
import { StepContent } from './ImportBranches.style'

interface BranchImport {
  name: string
  headerColor: string
  primaryColor: string
  secondaryColor: string
  textColor: string
  fontFamily: string
  hostname: string
}

export const ImportBranches = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const client = useApolloClient()

  const [fileList, setFileList] = useState<RcFile[]>()
  const [dataPreview, setDataPreview] =
    useState<CreateBranchMutationVariables[]>()
  const [current, setCurrent] = useState(0)
  const [rows, setRows] = useState(0)
  const [processingRow, setProcessingRow] = useState(0)
  const [processing, setProcessing] = useState(false)

  const [createBranch] = useMutation<CreateBranchMutation>(
    CREATE_BRANCH_MUTATION
  )

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const parse = (file: RcFile, limit?: number) =>
    new Promise((resolve, reject) => {
      papaparse(file, {
        complete: (results) => resolve(results.data),
        error: (error) => reject(error),
        header: limit === 1 ? false : true,
        preview: limit,
        skipEmptyLines: true,
      })
    })

  useEffect(() => {
    const parseCSV = async () => {
      if (fileList && fileList.length > 0) {
        const parsedDataPreview = await parse(fileList?.[0], 5)
        setDataPreview(
          (parsedDataPreview as [BranchImport]).map((branch) =>
            branchMapper(branch)
          )
        )
      }
    }
    parseCSV()
  }, [fileList])

  useEffect(() => {
    if (current === 2) {
      const reader = new FileReader()
      if (fileList && fileList.length > 0) {
        reader.readAsText(fileList?.[0])
        reader.addEventListener('load', (event) => {
          const csv = (event.target as FileReader).result
          const csvArray = (csv as string).split('\n')
          setRows(csvArray.length)
        })
      }
    }
  }, [current, fileList])

  useEffect(() => {
    const parseHeader = async () => {
      if (fileList && fileList.length > 0) {
        const header = await parse(fileList?.[0], 1)
        if (
          !arraysEqual((header as string[][])[0], [
            'name',
            'hostname',
            'fontFamily',
            'primaryColor',
            'secondaryColor',
            'textColor',
            'headerColor',
          ])
        ) {
          message.error(
            t({
              id: 'error.wrong_file_structure',
              message: 'Het bestand heeft een verkeerde structuur.',
            })
          )
          setFileList([])
        }
      }
    }
    parseHeader()
  }, [fileList])

  const columns: ColumnsType<CreateBranchMutationVariables> = [
    {
      title: t({
        id: 'settings.branches.import.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t({
        id: 'settings.branches.import.table.hostname',
        message: 'Hostnaam',
      }),
      dataIndex: 'hostnames',
      key: 'hostnames',
    },
    {
      title: t({
        id: 'settings.branches.import.table.font_family',
        message: 'Lettertype',
      }),
      dataIndex: ['fontFamily'],
      key: 'fontFamily',
    },
    {
      title: t({
        id: 'settings.branches.import.table.primary_color',
        message: 'Primaire kleur',
      }),
      dataIndex: ['primaryColor'],
      key: 'primaryColor',
    },
    {
      title: t({
        id: 'settings.branches.import.table.secondary_color',
        message: 'Secundaire kleur',
      }),
      dataIndex: ['secondaryColor'],
      key: 'secondaryColor',
    },
    {
      title: t({
        id: 'settings.branches.import.table.text_color',
        message: 'Tekstkleur',
      }),
      dataIndex: ['textColor'],
      key: 'textColor',
    },
    {
      title: t({
        id: 'settings.branches.import.table.header_color',
        message: 'Navigatiekleur',
      }),
      dataIndex: ['headerColor'],
      key: 'headerColor',
    },
  ]

  const steps = [
    {
      title: t({
        id: 'settings.branches.import.step.upload',
        message: 'Uploaden',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="settings.branches.import.step.upload.title">
              Bestand uploaden
            </Trans>
          </h3>
          <p>
            <Trans id="settings.branches.import.step.upload.description">
              Selecteer een passend CSV-bestand met dezelfde structuur als ons{' '}
              <a href="/examples/branch-import.csv" target="_blank">
                voorbeeld
              </a>
              .
            </Trans>
          </p>
          <Upload
            fileList={fileList}
            beforeUpload={(file) => setFileList([file])}
          >
            <Button icon={<UploadOutlined />}>
              <Trans id="settings.branches.import.step.upload.button">
                Selecteer een bestand
              </Trans>
            </Button>
          </Upload>
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'settings.branches.import.step.validate',
        message: 'Valideren',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="settings.branches.import.step.validate.title">
              Voorbeeldtabel
            </Trans>
          </h3>
          <p>
            <Trans id="settings.branches.import.step.validate.description">
              Kijk even na of deze structuur klopt.
            </Trans>
          </p>
          {dataPreview && (
            <Table
              dataSource={dataPreview}
              columns={columns}
              pagination={false}
              rowKey="name"
            />
          )}
        </StepContent>
      ),
    },
    {
      title: t({
        id: 'settings.branches.import.step.import',
        message: 'Importeren',
      }),
      content: (
        <StepContent>
          <h3>
            <Trans id="settings.branches.import.step.import.title">
              Importeren starten
            </Trans>
          </h3>
          <p>
            <Trans id="settings.branches.import.step.import.description">
              De pagina verlaten zal het importeren stoppen.
            </Trans>
          </p>
          <Progress percent={(processingRow / rows) * 100} status="active" />
        </StepContent>
      ),
    },
  ]

  const branchMapper = (
    branch: BranchImport
  ): CreateBranchMutationVariables => {
    return {
      name: branch.name,
      headerColor: branch.headerColor,
      primaryColor: branch.primaryColor,
      secondaryColor: branch.secondaryColor,
      textColor: branch.textColor,
      fontFamily: branch.fontFamily,
      hostnames: [branch.hostname],
      certificationSettings: {
        allowChoice: false,
      },
    }
  }

  const handleImport = () => {
    setProcessing(true)
    let currentRow = 0
    if (fileList && fileList.length > 0) {
      papaparse(fileList[0], {
        step: (results, parser) => {
          parser.pause()
          currentRow++
          setProcessingRow(currentRow)
          createBranch({
            variables: branchMapper(results.data as BranchImport),
          })
            .catch((error) => message.error(error.message))
            .finally(() => parser.resume())
        },
        complete: () => {
          notification.success({
            message: t({
              id: 'settings.branches.import.success',
              message: 'Importeren voltooid',
            }),
          })
          setTimeout(function () {
            client.refetchQueries({ include: ['branches'] })
          }, 100)
          navigate('/settings/branches')
        },
        error: (error) => {
          message.error(error.message)
          setProcessing(false)
          setProcessingRow(0)
        },
        header: true,
        skipEmptyLines: true,
      })
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
      />

      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24} lg={18}>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <Button
                  disabled={processing}
                  style={{ margin: '0 8px' }}
                  onClick={() => prev()}
                >
                  <Trans id="settings.branches.import.action.previous">
                    Terug
                  </Trans>
                </Button>
              )}
              {fileList &&
                fileList.length > 0 &&
                current < steps.length - 1 && (
                  <Button
                    disabled={processing}
                    type="primary"
                    onClick={() => next()}
                  >
                    <Trans id="settings.branches.import.action.next">
                      Volgende
                    </Trans>
                  </Button>
                )}
              {current === steps.length - 1 && (
                <Button
                  disabled={processing}
                  type="primary"
                  onClick={handleImport}
                >
                  <Trans id="settings.branches.import.action.start">
                    Start het importeren
                  </Trans>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  )
}
