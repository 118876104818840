/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CompareFn } from 'antd/lib/table/interface'
import dayjs from 'dayjs'

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
export const dateSort: (key: string) => CompareFn<any> =
  (key: string) => (itemA: any, itemB: any) => {
    const dateA = itemA[key]
    const dateB = itemB[key]

    // Handle cases where dates are missing
    if (!dateA && !dateB) {
      return 0
    }
    if (!dateA) {
      return -1 // itemA should come last
    }
    if (!dateB) {
      return 1 // itemB should come last
    }

    // If both dates are present, compare them
    return dayjs(dateA).diff(dayjs(dateB))
  }

export const numSort: (key: string) => CompareFn<string> =
  (key: string) => (numA: any, numB: any) =>
    numA[key] - numB[key]

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
export const defaultSort: (key: string) => CompareFn<string> =
  (key: string) => (a: any, b: any) => {
    if (a[key] < b[key]) return -1
    if (b[key] < a[key]) return 1
    return 0
  }

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
export const arraySort: (key: string) => CompareFn<string> =
  (key: string) => (a: any, b: any) => {
    if (a[key].length < b[key].length) return 1
    if (b[key].length < a[key].length) return -1
    return 0
  }
