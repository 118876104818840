import { AnyAbility } from '@casl/ability'
import { t } from '@lingui/macro'
import { StepType, TourProps } from '@reactour/tour'
import { useMemo } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

export const useTourSteps = (): (StepType | false)[] => []
export const usePathTourSteps = (
  ability: AnyAbility,
  tour: TourProps
): (StepType | false)[] => {
  return useMemo<StepType[]>(() => {
    const steps: StepType[] = [
      {
        selector: 'body',
        position: 'center',
        content: t({
          id: 'paths.detail.tour.welcome',
          message:
            'Welkom! Dit is je nieuw aangemaakt leerpad. Ik help je even op weg.',
        }),
        stepInteraction: false,
      },
      {
        selector: '.path-hover-menu .path-hover-menu__edit',
        content: t({
          id: 'paths.detail.tour.edit',
          message:
            'Hier kan je de informatie van het leerpad bewerken. Je keert hiermee terug naar de stappenflow van tijdens de creatie.',
        }),
        stepInteraction: false,
      },
      {
        selector: '.path-hover-menu .path-hover-menu__delete',
        content: t({
          id: 'paths.detail.tour.delete',
          message:
            'Hier kan je het leerpad verwijderen. Deze actie kan niet ongedaan gemaakt worden.',
        }),
        stepInteraction: false,
      },
      ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS
      ) && {
        selector: '.ant-tabs [data-node-key="participants"]',

        content: t({
          id: 'paths.detail.tour.participants',
          message: 'Hier kan je deelnemers bekijken, beheren en verwijderen.',
        }),
        stepInteraction: false,
      },
      {
        selector: '.ant-tabs [data-node-key="downloads"]',
        content: t({
          id: 'paths.detail.tour.downloads',
          message: 'Hier kan je bijlagen bekijken en toevoegen.',
        }),
        stepInteraction: false,
      },
      {
        selector: '.path-timeline',
        content: t({
          id: 'paths.detail.tour.timeline',
          message:
            "Dit is de tijdslijn van het leerpad. Klik op 'Module toevoegen' om een eerste module toe te voegen.",
        }),
        stepInteraction: true,
        action: (node) => {
          const handleClick = () => {
            tour.setIsOpen(false)
            node?.removeEventListener('click', handleClick)
          }
          node?.addEventListener('click', handleClick)
        },
      },
    ].filter(Boolean) as StepType[]

    return steps
  }, [ability])
}
