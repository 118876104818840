import styled from 'styled-components'

export const Section = styled.div`
  padding: 1.75rem 1.5rem;

  &:not(:first-child) {
    border-top: 1px solid #e5e5e5;
  }
`

export const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
`
