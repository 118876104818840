import { Trans } from '@lingui/macro'
import { Alert } from 'antd'
import { useEffect, useState } from 'react'

export const ConnectionStatus = ({ connected }: { connected: boolean }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (connected) return setShow(false)

    const timer = setTimeout(() => {
      setShow(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [connected])

  return (
    show && (
      <Alert
        showIcon={true}
        type="warning"
        style={{ marginBottom: 24 }}
        message={
          <Trans id="unit.viewer.video.connecting_to_event_server">
            Problemen met connecteren... Netwerkissues kunnen de interactieve
            delen van deze sessie negatief beïnvloeden.
          </Trans>
        }
      />
    )
  )
}
