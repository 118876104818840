import {
  BoldOutlined,
  ItalicOutlined,
  LinkOutlined,
  UndoOutlined,
  RedoOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  DashOutlined,
  DownOutlined,
} from '@ant-design/icons'
import { Trans, t } from '@lingui/macro'
import { Editor } from '@tiptap/react'
import { Button, Dropdown, Form, Input, Modal, Space, Tooltip } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { useState } from 'react'

// Validator to check if value is not an empty string
const notEmptyValidator = (_, value) => {
  if (value && value.trim() !== '') {
    return Promise.resolve() // validation passed
  }
  return Promise.reject(
    new Error(
      t({
        id: 'error.url_empty',
        message: 'URL mag niet leeg zijn',
      })
    )
  ) // validation failed
}

// Validator to check if value starts with '/' or an URL protocol
const urlFormatValidator = (_, value) => {
  const pattern = /^(\/|https?:\/\/)/
  if (pattern.test(value) || value === '') {
    return Promise.resolve() // validation passed
  }
  return Promise.reject(
    new Error(
      t({
        id: 'error.url_not_valid',
        message: 'Een geldige URL begint met http(s):// of /.',
      })
    )
  )
}

export const RichEditorMenuBar = ({
  editor,
  disableTextStyles,
  disableLinks,
  disableClearMarkup,
  disableHorizontalRuler,
  disableHistory,
  boldHeadings = false,
}: {
  editor: Editor | null
  disableTextStyles: boolean
  disableLinks: boolean
  disableClearMarkup: boolean
  disableHorizontalRuler: boolean
  disableHistory: boolean
  boldHeadings?: boolean
}) => {
  const [isLinkModalOpen, setLinkModalOpen] = useState(false)

  const [form] = Form.useForm()

  if (!editor) {
    return null
  }

  const isCursorInsideLink = () => {
    const { state } = editor
    const { link } = state.schema.marks

    // Check if there's a link mark active at the current selection
    if (link?.isInSet(state.storedMarks || state.selection.$from.marks())) {
      return true
    }

    // Check if the cursor's direct parent node is a link
    return state.selection.$from.parent.type.name === 'link'
  }

  const getLinkDetails = () => {
    const { state } = editor
    const { link } = state.schema.marks
    const linkMark = state.storedMarks || state.selection.$from.marks()

    // Check if there's a link mark active at the current selection
    const activeLinkMark = linkMark.find((mark) => mark.type === link)

    if (!activeLinkMark) return

    // Find the range (start and end positions) of the link mark in the document
    let from = state.selection.from
    let to = state.selection.to

    while (from > 0 && link.isInSet(state.doc.resolve(from).marks())) {
      from--
    }

    while (
      to < state.doc.content.size &&
      link.isInSet(state.doc.resolve(to).marks())
    ) {
      to++
    }

    // Get the full text between the found start and end positions
    const linkText = state.doc.textBetween(from, to)

    return {
      url: activeLinkMark.attrs.href,
      text: linkText,
      from,
      to,
    }
  }

  const replaceLink = (newText: string, newUrl: string) => {
    const { state, view } = editor
    const { link } = state.schema.marks
    const linkMark = state.selection.$from
      .marks()
      .find((mark) => mark.type === link)

    if (!linkMark) return

    // Find the range (start and end positions) of the link mark in the document
    let from = state.selection.from
    let to = state.selection.to

    while (from > 0 && link.isInSet(state.doc.resolve(from).marks())) {
      from--
    }

    while (
      to < state.doc.content.size &&
      link.isInSet(state.doc.resolve(to).marks())
    ) {
      to++
    }

    // Create a new link mark with the updated URL
    const updatedLinkMark = link.create({ href: newUrl })

    // Create a new text node with the updated link mark and new text
    const { schema } = state
    const textNode = schema.text(newText || 'link', [updatedLinkMark])

    // Replace the current link with the new text node
    const tr = state.tr.replaceWith(from, to, textNode)

    // Update the editor state
    view.dispatch(tr)
  }

  const handleSaveLink = ({ text, url }: { text: string; url: string }) => {
    setLinkModalOpen(false)
    if (isCursorInsideLink() && text) {
      // Update
      replaceLink(text, url)
    } else {
      // Add
      editor.chain().focus().toggleLink({ href: url }).run()
    }
    form.resetFields()
  }

  return (
    <>
      <ButtonGroup style={{ flexWrap: 'wrap', marginTop: '0.25rem' }}>
        <Space>
          {!disableTextStyles && (
            <Dropdown
              trigger={['click']}
              menu={{
                selectedKeys: ['active'],
                items: [
                  {
                    key: editor.isActive('paragraph') ? 'active' : 'paragraph',
                    onClick: () => editor.chain().focus().setParagraph().run(),
                    label: t({
                      id: 'editor.paragraph',
                      message: 'Paragraaf',
                    }),
                  },
                  {
                    key: editor.isActive('heading', { level: 2 })
                      ? 'active'
                      : 'h2',
                    onClick: () => {
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                      if (boldHeadings) editor.chain().focus().setBold().run()
                    },
                    label: t({
                      id: 'editor.heading1',
                      message: 'Kop 1',
                    }),
                  },
                  {
                    key: editor.isActive('heading', { level: 3 })
                      ? 'active'
                      : 'h3',
                    onClick: () => {
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                      if (boldHeadings) editor.chain().focus().setBold().run()
                    },
                    label: t({
                      id: 'editor.heading2',
                      message: 'Kop 2',
                    }),
                  },
                  {
                    key: editor.isActive('heading', { level: 4 })
                      ? 'active'
                      : 'h4',
                    onClick: () => {
                      editor.chain().focus().toggleHeading({ level: 4 }).run()
                      if (boldHeadings) editor.chain().focus().setBold().run()
                    },
                    label: t({
                      id: 'editor.heading3',
                      message: 'Kop 3',
                    }),
                  },
                  {
                    key: editor.isActive('heading', { level: 5 })
                      ? 'active'
                      : 'h5',
                    onClick: () => {
                      editor.chain().focus().toggleHeading({ level: 5 }).run()
                      if (boldHeadings) editor.chain().focus().setBold().run()
                    },
                    label: t({
                      id: 'editor.heading4',
                      message: 'Kop 4',
                    }),
                  },
                  {
                    key: editor.isActive('heading', { level: 6 })
                      ? 'active'
                      : 'h6',
                    onClick: () => {
                      editor.chain().focus().toggleHeading({ level: 6 }).run()
                      if (boldHeadings) editor.chain().focus().setBold().run()
                    },
                    label: t({
                      id: 'editor.heading5',
                      message: 'Kop 5',
                    }),
                  },
                ],
              }}
            >
              <Tooltip
                title={t({
                  id: 'editor.style',
                  message: 'Tekststijl',
                })}
                mouseEnterDelay={1}
              >
                <Button
                  size={'small'}
                  style={{ minWidth: 115, textAlign: 'center', fontSize: 14 }}
                  tabIndex={-1}
                >
                  <CurrentLabel editor={editor} />
                  <DownOutlined />
                </Button>
              </Tooltip>
            </Dropdown>
          )}
          <div>
            <Tooltip
              title={t({
                id: 'editor.bold',
                message: 'Vet',
              })}
              mouseEnterDelay={1}
            >
              <Button
                tabIndex={-1}
                type={editor.isActive('bold') ? 'primary' : 'default'}
                size={'small'}
                onClick={() => editor.chain().focus().toggleBold().run()}
              >
                <BoldOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              title={t({
                id: 'editor.italic',
                message: 'Cursief',
              })}
              mouseEnterDelay={1}
            >
              <Button
                tabIndex={-1}
                type={editor.isActive('italic') ? 'primary' : 'default'}
                size={'small'}
                onClick={() => editor.chain().focus().toggleItalic().run()}
              >
                <ItalicOutlined />
              </Button>
            </Tooltip>
            {!disableLinks && (
              <Tooltip
                title={t({
                  id: 'editor.link',
                  message: 'Link',
                })}
                mouseEnterDelay={1}
              >
                <Button
                  tabIndex={-1}
                  type={isCursorInsideLink() ? 'primary' : 'default'}
                  size={'small'}
                  onClick={() => {
                    if (isCursorInsideLink()) {
                      /** Load details */
                      const details = getLinkDetails()
                      if (details) {
                        form.setFieldsValue({
                          url: details.url,
                          text: details.text,
                        })
                      }
                    } else {
                      /** Reset details */
                      form.resetFields()
                    }
                    setLinkModalOpen(true)
                  }}
                >
                  <LinkOutlined />
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={t({
                id: 'editor.bullet_list',
                message: 'Opsommingstekens',
              })}
              mouseEnterDelay={1}
            >
              <Button
                tabIndex={-1}
                type={editor.isActive('bulletList') ? 'primary' : 'default'}
                size={'small'}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
              >
                <UnorderedListOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              title={t({
                id: 'editor.numbered_list',
                message: 'Nummering',
              })}
              mouseEnterDelay={1}
            >
              <Button
                tabIndex={-1}
                type={editor.isActive('orderedList') ? 'primary' : 'default'}
                size={'small'}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
              >
                <OrderedListOutlined />
              </Button>
            </Tooltip>
            {!disableHorizontalRuler && (
              <Tooltip
                title={t({
                  id: 'editor.horizontal_rule',
                  message: 'Horizontale lijn',
                })}
                mouseEnterDelay={1}
              >
                <Button
                  tabIndex={-1}
                  size={'small'}
                  onClick={() =>
                    editor.chain().focus().setHorizontalRule().run()
                  }
                >
                  <DashOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
          {!disableHistory && (
            <div>
              <Tooltip
                title={t({
                  id: 'editor.undo',
                  message: 'Ongedaan maken',
                })}
                mouseEnterDelay={1}
              >
                <Button
                  tabIndex={-1}
                  size={'small'}
                  onClick={() => editor.chain().focus().undo().run()}
                >
                  <UndoOutlined />
                </Button>
              </Tooltip>
              <Tooltip
                title={t({
                  id: 'editor.redo',
                  message: 'Opnieuw uitvoeren',
                })}
                mouseEnterDelay={1}
              >
                <Button
                  tabIndex={-1}
                  size={'small'}
                  onClick={() => editor.chain().focus().redo().run()}
                >
                  <RedoOutlined />
                </Button>
              </Tooltip>
            </div>
          )}
          {!disableClearMarkup && (
            <Button
              tabIndex={-1}
              size={'small'}
              style={{ fontSize: 14 }}
              onClick={() => {
                editor.chain().focus().unsetAllMarks().run()
                editor.chain().focus().clearNodes().run()
              }}
            >
              <Trans id="editor.clear_formatting">Opmaak verwijderen</Trans>
            </Button>
          )}
        </Space>
      </ButtonGroup>
      <Modal
        title={
          isCursorInsideLink()
            ? t({
                id: 'editor.edit_link',
                message: 'Link bewerken',
              })
            : t({
                id: 'editor.add_link',
                message: 'Link toevoegen',
              })
        }
        open={isLinkModalOpen}
        onOk={() => form.submit()}
        okText={
          isCursorInsideLink()
            ? t({
                id: 'action.edit',
                message: 'Bewerken',
              })
            : t({
                id: 'action.add',
                message: 'Toevoegen',
              })
        }
        onCancel={() => setLinkModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onKeyDown={(e) => {
            e.code === 'Enter' && form.submit()
          }}
          onFinish={(values) => handleSaveLink(values)}
        >
          <Form.Item
            label={t({
              id: 'editor.url_form.label.url',
              message: 'URL',
            })}
            name="url"
            required
            rules={[
              {
                validator: notEmptyValidator,
                message: t({
                  id: 'error.url_empty',
                  message: 'URL mag niet leeg zijn',
                }),
              },
              {
                validator: urlFormatValidator,
                message: t({
                  id: 'error.url_not_valid',
                  message: 'Een geldige URL begint met http(s):// of /.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {isCursorInsideLink() && (
            <Form.Item
              label={t({
                id: 'editor.url_form.label.text',
                message: 'Tekst',
              })}
              name="text"
              required={isCursorInsideLink()}
              rules={[
                {
                  validator: notEmptyValidator,
                  message: t({
                    id: 'editor.url_form.validation.text',
                    message: 'Tekst is verplicht',
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}

const CurrentLabel = ({ editor }: { editor: Editor }) => {
  if (editor.isActive('paragraph'))
    return (
      <span>
        <Trans id="editor.paragraph">Paragraaf</Trans>
      </span>
    )
  if (editor.isActive('heading', { level: 2 }))
    return (
      <span>
        <Trans id="editor.heading1">Kop 1</Trans>
      </span>
    )
  if (editor.isActive('heading', { level: 3 }))
    return (
      <span>
        <Trans id="editor.heading2">Kop 2</Trans>
      </span>
    )
  if (editor.isActive('heading', { level: 4 }))
    return (
      <span>
        <Trans id="editor.heading3">Kop 3</Trans>
      </span>
    )
  if (editor.isActive('heading', { level: 5 }))
    return (
      <span>
        <Trans id="editor.heading4">Kop 4</Trans>
      </span>
    )
  if (editor.isActive('heading', { level: 6 }))
    return (
      <span>
        <Trans id="editor.heading5">Kop 5</Trans>
      </span>
    )
  return <span></span>
}
