import { CheckOutlined, AlertOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Alert } from 'antd'
import { useContext, useMemo } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'
import { useAuth } from '../../auth/hooks/use-auth'

import { useLearningPathStatus } from './use-path-status.hook'

type RegistrationStatus = {
  registered: boolean
  waiting: boolean
  approved: boolean
  denied: boolean
  // Available actions
  canRegister: boolean
  canAdminRegister: boolean
  canRegisterSubject: boolean
  canApprove: boolean
  canDeny: boolean
  canDelete: boolean
  canAskQuestion: boolean
  alert: React.ReactNode[] | null
}

// Helper functions to improve readability
const isUserMatch = (id1?: string | null, id2?: string | null): boolean => {
  return !!id1 && !!id2 && String(id1) === String(id2)
}

const getRegistrationAlerts = (
  isRegistered: boolean,
  isWaiting: boolean,
  isDenied: boolean
): React.ReactNode[] | null => {
  const alerts: React.ReactNode[] = []

  if (isRegistered) {
    alerts.push(
      <Alert
        key="registered"
        showIcon
        icon={<CheckOutlined />}
        banner
        type="success"
        message={
          <Trans id="paths.card.registered">
            Je bent ingeschreven voor dit leerpad
          </Trans>
        }
      />
    )
  }

  if (isWaiting) {
    alerts.push(
      <Alert
        key="waiting"
        showIcon
        icon={<AlertOutlined />}
        banner
        type="warning"
        message={
          <Trans id="paths.card.in_review">
            Je inschrijving wacht op goedkeuring van een beheerder
          </Trans>
        }
      />
    )
  }

  if (isDenied) {
    alerts.push(
      <Alert
        key="denied"
        showIcon
        icon={<AlertOutlined />}
        banner
        type="error"
        message={t({
          id: 'path.card.denied',
          message: 'Je inschrijving voor dit leerpad is afgewezen',
        })}
      />
    )
  }

  return alerts?.length > 0 ? alerts : null
}

export const useUserPathStatus = (
  path: PathQuery['fetchLearningPathById'],
  activity?: PathQuery['fetchLearningPathById']['my_activity'],
  request?: PathQuery['fetchLearningPathById']['my_request'],
  subject_id?: string
): RegistrationStatus => {
  const { user } = useAuth()
  const ability = useContext(AbilityContext)

  const isAdminWrite = ability.can(
    PermissionAction.UPDATE,
    PermissionObjectType.BRANCH_LEARNING_PATH_PARTICIPANTS
  )

  const { openForRegistration, registrationStop } = useLearningPathStatus(path)

  const currentUserId = subject_id || user?._id

  return useMemo(() => {
    const { approval } = path

    // Basic registration status
    const isActive = !!activity?.created

    // Core status flags
    const registered = isActive

    const waiting =
      !!request && !!approval && !request?.approved && !request?.denied

    const denied = !!request?.denied

    const approved = !!request?.approved

    // User relationship flags
    const selfRegistered = isUserMatch(activity?.created_by?._id, currentUserId)

    // Available actions
    const canRegister =
      !isActive && !registrationStop && !denied && openForRegistration

    const canAdminRegister = !registrationStop && isAdminWrite

    const canRegisterSubject =
      !isActive && !registrationStop && !denied && isAdminWrite

    const canApprove =
      (waiting || denied) && (approval || denied) && isAdminWrite

    const canDeny = waiting && !denied && approval && isAdminWrite

    const canDelete = !!activity && isAdminWrite

    const canAskQuestion = registered

    return {
      // Status flags
      registered,
      waiting,
      approved,
      denied,
      selfRegistered,

      // Available actions
      canRegister,
      canAdminRegister,
      canRegisterSubject,
      canApprove,
      canDeny,
      canDelete,
      canAskQuestion,

      // Alerts
      alert: getRegistrationAlerts(registered, waiting, denied),
    }
  }, [
    path,
    currentUserId,
    registrationStop,
    openForRegistration,
    isAdminWrite,
    activity,
    request,
  ])
}

export type { RegistrationStatus }
