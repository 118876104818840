import { Row } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const Section = styled.section`
  padding: 4rem 0;
  overflow: hidden;
  ${({ dark }: { dark?: boolean }) =>
    dark &&
    `
    background-color: var(--secondary-color);
    --text-color: #FFF;
  `}
`

export const SectionTitleWrapper = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const SectionTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 0;
  @media screen and (min-width: 992px) {
    font-size: 1.875rem;
  }
`

export const CoursesRow = styled(Row as unknown as AnyStyledComponent)`
  margin-top: 2rem;
`
