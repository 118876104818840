import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Col, PageHeader, Row, Statistic, Table } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { ColumnsType } from 'antd/lib/table'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { formatDuration } from '@lms-shared-patterns/utils'
import {
  CertificationType,
  CertificationTypesQuery,
  CompletedCoursesQuery,
} from 'apps/lms-front/src/generated/graphql'

import CERTIFICATION_TYPES from '../../../settings/queries/certification-types.graphql'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import COMPLETED_COURSES_QUERY from '../../queries/completed-courses.graphql'
import { CertificatePreview } from '../certificate-preview/CertificatePreview'

type CertificateDataItem = CompletedCoursesQuery['fetchCompletedCourses'][0]
export const CertificatesOverview = () => {
  const [expandedCourseId, _] = useQueryParam('id', StringParam)
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const { data, loading } = useQuery<CompletedCoursesQuery>(
    COMPLETED_COURSES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filter:
          start && end
            ? { start, end }
            : {
                start: dayjs().startOf('year').toDate(),
                end: dayjs().endOf('year').toDate(),
              },
      },
    }
  )

  const expandedRowRef = useRef<HTMLDivElement>(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  useEffect(() => {
    if (expandedCourseId && data?.fetchCompletedCourses) {
      const courseToExpand = data.fetchCompletedCourses.find(
        (course) => course._id === expandedCourseId
      )
      if (courseToExpand) {
        setExpandedRowKeys([expandedCourseId])

        // Scroll to the expanded row after a short delay to ensure the row has expanded
        setTimeout(() => {
          if (expandedRowRef.current) {
            expandedRowRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        }, 100)
      }
    }
  }, [expandedCourseId, data])

  const { data: certificationTypes } = useQuery<CertificationTypesQuery>(
    CERTIFICATION_TYPES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const duration_reducer = (prev: number, cur: CertificateDataItem) => {
    const duration = cur?.duration ? cur?.duration * 60 : cur.calcDuration || 0
    return prev + duration
  }

  const total_training_duration = useMemo(() => {
    return data?.fetchCompletedCourses.reduce(duration_reducer, 0)
  }, [data])

  const training_duration = useMemo(() => {
    return data?.fetchCompletedCourses
      .filter(
        (course) =>
          course.my_activity && !course.my_activity?.certification_type
      )
      .reduce(duration_reducer, 0)
  }, [data])

  const certification_duration_columns:
    | ColumnsType<CertificateDataItem>
    | undefined = [
    ...(certificationTypes?.fetchCertificationTypes
      ?.reduce<Map<string, ColumnsType<CertificateDataItem>[0]>>(
        (acc, certification) => {
          const key = `${certification._id}-duration`

          if (acc.has(certification.name)) {
            const existingColumn = acc.get(certification.name)
            existingColumn!.key = `${existingColumn!.key},${key}`
            const originalRender = existingColumn!.render

            existingColumn!.render = (_: number, cur: CertificateDataItem) => {
              const duration = cur?.duration
                ? cur.duration * 60
                : cur.calcDuration || 0
              if (
                new ObjectId(
                  cur.my_activity?.certification_type?._id as string
                ).equals(certification._id)
              ) {
                return formatDuration(duration)
              }
              return originalRender?.(_, cur, _)
            }
          } else {
            if (
              data?.fetchCompletedCourses?.some((x) =>
                new ObjectId(
                  x.my_activity?.certification_type?._id as string
                ).equals(certification._id)
              )
            )
              acc.set(certification.name, {
                title: certification.name,
                dataIndex: ['my_activity', 'duration'],
                key,
                align: 'center' as ColumnsType['0']['align'],
                render: (_: number, cur: CertificateDataItem) => {
                  const duration = cur?.duration
                    ? cur.duration * 60
                    : cur.calcDuration || 0
                  return new ObjectId(
                    cur.my_activity?.certification_type?._id
                  ).equals(certification._id)
                    ? formatDuration(duration)
                    : null
                },
              })
          }

          return acc
        },
        new Map()
      )
      ?.values() || []),
  ]

  const filteredCertificatesType = useMemo(() => {
    return certificationTypes?.fetchCertificationTypes.reduce(
      (acc: CertificationType[], current) => {
        const x = acc.find((item) => item.name === current.name)
        return x ? acc : [...acc, current]
      },
      []
    )
  }, [certificationTypes])

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'certificates.overview.heading',
          message: 'Mijn attesten',
        })}
        extra={
          <DatePicker.RangePicker
            ranges={{
              [t({
                id: 'datepicker.preset.last_7_days',
                message: 'Laatste 7 dagen',
              })]: [dayjs().add(-7, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.last_30_days',
                message: 'Laatste 30 dagen',
              })]: [dayjs().add(-30, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.this_week',
                message: 'Deze week',
              })]: [dayjs().startOf('week'), dayjs().endOf('week')],
              [t({
                id: 'datepicker.preset.this_month',
                message: 'Deze maand',
              })]: [dayjs().startOf('month'), dayjs().endOf('month')],
              [t({
                id: 'datepicker.preset.this_quarter',
                message: 'Dit kwartaal',
              })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
              [t({
                id: 'datepicker.preset.this_year',
                message: 'Dit jaar',
              })]: [dayjs().startOf('year'), dayjs().endOf('year')],
            }}
            allowClear={false}
            allowEmpty={[false, false]}
            defaultValue={[
              start ? dayjs(start) : dayjs().startOf('year'),
              end ? dayjs(end) : dayjs().endOf('year'),
            ]}
            disabledDate={(current) => {
              return current && current > dayjs().endOf('day')
            }}
            onChange={(dates) => {
              if (dates) {
                if (dates[0])
                  setStart(dayjs(dates[0]).startOf('day').toISOString())
                if (dates[1]) setEnd(dayjs(dates[1]).endOf('day').toISOString())
              }
            }}
            style={{ marginBottom: 24 }}
            format="DD/MM/YYYY"
          />
        }
      />
      <Content>
        <Row
          gutter={16}
          style={{
            textAlign: 'center',
            marginBottom: 16,
            justifyContent: 'center',
          }}
        >
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'certificates.overview.total_training_duration',
                message: 'Totale trainingstijd',
              })}
              value={formatDuration(total_training_duration || 0)}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'certificates.overview.training_duration',
                message: 'Trainingstijd niet-erkend',
              })}
              value={formatDuration(training_duration || 0)}
              loading={loading}
            />
          </Col>
          {filteredCertificatesType
            ?.map<CertificationType & { duration?: number }>(
              (certification) => {
                const duration = data?.fetchCompletedCourses
                  .filter(
                    (course) =>
                      course.my_activity?.certification_type?.name ===
                      certification.name
                  )
                  .reduce(duration_reducer, 0)

                return {
                  ...certification,
                  duration,
                }
              }
            )
            .filter(
              (certification) =>
                certification.duration && certification.duration > 0
            )
            .map((certification) => {
              return (
                <Col
                  key={certification._id}
                  xs={{ span: 12 }}
                  md={{ span: 6 }}
                  style={{ marginBottom: 24 }}
                >
                  <Statistic
                    title={t({
                      id: 'certificates.overview.certification_duration',
                      message: `Trainingstijd ${certification.name}`,
                    })}
                    value={formatDuration(certification.duration || 0)}
                    loading={loading}
                  />
                </Col>
              )
            })}
        </Row>
        <Row>
          <Col>
            <Table
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    id={`certificates-preview-${record._id}`}
                    ref={
                      record._id === expandedCourseId ? expandedRowRef : null
                    }
                  >
                    <CertificatePreview course_id={record._id} />{' '}
                  </div>
                ),
                expandRowByClick: true,
                expandedRowKeys,
                onExpandedRowsChange: (newExpandedRows) => {
                  setExpandedRowKeys(newExpandedRows as string[])
                },
              }}
              bordered
              size="small"
              loading={loading}
              dataSource={data?.fetchCompletedCourses}
              pagination={{
                pageSize: 50,
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              rowKey="_id"
              columns={[
                {
                  title: t({
                    id: 'certificates.overview.table.date',
                    message: 'Datum',
                  }),
                  dataIndex: ['my_activity', 'completed'],
                  key: 'completed',
                  render: (completed) => dayjs(completed).format('DD/MM/YYYY'),
                  sortDirections: ['descend', 'ascend'],
                  defaultSortOrder: 'descend',
                  sorter: (a, b) =>
                    dayjs(a.my_activity?.completed).unix() -
                    dayjs(b.my_activity?.completed).unix(),
                },
                {
                  title: t({
                    id: 'certificates.overview.table.course',
                    message: 'Naam',
                  }),
                  dataIndex: ['name'],
                  key: 'name',
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  width: '30%',
                  ellipsis: true,
                },
                {
                  title: t({
                    id: 'certificates.overview.table.lecturer',
                    message: 'Docent',
                  }),
                  dataIndex: ['lecturer'],
                  key: 'lecturer',
                  sorter: (a, b) =>
                    (a.lecturer || '').localeCompare(b.lecturer || ''),
                  width: '20%',
                  ellipsis: true,
                },
                {
                  title: t({
                    id: 'certificates.overview.table.certification_type',
                    message: 'Type',
                  }),
                  dataIndex: ['my_activity', 'certification_type'],
                  key: 'certification_type',
                  align: 'center' as ColumnsType['0']['align'],
                  render: (certification_type?: { _id: string }) => {
                    const type =
                      certificationTypes?.fetchCertificationTypes.find(
                        (certification) =>
                          new ObjectId(certification._id).equals(
                            certification_type?._id || ''
                          )
                      )
                    return type?.name || null
                  },
                  filters: [
                    {
                      text: t({
                        id: 'certificates.overview.table.training_duration.not_certified',
                        message: 'niet-erkend',
                      }),
                      value: false,
                    },
                    ...(certificationTypes?.fetchCertificationTypes.map(
                      (type) => ({
                        text: type.name,
                        value: type._id,
                      })
                    ) || []),
                  ],
                  onFilter: (value, record) => {
                    if (value === record.my_activity?.certification_type?._id)
                      return true
                    if (value === false)
                      return !record.my_activity?.certification_type?._id
                    return false
                  },
                  sortDirections: ['descend', 'ascend'],
                  sorter: (a, b) =>
                    (
                      a.my_activity?.certification_type?._id || ''
                    ).localeCompare(
                      b.my_activity?.certification_type?._id || ''
                    ),
                },
                {
                  title: t({
                    id: 'certificates.overview.table.training_duration',
                    message: 'Trainingstijd',
                  }),
                  children: [
                    /* TODO: replace ITAA-mention with custom translation */
                    {
                      title: t({
                        id: 'certificates.overview.table.training_duration.not_certified',
                        message: 'niet-erkend',
                      }),
                      dataIndex: ['duration'],
                      render: (
                        _: number,
                        cur: CompletedCoursesQuery['fetchCompletedCourses'][0]
                      ) => {
                        const duration = cur?.duration
                          ? cur?.duration * 60
                          : cur.calcDuration || 0
                        return cur.my_activity?.certification_type
                          ? null
                          : formatDuration(duration)
                      },
                      align: 'center',
                    },
                    ...certification_duration_columns,
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Content>
    </>
  )
}
