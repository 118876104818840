import { PathQuery } from 'apps/lms-front/src/generated/graphql'

import { useUserPathStatus } from '../hooks/use-user-path-status.hook'

export const UserPathStatus = ({
  path,
}: {
  path: PathQuery['fetchLearningPathById']
}) => {
  const { alert } = useUserPathStatus(path, path.my_activity, path.my_request)

  return <div>{alert}</div>
}
