import { MinusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Avatar, Button, Card, Form, Input, Space, notification } from 'antd'
import { OTPInput, SlotProps } from 'input-otp'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { StringParam, useQueryParam } from 'use-query-params'

import { AuthMethod } from '@lms-shared-patterns/models'
import { RequestMagicCodeMutation } from 'apps/lms-front/src/generated/graphql'
import REQUEST_MAGIC_CODE_MUTATION from 'apps/lms-front/src/modules/auth/mutations/request-magic-code.graphql'

import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { useAuth } from '../../hooks/use-auth'
import { useBranch } from '../../hooks/use-branch'
import { AlignVertically } from '../login'

const MethodCard = styled((props) => <Card {...props} />)`
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`

const MicrosoftLogoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    style={{ width: '1rem' }}
  >
    <path fill="#f3f3f3" d="M0 0h23v23H0z" />
    <path fill="#f35325" d="M1 1h10v10H1z" />
    <path fill="#81bc06" d="M12 1h10v10H12z" />
    <path fill="#05a6f0" d="M1 12h10v10H1z" />
    <path fill="#ffba08" d="M12 12h10v10H12z" />
  </svg>
)

export const MobileLogin = () => {
  const branch = useBranch()

  const [method, setMethod] = useQueryParam('method', StringParam)
  const [codeSent, setCodeSent] = useState<boolean>(false)
  const [name] = useState<string | undefined | null>(
    localStorage.getItem('aa_first_name')
  )
  const [email, setEmail] = useState<string | undefined | null>(
    localStorage.getItem('aa_email')
  )
  const [picture] = useState<string | undefined | null>(
    localStorage.getItem('aa_avatar')
  )

  const [requestMagicCode] = useMutation<RequestMagicCodeMutation>(
    REQUEST_MAGIC_CODE_MUTATION
  )

  const { pathname } = window.location
  const isAdminLogin = pathname === '/adminlogin'

  const loginMethods = isAdminLogin
    ? [AuthMethod.EMAIL_PASS]
    : branch?.loginMethods

  const [loading, setLoading] = useState<boolean>(false)
  const { login, loginWithMicrosoftAzureAd, verifyMagicCode } = useAuth()

  const [otpForm] = Form.useForm()
  const otpEmail = Form.useWatch('email', otpForm)

  return (
    <AlignVertically>
      {!method && (
        <div>
          <h1>
            {name ? (
              <Trans id="login.welcome_name">Welkom terug, {name}!</Trans>
            ) : (
              <Trans id="login.title">Inloggen</Trans>
            )}
          </h1>
          <p>Selecteer een inlogmethode.</p>
          <Space direction="vertical" style={{ width: '100%' }}>
            {loginMethods?.includes(AuthMethod.EMAIL_PASS) && (
              <MethodCard onClick={() => setMethod(AuthMethod.EMAIL_PASS)}>
                <Card.Meta
                  title={
                    <Space>
                      <div>💻</div>
                      <Trans id="login_methods.email_pass">
                        E-mail en wachtwoord
                      </Trans>
                    </Space>
                  }
                />
              </MethodCard>
            )}
            {loginMethods?.includes(AuthMethod.MICROSOFT) && (
              <MethodCard onClick={() => loginWithMicrosoftAzureAd()}>
                <Card.Meta
                  title={
                    <Space>
                      <div>
                        <MicrosoftLogoSVG />
                      </div>
                      <Trans id="login_methods.microsoft_account">
                        Microsoft-account
                      </Trans>
                    </Space>
                  }
                />
              </MethodCard>
            )}
            <MethodCard onClick={() => setMethod(AuthMethod.EMAIL_CODE)}>
              <Card.Meta
                title={
                  <Space>
                    <div>✉️</div>
                    <Trans id="login_methods.email_code">
                      Via éénmalige code in e-mail
                    </Trans>
                  </Space>
                }
                description="We verzenden je een éénmalige code per mail. Deze kan je vervolgens invullen om in te loggen."
              />
            </MethodCard>
          </Space>
        </div>
      )}
      {method === AuthMethod.EMAIL_PASS && (
        <>
          <h1>
            <Trans id="login.title.email_pass">Inloggen in je account</Trans>
          </h1>
          <p>
            <Trans id="login.subtitle.email_pass">
              Vul je gegevens in om verder te gaan.
            </Trans>
          </p>
          <Form
            hidden={
              !(loginMethods && loginMethods.includes(AuthMethod.EMAIL_PASS))
            }
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onFinish={(data: any) => {
              setLoading(true)
              return login({
                email: isAdminLogin ? data.email : email || data.email,
                password: data.password,
                admin: isAdminLogin,
              })
                .catch(errorNotifierFn)
                .finally(() => setLoading(false))
            }}
          >
            {email && !isAdminLogin && (
              <Card style={{ marginBottom: 24 }}>
                <Card.Meta
                  avatar={<Avatar size={'large'} src={picture} />}
                  description={
                    <Space direction="vertical">
                      {email}
                      <Button
                        type="text"
                        size="small"
                        style={{
                          margin: '-10px 0',
                          padding: '10px 0',
                          fontSize: 14,
                        }}
                        onClick={() => setEmail(undefined)}
                      >
                        (<Trans id="login.other_account">ander account?</Trans>)
                      </Button>
                    </Space>
                  }
                />
              </Card>
            )}
            <Form.Item
              hidden={!!email && !isAdminLogin}
              label={t({
                id: 'login.form.label.email',
                message: 'E-mailadres',
              })}
              labelAlign="left"
              name="email"
              normalize={(value) => value.trim()}
              rules={[
                {
                  type: 'email',
                  message: t({
                    id: 'login.form.validation.required.email',
                    message: 'Voer een geldig e-mailadres in',
                  }),
                },
                {
                  required: email && !isAdminLogin ? false : true,
                  message: t({
                    id: 'login.form.validation.email',
                    message: 'Gelieve je e-mailadres in te geven',
                  }),
                },
              ]}
            >
              <Input disabled={loading} size="large" />
            </Form.Item>

            <Form.Item
              label={t({
                id: 'login.form.label.password',
                message: 'Wachtwoord',
              })}
              name="password"
              rules={[
                {
                  required: true,
                  message: t({
                    id: 'login.form.validation.password',
                    message: 'Gelieve je wachtwoord in te geven',
                  }),
                },
              ]}
            >
              <Input.Password disabled={loading} size="large" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ margin: '0 auto', display: 'block' }}
              >
                <Trans id="login.action.login">Inloggen</Trans>
              </Button>
            </Form.Item>
          </Form>
          <p
            style={{
              marginTop: 16,
              marginBottom: 0,
              textAlign: 'center',
            }}
          >
            <Link to={'/reset-password'}>
              <Trans id="login.forgot_password">Wachtwoord vergeten?</Trans>
            </Link>
          </p>
        </>
      )}
      {method === AuthMethod.EMAIL_CODE && (
        <>
          <center>
            <h1>
              <Trans id="login.title.email_code">
                Inloggen via éénmalige code
              </Trans>
            </h1>
            <Form hidden={codeSent} form={otpForm} validateTrigger="submit">
              <Space direction="vertical" style={{ width: '100%' }}>
                <p>
                  <Trans id="login.subtitle.email_code">
                    Vul je e-mailadres in om verder te gaan.
                  </Trans>
                </p>
                <Form.Item
                  labelAlign="left"
                  name="email"
                  normalize={(value) => value.trim()}
                  rules={[
                    {
                      type: 'email',
                      message: t({
                        id: 'login.form.validation.required.email',
                        message: 'Voer een geldig e-mailadres in',
                      }),
                    },
                    {
                      required: email && !isAdminLogin ? false : true,
                      message: t({
                        id: 'login.form.validation.email',
                        message: 'Gelieve je e-mailadres in te geven',
                      }),
                    },
                  ]}
                >
                  <Input
                    disabled={loading}
                    size="large"
                    placeholder={t({
                      id: 'login.form.label.email',
                      message: 'E-mailadres',
                    })}
                  />
                </Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  onClick={() =>
                    requestMagicCode({ variables: { email: otpEmail } })
                      .then((data) =>
                        data.data?.requestMagicCodeEmail
                          ? setCodeSent(true)
                          : setCodeSent(false)
                      )
                      .catch(errorNotifierFn)
                  }
                >
                  <Trans id="login.form.action.send_email_code">
                    Verstuur code
                  </Trans>
                </Button>
              </Space>
            </Form>
            {codeSent && (
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <p>
                  We hebben een code verzonden naar &quot;{otpEmail}&quot;. Vul
                  de code hieronder in om in te loggen.
                </p>
                <OTPInput
                  textAlign="center"
                  maxLength={6}
                  onComplete={(code: string) => {
                    verifyMagicCode(
                      otpEmail || '',
                      code,
                      () => ({}),
                      () => {
                        notification.error({
                          message: t({
                            id: 'login.form.validation.otp_code',
                            message:
                              'Deze code is verlopen of onjuist. Probeer opnieuw.',
                          }),
                        })
                      }
                    )
                  }}
                  render={({ slots }) => (
                    <Space>
                      <Space
                        style={{ overflow: 'visible', verticalAlign: 'middle' }}
                      >
                        {slots.slice(0, 3).map((slot, idx) => (
                          <Slot key={idx} {...slot} />
                        ))}
                      </Space>
                      <MinusOutlined />
                      <Space
                        style={{ overflow: 'visible', verticalAlign: 'middle' }}
                      >
                        {slots.slice(3).map((slot, idx) => (
                          <Slot key={idx} {...slot} />
                        ))}
                      </Space>
                    </Space>
                  )}
                ></OTPInput>
                <Button type="text" onClick={() => setCodeSent(false)}>
                  Ander e-mailadres?
                </Button>
              </Space>
            )}
          </center>
        </>
      )}
    </AlignVertically>
  )
}

const SlotContainer = styled.div<{ isActive: boolean }>`
  background-color: #fff;
  position: relative;
  width: 2.5rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-style: solid;
  border-width: 1px 1px 1px 0; // top, right, bottom, left
  border-color: var(--border-color); // Assume --border-color is defined in CSS
  border-radius: 0;
  outline: 0 solid var(--ant-primary-color);

  // First child specific styles
  &:first-of-type {
    border-left-width: 1px;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  // Last child specific styles
  &:last-of-type {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  // Active state styles
  ${(props) =>
    props.isActive &&
    css`
      outline: 4px solid var(--ant-primary-color) !important;
    `}
`

const Slot: React.FC<SlotProps> = ({
  isActive,
  char,
  hasFakeCaret,
}: SlotProps) => {
  return (
    <SlotContainer isActive={isActive}>
      {char !== null && <div>{char}</div>}
      {hasFakeCaret && <FakeCaret />}
    </SlotContainer>
  )
}

const blink = keyframes`
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

// Styled component for the outer container
const CaretContainer = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${blink} 1s step-start infinite;
`

// Styled component for the caret itself
const Caret = styled.div`
  width: 1px;
  height: 2rem; // converted 8 to rem for better scaling
  background-color: var(--text-color);
`

function FakeCaret() {
  return (
    <CaretContainer>
      <Caret />
    </CaretContainer>
  )
}
