import styled from 'styled-components'

export const TextLogo = styled.div`
  color: #fff;
  padding-right: 1rem;
  font-weight: 700;
  font-size: 1.25rem;
  cursor: pointer;
  user-select: none;
`
