import {
  UserOutlined,
  CalendarOutlined,
  default as Icon,
} from '@ant-design/icons'
import { Space } from 'antd'
import Color from 'color'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { CoursesQuery } from 'apps/lms-front/src/generated/graphql'

import { useBranch } from '../../../auth/hooks/use-branch'
import { LoadScreen } from '../../../core/components/LoadScreen'

import {
  SoftwareCourseCardMeta,
  SoftwareCourseCardTitle,
  SoftwareCourseCardWrapper,
} from './SoftwareCourseCard.style'

const DiscoverSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7499 15.75L12.5249 12.525"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const HandshakeSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 12.75L9.75 14.25C9.89774 14.3977 10.0731 14.5149 10.2662 14.5949C10.4592 14.6748 10.6661 14.716 10.875 14.716C11.0839 14.716 11.2908 14.6748 11.4838 14.5949C11.6769 14.5149 11.8523 14.3977 12 14.25C12.1477 14.1023 12.2649 13.9269 12.3449 13.7338C12.4248 13.5408 12.466 13.3339 12.466 13.125C12.466 12.9161 12.4248 12.7092 12.3449 12.5162C12.2649 12.3231 12.1477 12.1477 12 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4999 10.5L12.3749 12.375C12.6733 12.6734 13.078 12.841 13.4999 12.841C13.9219 12.841 14.3266 12.6734 14.6249 12.375C14.9233 12.0766 15.0909 11.672 15.0909 11.25C15.0909 10.8281 14.9233 10.4234 14.6249 10.125L11.7149 7.21501C11.293 6.79366 10.7212 6.55699 10.1249 6.55699C9.52867 6.55699 8.9568 6.79366 8.53493 7.21501L7.87493 7.87501C7.57656 8.17338 7.17188 8.341 6.74993 8.341C6.32797 8.341 5.92329 8.17338 5.62493 7.87501C5.32656 7.57664 5.15894 7.17197 5.15894 6.75001C5.15894 6.32805 5.32656 5.92338 5.62493 5.62501L7.73243 3.51751C8.41661 2.83512 9.30885 2.40043 10.2679 2.28224C11.227 2.16406 12.198 2.36913 13.0274 2.86501L13.3799 3.07501C13.6993 3.26775 14.079 3.3346 14.4449 3.26251L15.7499 3.00001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 2.25L16.5 10.5H15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 2.25L1.5 10.5L6.375 15.375C6.67337 15.6734 7.07804 15.841 7.5 15.841C7.92196 15.841 8.32663 15.6734 8.625 15.375C8.92337 15.0766 9.09099 14.672 9.09099 14.25C9.09099 13.828 8.92337 13.4234 8.625 13.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 3H8.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const UpdatesSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.11296 2.2571 5.30173 2.99342 3.945 4.305L2.25 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 2.25V6H6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 9C2.25 10.7902 2.96116 12.5071 4.22703 13.773C5.4929 15.0388 7.20979 15.75 9 15.75C10.887 15.7429 12.6983 15.0066 14.055 13.695L15.75 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12H15.75V15.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MasterClassSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 10.5C11.4853 10.5 13.5 8.48528 13.5 6C13.5 3.51472 11.4853 1.5 9 1.5C6.51472 1.5 4.5 3.51472 4.5 6C4.5 8.48528 6.51472 10.5 9 10.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6078 9.66748L12.75 16.5L9 14.25L5.25 16.5L6.39225 9.66748"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const SoftwareCourseCardTitleSwitch = (props: { name: string }) => {
  switch (props.name.toLowerCase()) {
    case 'ontdek': {
      return (
        <Space size="small" align="center">
          <Icon component={DiscoverSvg} />
          Ontdek
        </Space>
      )
    }
    case 'onboarding': {
      return (
        <Space size="small" align="center">
          <Icon component={HandshakeSvg} />
          Onboarding
        </Space>
      )
    }
    case 'updates': {
      return (
        <Space size="small" align="center">
          <Icon component={UpdatesSvg} />
          Updates
        </Space>
      )
    }
    case 'masterclass': {
      return (
        <Space size="small" align="center">
          <Icon component={MasterClassSvg} />
          Masterclass
        </Space>
      )
    }
    default: {
      return <span>{props.name}</span>
    }
  }
}

export const SoftwareCourseCard = ({
  course,
}: {
  course: CoursesQuery['fetchCourses']['results'][0]
}) => {
  const branch = useBranch()
  const navigate = useNavigate()

  if (!branch) return <LoadScreen />

  return (
    <SoftwareCourseCardWrapper
      left={branch.theme.headerColor}
      right={
        branch.disableLandingGradient
          ? branch.theme.headerColor
          : Color(branch.theme.headerColor)
              .mix(Color(branch.theme.primaryColor), 0.5)
              .hex() || branch.theme.headerColor
      }
      onClick={() => {
        sessionStorage.setItem('aa_software_scrolly', window.scrollY.toString())
        navigate('/courses/' + course.slug)
      }}
    >
      <Space direction="vertical">
        <Space wrap size={['middle', 0]}>
          <SoftwareCourseCardMeta>
            <UserOutlined />
            {course.lecturer}
          </SoftwareCourseCardMeta>
          <SoftwareCourseCardMeta>
            <CalendarOutlined />
            {dayjs(course.published).format('DD/MM/YYYY')}
          </SoftwareCourseCardMeta>
        </Space>
        <SoftwareCourseCardTitle>
          <SoftwareCourseCardTitleSwitch name={course.translation.name} />
        </SoftwareCourseCardTitle>
      </Space>
    </SoftwareCourseCardWrapper>
  )
}
