import { useQuery } from '@apollo/client'
import { AnyMongoAbility } from '@casl/ability'
import { useContext, useEffect, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'
import {
  BranchUsersKeyValuesQuery,
  HierarchyQuery,
  HierarchySection,
  TreeBranchesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../auth/components/Can'

import HIERARCHY_QUERY from './../queries/hierarchy.graphql'
import TREE_BRANCHES_QUERY from './../queries/tree-branches.graphql'

export type TreeSelectNodeProps = {
  id: string
  branch_id?: string
  pId?: string
  value: string
  title: string
  label: string
  level: number
  selectable?: boolean
  disabled?: boolean
  hidden?: boolean
}

export type TreeItem = {
  id: string
  pId: string
  value: string
  title: string
  label: string
  branch_id: string
  level: number
  disabled: boolean
  key: string
  children: TreeItem[]
  meta?: any
}

export const useHierarchyTree = ({
  filterByPermission,
  noBranches,
  all = false,
}: {
  filterByPermission?: {
    action: PermissionAction
    object: PermissionObjectType
  }
  noBranches?: boolean
  all?: boolean
} = {}) => {
  const ability = useContext(AbilityContext)
  const [treeData, setTreeData] = useState<TreeSelectNodeProps[]>([])

  const { data: branches, loading: branchesLoading } =
    useQuery<TreeBranchesQuery>(TREE_BRANCHES_QUERY, {
      fetchPolicy: 'cache-and-network',
      skip: !ability.can(PermissionAction.READ, PermissionObjectType.BRANCH),
    })

  const {
    data: hierarchyData,
    loading: hierarchyLoading,
    refetch,
  } = useQuery<HierarchyQuery>(HIERARCHY_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      all,
    },
  })

  useEffect(() => {
    const branchesTreeItems =
      branches?.fetchBranches.map((b) => ({
        id: b._id,
        value: b._id,
        title: b.name,
        label: b.name,
        branch_id: b._id,
        level: 1,
      })) || []

    const hierarchySectionTreeItems = hierarchyData?.fetchHierarchy
      ? prepSectionsForTreeSelect(
          hierarchyData?.fetchHierarchy,
          ability,
          filterByPermission
        )
      : []

    setTreeData(
      [
        ...branchesTreeItems.filter(() => !noBranches),
        ...hierarchySectionTreeItems.filter((h) =>
          noBranches ? true : h.level > 1
        ),
      ].sort((a, b) => a.title.localeCompare(b.title))
    )
  }, [branches, hierarchyData, ability])

  return {
    data: treeData,
    loading: hierarchyLoading || branchesLoading,
    refetch,
  }
}

export const prepSectionsForTreeSelect = (
  data: Pick<HierarchySection, '_id' | 'name' | 'path' | 'meta'>[],
  ability: AnyMongoAbility,
  filterByPermission?: {
    action: PermissionAction
    object: PermissionObjectType
  }
) => {
  return data.map((h) => {
    const parts = h.path.split(',').filter(Boolean)
    return {
      id: parts.at(-1),
      pId: parts.at(-2),
      value: parts.at(-1),
      title: h.name,
      label: h.name,
      branch_id: parts[0],
      level: parts.length,
      disabled:
        filterByPermission &&
        ability.cannot(
          filterByPermission.action,
          subject(filterByPermission.object, h)
        ),
      meta: h.meta,
    } as TreeSelectNodeProps
  })
}

export const prepUsersForTreeSelect = (
  data: BranchUsersKeyValuesQuery['fetchBranchUsers'],
  ability: AnyMongoAbility,
  filterByPermission?: {
    action: PermissionAction
    object: PermissionObjectType
  }
) => {
  return data.results.map((user) => ({
    id: user._id,
    pId: 'users',
    value: user._id,
    title: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
    level: 1,
    disabled:
      filterByPermission &&
      ability.cannot(
        filterByPermission.action,
        subject(filterByPermission.object, user)
      ),
  }))
}

export const convertSimpleTreeDataToTree = (
  treeData: Array<TreeSelectNodeProps>,
  rootPIdValue = null
): Array<TreeItem> => {
  const map: Map<string, TreeItem> = new Map()
  treeData.forEach((item) => {
    const id = item.id
    const parentId = item.pId === rootPIdValue ? undefined : item.pId

    if (!map[id]) {
      map[id] = { children: [] }
    }
    map[id] = {
      ...item,
      key: item.id,
      title: item.title, // Assuming the title is the id, modify as needed
      children: map[id]['children'],
    }

    const treeItem = map[id]

    if (parentId) {
      if (!map[parentId]) {
        map[parentId] = { children: [] }
      }
      map[parentId].children.push(treeItem)
    }
  })

  return Object.values(map).filter((item: TreeItem) => !item.pId)
}
