/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CreateUserRoleMutation,
  DeleteUserRoleMutation,
  DuplicateUserRoleMutation,
  UserRole,
  UserRolesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort, numSort } from '../../../shared/utils/sort'
import USER_ROLES_QUERY from '../../queries/user-roles.graphql'

import CREATE_USER_ROLE_MUTATION from './../../mutations/create-user-role.graphql'
import DELETE_USER_ROLE_MUTATION from './../../mutations/delete-user-role.graphql'
import DUPLICATE_USER_ROLE_MUTATION from './../../mutations/duplicate-user-role.graphql'

interface UserRoleRow {
  key: string
  name: string
  branch: string
  level: number
  readonly: boolean
  count: number
}

export const RolesSettings = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [userRoleModalVisible, setUserRoleModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [form] = useForm()

  const navigate = useNavigate()

  const { data, loading } = useQuery<UserRolesQuery>(USER_ROLES_QUERY)

  const userRoles = useMemo(() => {
    const fuse = new Fuse(data?.fetchUserRoles || [], {
      keys: ['name'],
      findAllMatches: true,
    })
    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchUserRoles || []
    ).map<UserRoleRow>((item: UserRole) => ({
      key: item._id,
      name: item.name,
      branch: item.branch_name,
      readonly: item.readonly || false,
      level: item.level || 15,
      count: item.userCount,
    }))
  }, [data, searchTerm])

  const [createUserRole, { loading: creating }] =
    useMutation<CreateUserRoleMutation>(CREATE_USER_ROLE_MUTATION)

  const [deleteUserRole, { loading: deleting }] =
    useMutation<DeleteUserRoleMutation>(DELETE_USER_ROLE_MUTATION)

  const [duplicateUserRole] = useMutation<DuplicateUserRoleMutation>(
    DUPLICATE_USER_ROLE_MUTATION
  )

  useEffect(() => {
    if (userRoleModalVisible) form.resetFields()
  }, [userRoleModalVisible, form])

  const columns: ColumnsType<UserRoleRow> = [
    {
      title: t({
        id: 'settings.roles.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.roles.table.branch',
        message: 'Afdeling',
      }),
      dataIndex: 'branch',
      key: 'branch',
      sorter: defaultSort('branch') as CompareFn<unknown>,
      sortOrder: 'ascend',
      filters: data
        ? [
            ...data.fetchUserRoles.reduce((acc, { branch_name }) => {
              acc.add(branch_name)
              return acc
            }, new Set<string>()),
          ]
            .sort((a, b) => a.localeCompare(b))
            .map((name) => ({ text: name?.trim() || '(geen)', value: name }))
        : [],
      onFilter: (value, record) => record.branch === value,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.roles.table.count',
        message: 'Aantal gebruikers',
      }),
      dataIndex: 'count',
      key: 'count',
      sorter: numSort('count') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.roles.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      width: 125,
      render: (_: string, record: UserRoleRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.ROLE}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() =>
                  navigate('/settings/roles/edit-role/' + record.key)
                }
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.CREATE} a={PermissionObjectType.ROLE}>
            <Tooltip
              title={t({
                id: 'actions.duplicate',
                message: 'Dupliceren',
              })}
            >
              <Button
                onClick={() =>
                  duplicateUserRole({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['userRoles'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.roles.action.duplicate.success',
                          message: 'Gebruikersrol succesvol gedupliceerd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                }
                shape="circle"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.ROLE}>
            {!record.readonly && (
              <Tooltip
                title={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
              >
                <Popconfirm
                  placement={'left'}
                  title={t({
                    id: 'settings.roles.action.delete.confirmation',
                    message:
                      'Ben je zeker dat je deze gebruikersrol wil verwijderen?',
                  })}
                  open={
                    deletePopConfirmIndex === record.key &&
                    deletePopConfirmVisible
                  }
                  okType="danger"
                  okText={t({
                    id: 'actions.delete',
                    message: 'Verwijderen',
                  })}
                  cancelText={t({
                    id: 'actions.cancel',
                    message: 'Annuleren',
                  })}
                  okButtonProps={{ loading: deleting }}
                  onConfirm={() => {
                    deleteUserRole({
                      variables: {
                        id: record.key,
                      },
                      refetchQueries: ['userRoles'],
                    })
                      .then(() => {
                        notification.success({
                          message: t({
                            id: 'settings.roles.action.delete.success',
                            message: 'Gebruikersrol succesvol verwijderd',
                          }),
                        })
                      })
                      .catch(errorNotifierFn)
                      .finally(() => setDeletePopConfirmVisible(false))
                  }}
                  onCancel={() => setDeletePopConfirmVisible(false)}
                >
                  <Button
                    onClick={() => {
                      if (record.count > 0) {
                        notification.error({
                          message: t({
                            id: 'settings.roles.action.delete.error',
                            message:
                              'Een gebruikersrol kan pas verwijderd worden wanneer deze niet langer in gebruik is.',
                          }),
                        })
                      } else {
                        setDeletePopConfirmIndex(record.key)
                        setDeletePopConfirmVisible(true)
                      }
                    }}
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createUserRole({
        variables: fields,
        refetchQueries: ['userRoles'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.roles.action.create.success',
              message: 'Gebruikersrol succesvol aangemaakt',
            }),
          })
          setUserRoleModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.roles.title',
          message: 'Gebruikersrollen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.roles.search',
              message: 'Zoeken',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            I={PermissionAction.CREATE}
            a={PermissionObjectType.ROLE}
            key="2"
          >
            <Button
              onClick={() => setUserRoleModalVisible(true)}
              type="primary"
            >
              <Trans id="settings.roles.create">Nieuwe rol aanmaken</Trans>
            </Button>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.roles.table.empty',
            message: 'Geen gebruikersrollen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        dataSource={userRoles}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={t({
          id: 'settings.roles.create',
          message: 'Nieuwe rol aanmaken',
        })}
        open={userRoleModalVisible}
        onOk={handleCreation}
        onCancel={() => {
          setUserRoleModalVisible(false)
        }}
        confirmLoading={creating}
        cancelText={t({
          id: 'actions.cancel',
          message: 'Annuleren',
        })}
        okText={t({
          id: 'actions.create',
          message: 'Aanmaken',
        })}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleCreation}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.roles.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.roles.form.validation.name',
                  message: 'Gelieve een naam voor deze rol in te vullen',
                }),
              },
              {
                pattern: /^[\s\w-]*$/,
                message: t({
                  id: 'settings.roles.form.validation.name.pattern',
                  message: 'Gelieve geen speciale tekens te gebruiken',
                }),
              },
            ]}
          >
            <Input pattern="^[\s\w-]*$" />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.roles.form.label.level',
              message: 'Niveau',
            })}
            name="level"
            required
          >
            <InputNumber />
          </Form.Item>
          <Button hidden type="primary" htmlType={'submit'}>
            <Trans id="actions.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
