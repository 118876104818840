import { SendOutlined, UserOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useRoomContext } from '@livekit/components-react'
import '@livekit/components-styles'
import { Avatar, Button, Drawer, Input, List } from 'antd'
import { RemoteParticipant, RoomEvent } from 'livekit-client'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import { useStream } from '../../../../contexts/StreamContext'

interface ChatMessage {
  sender: string
  message: string
}

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f0f2f5;
  border-radius: 8px;
  overflow: hidden;
`

const MessageList = styled(List<ChatMessage>)`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: white;
`

const MessageItem = styled(List.Item as unknown as AnyStyledComponent)`
  padding: 8px 0;
`

const MessageContent = styled.div`
  background-color: #e6f7ff;
  border-radius: 12px;
  padding: 8px 12px;
  max-width: 80%;
`

const SenderName = styled.span`
  font-weight: bold;
  margin-right: 8px;
`

const InputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-top: 1px solid #e8e8e8;
`

const StyledInput = styled(Input as unknown as AnyStyledComponent)`
  flex: 1;
  margin-right: 8px;
`

export const Chat = ({
  isVisible = false,
  onUnreadCountChange,
  setVisibility,
}: {
  isVisible: boolean
  onUnreadCountChange: (count: number) => void
  setVisibility: (isVisible: boolean) => void
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const { user } = useAuth()
  const room = useRoomContext()
  const [, setUnreadCount] = useState(0)
  const { chatParticipants } = useStream()

  const chatRef = useRef<HTMLDivElement>(null)
  const [isChatFocused, setIsChatFocused] = useState(false)

  const handleDataReceived = useCallback(
    (payload: Uint8Array, participant?: RemoteParticipant) => {
      const decodedMessage = new TextDecoder().decode(payload)
      setMessages((prev) => [
        ...prev,
        { sender: participant?.identity || 'Unknown', message: decodedMessage },
      ])
      if (!isChatFocused) {
        setUnreadCount((prev) => {
          const newCount = prev + 1
          onUnreadCountChange(newCount)
          return newCount
        })
      }
    },
    [isChatFocused, onUnreadCountChange]
  )

  const renderAvatar = (sender) => {
    if (
      sender ===
        t({
          id: 'stream.chat.you',
          message: 'Jij',
        }) &&
      user?.picture?.url
    )
      return <Avatar src={user?.picture?.url} />
    if (
      chatParticipants.some((p) => `${p.firstName} ${p.lastName}` === sender)
    ) {
      const profilePicture = chatParticipants.find(
        (p) => `${p.firstName} ${p.lastName}` === sender
      )?.picture?.url
      if (profilePicture) {
        return <Avatar src={profilePicture} />
      }
    }
    return <Avatar icon={<UserOutlined />} />
  }

  useEffect(() => {
    room.on(RoomEvent.DataReceived, handleDataReceived)

    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived)
    }
  }, [room, handleDataReceived])

  const sendMessage = useCallback(() => {
    if (inputMessage.trim()) {
      room.localParticipant.publishData(
        new TextEncoder().encode(inputMessage),
        { reliable: true }
      )
      setMessages((prev) => [
        ...prev,
        {
          sender: t({
            id: 'stream.chat.you',
            message: 'Jij',
          }),
          message: inputMessage,
        },
      ])
      setInputMessage('')
    }
  }, [inputMessage, room.localParticipant])

  const handleChatFocus = useCallback(() => {
    setIsChatFocused(true)
    setUnreadCount(0)
    onUnreadCountChange(0)
  }, [onUnreadCountChange])

  const handleChatBlur = useCallback(() => {
    setIsChatFocused(false)
  }, [])

  return (
    <Drawer
      title="Chat"
      placement="right"
      closable={true}
      onClose={() => {
        setVisibility(false)
      }}
      open={isVisible}
      getContainer={false}
      style={{ zIndex: 99999 }}
      bodyStyle={{ padding: 0 }}
    >
      <ChatWrapper
        style={{ display: isVisible ? 'flex' : 'none' }}
        ref={chatRef}
      >
        <MessageList
          dataSource={messages}
          renderItem={(item) => (
            <MessageItem>
              <List.Item.Meta
                avatar={renderAvatar(item.sender)}
                title={<SenderName>{item.sender}</SenderName>}
                description={<MessageContent>{item.message}</MessageContent>}
              />
            </MessageItem>
          )}
        />
        <InputArea>
          <StyledInput
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onPressEnter={sendMessage}
            placeholder={t({
              id: 'stream.chat.input_placeholder',
              message: 'Typ een bericht...',
            })}
            onFocus={handleChatFocus}
            onBlur={handleChatBlur}
          />
          <Button onClick={sendMessage} type="primary" icon={<SendOutlined />}>
            {t({ id: 'stream.chat.send', message: 'Verzenden' })}
          </Button>
        </InputArea>
      </ChatWrapper>
    </Drawer>
  )
}
