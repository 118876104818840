import { t } from '@lingui/macro'
import { Form } from 'antd'

import { RichEditor } from '../../shared/components/rich-editor/RichEditor'

export const StepEditContentUnit = () => {
  return (
    <>
      <Form.Item
        label={t({
          id: 'course.unit.form.label.content',
          message: 'Inhoud',
        })}
        name={['custom_content_unit', 'content']}
        required={true}
      >
        <RichEditor />
      </Form.Item>
    </>
  )
}
