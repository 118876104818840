export enum ImageMimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  SVG = 'image/svg+xml',
  ICO = 'image/x-icon',
  MS_ICO = 'image/vnd.microsoft.icon',
}

export enum DocumentMimeType {
  PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EPUB = 'application/epub+zip',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  RTF = 'application/rtf',
  ZIP = 'application/zip',
}
