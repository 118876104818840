import { useQuery } from '@apollo/client'

import { LanguagesQuery } from 'apps/lms-front/src/generated/graphql'

import LANGUAGES_QUERY from '../../settings/queries/languages.graphql'

export const useLanguages = () => {
  const { data } = useQuery<LanguagesQuery>(LANGUAGES_QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    languages: data?.fetchLanguages || [],
  }
}

export const useDefaultLanguage = () => {
  const { data } = useQuery<LanguagesQuery>(LANGUAGES_QUERY, {
    fetchPolicy: 'cache-first',
  })

  const defaultLanguage = data?.fetchLanguages.find((lang) => lang.default)
  return (
    defaultLanguage || {
      code: 'en',
      name: 'English',
      default: true,
      active: true,
    }
  )
}
