import {
  SafetyCertificateOutlined,
  DownOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Dropdown, Menu, Modal, Space } from 'antd'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  CourseBySlugQuery,
  ResetUserCourseActivityMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { StartButton } from '../../pages/course-viewer/CourseViewer.style'

import RESET_COURSE_ACTIVITY_MUTATION from './../../mutations/reset-user-course-activity.graphql'

interface Props {
  course: CourseBySlugQuery['fetchCourseBySlug']
  parent: string
}

export const CourseViewerButton = ({
  course: {
    firstUnit,
    _id: course_id,
    my_activity,
    certificationType: courseCertificationType,
  },
  parent,
}: Props) => {
  const branch = useBranch()
  const navigate = useNavigate()

  const [resetModalOpen, setResetModalOpen] = useState<boolean>(false)

  const [resetCourseActivity, { loading: resetting }] =
    useMutation<ResetUserCourseActivityMutation>(RESET_COURSE_ACTIVITY_MUTATION)

  if (!firstUnit) return null

  const certificationType = my_activity?.certification_type

  if (my_activity?.started)
    return courseCertificationType?.length &&
      branch?.certificationSettings.allowChoice ? (
      <DropdownButton
        icon={<DownOutlined />}
        type="primary"
        menu={{
          items: [
            {
              onClick: () => setResetModalOpen(true),
              key: 'reset',
              label: t({
                id: 'course.action.reset',
                message: 'Opleiding resetten',
              }),
            },
          ],
        }}
      >
        <Link className="btn-link" to={`${parent}/unit/${firstUnit || ''}`}>
          {my_activity?.completed
            ? t({
                id: 'course.action.view',
                message: 'Bekijk deze opleiding',
              })
            : t({
                id: 'course.action.resume',
                message: 'Hervat deze opleiding',
              })}
          <span> {certificationType && `(${certificationType.name})`}</span>
        </Link>
        <Modal
          forceRender
          title={t({
            id: 'course.reset.title',
            message: 'Ben je zeker dat je deze opleiding wil resetten?',
          })}
          open={resetModalOpen}
          onCancel={() => setResetModalOpen(false)}
          onOk={() => {
            resetCourseActivity({
              variables: {
                id: course_id,
              },
            }).then(() => {
              setResetModalOpen(false)
            })
          }}
          okText={t({
            id: 'course.reset.confirm',
            message: 'Doorgaan met resetten',
          })}
          okButtonProps={{ loading: resetting }}
          cancelText={t({
            id: 'action.cancel',
            message: 'Annuleren',
          })}
        >
          <p>
            <Trans id="course.reset.description">
              Hiermee verlies je al je vooruitgang en zal je de opleiding van
              nul af aan moeten herbeginnen.
            </Trans>
          </p>
        </Modal>
      </DropdownButton>
    ) : (
      <StartButton type="primary">
        <Link className="btn-link" to={`${parent}/unit/${firstUnit || ''}`}>
          {my_activity?.completed
            ? t({
                id: 'course.action.view',
                message: 'Bekijk deze opleiding',
              })
            : t({
                id: 'course.action.resume',
                message: 'Hervat deze opleiding',
              })}
        </Link>
      </StartButton>
    )

  if (
    courseCertificationType?.length &&
    branch?.certificationSettings.allowChoice
  ) {
    const menu = (
      <Menu
        onClick={(info) => {
          if (info.key === 'default')
            navigate(`${parent}/unit/${firstUnit || ''}`)
          else navigate(`${parent}/unit/${firstUnit || ''}/${info.key}`)
        }}
        items={[
          {
            key: 'default',
            icon: <FileOutlined />,
            label: (
              <Space>
                <Trans id="course.start.not_certified">Standaard</Trans>
              </Space>
            ),
          },
          ...courseCertificationType.map((type) => ({
            key: type._id,
            icon: <SafetyCertificateOutlined />,
            label: type.name,
            value: type._id,
          })),
        ]}
      />
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <StartButton type="primary">
          <Trans id="course.action.start">Start deze opleiding</Trans>
          <DownOutlined style={{ marginLeft: 4 }} />
        </StartButton>
      </Dropdown>
    )
  } else if (
    courseCertificationType?.length &&
    courseCertificationType?.length > 1 &&
    !branch?.certificationSettings.allowChoice
  ) {
    const menu = (
      <Menu
        onClick={(info) => {
          if (info.key === 'default')
            navigate(`${parent}/unit/${firstUnit || ''}`)
          else navigate(`${parent}/unit/${firstUnit || ''}/${info.key}`)
        }}
        items={courseCertificationType.map((type) => ({
          key: type._id,
          icon: <SafetyCertificateOutlined />,
          label: type.name,
          value: type._id,
        }))}
      />
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        <StartButton type="primary">
          <Trans id="course.action.start">Start deze opleiding</Trans>
          <DownOutlined style={{ marginLeft: 4 }} />
        </StartButton>
      </Dropdown>
    )
  } else {
    return (
      <StartButton type="primary">
        <Link
          className="btn-link"
          to={`${parent}/unit/${firstUnit || ''}/${
            courseCertificationType && courseCertificationType.length === 1
              ? `${courseCertificationType[0]?._id || ''}`
              : ''
          }`}
        >
          <Trans id="course.action.start">Start deze opleiding</Trans>
        </Link>
      </StartButton>
    )
  }
}
