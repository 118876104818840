import { TreeSelect as AntTreeSelect, TreeSelectProps } from 'antd'

export const TreeSelect = (props: TreeSelectProps & { hidden?: boolean }) => {
  if (props.hidden) return null
  return (
    <AntTreeSelect
      {...props}
      disabled={props.disabled || props.loading}
      value={props.loading ? null : props.value}
    />
  )
}
