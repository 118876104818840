import { t } from '@lingui/macro'
import { Tag, message } from 'antd'
import { AxiosRequestConfig } from 'axios'

import { exporter } from '../../../reports/helpers/export'

type ExportButtonProps = {
  url: string
  params?: AxiosRequestConfig<unknown>['params']
  type: string
  name: string
  dates?: string[]
  total?: unknown[]
}

export const ExportButton = ({
  url,
  params,
  type = 'json',
  name,
  dates = [],
  total = [],
}: ExportButtonProps) => {
  return (
    <Tag
      onClick={() => {
        const hideExportLoader =
          type === 'zip'
            ? message.loading(
                t({
                  id: 'export.preparing.large',
                  message:
                    'Download aan het voorbereiden... Dit kan enkele minuten duren. Sluit de pagina niet.',
                }),
                0
              )
            : message.loading(
                t({
                  id: 'export.preparing',
                }),
                0
              )

        exporter({
          url,
          params,
          type,
          name,
          dates,
          total,
        })
          .then(() => {
            message.success(t({ id: 'export.ready' }), 1)
          })
          .finally(hideExportLoader)
      }}
      style={{ cursor: 'pointer' }}
    >
      {type.toUpperCase()}
    </Tag>
  )
}
