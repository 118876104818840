import styled from 'styled-components'

export const H1 = styled.h1`
  text-align: center;
  font-size: 1.875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`

export const AuthForm = styled.div`
  margin: 0 auto;
  background-color: #fff;

  padding: 1rem;

  @media screen and (min-width: 992px) {
    padding: 4.5rem 9rem;
  }
`
