import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Plural, Trans, t } from '@lingui/macro'
import { Button, Col, Row, Space, notification } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useWindowSize } from 'usehooks-ts'

import {
  CancelVodEventMutation,
  VodEventQuery,
  StartVodEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Header } from '../../../units/components/header'
import CANCEL_EVENT_MUTATION from '../../mutations/cancel-vod-event.graphql'
import START_EVENT_MUTATION from '../../mutations/start-vod-event.graphql'
import EVENT_QUERY from '../../queries/vod-event.graphql'

export const BigScreen = styled.div`
  height: calc(100vh - 4.5rem);
  padding: 2vw 10vw;
`

export const BigScreenTitle = styled.div`
  font-size: 2.5vw;
  font-weight: bold;
`

export const BigScreenSubTitle = styled.div`
  font-size: 1.8vw;
  margin-bottom: 2vw;
`

export const BigScreenUserCount = styled.div`
  font-size: 1.5vw;
  margin-top: 1vw;
  text-align: center;
`

export const EventHostViewer: (
  props: PageProps
) => React.ReactElement | undefined = () => {
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const { event_id: id } = useParams()

  const { data: event, loading } = useQuery<VodEventQuery>(EVENT_QUERY, {
    variables: {
      id,
    },
    pollInterval: 2000,
  })

  const [startEvent, { loading: starting }] =
    useMutation<StartVodEventMutation>(START_EVENT_MUTATION, {
      variables: {
        id,
      },
      onCompleted: () => {
        navigate(`/vod-events/${id}/unit/${event?.fetchVODEventById.firstUnit}`)
      },
    })

  const [cancelEvent, { loading: canceling }] =
    useMutation<CancelVodEventMutation>(CANCEL_EVENT_MUTATION, {
      variables: {
        id,
      },
      onCompleted: () => {
        navigate(`/courses/${event?.fetchVODEventById.course?._id || ''}`)
        notification.info({
          message: t({
            id: 'event.cancelled',
            message: 'Groepsessie geannuleerd',
          }),
        })
      },
    })

  const joinUrl = `https://${window.location.host}/join/${id || ''}`

  if (loading) return <LoadScreen />
  if (!event) return

  return (
    <>
      <Header
        title={
          <Space size="small">
            <Button
              type="text"
              style={{ color: '#FFF' }}
              onClick={() =>
                navigate(`/courses/${event.fetchVODEventById.course?._id}`)
              }
            >
              <ArrowLeftOutlined />
            </Button>
            {`Groepsessie: ${event?.fetchVODEventById.course?.name}`}
          </Space>
        }
      />
      <BigScreen>
        <Row style={{ height: '100%' }} align={'middle'}>
          <Col span={12}>
            <Space direction="vertical">
              {event?.fetchVODEventById.course?.image && (
                <img
                  src={event?.fetchVODEventById.course?.image?.url}
                  alt=""
                  style={{
                    maxHeight: '20vw',
                    maxWidth: '50vw',
                    marginBottom: '2vw',
                  }}
                />
              )}
              <BigScreenTitle>
                {event?.fetchVODEventById.course?.name}
              </BigScreenTitle>
              <BigScreenSubTitle>
                {event?.fetchVODEventById.course?.lecturer}
              </BigScreenSubTitle>
              <Space size="large">
                {event?.fetchVODEventById.firstUnit && (
                  <Button
                    loading={starting}
                    disabled={canceling}
                    size="large"
                    type="primary"
                    onClick={() => startEvent()}
                  >
                    <Trans id="event.host.start">Start de opleiding</Trans>
                  </Button>
                )}
                <Button
                  size="large"
                  download
                  href={`${import.meta.env.NX_BACKEND_URL}/api/event/qrcode/${
                    event.fetchVODEventById._id
                  }?host=${window.location.host}`}
                >
                  <Trans id="event.host.download_qr">QR-code downloaden</Trans>
                </Button>
                <Button
                  hidden
                  disabled={starting}
                  loading={canceling}
                  onClick={() =>
                    cancelEvent({
                      variables: {
                        id: event?.fetchVODEventById._id,
                      },
                    })
                  }
                >
                  <Trans id="action.cancel">Annuleren</Trans>
                </Button>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <center>
              <BigScreenTitle>
                <Trans id="event.host.scan_qr_code">
                  Scan de QR-code met je smartphone
                </Trans>
              </BigScreenTitle>
              <BigScreenSubTitle>
                <Trans id="event.host.join_subtitle">
                  Deze stap is vereist om deel te nemen aan
                  <br />
                  de interactieve onderdelen van deze opleiding.
                </Trans>
              </BigScreenSubTitle>

              <QRCodeSVG
                value={joinUrl}
                size={(width * 0.8) / 4}
                bgColor="#f0f2f5"
                fgColor={'var(--text-color)'}
              />
              <BigScreenUserCount>
                <Plural
                  value={event?.fetchVODEventById.participants.length || 0}
                  one={'# deelnemer'}
                  other={'# deelnemers'}
                />
              </BigScreenUserCount>
            </center>
          </Col>
        </Row>
      </BigScreen>
    </>
  )
}
