import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Heading } from '@tiptap/extension-heading'
import { History } from '@tiptap/extension-history'
import { HorizontalRule } from '@tiptap/extension-horizontal-rule'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { useEditor, EditorContent, Extensions } from '@tiptap/react'
import { useMemo } from 'react'

import { RichEditorMenuBar } from '../rich-editor-menubar/RichEditorMenuBar'

export const RichEditor = ({
  value,
  onChange,
  disableTextStyles = false,
  disableLinks = false,
  disableClearMarkup = false,
  disableHorizontalRuler = false,
  disableHistory = false,
  boldHeadings = false,
  Extensions = [],
}: {
  value?: string
  onChange?: (value: string) => void
  disableTextStyles?: boolean
  disableLinks?: boolean
  disableClearMarkup?: boolean
  disableHorizontalRuler?: boolean
  disableHistory?: boolean
  boldHeadings?: boolean
  Extensions?: Extensions
}) => {
  const LinkExtension: Extensions = useMemo(() => {
    return disableLinks ? [] : [Link]
  }, [disableLinks])

  const HorizontalRuleExtension: Extensions = useMemo(() => {
    return disableHorizontalRuler ? [] : [HorizontalRule]
  }, [disableHorizontalRuler])

  const HistoryExtension: Extensions = useMemo(() => {
    return disableHorizontalRuler ? [] : [History]
  }, [disableHorizontalRuler])

  const HeadingExtension: Extensions = useMemo(() => {
    return disableTextStyles ? [] : [Heading]
  }, [disableTextStyles])

  const editor = useEditor(
    {
      extensions: [
        Document,
        Text,
        Paragraph,
        Bold,
        BulletList,
        OrderedList,
        HardBreak,
        Italic,
        ListItem,
        ...HeadingExtension,
        ...HistoryExtension,
        ...HorizontalRuleExtension,
        ...LinkExtension,
        ...Extensions,
      ],
      content: value,
      onUpdate: ({ editor }) => onChange && onChange(editor.getHTML()),
    },
    [!!value]
  )

  return (
    <div>
      <RichEditorMenuBar
        editor={editor}
        disableLinks={disableLinks}
        disableTextStyles={disableTextStyles}
        disableClearMarkup={disableClearMarkup}
        disableHorizontalRuler={disableHorizontalRuler}
        disableHistory={disableHistory}
        boldHeadings={boldHeadings}
      />
      <EditorContent
        editor={editor}
        className={'ant-input'}
        style={{ marginTop: '0.5rem', paddingTop: '0.5rem' }}
      />
    </div>
  )
}
