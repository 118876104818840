import { Col } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.div`
  min-height: calc(100vh - 72px);
`

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 130%;
  margin-bottom: 0.5rem;
`

export const SpecialCol = styled(Col as unknown as AnyStyledComponent)`
  @media screen and (min-width: 1024px) {
    max-height: calc(100vh - 72px);
    overflow: auto;
  }
`
