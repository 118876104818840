import 'ts-polyfill/lib/es2019-array'

import { Route } from './route.interface'

export const flattenRoutes = (routes: Route[]): Route[] =>
  routes
    .map((route) => [route.routes ? flattenRoutes(route.routes) : [], route])
    .flat(Number.POSITIVE_INFINITY) as Route[]

export const combinePaths = (parent: string, child: string) =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`

export const buildPaths = (navigation: Route[], parentPath = ''): Route[] =>
  navigation.map((route) => {
    const path = combinePaths(parentPath, route.path)

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    }
  })

export const setupParents = (routes: Route[], parentRoute?: Route): Route[] =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    }

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    }
  })

export const pathTo = (route: Route): Route[] => {
  if (!route.parent) {
    return [route]
  }

  return [...pathTo(route.parent), route]
}
