/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { DeleteOutlined, EditOutlined, DownOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  ExtensionsQuery,
  CreateExtensionMutation,
  DeleteExtensionMutation,
  UpdateExtensionMutation,
  ExtensionType,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort } from '../../../shared/utils/sort'

import CREATE_EXTENSION_MUTATION from './../../mutations/create-extension.graphql'
import DELETE_EXTENSION_MUTATION from './../../mutations/delete-extension.graphql'
import UPDATE_EXTENSION_MUTATION from './../../mutations/update-extension.graphql'
import EXTENSIONS_QUERY from './../../queries/extensions.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface ExtensionRow {
  _id: string
  key: string
  name: string
  created?: Date
  kind?: ExtensionType
}

export const Extensions = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [extensionModalVisible, setExtensionModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<ExtensionsQuery['fetchExtensions'][0]>()
  const [form] = useForm()
  const extensionKind = Form.useWatch('kind', form)

  const { data, loading } = useQuery<ExtensionsQuery>(EXTENSIONS_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [createExtension, { loading: creating }] =
    useMutation<CreateExtensionMutation>(CREATE_EXTENSION_MUTATION)

  const [updateExtension, { loading: updating }] =
    useMutation<UpdateExtensionMutation>(UPDATE_EXTENSION_MUTATION)

  const [deleteExtension, { loading: deleting }] =
    useMutation<DeleteExtensionMutation>(DELETE_EXTENSION_MUTATION)

  useEffect(() => {
    if (updateSubject) {
      setExtensionModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (extensionModalVisible) form.resetFields()
  }, [extensionModalVisible, form])

  const extensions = useMemo(() => {
    const fuse = new Fuse(data?.fetchExtensions || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchExtensions || []
    ).map<ExtensionRow>((item: ExtensionsQuery['fetchExtensions'][0]) => ({
      _id: item._id,
      key: item.key,
      name: item.name,
      created: item.created,
      kind: item.kind,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<ExtensionRow> = [
    {
      title: t({
        id: 'settings.extensions.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.extensions.table.id',
        message: 'ID',
      }),
      dataIndex: 'key',
      key: 'key',
      sorter: defaultSort('key') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.extensions.table.type',
        message: 'Type',
      }),
      dataIndex: 'kind',
      key: 'kind',
      sorter: defaultSort('kind') as CompareFn<unknown>,
    },
    {
      title: t({
        id: 'settings.extensions.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: ExtensionRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.EXTENSION}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchExtensions.find((extension) =>
                      new ObjectId(extension._id).equals(record._id)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.EXTENSION}>
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.extensions.delete.confirm',
                  message: 'Ben je zeker dat je deze extensie wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record._id &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteExtension({
                    variables: {
                      id: record._id,
                    },
                    refetchQueries: ['extensions'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.extensions.delete.success',
                          message: 'Extensie succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record._id)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const extensions = await form.validateFields()
      createExtension({
        variables: {
          ...extensions,
        },
        refetchQueries: ['extensions'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.extensions.create.success',
              message: 'Extensie succesvol aangemaakt',
            }),
          })
          setExtensionModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const extensions = await form.validateFields()
      updateExtension({
        variables: {
          id: updateSubject?._id,
          ...extensions,
        },
        refetchQueries: ['extensions'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.extensions.update.success',
              message: 'Extensie succesvol gewijzigd',
            }),
          })
          setExtensionModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.extensions.title',
          message: 'Extensies',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.extensions.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.EXTENSION}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setExtensionModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-extensions',
                    label: t({
                      id: 'settings.extensions.import',
                      message: 'Extensies importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="settings.extensions.action.create">
                Extensie aanmaken
              </Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.extensions.table.empty',
            message: 'Geen extensies gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={extensions}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.extensions.action.update.title',
                message: 'Extensie wijzigen',
              })
            : t({
                id: 'settings.extensions.action.create.title',
                message: 'Nieuwe extensie aanmaken',
              })
        }
        open={extensionModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setExtensionModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.extensions.form.validation.name',
                  message: 'Gelieve de naam van de extensie in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.id',
              message: 'ID',
            })}
            name="key"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.extensions.form.validation.id',
                  message: 'Gelieve een unieke identifier in te vullen',
                }),
              },
              {
                max: 24,
                message: t({
                  id: 'settings.extensions.form.validation.max_characters',
                  message: `Gelieve onder de ${24} tekens te blijven`,
                }),
              },
              {
                pattern: /^[_a-z]+$/g,
                message: t({
                  id: 'settings.extensions.form.validation.id_pattern',
                  message:
                    'De ID mag enkel kleine letters en underscores bevatten.',
                }),
              },
            ]}
          >
            <Input disabled={!!updateSubject} />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.type',
              message: 'Type',
            })}
            name="kind"
            required={true}
          >
            <Select
              options={[
                {
                  label: t({
                    id: 'settings.extensions.form.label.type.nav_item',
                    message: 'Navigatie onderdeel',
                  }),
                  value: ExtensionType.NavItem,
                },
                {
                  label: t({
                    id: 'settings.extensions.form.label.type.branch_nav_item',
                    message: 'Kantoor navigatie onderdeel',
                  }),
                  value: ExtensionType.BranchNavItem,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.auth',
              message: 'Authenticatie',
            })}
            name="authenticated"
            valuePropName="checked"
          >
            <Switch
              unCheckedChildren={t({
                id: 'settings.extensions.form.label.auth.unchecked',
                message: 'Niet toestaan',
              })}
              checkedChildren={t({
                id: 'settings.extensions.form.label.auth.checked',
                message: 'Toestaan',
              })}
            />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.label',
              message: 'Label',
            })}
            name="label"
            hidden={
              extensionKind !== ExtensionType.NavItem &&
              extensionKind !== ExtensionType.BranchNavItem
            }
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.extensions.form.validation.label',
                  message:
                    'Gelieve het label van het navigatie item in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.url',
              message: 'URL',
            })}
            name="url"
            hidden={
              extensionKind !== ExtensionType.NavItem &&
              extensionKind !== ExtensionType.BranchNavItem
            }
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.extensions.form.validation.url',
                  message: 'Gelieve de URL van het navigatie item in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.order',
              message: 'Volgorde',
            })}
            name="order"
            hidden={
              extensionKind !== ExtensionType.NavItem &&
              extensionKind !== ExtensionType.BranchNavItem
            }
          >
            <InputNumber defaultValue={99} />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.extensions.form.label.roles',
              message: 'Limiteren tot',
            })}
            name="roles"
          >
            <Select
              mode="multiple"
              allowClear
              options={data?.fetchUserRoles.map((role) => ({
                label: role.name,
                value: role._id,
              }))}
            />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="actions.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
