import {
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  StarFilled,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Space, Tooltip } from 'antd'
import {
  MouseEventHandler,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import AutosizeInput from 'react-input-autosize'
import { useNavigate } from 'react-router-dom'
import { Handle, NodeProps, Position, useNodeId, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
  subject,
} from '@lms-shared-patterns/models'

import { AbilityContext, Can } from '../../../auth/components/Can'

import { SingleConnectionHandle } from './SingleConnectionHandle'

const Node = ({
  data,
  selected,
  onLabelChange,
  onSectionChangeRequest,
  onEditSectionRequest: onNodeEdit,
  root = false,
}: NodeProps & {
  onLabelChange: (id: string, label: string) => void
  onSectionChangeRequest: (section: string) => void
  onEditSectionRequest: (section_id?: string | null) => void
  root: boolean
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const nodeId = useNodeId()
  const [value, setValue] = useState(data.label)
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  const { deleteElements } = useReactFlow()

  const handleInputClick = useCallback<MouseEventHandler<HTMLInputElement>>(
    (e) => {
      // Preventing any parent handlers
      e.stopPropagation()
      e.preventDefault()
      if (inputRef.current) {
        inputRef.current.blur()
      }
    },
    []
  )

  useEffect(() => {
    if (selected && inputRef.current) {
      inputRef.current.select()
    }
  }, [selected])

  const onNodeRemove = useCallback(() => {
    if (nodeId)
      deleteElements({
        nodes: [{ id: nodeId }],
      })
  }, [nodeId, deleteElements])

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      {data.meta?.invoiceable && (
        <StarFilled
          style={{
            position: 'absolute',
            top: '-0.55em',
            right: '-0.5em',
            color: 'var(--ant-primary-color)',
          }}
        />
      )}
      {!root && (
        <SingleConnectionHandle type="target" position={Position.Top} />
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          opacity: ability.cannot(
            PermissionAction.UPDATE,
            PermissionObjectType.HIERARCHY
          )
            ? 0
            : 1,
        }}
      />
      <Space size={0} direction="vertical">
        <Input
          ref={inputRef}
          onContextMenu={handleInputClick}
          className={
            selected
              ? 'nodrag'
              : ability.cannot(
                  PermissionAction.UPDATE,
                  PermissionObjectType.HIERARCHY
                )
              ? 'pointer-events-none'
              : 'ready-for-input'
          }
          disabled={ability.cannot(
            PermissionAction.UPDATE,
            PermissionObjectType.HIERARCHY
          )}
          onChange={(e) => {
            setValue(e.target.value)
            if (nodeId) onLabelChange(nodeId, e.target.value)
          }}
          value={value}
          onKeyDown={(event) => {
            if (
              (event.key === 'Backspace' || event.key === 'Delete') &&
              value === ''
            ) {
              onNodeRemove()
            }
            if (event.key === 'Enter') {
              event.preventDefault()
              event.stopPropagation()
              if (inputRef.current) {
                inputRef.current.blur()
              }
            }
          }}
        />
        <Space style={{ fontSize: 12, color: '#AAA' }}>
          <Can
            I={PermissionAction.READ}
            a={subject(PermissionObjectType.BRANCH_USER, data)}
          >
            <Tooltip
              title={t({
                id: 'hierarchy.node.users',
                message: 'Gebruikersoverzicht',
              })}
              placement="bottom"
            >
              <UserOutlined
                onClick={() => {
                  navigate(`/branch/users?section=${nodeId}`)
                  onSectionChangeRequest('users')
                }}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.HIERARCHY}>
            {!root && data.path && (
              <Tooltip
                title={t({
                  id: 'hierarchy.node.edit',
                  message: 'Bewerken',
                })}
                placement="bottom"
              >
                <EditOutlined onClick={() => onNodeEdit(nodeId)} />
              </Tooltip>
            )}
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.HIERARCHY}>
            {!root && (
              <Tooltip
                title={t({
                  id: 'hierarchy.node.delete',
                  message: 'Verwijderen',
                })}
                placement="bottom"
              >
                <DeleteOutlined onClick={() => onNodeRemove()} />
              </Tooltip>
            )}
          </Can>
        </Space>
      </Space>
    </div>
  )
}

export const HierarchySectionNode = memo(Node)

const Input = styled(AutosizeInput)`
  font-size: 12px;
  color: #000;
  text-align: center;

  input {
    background: none;
    padding: 4px 10px;
    outline: none !important;
    border: none !important;
  }
`
