import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 0.75rem;
  margin-bottom: 0.125rem;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  & + p,
  & + ul,
  & + ol {
    margin-top: -1rem;
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  li > & {
    margin-bottom: 1.25rem;
  }
`

export const CompanyName = styled.h3`
  margin-bottom: 0;
  font-weight: 500;
`

export const Meta = styled.p`
  margin-bottom: 0;
  color: #555;
`

export const Table = styled.table`
  border-collapse: collapse;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;

  th,
  td {
    text-align: left;
    padding: 0.75rem;
    border: 1px solid #ddd;
  }

  th {
    padding-right: 1.5rem;
  }
`

export const EmailTable = styled.table`
  border-collapse: collapse;
  line-height: 1.5;

  th,
  td {
    text-align: left;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }

  th {
    padding-right: 1rem;
  }
`
