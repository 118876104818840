import {
  StarOutlined,
  BankOutlined,
  ScheduleOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Card, Col, Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import {
  JobSchedule,
  JobType,
  StudentJobSchedule,
} from '@lms-shared-patterns/models/job-type.model'
import {
  ContractType,
  JobOpportunitiesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { emptyPixel } from '../../shared/constants/empty-pixel'
import { renderJobType } from '../utils/render-job-type'

import { BookmarkButton, JobCardWrapper } from './JobCard.style'

export const JobCard = ({
  job,
  anonymized,
}: {
  job: JobOpportunitiesQuery['fetchJobOpportunities']['results'][0]
  anonymized?: boolean
}) => {
  const navigate = useNavigate()

  return (
    <Col
      sm={24}
      style={{
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <JobCardWrapper
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (e.ctrlKey || e.metaKey) return window.open(`/jobs/${job._id}`)
          return navigate(`/jobs/${job._id}`)
        }}
        style={{ cursor: 'pointer' }}
      >
        <BookmarkButton hidden>
          <StarOutlined />
        </BookmarkButton>
        <Card.Meta
          title={
            <h3 style={{ margin: 0 }}>
              <Trans id="job_overview.item.heading">
                <strong>
                  {renderJobType(
                    job.title,
                    'position' in job ? job.position || undefined : undefined
                  )}
                </strong>{' '}
                in {job.city}
              </Trans>
            </h3>
          }
          avatar={
            <img
              src={anonymized ? emptyPixel : job.branch?.logo?.url}
              alt={job.branch?.logo?.alt || ''}
              loading="lazy"
              width={job.branch?.logo?.width}
              height={job.branch?.logo?.height}
              style={{
                backgroundColor: job.branch?.theme.headerColor,
                width: 64,
                height: 64,
                objectFit: 'contain',
                padding: 8,
                borderRadius: 4,
              }}
            />
          }
          description={
            <Space size="middle" wrap>
              <Space style={{ filter: anonymized ? 'blur(5px)' : undefined }}>
                <BankOutlined />
                {job.branch?.hiringOrganisation?.name || job.branch?.name}
              </Space>
              {/* <Space>
                <TeamOutlined />
                <Plural
                  id={'job.teamsize.label'}
                  value={job.teamSize}
                  one={'# medewerker'}
                  other={'# medewerkers'}
                />
              </Space> */}
              {job.__typename === JobType.StudentJob && (
                <Space>
                  <ScheduleOutlined />
                  {job.studentJobSchedule
                    .map((a) => {
                      if (a === StudentJobSchedule.FixedDays)
                        return 'Vaste dag(en) per week'
                      if (a === StudentJobSchedule.Vacations)
                        return 'Schoolvakanties'
                      return undefined
                    })
                    .filter(Boolean)
                    .join(' / ')}
                </Space>
              )}
              {job.__typename === JobType.FirstJob && (
                <Space>
                  <ScheduleOutlined />
                  {job.firstJobSchedule
                    .map((a) => {
                      if (a === JobSchedule.FullTime) return 'Voltijds'
                      if (a === JobSchedule.PartTime) return 'Part-time'
                      return undefined
                    })
                    .filter(Boolean)
                    .join(' / ')}
                </Space>
              )}
              {job.__typename === JobType.FirstJob && (
                <Space>
                  <SolutionOutlined />
                  {job.contract
                    .map((a) => {
                      if (a === ContractType.Permanent) return 'Vast contract'
                      if (a === ContractType.Temporary)
                        return 'Tijdelijk contract'
                      return undefined
                    })
                    .filter(Boolean)
                    .join(' / ')}
                </Space>
              )}
            </Space>
          }
        />
      </JobCardWrapper>
    </Col>
  )
}
