import {
  MinusCircleOutlined,
  SearchOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Button, Input, List, Modal } from 'antd'
import { Participant } from 'livekit-client'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useStream } from '../../../../contexts/StreamContext'

const ScrollableList = styled(List)`
  max-height: 60vh;
  overflow-y: auto;
`

interface ParticipantListProps {
  participants: Participant[]
  togglePermission: (participant: Participant) => void
}

const ParticipantList: React.FC<ParticipantListProps> = ({
  participants,
  togglePermission,
}) => {
  const { isMeetingHost } = useStream()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) =>
      participant.identity.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [participants, searchTerm])

  return (
    <>
      <Button
        style={{
          backgroundColor: '#2b21ad',
          border: 'none',
          color: 'white',
          fontWeight: 'bold',
          padding: '10px 20px',
        }}
        icon={<UserOutlined />}
        onClick={() => setIsModalVisible(true)}
      >
        +{participants.length} Users
      </Button>
      <Modal
        title="Participants"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search participants"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <ScrollableList
          dataSource={filteredParticipants}
          renderItem={(item) => {
            const participant = item as Participant
            return (
              <List.Item
                actions={[
                  isMeetingHost && (
                    <Button
                      type="text"
                      onClick={() => togglePermission(participant)}
                      icon={
                        participant?.permissions?.canPublish ? (
                          <MinusCircleOutlined style={{ color: 'red' }} />
                        ) : (
                          <SoundOutlined style={{ color: 'green' }} />
                        )
                      }
                    />
                  ),
                ].filter(Boolean)}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={participant.identity}
                />
              </List.Item>
            )
          }}
        />
      </Modal>
    </>
  )
}

export default ParticipantList
