import { Trans } from '@lingui/macro'
import { Modal, Button, Progress } from 'antd'
import React, { useEffect, useState } from 'react'

interface AttentionCheckModalProps {
  isVisible: boolean
  timeLeft: number
  totalTime: number
  onConfirm: () => void
}

export const AttentionCheckModal: React.FC<AttentionCheckModalProps> = ({
  isVisible,
  timeLeft,
  totalTime,
  onConfirm,
}) => {
  const [smoothTimeLeft, setSmoothTimeLeft] = useState(timeLeft)

  useEffect(() => {
    if (!isVisible) {
      setSmoothTimeLeft(totalTime)
      return
    }

    const interval = setInterval(() => {
      setSmoothTimeLeft((prev) => Math.max(prev - 0.1, 0))
    }, 100)

    return () => clearInterval(interval)
  }, [isVisible, totalTime])

  useEffect(() => {
    setSmoothTimeLeft(timeLeft)
  }, [timeLeft])

  return (
    <Modal
      title={<Trans id="attention.check.title">Attention Check</Trans>}
      open={isVisible}
      closable={false}
      footer={null}
      maskClosable={false}
    >
      <p>
        <Trans id="attention.check.message">
          Please confirm your presence by clicking the button below.
        </Trans>
      </p>
      <Progress
        percent={(smoothTimeLeft / totalTime) * 100}
        showInfo={false}
        strokeColor={{
          '0%': '#2b21ad',
          '100%': '#2b21ad',
        }}
      />
      <Button type="primary" onClick={onConfirm} style={{ marginTop: 16 }}>
        <Trans id="attention.check.confirm">I&apos;m Here</Trans>
      </Button>
    </Modal>
  )
}
