import { Participant, Room } from 'livekit-client'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useSocket } from 'apps/lms-front/src/modules/shared/hooks/use-socket.hook'

interface StreamContextType {
  room: Room | undefined
  setRoom: React.Dispatch<React.SetStateAction<Room | undefined>>
  token: string | undefined
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>
  isRoomActive: boolean
  setIsRoomActive: React.Dispatch<React.SetStateAction<boolean>>
  isMeetingHost: boolean
  setIsMeetingHost: React.Dispatch<React.SetStateAction<boolean>>
  participantName: string
  setParticipantName: React.Dispatch<React.SetStateAction<string>>
  isRecording: boolean
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>
  emit: (event: string, data: any) => void
  disconnect: () => void
  connected: boolean
  getToken: (call_id: string) => Promise<string>
  leaveRoom: () => void
  mainParticipant: Participant | null
  setMainParticipant: React.Dispatch<React.SetStateAction<Participant | null>>
  localParticipant: Participant | null
  setLocalParticipant: React.Dispatch<React.SetStateAction<Participant | null>>
  participants: Participant[]
  setParticipants: React.Dispatch<React.SetStateAction<Participant[]>>
  pendingMainParticipantId: string | null
  setPendingMainParticipantId: React.Dispatch<
    React.SetStateAction<string | null>
  >
  setChatParticipants: React.Dispatch<React.SetStateAction<ChatParticipant[]>>
  chatParticipants: ChatParticipant[]
  canEnableCamera: boolean
  setCanEnableCamera: React.Dispatch<React.SetStateAction<boolean>>
  canEnableMicrophone: boolean
  setCanEnableMicrophone: React.Dispatch<React.SetStateAction<boolean>>
  callId: string | null
  setCallId: React.Dispatch<React.SetStateAction<string | null>>
}
export type ChatParticipant = {
  _id: string
  firstName: string
  lastName: string
  picture: {
    url: string
    width: number
    height: number
  }
}
const StreamContext = createContext<StreamContextType | undefined>(undefined)

export const StreamProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [localParticipant, setLocalParticipant] = useState<Participant | null>(
    null
  )
  const [participants, setParticipants] = useState<Participant[]>([])

  const [room, setRoom] = useState<Room | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [isRoomActive, setIsRoomActive] = useState(false)
  const [isMeetingHost, setIsMeetingHost] = useState(false)
  const [participantName, setParticipantName] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [mainParticipant, setMainParticipant] = useState<Participant | null>(
    null
  )
  const [chatParticipants, setChatParticipants] = useState<ChatParticipant[]>(
    []
  )
  const [pendingMainParticipantId, setPendingMainParticipantId] = useState<
    string | null
  >(null)

  const [callId, setCallId] = useState<string | null>(null)

  const [canEnableCamera, setCanEnableCamera] = useState(false)
  const [canEnableMicrophone, setCanEnableMicrophone] = useState(false)
  const { token: userToken, user } = useAuth()

  const findParticipantById = useCallback(
    (participantId: string) => {
      return (
        participants.find((p) => p.identity === participantId) ||
        (localParticipant && localParticipant.identity === participantId
          ? localParticipant
          : null)
      )
    },
    [participants, localParticipant]
  )

  const { connected, emit, disconnect } = useSocket('/api/stream/ws', {
    token: userToken,
    transports: ['polling', 'websocket'],
    events: {
      roomIsActivated: (data) => {
        setIsRoomActive(data.is_active)
      },
      mainParticipantChanged: ({ participantId }) => {
        if (participantId) {
          const newMainParticipant = findParticipantById(participantId)

          if (newMainParticipant) {
            setMainParticipant(newMainParticipant)
            setPendingMainParticipantId(null)
          } else {
            setPendingMainParticipantId(participantId)
          }
        }
      },
      roomClosed: () => {
        setIsRoomActive(false)
        setRoom(undefined)
        setToken(undefined)
      },
    },
  })

  useEffect(() => {
    if (user) {
      setParticipantName(`${user.firstName} ${user.lastName}`)
    }
  }, [user])

  const getToken = async (call_id: string) => {
    const response = await fetch(
      `${import.meta.env.NX_BACKEND_URL}/api/meetings/generate-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
        },
        body: JSON.stringify({
          call_id,
        }),
      }
    )

    if (!response.ok) {
      const error = await response.json()
      const errorMessage = `Failed to get token: ${
        error.errorMessage || response.statusText
      }`
      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    const data = await response.json()
    return data.token
  }

  const leaveRoom = useCallback(() => {
    if (isMeetingHost) {
      emit('closeMeetingRoom', { call_id: callId })
    }
    setRoom(undefined)
    setToken(undefined)
  }, [isMeetingHost, emit, callId])

  const value = {
    room,
    setRoom,
    token,
    setToken,
    isRoomActive,
    setIsRoomActive,
    isMeetingHost,
    setIsMeetingHost,
    participantName,
    setParticipantName,
    callId,
    setCallId,
    isRecording,
    setIsRecording,
    emit,
    disconnect,
    connected,
    getToken,
    leaveRoom,
    mainParticipant,
    setMainParticipant,
    localParticipant,
    setLocalParticipant,
    participants,
    setParticipants,
    pendingMainParticipantId,
    setPendingMainParticipantId,
    setChatParticipants,
    chatParticipants,
    canEnableCamera,
    setCanEnableCamera,
    canEnableMicrophone,
    setCanEnableMicrophone,
  }

  return (
    <StreamContext.Provider value={value}>{children}</StreamContext.Provider>
  )
}

export const useStream = () => {
  const context = useContext(StreamContext)
  if (context === undefined) {
    throw new Error('useStream must be used within a StreamProvider')
  }
  return context
}
