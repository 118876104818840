/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import {
  Drawer,
  PageHeader,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  FetchWebhookResponseQuery,
  WebhookEventType,
} from 'apps/lms-front/src/generated/graphql'

import { formatEventsString } from '../../../settings/pages/webhooks/Webhooks'
import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import FETCH_WEBHOOK_ANALYTICS from '../../queries/webhook-response.graphql'

const { Text } = Typography

interface WebhookResponse {
  _id: string
  webhook_id: string
  status: number
  response_body: string
  webhook: {
    name: string
    url: string
    event_type: WebhookEventType
  }
  branch_id: string
  request_duration: number
}

export const WebhookDelivery = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [isDrawerVisible, setDrawerVisibility] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState<WebhookResponse>()

  const [query, setSearchTerm] = useQueryParam('query', StringParam)
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)
  const [sortField, setSortField] = useState<string>()
  const [sortOrder, setSortOrder] = useState<number>()

  useEffect(() => {
    if (!start && !end) {
      setStart(dayjs().subtract(14, 'days').toISOString())
      setEnd(dayjs().toISOString())
    }
  }, [])

  const parseAndRenderJson = (data: any): JSX.Element => {
    if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data)
        return parseAndRenderJson(parsedData)
      } catch {
        return <span>{data}</span>
      }
    } else if (typeof data === 'object' && data !== null) {
      return Array.isArray(data) ? (
        <ul style={{ listStyle: 'none' }}>
          {data.map((item, index) => (
            <li key={index}>{parseAndRenderJson(item)}</li>
          ))}
        </ul>
      ) : (
        <ul style={{ listStyle: 'none' }}>
          {Object.keys(data).map((key) => (
            <li key={key}>
              <strong>{key}:</strong> {parseAndRenderJson(data[key])}
            </li>
          ))}
        </ul>
      )
    }

    return <span>{String(data)}</span>
  }

  const { data, loading } = useQuery<FetchWebhookResponseQuery>(
    FETCH_WEBHOOK_ANALYTICS,
    {
      variables: {
        query,
        page,
        limit: pageSize,
        sort_by: sortField,
        sort_order: sortOrder,
        start,
        end,
      },
    }
  )

  const columns: ColumnsType<WebhookResponse> = [
    {
      title: t({
        id: 'reports.webhook_deliveries.table.created',
        message: 'Tijdstip',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      render: (date: string) => {
        return (
          <Tooltip title={dayjs(date).format('DD/MM/YYYY HH:mm')}>
            {dayjs(date).format('DD/MM/YYYY')}
          </Tooltip>
        )
      },
      width: 70,
    },
    {
      title: t({
        id: 'reports.webhook_deliveries.table.status',
        message: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, record) => (
        <Tag
          key={record._id}
          color={record?.status && record?.status === 200 ? 'success' : 'error'}
        >
          {record.status}
        </Tag>
      ),
      width: 50,
    },
    {
      title: t({
        id: 'reports.webhook_deliveries.table.name',
        message: 'Naam',
      }),
      dataIndex: 'webhook.name',
      key: 'webhook.name',
      sorter: true,
      render: (_, record) => <div>{record.webhook.name}</div>,
      width: 120,
    },
    {
      title: t({
        id: 'reports.webhook_deliveries.table.url',
        message: 'URL',
      }),
      dataIndex: 'webhook.url',
      key: 'webhook.url',
      render: (_, record) => (
        <Tooltip
          placement="topLeft"
          title={record.webhook.url}
          overlayStyle={{ maxWidth: '500px' }}
        >
          {record.webhook.url}
        </Tooltip>
      ),
      ellipsis: true,
      width: 200,
    },
    {
      title: t({
        id: 'reports.webhook_deliveries.table.event_type',
        message: 'Type',
      }),
      dataIndex: 'webhook.event_type',
      key: 'webhook.event_type',
      sorter: true,
      render: (_, record) => (
        <div>{formatEventsString(record.webhook.event_type)}</div>
      ),
      width: 150,
    },
    {
      title: t({
        id: 'reports.webhook_deliveries.table.request_duration',
        message: 'Tijdsduur',
      }),
      dataIndex: 'request_duration',
      key: 'request_duration',
      sorter: true,
      render: (_, record) => (
        <div>
          <Text>{Number(record.request_duration / 1000).toFixed(2)} s</Text>
        </div>
      ),
      width: 100,
    },
  ]

  return (
    <>
      <PageHeader
        key="page-header"
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.webhooks.title',
          message: 'Webhooks',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={
          <Space style={{ marginBottom: 24 }}>
            <InputSearch
              key="1"
              placeholder={t({
                id: 'reports.webhooks.search',
                message: 'Zoeken...',
              })}
              onSearch={(value) => {
                setSearchTerm(value)
                setPage(1)
              }}
              style={{ width: 200 }}
            />
            <DatePicker.RangePicker
              key="2"
              ranges={{
                [t({
                  id: 'datepicker.preset.last_7_days',
                  message: 'Laatste 7 dagen',
                })]: [dayjs().add(-7, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.last_30_days',
                  message: 'Laatste 30 dagen',
                })]: [dayjs().add(-30, 'd'), dayjs()],
                [t({
                  id: 'datepicker.preset.this_week',
                  message: 'Deze week',
                })]: [dayjs().startOf('week'), dayjs().endOf('week')],
                [t({
                  id: 'datepicker.preset.this_month',
                  message: 'Deze maand',
                })]: [dayjs().startOf('month'), dayjs().endOf('month')],
                [t({
                  id: 'datepicker.preset.this_quarter',
                  message: 'Dit kwartaal',
                })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
                [t({
                  id: 'datepicker.preset.this_year',
                  message: 'Dit jaar',
                })]: [dayjs().startOf('year'), dayjs().endOf('year')],
              }}
              allowClear={false}
              allowEmpty={[false, false]}
              defaultValue={[
                start ? dayjs(start) : dayjs().subtract(14, 'days'),
                end ? dayjs(end) : dayjs(),
              ]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day')
              }}
              onChange={(dates) => {
                if (dates) {
                  setStart(dates[0]?.toISOString())
                  setEnd(dates[1]?.toISOString())
                }
              }}
              format="DD/MM/YYYY"
            />
          </Space>
        }
      />
      <Table
        key="table"
        locale={{
          emptyText: t({
            id: 'reports.webhooks.table.empty',
            message: 'Geen webhook deliveries gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={data?.webhookResponses.results || []}
        loading={loading}
        // @ts-ignore
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              // @ts-ignore
              setSelectedEntity(record)
              setDrawerVisibility(true)
            },
          }
        }}
        onChange={(_, __, sorterInfo) => {
          if (!('length' in sorterInfo)) {
            setSortField(String(sorterInfo.field))
            setSortOrder(
              sorterInfo.order === 'ascend'
                ? 1
                : sorterInfo.order === 'descend'
                ? -1
                : undefined
            )
          }
        }}
        pagination={{
          current: page,
          onChange: (page: number) => {
            setPage(page)
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
          },
          onShowSizeChange(_, size) {
            setPage(1)
            setPageSize(size)
          },
          total: data?.webhookResponses.count,
        }}
        showSorterTooltip={false}
        rowKey={(record) => record._id}
      />
      <Drawer
        forceRender
        title={t({
          id: 'reports.webhookResponse.action.title',
          message: 'Webhook response overview',
        })}
        open={isDrawerVisible}
        afterOpenChange={(visible) => {
          if (!visible) setSelectedEntity(undefined)
        }}
        onClose={() => setDrawerVisibility(false)}
        width={640}
        contentWrapperStyle={{ maxWidth: '100%' }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <h3>Event Type:</h3>
            <p>
              {selectedEntity?.webhook.event_type &&
                formatEventsString(selectedEntity.webhook.event_type)}
            </p>
          </div>
          <div>
            <h3>Code:</h3>
            <Tag
              key="6"
              color={
                selectedEntity?.status && selectedEntity?.status === 200
                  ? 'success'
                  : 'error'
              }
            >
              {selectedEntity?.status}
            </Tag>
          </div>
        </div>
        <div>
          <h3>URL:</h3>
          <p>{selectedEntity?.webhook.url}</p>
        </div>

        <div>
          <h2>Response:</h2>
          <h3>Headers:</h3>
          <div>
            {selectedEntity?.response_body &&
              parseAndRenderJson(
                JSON.parse(selectedEntity?.response_body)?.headers
              )}
          </div>

          <h3>Data:</h3>
          <div>
            {selectedEntity?.response_body &&
              parseAndRenderJson(
                JSON.parse(JSON.parse(selectedEntity?.response_body)?.data).data
              )}
          </div>
        </div>
      </Drawer>
    </>
  )
}
