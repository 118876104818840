import { notification } from 'antd'

export const errorNotifierFn = ({ message }: Error) => {
  notification.error({
    message,
  })
}

export const validationErrorNotifierFn = (error: {
  errorFields: {
    errors: string[]
  }[]
}) =>
  notification.error({
    message: error.errorFields[0].errors[0],
  })
