export const arraysEqual = (a: unknown[], b: unknown[]) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (const [i, element] of a.entries()) {
    if (element !== b[i]) return false
  }
  return true
}
