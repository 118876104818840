import { t } from '@lingui/macro'

export interface CharacterLimitValidatorOptions {
  html: boolean
}

export const characterLimitValidator =
  (max: number, options: CharacterLimitValidatorOptions) => (_, value) => {
    let length = value?.length || 0

    if (options.html && value) {
      length = value.replaceAll(/<[^>]+>/g, '').length || 0
    }

    const remaining = max - length

    return remaining >= 0
      ? Promise.resolve()
      : Promise.reject(
          new Error(
            t({
              id: 'validation.limit.reason',
              message: `Dit veld mag maximaal ${max} karakters bevatten.`,
            })
          )
        )
  }
