import styled from 'styled-components'

export const ExternalLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -4 48 48"
    width="14"
    height="14"
    fill="currentColor"
  >
    <path d="M41.47 4.986A1.5 1.5 0 0 0 41.31 5H27.5a1.5 1.5 0 1 0 0 3h10.379l-15.44 15.44a1.5 1.5 0 1 0 2.122 2.12L40 10.122V20.5a1.5 1.5 0 1 0 3 0V6.69a1.5 1.5 0 0 0-1.53-1.704zM12.5 8C8.375 8 5 11.375 5 15.5v20c0 4.125 3.375 7.5 7.5 7.5h20c4.125 0 7.5-3.375 7.5-7.5v-10a1.5 1.5 0 1 0-3 0v10c0 2.503-1.997 4.5-4.5 4.5h-20A4.477 4.477 0 0 1 8 35.5v-20c0-2.503 1.997-4.5 4.5-4.5h10a1.5 1.5 0 1 0 0-3h-10z" />
  </svg>
)

const SliderArrowWrapper = styled.div`
  display: inline-block;
  border-radius: 100%;
  cursor: pointer;
  color: var(--ant-primary-color);
  transition:
    color 200ms ease,
    opacity 200ms ease;

  &:hover {
    color: var(--ant-primary-color-active);
  }
`

const SliderArrow = (props: {
  style?: React.CSSProperties
  onClick: () => void
}) => (
  <SliderArrowWrapper {...props}>
    <svg
      style={{ display: 'block' }}
      width="48"
      height="48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m24 16-1.41 1.41L28.17 23H16v2h12.17l-5.58 5.59L24 32l8-8-8-8Z"
        fill="currentColor"
      />
      <rect
        x=".5"
        y=".5"
        width="47"
        height="47"
        rx="23.5"
        stroke="currentColor"
      />
    </svg>
  </SliderArrowWrapper>
)

export const SliderArrowLeft = styled(SliderArrow)`
  transform: rotate(-180deg);
  margin-right: 1rem;
  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    `
  pointer-events: none;
  color: var(--ant-primary-color);
  opacity: 0.5;
  cursor: none;
`}
`
export const SliderArrowRight = styled(SliderArrow)`
  ${({ disabled }: { disabled: boolean }) =>
    disabled &&
    `
    pointer-events: none;
    color: var(--ant-primary-color);
    opacity: 0.5;
    cursor: none;
  `}
`
