/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
import { t } from '@lingui/macro'
import { Button, Input, Modal } from 'antd'
import { FC, useState } from 'react'

interface TriggerProps {
  [key: string]: string | ((e?: Event) => void)
}

interface Props {
  onSubmit: (value: string, meta?: unknown) => Promise<unknown>
  onCancel?: () => void
  trigger: (triggerProps: TriggerProps) => React.ReactNode
  defaultValue?: string
  children: React.ReactNode
  meta?: unknown
}

export const Prompt: FC<Props> = ({
  children,
  onSubmit = () => {
    return new Promise((resolve) => resolve(true))
  },
  onCancel = () => {},
  trigger = (triggerProps) => <Button type="primary" {...triggerProps} />,
  defaultValue = '',
  meta,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [value, setValue] = useState(defaultValue)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    onSubmit(value, meta).then(() => {
      setConfirmLoading(false)
      setIsModalVisible(false)
      setValue('')
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    onCancel()
  }

  return (
    <>
      {trigger({ onClick: () => showModal() })}
      <Modal
        title={children}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t({
          id: 'action.confirm',
          message: 'Bevestigen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        confirmLoading={confirmLoading}
      >
        <Input
          type={'text'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation()
              handleOk()
            }
          }}
        />
      </Modal>
    </>
  )
}
