import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd'

import {
  AssignmentStep,
  SurveyQuestionType,
} from 'apps/lms-front/src/generated/graphql'

export const StepEditSurveyUnit = ({
  form,
}: {
  step: AssignmentStep
  form: FormInstance
}) => {
  const formValues = Form.useWatch(['custom_survey_unit'], form)
  return (
    <>
      <Form.List name={['custom_survey_unit', 'questions']}>
        {(fields, { add, remove }) => (
          <Row>
            <Col span={24}>
              <Collapse ghost={fields.length === 0}>
                {fields.map(({ key, name, ...field }, i, arr) => (
                  <Collapse.Panel
                    key={key}
                    forceRender={true}
                    header={
                      <div
                        style={{ flex: 1, display: 'flex' }}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => e.stopPropagation()}
                        onKeyPress={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                        role={'menuitem'}
                        tabIndex={-1}
                      >
                        <Form.Item
                          {...field}
                          style={{
                            flex: 1,
                            marginTop: -4,
                            marginBottom: -4,
                          }}
                          wrapperCol={{ span: 24 }}
                          name={[name, 'question']}
                          rules={[
                            {
                              required: true,
                              message: t({
                                id: 'course.unit.form.validation.survey.question',
                                message: 'Gelieve een vraag in te vullen',
                              }),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t({
                              id: 'course.unit.form.placeholder.survey.question',
                              message: 'Vul hier de vraag in',
                            })}
                          />
                        </Form.Item>
                      </div>
                    }
                    style={{
                      marginBottom: i === arr.length - 1 ? 24 : 0,
                    }}
                    extra={
                      <MinusCircleOutlined
                        style={{
                          padding: 12,
                          margin: -12,
                          marginLeft: 0,
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          remove(name)
                        }}
                      />
                    }
                  >
                    <Form.Item
                      name={[name, 'answer_type']}
                      required={true}
                      style={{ paddingLeft: 48 }}
                    >
                      <Select
                        placeholder={t({
                          id: 'course.unit.form.placeholder.survey.answer_type',
                          message:
                            'Selecteer het type antwoord voor deze vraag',
                        })}
                      >
                        <Select.Option
                          value={SurveyQuestionType.MultipleChoice}
                        >
                          <Trans id="course.unit.form.survey.answer_type.multiple_choice">
                            Meerkeuzevraag
                          </Trans>
                        </Select.Option>
                        <Select.Option value={SurveyQuestionType.OpenEnded}>
                          <Trans id="course.unit.form.survey.answer_type.open_ended">
                            Open antwoord
                          </Trans>
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {formValues?.questions[name]?.answer_type ===
                      SurveyQuestionType.MultipleChoice && (
                      <Form.List name={[name, 'answers']}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <div
                                key={key}
                                style={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  marginBottom: -8,
                                  paddingLeft: 24,
                                }}
                              >
                                <MinusCircleOutlined
                                  style={{
                                    margin: -8,
                                    padding: 8,
                                    marginRight: 4,
                                  }}
                                  onClick={() => remove(name)}
                                />
                                <Form.Item
                                  {...restField}
                                  style={{ flex: 1 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'answer']}
                                  rules={[
                                    {
                                      required: true,
                                      message: t({
                                        id: 'course.unit.form.validation.survey.answer',
                                        message: 'Vul een antwoord in',
                                      }),
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                            ))}

                            <Form.Item
                              wrapperCol={{ span: 24 }}
                              style={{ paddingLeft: 48 }}
                            >
                              <Button type="dashed" onClick={() => add()} block>
                                <Space style={{ justifyContent: 'center' }}>
                                  <PlusOutlined />
                                  <Trans id="course.unit.form.action.survey.add_answer">
                                    Antwoord toevoegen
                                  </Trans>
                                </Space>
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    )}
                  </Collapse.Panel>
                ))}
              </Collapse>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="dashed" onClick={() => add()} block>
                  <Space style={{ justifyContent: 'center' }}>
                    <PlusOutlined />
                    <Trans id="course.unit.form.action.survey.add_question">
                      Vraag toevoegen
                    </Trans>
                  </Space>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.List>
    </>
  )
}
