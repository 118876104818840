import { DownOutlined, PlusOutlined, ReadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Col, Empty, PageHeader, Row, Space } from 'antd'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { PathsQuery } from 'apps/lms-front/src/generated/graphql'

import { AbilityContext, Can } from '../../auth/components/Can'
import { LoadScreen } from '../../core/components/LoadScreen'
import { DropdownButton } from '../../shared/components/dynamic-dropdown-button/DropdownButton'
import { LearningPathCard } from '../components/LearningPathCard'

import PATHS_QUERY from './../queries/paths.graphql'
import { Content } from './LearningPathOverview.style'

export const LearningPathOverview = () => {
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  const { data, loading } = useQuery<PathsQuery>(PATHS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <Content>
        <PageHeader
          title={t({
            id: 'learning_paths.title',
            message: 'Leerpaden',
          })}
          style={{ marginTop: -8, padding: 0, paddingBottom: 20 }}
          extra={
            <>
              <Can
                I={PermissionAction.READ}
                a={PermissionObjectType.BRANCH_LEARNING_PATH}
                key="1"
              >
                <Button type="default" onClick={() => navigate('/courses')}>
                  <Space>
                    <ReadOutlined />
                    <Trans id="learning_paths.courses.title">Opleidingen</Trans>
                  </Space>
                </Button>
              </Can>
              <Can
                I={PermissionAction.CREATE}
                a={PermissionObjectType.LEARNING_PATH}
                key="2"
              >
                <DropdownButton
                  icon={<DownOutlined />}
                  type="primary"
                  menu={{
                    hidden: !(
                      ability.can(
                        PermissionAction.IMPORT,
                        PermissionObjectType.LEARNING_PATH
                      ) ||
                      ability.can(
                        PermissionAction.IMPORT,
                        PermissionObjectType.BRANCH_LEARNING_PATH
                      )
                    ),
                    onClick: ({ key }) => navigate(key),
                  }}
                >
                  <Link className={'btn-link'} to={'/paths/add'}>
                    <Space>
                      <PlusOutlined />
                      <Trans id="learning_paths.action.create">
                        Leerpad aanmaken
                      </Trans>
                    </Space>
                  </Link>
                </DropdownButton>
              </Can>
            </>
          }
        />
        <Row gutter={[16, 16]} align={'stretch'}>
          {loading ? (
            <Col span={24}>
              <LoadScreen />
            </Col>
          ) : data?.fetchLearningPaths.results?.length === 0 ? (
            <Col span={24}>
              <Empty
                imageStyle={{
                  height: 200,
                }}
                description={t({
                  id: 'learning_paths.empty',
                  message: 'Geen leerpaden gevonden.',
                })}
              />
            </Col>
          ) : (
            data?.fetchLearningPaths.results?.map((path) => (
              <LearningPathCard key={path._id} path={path} />
            ))
          )}
        </Row>
      </Content>
    </>
  )
}
