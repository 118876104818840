import { useMutation, useQuery } from '@apollo/client'
import Dagre from '@dagrejs/dagre'
import { Trans, t } from '@lingui/macro'
import { Button, notification } from 'antd'
import { ObjectId } from 'bson'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  MiniMap,
  Node,
  NodeProps,
  NodeTypes,
  Panel,
  ReactFlow,
  SelectionMode,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from 'reactflow'
import 'reactflow/dist/style.css'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  HierarchyQuery,
  UpdateHierarchyMutation,
} from 'apps/lms-front/src/generated/graphql'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { HierarchySectionNode } from '../../components/hierarchy/HierarchySectionNode'
import { UpdateHierarchySectionModal } from '../../components/hierarchy/UpdateHierarchySectionModal'

import UPDATE_HIERARCHY_MUTATION from './../../mutations/update-hierarchy.graphql'
import FETCH_HIERARCHY from './../../queries/hierarchy.graphql'

type BranchHierarchyProps = {
  onNavigate: (section: string) => void
}

const graph = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

const getLayoutedElements = (nodes, edges) => {
  const settings: Dagre.GraphLabel = {
    compound: true,
    rankdir: 'TB',
    nodesep: 60,
    edgesep: 10,
    ranksep: 100,
    ranker: 'longest-path',
  }
  graph.setGraph(settings)

  edges.forEach((edge) => graph.setEdge(edge.source, edge.target))
  nodes.forEach((node) => graph.setNode(node.id, node))

  Dagre.layout(graph, settings)

  return {
    nodes: nodes.map((node) => {
      const { x, y } = graph.node(node.id)

      return { ...node, position: { x, y } }
    }),
    edges,
  }
}

export const BranchHierarchy = ({ onNavigate }: BranchHierarchyProps) => {
  const [isHierarchyChanged, setHierarchyChanged] = useState<boolean>()
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const connectingNodeId = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [initialLayout, setInitialLayout] = useState<boolean>(true)
  const connectionCreated = useRef(false)
  const { fitView, project } = useReactFlow()
  const [updateSubject, setUpdateSubject] = useState<string | null>(null)

  const branch = useBranch()
  const ability = useContext(AbilityContext)

  const reactFlowStore = useStoreApi()
  const { resetSelectedElements, addSelectedNodes } = reactFlowStore.getState()

  const hierarchyState = useMemo(
    () => ({
      nodes: nodes.map((node) => ({ label: node.data.label, value: node.id })),
      edges: edges.map((edge) => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
      })),
    }),
    [nodes, edges]
  )
  const stateJSON = JSON.stringify(hierarchyState)

  useEffect(() => {
    setHierarchyChanged(true)
  }, [stateJSON])

  const { data: hierarchyData, refetch } = useQuery<HierarchyQuery>(
    FETCH_HIERARCHY,
    {
      onCompleted: () => {
        setInitialLayout(true)
      },
      fetchPolicy: 'no-cache',
    }
  )

  const onLayout = useCallback(() => {
    const layouted = getLayoutedElements(nodes, edges)

    setNodes([...layouted.nodes])
    setEdges([...layouted.edges])

    window.requestAnimationFrame(() => {
      setTimeout(() => {
        fitView()
        setInitialLayout(false)
      }, 0)
    })
  }, [nodes, edges, fitView, setNodes, setEdges])

  /**
   * When the data is loaded, we want to convert it to the format that react-flow expects
   */
  useEffect(() => {
    if (hierarchyData?.fetchHierarchy) {
      const nodes: Node[] = []
      const edges: Edge[] = []

      if (hierarchyData.fetchHierarchy.length === 0) {
        setNodes([
          {
            id: branch?._id as string,
            type: 'input',
            data: {
              label: branch?.name || 'Root',
              value: branch?._id as string,
            },
            position: { x: 0, y: 0 },
            deletable: false,
          },
        ])
        setEdges([])
        return
      }

      hierarchyData.fetchHierarchy.forEach((section, index) => {
        nodes[index] = {
          id: section._id as string,
          type: 'section', // You might want to determine types based on your own criteria
          data: {
            label: section.name,
            value: section._id as string,
            path: section.path,
            meta: section.meta,
          },
          position: { x: index * 100, y: index * 100 }, // Replace with a proper positioning logic
          width: 100,
          height: 50,
        }
      })

      const idPathMap = hierarchyData.fetchHierarchy.reduce((acc, section) => {
        acc[section._id] = section.path
        return acc
      }, {})

      const depth = hierarchyData.fetchHierarchy.reduce((prev, cur) => {
        const path = cur.path.split(',').filter(Boolean)
        return Math.min(path.length, prev)
      }, 9999)

      hierarchyData.fetchHierarchy.forEach((section, index) => {
        const path = section.path.split(',').filter(Boolean)

        /**
         * If the section is a root node, we want to set the root property to true
         */
        if (path.length <= depth) {
          // If the section is a root node
          nodes[index].data.root = true
          nodes[index].deletable = false
          return
        }

        // If the section is not a root node
        const sourceId = path.at(-2) // The parent node's id
        if (!sourceId) return
        const targetId = section._id // The current section's id
        if (idPathMap[sourceId]) {
          // Check if the source id exists in our sections
          edges.push({
            id: new ObjectId().toString(),
            source: sourceId,
            target: targetId,
            type: 'smoothstep',
          })
        }
      })

      setNodes(nodes)
      setEdges(edges)
    }
  }, [hierarchyData, setNodes, setEdges])

  /**
   * When the nodes are loaded, we want to layout the graph
   */
  useEffect(() => {
    if (initialLayout && nodes.length > 0) {
      onLayout()
      setHierarchyChanged(false)
    }
  }, [initialLayout, nodes, edges, onLayout])

  const [updateHierarchy, { loading: updatingHierarchy }] =
    useMutation<UpdateHierarchyMutation>(UPDATE_HIERARCHY_MUTATION, {
      variables: {
        nodes: nodes.map((node) => ({
          _id: node.id,
          label: node.data.label,
        })),
        edges: edges.map((edge) => ({
          _id: edge.id,
          source: edge.source,
          target: edge.target,
        })),
      },
    })

  /**
   * When a new edge is created, we want to add it to the edges array
   */
  const onConnect = useCallback(
    (params) => {
      connectionCreated.current = true
      setEdges((currentEdges) =>
        addEdge(
          { ...params, id: new ObjectId(), type: 'smoothstep' },
          currentEdges
        )
      )
    },
    [setEdges]
  )

  /**
   * We want to detect if there are any nodes in the graph that are not connected to any other nodes
   */
  const hasSingleNodes = () => {
    const singleNodes = nodes.filter((node) => {
      return !edges.some((edge) => edge.target === node.id)
    })

    return singleNodes.length > 1
  }

  /**
   * When a new node is created, we want to select it
   */
  const NODE_CREATION_TIMEOUT = 50
  const onNodeCreated = useCallback(
    (nodeId) => {
      window.setTimeout(() => {
        resetSelectedElements()
        addSelectedNodes([nodeId])
      }, NODE_CREATION_TIMEOUT)
    },
    [resetSelectedElements, addSelectedNodes]
  )

  /**
   * When a new connection is started, we want to store the id of the node that is being connected
   */
  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId
    connectionCreated.current = false
  }, [])

  /**
   * When a new connection is ended, we want to create a new node and edge
   */
  const NODE_WIDTH = 150
  const onConnectEnd = useCallback(
    (event) => {
      if (
        ability.cannot(PermissionAction.UPDATE, PermissionObjectType.HIERARCHY)
      )
        return

      if (reactFlowWrapper.current && !connectionCreated.current) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect()
        const nodeId = new ObjectId().toString()
        const newNode: Node = {
          id: nodeId,
          // we are removing the half of the node width (75) to center the new node
          position: project({
            x: event.clientX - left - NODE_WIDTH / 2,
            y: event.clientY - top,
          }),
          data: { label: '', value: nodeId },
          type: 'section',
          width: NODE_WIDTH,
        }

        setNodes((currentNodes) => [
          ...currentNodes.map((node) => ({ ...node, selected: false })),
          newNode,
        ])
        setEdges((previousEdges) => {
          if (!connectingNodeId.current) return previousEdges

          const newEdge: Edge = {
            id: new ObjectId().toString(),
            type: 'smoothstep',
            source: connectingNodeId.current,
            target: nodeId,
          }
          return [...previousEdges, newEdge]
        })

        onNodeCreated(newNode.id)
      }
    },
    [project, setEdges, setNodes, onNodeCreated]
  )

  /**
   * When a node's label is changed, we want to update the node's label
   */
  const handleLabelChange = useCallback(
    (id, value) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                label: value,
              },
            }
          }
          return node
        })
      )
    },
    [setNodes]
  )

  /**
   * We want to wrap the BranchUnitNode component in order to pass the handleLabelChange function
   */
  const nodeTypes: NodeTypes = useMemo(() => {
    const HierarchySectionNodeWrapper = (props: NodeProps) => {
      return (
        <HierarchySectionNode
          {...props}
          root={props.data.root}
          onLabelChange={handleLabelChange}
          onSectionChangeRequest={onNavigate}
          onEditSectionRequest={(section_id) => {
            if (section_id) setUpdateSubject(section_id)
          }}
        />
      )
    }
    return {
      section: HierarchySectionNodeWrapper,
    }
  }, [handleLabelChange])

  return (
    <div
      className={`react-flow-wrapper ${
        ability.can(PermissionAction.UPDATE, PermissionObjectType.HIERARCHY)
          ? `can-update-hierarchy`
          : ``
      }`}
      ref={reactFlowWrapper}
      style={{ width: '100%', height: '100%' }}
    >
      <ReactFlow
        style={{
          opacity: initialLayout ? '0' : '1',
          transition: 'opacity 0.3s ease',
        }}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        fitView
        panOnScroll
        selectionOnDrag
        selectionMode={SelectionMode.Partial}
        panOnDrag={[1, 2]}
        nodeTypes={nodeTypes}
        elementsSelectable={ability.can(
          PermissionAction.UPDATE,
          PermissionObjectType.HIERARCHY
        )}
      >
        <Can I={PermissionAction.UPDATE} a={PermissionObjectType.HIERARCHY}>
          <Panel position="top-right">
            <Button
              type={'primary'}
              disabled={!(isHierarchyChanged && nodes.length > 0)}
              loading={updatingHierarchy}
              onClick={() => {
                if (
                  ability.can(
                    PermissionAction.UPDATE,
                    PermissionObjectType.HIERARCHY
                  )
                ) {
                  if (hasSingleNodes()) {
                    notification.error({
                      description: t({
                        id: 'error.hierarchy_validation_on_save_failed.message',
                        message:
                          'Alle secties binnen de structuur moeten verbonden zijn om de organisatiestructuur op te slaan.',
                      }),
                      message: (
                        <strong>
                          {t({
                            id: 'error.hierarchy_validation_on_save_failed.title',
                            message: 'Opslaan mislukt',
                          })}
                        </strong>
                      ),
                    })
                    return
                  }
                  updateHierarchy().then(() => {
                    setHierarchyChanged(false)
                  })
                }
              }}
            >
              <Trans id="action.save_changes">Wijzigingen opslaan</Trans>
            </Button>
          </Panel>
        </Can>

        <Controls onFitView={onLayout} />
        <MiniMap />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
      {!!updateSubject && (
        <UpdateHierarchySectionModal
          section_id={updateSubject}
          onCancel={() => setUpdateSubject(null)}
          onUpdateComplete={() => refetch().then(() => setInitialLayout(true))}
        />
      )}
    </div>
  )
}
