import { Trans } from '@lingui/macro'
import { Col, Space, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'

import { PathQuery, PathsQuery } from 'apps/lms-front/src/generated/graphql'

import {
  CardBody,
  Intro,
  LearningPathCardWrapper,
  Title,
} from './LearningPathCard.style'
import { UserPathStatus } from './UserPathStatus'

export const LearningPathCard = ({
  path,
}: {
  path: PathsQuery['fetchLearningPaths']['results'][0]
}) => {
  const navigate = useNavigate()

  return (
    <>
      <Col span={24} sm={{ span: 12 }} lg={{ span: 8 }}>
        <LearningPathCardWrapper
          role="button"
          tabIndex={0}
          onClick={() => navigate(`/paths/${path._id}`)}
          aria-label={path.title}
          onKeyDown={(e) => e.key === 'Enter' && navigate(`/paths/${path._id}`)}
        >
          <CardBody>
            <Title>
              <Space align="center">
                {path.title}
                {!path.published && (
                  <Tag style={{ verticalAlign: 'middle' }}>
                    <Trans id="path.tag.draft">Concept</Trans>
                  </Tag>
                )}
              </Space>
            </Title>
            <Intro>{path.intro}</Intro>
          </CardBody>
          <UserPathStatus path={path as PathQuery['fetchLearningPathById']} />
        </LearningPathCardWrapper>
      </Col>
    </>
  )
}
