/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Form, message, Modal, Select, Switch, Tag } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CourseSharingOptions,
  FetchCourseSharingOptionsQuery,
  UpdateCourseSharingOptionsMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { LoadSection } from '../../../core/components/LoadScreen'

import UPDATE_SHARING_OPTIONS_MUTATION from './../../mutations/update-sharing-options.graphql'
import SHARING_OPTIONS_QUERY from './../../queries/sharing-options.graphql'

import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

interface Props {
  className?: string
  id: string
  options: CourseSharingOptions
  visible?: boolean
  setVisible: (state: boolean) => void
}

export const CourseSharingModal = ({
  id,
  options,
  visible = false,
  setVisible,
}: Props) => {
  const {
    public: initialMode,
    users: initialUsers,
    branches: initialBranches,
    channels: initialChannels,
  } = options

  const { data, loading } = useQuery<FetchCourseSharingOptionsQuery>(
    SHARING_OPTIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const [update, { loading: updating }] =
    useMutation<UpdateCourseSharingOptionsMutation>(
      UPDATE_SHARING_OPTIONS_MUTATION
    )

  const [form] = useForm()
  const [mode, setMode] = useState<boolean>(
    initialMode === undefined ? true : initialMode
  )

  const users = useMemo(() => {
    return data?.fetchUsers?.results
      .map((user) => ({
        label: `${user.firstName} ${user.lastName} (${user.email})`,
        value: user._id,
      }))
      .sort((a, b) => (b.label < a.label ? 1 : -1))
  }, [data])

  const branches = useMemo(() => {
    return [
      ...(data?.fetchBranches
        .map((branch) => ({
          label: branch.name,
          value: branch._id,
        }))
        .sort((a, b) => (b.label < a.label ? 1 : -1)) || []),
    ]
  }, [data])

  const channels = useMemo(() => {
    return [
      ...(data?.fetchChannels
        .map((channel) => ({
          label: channel.name,
          value: channel._id,
        }))
        .sort((a, b) => (b.label < a.label ? 1 : -1)) || []),
    ]
  }, [data])

  return (
    <Modal
      title={t({
        id: 'modal.sharing.title',
        message: 'Opties voor delen',
      })}
      open={visible}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      okText={t({
        id: 'action.save',
        message: 'Opslaan',
      })}
      onOk={() => {
        const { branches, users, channels, mode } = form.getFieldsValue()

        update({
          variables: {
            id,
            public: mode,
            branches: mode ? [] : branches || [],
            channels: channels || [],
            users: users || [],
          },
        })
          .then(() => setVisible(false))
          .then(() =>
            message.info(
              t({
                id: 'modal.sharing.success',
                message: 'Instellingen voor delen succesvol gewijzigd.',
              })
            )
          )
          .catch(() =>
            message.error(
              t({
                id: 'modal.sharing.failure',
                message: 'Instellingen niet opgeslagen. Probeer later opnieuw.',
              })
            )
          )
      }}
      confirmLoading={updating}
      onCancel={() => setVisible(false)}
      closable={false}
      maskClosable={false}
    >
      {loading && <LoadSection />}
      <Form
        hidden={loading}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        initialValues={{
          mode: initialMode === undefined ? true : initialMode,
          users: initialUsers || [],
          branches: initialBranches || [],
          channels: initialChannels || [],
        }}
        onValuesChange={(e) => setMode(e.mode)}
      >
        <Form.Item
          label={t({
            id: 'modal.sharing.course.mode',
            message: 'Modus',
          })}
          name="mode"
          tooltip={t({
            id: 'modal.sharing.course.mode.tooltip',
            message:
              'Wanneer openbaar is de opleiding beschikbaar voor iedereen (overheen alle afdelingen)',
          })}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={
              <Trans id="modal.sharing.mode.public">Openbaar</Trans>
            }
            unCheckedChildren={
              <Trans id="modal.sharing.mode.invitation">Op uitnodiging</Trans>
            }
            defaultChecked
          />
        </Form.Item>
        <Can I={PermissionAction.READ} a={PermissionObjectType.USER}>
          <Form.Item
            label={<Trans id="modal.sharing.users">Gebruikers</Trans>}
            name="users"
          >
            <Select
              mode="multiple"
              showArrow
              tagRender={tagRender}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={users}
            />
          </Form.Item>
        </Can>
        <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH}>
          <Form.Item
            label={<Trans id="modal.sharing.branches">Afdelingen</Trans>}
            name="branches"
            hidden={mode}
          >
            <Select
              mode="multiple"
              optionFilterProp="children"
              showArrow
              tagRender={tagRender}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={branches}
            />
          </Form.Item>
        </Can>
        <Can I={PermissionAction.READ} a={PermissionObjectType.CHANNEL}>
          <Form.Item
            label={<Trans id="modal.sharing.channels">Kennisbronnen</Trans>}
            name="channels"
            hidden={mode}
            tooltip={t({
              id: 'modal.sharing.course.channels.tooltip',
              message:
                'Gebruikers moeten toegang hebben tot een kennisbron om de opleiding te kunnen bekijken',
            })}
          >
            <Select
              mode="multiple"
              optionFilterProp="children"
              showArrow
              tagRender={tagRender}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={channels}
            />
          </Form.Item>
        </Can>
      </Form>
    </Modal>
  )
}

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}
