import { t } from '@lingui/macro'

import { LoadSection } from '../../../core/components/LoadScreen'
import { useAxios } from '../../../shared/hooks/use-axios'
import { dateRenderer, percentageRenderer } from '../../helpers/renderers'
import { Table } from '../Table'
import { UserCourseActivityReport } from '../UserCourseActivityReport'

export const CourseUsersReport = ({
  courseId,
  sectionId,
}: {
  courseId: string
  sectionId: string | null | undefined
}) => {
  const [{ data }] = useAxios({
    url: `/api/activity/courses/${courseId}`,
    params: {
      section_id: sectionId ?? undefined,
    },
  })

  if (!data) return <LoadSection />

  return (
    <Table
      expandable={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        expandedRowRender: (record: any) => (
          <UserCourseActivityReport
            courseId={courseId}
            userId={record._id}
            branchId={record.branch_id}
          />
        ),
        expandRowByClick: true,
        rowExpandable: () => true,
      }}
      columns={[
        {
          title: t({
            id: 'reports.users_report.table.user',
            message: 'Gebruiker',
          }),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: t({
            id: 'reports.users_report.table.progress',
            message: 'Vooruitgang',
          }),
          dataIndex: 'progress',
          key: 'progress',
          render: percentageRenderer,
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.last_activity',
            message: 'Laatst actief op',
          }),
          dataIndex: 'last_activity',
          key: 'last_activity',
          render: dateRenderer,
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.completed',
            message: 'Voltooid op',
          }),
          dataIndex: 'completed',
          key: 'completed',
          render: dateRenderer,
          align: 'center' as const,
        },
        {
          title: t({
            id: 'reports.users_report.table.score',
            message: 'Score',
          }),
          dataIndex: 'score',
          key: 'score',
          render: percentageRenderer,
          align: 'center' as const,
        },
      ]}
      bordered
      size="small"
      dataSource={data}
      rowKey="_id"
      pagination={false}
    />
  )
}
