import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { createContext } from 'react'

import { ApolloConfig } from '../constants/apollo-config'

export const ApolloCache = new InMemoryCache()

export const ApolloCacheContext = createContext<{
  cachePersistor?: CachePersistor<NormalizedCacheObject>
  cache: InMemoryCache
}>({
  cache: ApolloCache,
})

export const ApolloCachePersistor = new CachePersistor({
  cache: ApolloCache,
  storage: new LocalStorageWrapper(window.localStorage),
})

if (import.meta.env.PROD) await ApolloCachePersistor.restore()

export const GraphQLClient = new ApolloClient({
  ...ApolloConfig,
  cache: ApolloCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})
