import { UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  notification,
  PageHeader,
  Row,
  Space,
  Upload,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PdfUnit,
  CreatePdfUnitMutation,
  CreatePdfUnitMutationVariables,
} from 'apps/lms-front/src/generated/graphql'
import { errorNotifierFn } from 'apps/lms-front/src/modules/shared/helpers/error-notifier'
import { PageProps } from 'apps/lms-front/src/modules/shared/interfaces/page.interface'
import { Content } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import CREATE_PDF_UNIT_MUTATION from './../../../mutations/create-pdf-unit.graphql'
import UPDATE_PDF_UNIT_MUTATION from './../../../mutations/update-pdf-unit.graphql'

interface Props extends PageProps {
  className?: string
  unit?: PdfUnit
}

export const CourseEditPDFUnit = ({ route, unit }: Props) => {
  const [uploading, setUploading] = useState(false)
  const navigate = useNavigate()

  const [createUnit, { loading: creating }] =
    useMutation<CreatePdfUnitMutation>(CREATE_PDF_UNIT_MUTATION)
  const [updateUnit, { loading: updating }] =
    useMutation<CreatePdfUnitMutation>(UPDATE_PDF_UNIT_MUTATION, {
      variables: {
        id: unit?._id,
      },
    })

  const { id, section } = useParams()

  const [form] = useForm()
  const [formDirty, setFormDirty] = useState<boolean>(false)

  const MAX_CHARACTERS = {
    NAME: 100,
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={route.label}
        subTitle={route.description}
        extra={[
          unit ? (
            <Button
              key="3"
              onClick={() =>
                navigate(`/courses/${unit.course_id}/edit-content`)
              }
            >
              <Trans id="course.edit_contents.back_to_overview">
                Terug naar overzicht
              </Trans>
            </Button>
          ) : (
            <Button onClick={() => navigate(-1)} key="2">
              <Trans id="action.cancel">Annuleren</Trans>
            </Button>
          ),
          <Button
            disabled={!formDirty || creating || updating || uploading}
            onClick={() => form.submit()}
            key="1"
            type="primary"
          >
            <Trans id="action.save">Opslaan</Trans>
          </Button>,
        ]}
      />
      <Content>
        <Row justify="center" style={{ flex: 1 }}>
          <Col xs={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={unit ? (unit as Store) : undefined}
              onFinish={(variables) => {
                if (variables.file.file) {
                  setUploading(true)
                  const formData = new FormData()
                  formData.append('file', variables.file.file)
                  fetch(
                    `${
                      import.meta.env.NX_BACKEND_URL
                    }/api/files/uploadPDFDocument`,
                    {
                      method: 'POST',
                      body: formData,
                      headers: {
                        Accept: '*/*',
                        Authorization: `Bearer ${localStorage.getItem(
                          'aa_lms_at'
                        )}`,
                        'x-academy-host': window.location.hostname,
                      },
                    }
                  )
                    .then((res) => res.json())
                    .then((response) => {
                      if (unit) {
                        updateUnit({
                          variables: {
                            ...variables,
                            file_id: response._id,
                          },
                        })
                          .then(() => {
                            notification.success({
                              message: t({
                                id: 'course.unit.edit.success',
                                message: 'Onderdeel succesvol opgeslagen',
                              }),
                            })
                            setFormDirty(false)
                          })
                          .catch(errorNotifierFn)
                          .finally(() => setUploading(false))
                      } else {
                        createUnit({
                          variables: {
                            name: variables.name,
                            file_id: response._id,
                            courseId: id,
                            sectionId: section,
                          } as CreatePdfUnitMutationVariables,
                        })
                          .then((result) => {
                            notification.success({
                              message: t({
                                id: 'course.unit.create.success',
                                message: 'Onderdeel succesvol opgeslagen',
                              }),
                            })
                            const id = result.data?.addPDFUnit.course_id
                            setUploading(false)
                            navigate(`/courses/${id}/edit-content`, {
                              replace: true,
                            })
                          })
                          .catch(errorNotifierFn)
                          .catch(() => setUploading(false))
                      }
                    })
                    .catch((error) => {
                      message.error(
                        t({
                          id: 'course.unit.form.upload.pdf.failed',
                          message: 'Er ging iets fout. Probeer later opnieuw.',
                        })
                      )
                      console.error(error)
                    })
                } else {
                  updateUnit({
                    variables: {
                      ...variables,
                    },
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'course.unit.edit.success',
                          message: 'Onderdeel succesvol opgeslagen',
                        }),
                      })
                      setFormDirty(false)
                    })
                    .catch(errorNotifierFn)
                }
              }}
              onFieldsChange={() => setFormDirty(true)}
              autoComplete="off"
            >
              <Form.Item
                label={t({
                  id: 'course.unit.form.label.name',
                  message: 'Naam',
                })}
                name="name"
                rules={[
                  {
                    required: !!unit,
                    message: t({
                      id: 'course.unit.form.validation.name',
                      message:
                        'Gelieve een naam voor dit onderdeel in te vullen',
                    }),
                  },
                  {
                    max: MAX_CHARACTERS.NAME,
                    message: t({
                      id: 'course.unit.form.validation.max_characters',
                      message: `Gelieve onder de ${MAX_CHARACTERS.NAME} tekens te blijven`,
                    }),
                  },
                ]}
              >
                <Input tabIndex={0} />
              </Form.Item>
              <Form.Item
                name="file"
                wrapperCol={{ sm: { offset: 8, span: 16 } }}
                rules={[
                  {
                    required: true,
                    message: t({
                      id: 'course.unit.form.validation.pdf',
                      message: 'Gelieve een geldig PDF-bestand te selecteren.',
                    }),
                  },
                ]}
                valuePropName="file"
              >
                <Upload
                  accept="application/pdf"
                  maxCount={1}
                  beforeUpload={() => {
                    return false
                  }}
                >
                  <Button>
                    <Space>
                      <UploadOutlined />
                      <Trans id="course.unit.form.upload.pdf">
                        Bestand selecteren
                      </Trans>
                    </Space>
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                <Button
                  disabled={!formDirty || updating || creating || uploading}
                  type="primary"
                  htmlType={'submit'}
                >
                  <Trans id="action.save">Opslaan</Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  )
}
