import { Button, Tag as AntTag } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const CourseImageOverlay = styled.div`
  ${({ rounded = false }: { rounded?: boolean; hover: boolean }) =>
    rounded && `border-radius: 5px;`}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  opacity: 0;
  transition: opacity 200ms ease;
  ${({ hover }: { rounded?: boolean; hover: boolean }) =>
    hover && `opacity: 0.9;`}
`

export const CourseImage = styled.img`
  ${({ rounded }: { rounded?: boolean }) => rounded && `border-radius: 5px;`}
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
`

export const CourseMeta = styled.div`
  padding: 1rem;

  .ant-tag {
    margin-top: 0.5rem;
  }
`

export const CourseLecturer = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0.3rem 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const CourseCardMeta = styled.span`
  svg {
    font-size: 0.75rem;
    vertical-align: baseline;
    margin-right: 0.5rem;
    position: relative;
  }
  display: inline-block;
  margin-right: 1rem;
  color: #909090;
`

export const CourseTitle = styled.h3`
  font-size: 1.25rem;
  line-height: 130%;
  font-weight: bold;
  margin-top: 1.125rem;
  margin-bottom: 0;
`

export const LastActive = styled.p`
  margin-top: 0.5rem;
  line-height: 135%;
  font-weight: 500;
  color: #9e9e9e;
`

export const CourseCardContents = styled.div`
  position: relative;
  flex: 1;
  min-height: 7rem;
  padding: 1.25rem 1.5rem 1.75rem;
  background-color: #fff;
  --text-color: initial;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  ${({ card }: { card: boolean }) => [
    `
    border-radius: ${card ? 5 : 0}px;
  `,
    card &&
      `  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  `,
  ]}
`

export const DraftTag = styled(AntTag as unknown as AnyStyledComponent)`
  background-color: var(--secondary-color);
  color: var(--ant-primary-color);
  border: none;
  padding: 6px 12px;
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const Tag = styled(AntTag as unknown as AnyStyledComponent)`
  position: absolute;
  top: 0;
  right: 0.75rem;
  transform: translateY(-50%);
  background-color: var(--ant-primary-color);
  color: var(--btn-text-color);
  border: none;
  padding: 6px 12px;
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const ProgressLabel = styled.p`
  color: var(--ant-primary-color);
  font-weight: 500;
  margin-top: 0.375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  text-transform: uppercase;
`

export const CloseButton = styled(Button as unknown as AnyStyledComponent)`
  font-size: 0.875rem;
  color: var(--text-color);
  margin: 0.5rem 0;
  position: absolute;
  top: 0.25rem;
  right: -1rem;
`
