import { Area } from '@ant-design/plots'
import { Trans, t } from '@lingui/macro'
import { Modal } from 'antd'
import { useMemo } from 'react'

type VideoProgressModalProps = {
  open: boolean
  onClose: () => unknown
  seekToUnwatchedPart: () => unknown
  allowChangingPlaybackRate: boolean
  viewMap: number[]
}

export const VideoProgressModal = ({
  open,
  onClose,
  seekToUnwatchedPart,
  allowChangingPlaybackRate,
  viewMap,
}: VideoProgressModalProps) => {
  const progress = useMemo(() => {
    return viewMap
      .map((item, i) => ({
        value: Math.min(item, 1),
        time: i,
      }))
      .reduce<{ time: number; value: number; count: number }[]>((prev, cur) => {
        const curTime = Math.floor(cur.time)
        prev[curTime] = prev[curTime]
          ? {
              time: curTime,
              value: prev[curTime].value + cur.value,
              count: prev[curTime].count + 1,
            }
          : {
              value: cur.value,
              time: curTime,
              count: 1,
            }
        return prev
      }, new Array<{ time: number; value: number; count: number }>())
      .map((item) => ({
        value: Math.ceil(item.value / item.count),
        time: item.time,
      }))
  }, [viewMap])

  return (
    <Modal
      open={open}
      okText={t({
        id: 'unit.viewer.video.progress_modal.go_to_unwatched_part',
        message: 'Ga naar het onbekeken deel',
      })}
      okType={'default'}
      onCancel={() => onClose()}
      onOk={() => {
        seekToUnwatchedPart()
        onClose()
      }}
      cancelText={t({
        id: 'action.close',
        message: 'Sluiten',
      })}
    >
      <h3>
        <Trans id="unit.viewer.video.progress_modal.title">
          Je hebt deze video nog niet volledig uitgekeken
        </Trans>
      </h3>
      {!allowChangingPlaybackRate && (
        <p>
          <Trans id="unit.viewer.video.progress_modal.playback_rate">
            Voor opleidingen met volgtijdelijkheid zijn we verplicht na te gaan
            of de volledige video werd bekeken, vooraleer jou als cursist te
            laten doorgaan naar het volgende opleidingsonderdeel.
          </Trans>
        </p>
      )}
      <p>
        <Trans id="unit.viewer.video.progress_modal.description">
          Hieronder zie je welk delen van de video je reeds bekeek. Klik op de
          knop <em>&apos;Ga naar het onbekeken deel&apos;</em> om dit onderdeel
          te voltooien.
        </Trans>
      </p>
      <div style={{ height: 150 }}>
        <Area
          data={progress}
          xField={'time'}
          yField={'value'}
          line={{
            size: 0,
          }}
          xAxis={{
            tickInterval: 1,
            tickMethod: 'time-cat',
            label: {
              formatter: (text) =>
                progress.length > 120
                  ? `${Math.floor(Number.parseInt(text) / 60)} min`
                  : `${text} sec`,
            },
            minTickInterval: 1,
          }}
          yAxis={{
            tickInterval: 1,
            label: null,
          }}
          tooltip={{
            title: (val) =>
              progress.length > 120
                ? `${Math.floor(Number.parseInt(val) / 60)} min`
                : `${val} sec`,
            formatter: (datum) => {
              return {
                name: t({
                  id: 'unit.viewer.video.progress_modal.tooltip.viewed',
                  message: 'Bekeken',
                }),
                value: datum.value === 1 ? 'ja' : 'nee',
              }
            },
          }}
          animation={{
            appear: false,
          }}
        />
      </div>
    </Modal>
  )
}
