import { Trans } from '@lingui/macro'
import { Modal, Button } from 'antd'
import confetti from 'canvas-confetti'
import { useEffect } from 'react'

type CompletionModalProps = {
  isModalVisible: boolean
  isGroupSession: boolean
  handleModalClose: () => void
  handleGoToCertificates: () => void
}

export const CourseCompletionModal = ({
  isModalVisible,
  isGroupSession,
  handleModalClose,
  handleGoToCertificates,
}: CompletionModalProps) => {
  const showConfetti = () => {
    confetti({
      particleCount: 400,
      spread: 150,
      origin: { y: 0.6 },
    })
  }

  useEffect(() => {
    if (isModalVisible) {
      // Wait a bit before showing confetti
      setTimeout(showConfetti, 200)
    }
  }, [isModalVisible])

  return (
    <Modal
      title={
        <Trans id="unit.viewer.course_completion.congratulations">
          Gefeliciteerd!
        </Trans>
      }
      closable={false}
      open={isModalVisible}
      centered={true}
      footer={[
        !isGroupSession && (
          <Button
            type="primary"
            key="achievements"
            onClick={handleGoToCertificates}
          >
            <Trans id="unit.viewer.course_completion.view_certificate">
              Bekijk attest
            </Trans>
          </Button>
        ),
        <Button key="close" onClick={handleModalClose}>
          <Trans id="unit.viewer.course_completion.close">Sluiten</Trans>
        </Button>,
      ]}
    >
      <p>
        <Trans id="unit.viewer.course_completion.success_message">
          Je hebt de opleiding succesvol afgerond!
        </Trans>
      </p>
    </Modal>
  )
}
