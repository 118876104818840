/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  DeleteBranchRuleMutation,
  DuplicateBranchRuleMutation,
  BranchRulesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort, numSort } from '../../../shared/utils/sort'

import DELETE_BRANCH_RULE_MUTATION from './../../mutations/delete-branch-rule.graphql'
import DUPLICATE_BRANCH_RULE_MUTATION from './../../mutations/duplicate-branch-rule.graphql'
import BRANCH_RULES_QUERY from './../../queries/branch-rules.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface RuleRow {
  key: string
  name: string
  weight: number
}

export const BranchRules = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)

  const navigate = useNavigate()

  const { data, loading } = useQuery<BranchRulesQuery>(BRANCH_RULES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const rules = useMemo(() => {
    const fuse = new Fuse(data?.fetchBranchRules || [], {
      keys: ['name'],
      findAllMatches: true,
    })
    const result = fuse.search(searchTerm)

    const rulesRows: RuleRow[] = []
    const rules =
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchBranchRules || []

    rules.forEach((rule) => {
      if (rule.__typename === 'VisibilityRule')
        rulesRows.push({
          key: rule._id,
          name: rule.name,
          weight: rule.weight,
        })
    })
    return rulesRows
  }, [data, searchTerm])

  const [deleteRule, { loading: deleting }] =
    useMutation<DeleteBranchRuleMutation>(DELETE_BRANCH_RULE_MUTATION)

  const [duplicateRule] = useMutation<DuplicateBranchRuleMutation>(
    DUPLICATE_BRANCH_RULE_MUTATION
  )

  const columns: ColumnsType<RuleRow> = [
    {
      title: t({
        id: 'settings.rules.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.roles.table.weight',
        message: 'Gewicht',
      }),
      dataIndex: 'weight',
      key: 'weight',
      sorter: numSort('weight') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.roles.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      width: 125,
      render: (_: string, record: RuleRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH_RULE}>
            <Can
              not
              I={PermissionAction.UPDATE}
              a={PermissionObjectType.BRANCH_RULE}
            >
              <Tooltip
                title={t({
                  id: 'actions.view',
                  message: 'Bekijken',
                })}
              >
                <Button
                  onClick={() => navigate('/branch/rules/rule/' + record.key)}
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </Can>
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.BRANCH_RULE}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() =>
                  navigate('/branch/rules/edit-rule/' + record.key)
                }
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.CREATE} a={PermissionObjectType.BRANCH_RULE}>
            <Tooltip
              title={t({
                id: 'actions.duplicate',
                message: 'Dupliceren',
              })}
            >
              <Button
                onClick={() =>
                  duplicateRule({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchRules', 'rules'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.rules.action.duplicate.success',
                          message: 'Regel succesvol gedupliceerd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                }
                shape="circle"
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.BRANCH_RULE}>
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.rules.action.delete.confirmation',
                  message: 'Ben je zeker dat je deze regel wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteRule({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['branchRules', 'rules'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.rules.action.delete.success',
                          message: 'Regel succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'branch.rules.heading',
          message: 'Regels',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            defaultValue={searchTerm || ''}
            placeholder={t({
              id: 'branch.rules.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="3"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.BRANCH_RULE}
          >
            <Button
              onClick={() => navigate('/branch/rules/edit-rule')}
              type="primary"
            >
              <Trans id="settings.rules.create">Nieuwe regel aanmaken</Trans>
            </Button>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.rules.table.empty',
            message: 'Geen regels gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        dataSource={rules}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
    </>
  )
}
