import { MailOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Avatar, Grid, Space } from 'antd'
import { posthog } from 'posthog-js'

import { FindBranchesByMetaQuery } from 'apps/lms-front/src/generated/graphql'

import {
  UnderlineCTA,
  VendorCTA,
  VendorCardHeader,
  VendorCardWrapper,
  VendorContact,
  VendorCoursesGrid,
  VendorLogoWrapper,
  VerticalDivider,
} from './VendorCard.style'

export const VendorCard = ({
  vendor,
  children,
}: {
  vendor: FindBranchesByMetaQuery['findBranchesByMeta'][0]
  children?: React.ReactNode
}) => {
  const breakpoint = Grid.useBreakpoint()
  return (
    <VendorCardWrapper color={vendor.theme.primaryColor}>
      <VendorCardHeader>
        <Space size="large">
          <VendorLogoWrapper>
            {vendor.logo ? (
              <img src={vendor.logo?.url} alt={vendor.name} />
            ) : (
              <h2>{vendor.name}</h2>
            )}
          </VendorLogoWrapper>
          {vendor.meta?.contact && breakpoint.lg && <VerticalDivider />}
          {vendor.meta?.contact && breakpoint.lg && (
            <VendorContact>
              {vendor.meta?.contact_avatar && (
                <Avatar size="large" src={vendor.meta?.contact_avatar} />
              )}
              <p>{vendor.meta?.contact}</p>
            </VendorContact>
          )}
        </Space>
        {!!vendor.contactLink && (
          <VendorCTA
            role="button"
            tabIndex={0}
            onClick={() => {
              posthog.capture(
                'software_academy_contact_link_clicked',
                {
                  software_branch_id: vendor._id,
                  software_branch: vendor.name,
                }
              )
              if (vendor.contactLink) window.open(vendor.contactLink, '_blank')
            }}
          >
            <Space align="start">
              <MailOutlined style={{ fontSize: 18 }} />
              {breakpoint.lg && (
                <UnderlineCTA>
                  <Trans id="courses.software.contact">
                    Contacteer {vendor.name}
                  </Trans>
                </UnderlineCTA>
              )}
            </Space>
          </VendorCTA>
        )}
      </VendorCardHeader>
      <VendorCoursesGrid>{children}</VendorCoursesGrid>
    </VendorCardWrapper>
  )
}
