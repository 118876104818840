import { useQuery } from '@apollo/client'
import '@livekit/components-styles'
import { Col, Row } from 'antd'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { Header } from '../../../units/components/header'
import { StreamProvider } from '../../contexts/StreamContext'
import MEETING_DETAILS_QUERY from '../../queries/fetch-event-by-call-id.graphql'

import { JoinStreamContent } from './JoinStreamContent'

export const JoinStream = () => {
  const navigate = useNavigate()
  const { call_id } = useParams()
  const { data, loading, refetch } = useQuery<FetchMeetingDetailsQuery>(
    MEETING_DETAILS_QUERY,
    {
      variables: {
        call_id: call_id,
      },
      onError: (error) => {
        console.error('Failed to fetch meeting details:', error)
      },
    }
  )

  if (loading) {
    return <LoadScreen />
  }

  if (!data) {
    return (
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col span={8}>
          <div
            style={{
              textAlign: 'center',
              background: '#fff',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Trans id="stream.join.invalid_link">
              Dit is geen geldige meeting link
            </Trans>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <StreamProvider>
      <Header
        title={data.fetchLiveEventByCallId.title}
        onBack={() =>
          navigate('/calendar?event=' + data.fetchLiveEventByCallId._id)
        }
      />
      <JoinStreamContent data={data} refetch={refetch} />
    </StreamProvider>
  )
}
