/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  notification,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'

import {
  BranchHiringOrganisation,
  BranchHiringOrganisationInput,
} from 'apps/lms-front/src/generated/graphql'

import { errorNotifierFn } from '../../../shared/helpers/error-notifier'

type OrganisationFormProps = {
  active?: boolean
  loading?: boolean
  data?: BranchHiringOrganisation
  onChange: (data: BranchHiringOrganisationInput) => Promise<unknown>
}

const singleColumnGridProps = {
  labelCol: { xl: { span: 5 }, md: { span: 6 } },
  wrapperCol: {
    xl: { span: 19 },
    md: { span: 18 },
  },
}

const doubleColumnLeftGridProps = {
  labelCol: { xl: { span: 10 }, md: { span: 12 } },
  wrapperCol: {
    xl: { span: 12 },
    md: { span: 10 },
    sm: { span: 14 },
  },
}

const doubleColumnRightGridProps = {
  labelCol: { xl: { span: 6 }, md: { span: 8 } },
  wrapperCol: {
    xl: { span: 14 },
    md: { span: 16 },
  },
}

export const HiringOrganisationForm = ({
  active,
  data,
  onChange: setData,
  loading,
}: OrganisationFormProps) => {
  const [collapseActiveKey, setCollapseActiveKeys] = useState<
    string | string[]
  >()
  const [infoForm] = useForm()

  useEffect(() => {
    if (active) {
      setCollapseActiveKeys(['1'])
    }
  }, [active])

  useEffect(() => {
    if (data) {
      infoForm.setFieldsValue(data)
    }
  }, [data, infoForm])

  return (
    <Collapse
      activeKey={collapseActiveKey}
      onChange={(key) => setCollapseActiveKeys(key)}
    >
      <Collapse.Panel
        header={t({
          id: 'settings.jobs.info.header',
          message: 'Gegevens organisatie wijzigen',
        })}
        key="1"
      >
        <PageHeader
          ghost={false}
          className="site-page-header"
          title={t({
            id: 'settings.jobs.info.heading',
            message: 'Gegevens van de organisatie',
          })}
          style={{ backgroundColor: '#FFF' }}
          extra={
            <Button
              onClick={() => {
                infoForm.submit()
              }}
              loading={loading}
              type="primary"
            >
              <Trans id="settings.jobs.action.save">Gegevens opslaan</Trans>
            </Button>
          }
        />
        <Form
          form={infoForm}
          onFinish={(values) =>
            setData(values)
              .then(() => setCollapseActiveKeys(undefined))
              .then(() =>
                notification.success({
                  message: t({
                    id: 'settings.jobs.info.notification.success',
                    message: 'Gegevens opgeslagen',
                  }),
                })
              )
              .catch(errorNotifierFn)
          }
          disabled={loading}
        >
          <Form.Item
            label={t({
              id: 'settings.jobs.form.label.company',
              message: 'Naam van organisatie',
            })}
            rules={[
              {
                required: true,
              },
            ]}
            {...singleColumnGridProps}
            name="name"
          >
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item
            label={t({
              id: 'settings.jobs.form.label.address',
              message: 'Adres',
            })}
            {...singleColumnGridProps}
            name="address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.zip',
                  message: 'Postcode',
                })}
                {...doubleColumnLeftGridProps}
                name="zipCode"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                label={t({
                  id: 'settings.jobs.form.label.city',
                  message: 'Gemeente',
                })}
                {...doubleColumnRightGridProps}
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={35} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t({
              id: 'settings.jobs.form.label.employee_count',
              message: 'Aantal medewerkers',
            })}
            {...singleColumnGridProps}
            name="employeeCount"
            rules={[
              {
                required: true,
              },
              {
                type: 'number',
                min: 1,
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  )
}
