import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans } from '@lingui/macro'
import { Modal } from 'antd'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { MyActiveVodEventsQuery } from 'apps/lms-front/src/generated/graphql'

import LEAVE_EVENT_MUTATION from './../mutations/leave-vod-event.graphql'
import MY_ACTIVE_EVENTS_QUERY from './../queries/my-active-vod-events.graphql'
import {
  EventBarCloseIcon,
  EventBarContent,
  EventBarImage,
  EventBarSubtitle,
  EventBarTitle,
  EventBarWrapper,
} from './ActiveEventBar.style'

export const ActiveEventBar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [stopEventModalVisible, setStopEventModalVisible] = useState(false)

  const { data: events } = useQuery<MyActiveVodEventsQuery>(
    MY_ACTIVE_EVENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )

  const event = events?.myActiveVODEvents.find((e) => e.course)

  const [leave] = useMutation(LEAVE_EVENT_MUTATION, {
    variables: {
      id: event?._id,
    },
    refetchQueries: ['myActiveVODEvents'],
  })

  if (!event) return null

  const course = event.course

  if (!course || location.pathname === '/remote') return null

  return (
    <>
      <EventBarWrapper
        onClick={() => navigate('/remote')}
        className="active-event-bar"
      >
        {course.image && (
          <EventBarImage
            src={course.image?.url}
            alt={course.name}
            width={course.image?.width}
            height={course.image?.height}
          />
        )}
        <EventBarContent>
          <EventBarTitle>
            <Trans id="event_bar.title">
              Klik om terug te keren naar groepsessie
            </Trans>
          </EventBarTitle>
          <EventBarSubtitle>{event.course?.name}</EventBarSubtitle>
          <EventBarCloseIcon
            onClick={(e) => {
              e.stopPropagation()
              setStopEventModalVisible(true)
            }}
          >
            <CloseOutlined />
          </EventBarCloseIcon>
        </EventBarContent>
      </EventBarWrapper>
      <Modal
        open={stopEventModalVisible}
        title={
          <Trans id="modal.leave_event.title">
            Ben je zeker dat je de groepsessie wil verlaten?
          </Trans>
        }
        okText={<Trans id="action.leave">Verlaten</Trans>}
        cancelText={<Trans id="action.cancel">Annuleren</Trans>}
        closable={true}
        maskClosable={true}
        onCancel={() => setStopEventModalVisible(false)}
        onOk={() => {
          leave()
          setStopEventModalVisible(false)
        }}
      >
        <Trans id="modal.leave_event.description">
          Je zal de QR-code opnieuw moeten scannen om verder deel te nemen.
        </Trans>
      </Modal>
    </>
  )
}
