import { InboxOutlined } from '@ant-design/icons'
import { PageHeader, UploadProps, message } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import Dragger from 'antd/lib/upload/Dragger'
import { useState } from 'react'

import { Terminal } from '../../components/terminal/Terminal'

export const ImportFlow = () => {
  const [uploaded, setUploaded] = useState<boolean>(false)
  const [output, setOutput] = useState<string>('')

  const logString = (value: string) =>
    setOutput((prev) =>
      value.trim()
        ? prev + `<p><em>${new Date().toISOString()}</em>${value}</p>`
        : prev + `<p>&nbsp;</p>`
    )

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: `${import.meta.env.NX_BACKEND_URL}/api/import`,
    customRequest: (options) => {
      setUploaded(true)
      logString('Uploading file... wait a minute. Do not close the page.')
      const formData = new FormData()
      formData.append('file', options.file)
      fetch(options.action, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
          'x-academy-host': window.location.hostname,
        },
      })
        .then(async (response) => {
          const decoder = new TextDecoder()
          const reader = response?.body?.getReader()
          while (reader) {
            if (reader) {
              const { value, done } = await reader.read()
              if (done) {
                break
              }
              logString(decoder.decode(value))
            }
          }
        })
        .catch((error) => {
          message.error(`Error: ${error.message}`)
          setUploaded(false)
          setOutput('')
        })
    },
    onChange(info) {
      const { status, name } = info.file
      if (status === 'error') {
        message.error(`${name} upload gefaald.`)
      }
    },
  }

  return (
    <Content>
      <PageHeader
        ghost={false}
        title={'Importeren'}
        className="site-page-header"
        subTitle="Gebruikers importeren"
      ></PageHeader>
      {!uploaded && (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Klik of sleep een bestand naar dit gebied om te uploaden
          </p>
        </Dragger>
      )}
      {uploaded && <Terminal>{output}</Terminal>}
    </Content>
  )
}
