import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Form, FormInstance, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { test } from 'fuzzyjs'
import { useCallback, useMemo, useState } from 'react'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  BranchAccessibleEventsQuery,
  EventStep,
  LiveEventQuery,
} from 'apps/lms-front/src/generated/graphql'

import LIVE_EVENT_QUERY from '../../events/queries/live-event.graphql'
import DatePicker from '../../shared/components/date-picker/DatePicker'
import { usePath } from '../contexts/PathContext'
import BRANCH_ACCESSIBLE_EVENTS_QUERY from '../queries/branch-accessible-events.graphql'

export const LinkedEventPicker = (props: {
  form: FormInstance
  step: EventStep
}) => {
  const { path } = usePath()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const linked_event_id = Form.useWatch('linked_event_id', props.form)
  const [range, setRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs(),
    dayjs().add(1, 'year'),
  ])

  const { data: event, loading: eventLoading } = useQuery<LiveEventQuery>(
    LIVE_EVENT_QUERY,
    {
      variables: {
        id: linked_event_id,
      },
      skip: !linked_event_id,
    }
  )

  const locationTypeOptions = useMemo(
    () => [
      {
        label: t({
          id: 'step.linked_event.field.location_type.physical',
          message: 'Fysiek',
        }),
        value: EventLocationType.Physical,
      },
      {
        label: t({
          id: 'step.linked_event.field.location_type.online',
          message: 'Online',
        }),
        value: EventLocationType.Online,
      },
    ],
    []
  )

  const checkCapacityAndShowModal = useCallback(
    (locationType: EventLocationType) => {
      if (locationType === EventLocationType.Physical) {
        if (
          event?.fetchLiveEventById?.physical_participant_count == null ||
          event?.fetchLiveEventById?.capacity == null ||
          path?.participant_count == null
        )
          return

        const totalAmount =
          event?.fetchLiveEventById?.physical_participant_count +
          path?.participant_count

        if (totalAmount > event?.fetchLiveEventById?.capacity)
          setIsModalVisible(true)
      }
    },
    [
      event?.fetchLiveEventById?.capacity,
      event?.fetchLiveEventById?.physical_participant_count,
      path?.participant_count,
    ]
  )

  const showLocationTypeSelect =
    event?.fetchLiveEventById?.location_type.length === 2

  const handleLocationTypeChange = (value: EventLocationType) => {
    props.form.setFieldsValue({ linked_event_location_type: value })
    checkCapacityAndShowModal(value)
  }

  const { data: events, loading: eventsLoading } =
    useQuery<BranchAccessibleEventsQuery>(BRANCH_ACCESSIBLE_EVENTS_QUERY, {
      variables: {
        start: range?.[0]?.toDate(),
        end: range?.[1]?.toDate(),
      },
      fetchPolicy: 'cache-and-network',
    })

  const results = useMemo(() => {
    const filtered =
      events?.fetchBranchAccessibleEvents.results.map((event) => ({
        label: `${event.title} (${dayjs(event.start).format(
          'D MMMM YYYY - HH:mm'
        )})`,
        value: event._id,
      })) || []
    const selection =
      event?.fetchLiveEventById &&
      !filtered.some((e) => e.value === event.fetchLiveEventById._id)
        ? [
            {
              label: `${event.fetchLiveEventById.title} (${dayjs(
                event.fetchLiveEventById.start
              ).format('D MMMM YYYY - HH:mm')})`,
              value: event.fetchLiveEventById._id,
            },
          ]
        : []
    return [...selection, ...filtered]
  }, [events, event])

  return (
    <div style={{ marginTop: 16 }}>
      <Form.Item
        label={t({
          id: 'step.linked_event.field.date',
          message: 'Filter op een periode',
        })}
        labelAlign="left"
        labelCol={{ span: 24 }}
      >
        <DatePicker.RangePicker
          format="D MMMM YYYY"
          disabledDate={(date) => {
            return date.isBefore(dayjs().endOf('day'))
          }}
          value={range}
          onChange={(value) => {
            setRange(value as [dayjs.Dayjs, dayjs.Dayjs])
          }}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        name={'linked_event_id'}
        label={t({
          id: 'step.linked_event.field.event',
          message: 'Selecteer een groepssessie',
        })}
        rules={[
          {
            required: true,
            message: t({
              id: 'step.linked_event.field.validation.event',
              message: 'Selecteer een groepssessie',
            }),
          },
        ]}
      >
        <Select
          disabled={eventsLoading || eventLoading}
          style={{ width: '100%' }}
          placeholder={t({
            id: 'step.linked_event.field.placeholder.event',
            message: 'Selecteer een groepssessie',
          })}
          options={results}
          filterOption={(inputValue: string, option) =>
            test(inputValue, option?.label || '', {
              caseSensitive: false,
            })
          }
          showSearch
        />
      </Form.Item>
      {showLocationTypeSelect && (
        <Form.Item
          name={'linked_event_location_type'}
          label={t({
            id: 'step.linked_event.field.location_type',
            message: 'Selecteer locatietype',
          })}
          rules={[
            {
              required: true,
              message: t({
                id: 'step.linked_event.field.validation.location_type',
                message: 'Selecteer een locatietype',
              }),
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder={t({
              id: 'step.linked_event.field.placeholder.location_type',
              message: 'Selecteer een locatietype',
            })}
            options={locationTypeOptions}
            onChange={handleLocationTypeChange}
          />
        </Form.Item>
      )}
      <Modal
        title={t({
          id: 'step.linked_event.full_modal.title',
          message: 'Let op: deze groepssessie heeft een beperkte capaciteit',
        })}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
      >
        <p>
          {t({
            id: 'step.linked_event.full_modal.message',
            message:
              'Je kan doorgaan met deze actie, maar mogelijks zullen niet alle deelnemers ingeschreven kunnen worden.',
          })}
        </p>
      </Modal>
    </div>
  )
}
