export enum CourseContentActionKind {
  ADD_SECTION = 'ADD_SECTION',
  REMOVE_SECTION = 'REMOVE_SECTION',
  CHANGE_SECTION_ORDER = 'CHANGE_SECTION_ORDER',
  CHANGE_UNIT_ORDER = 'CHANGE_UNIT_ORDER',
  CHANGE_CONTENT_ORDER = 'CHANGE_CONTENT_ORDER',
  UPDATE_SECTION_TITLE = 'UPDATE_SECTION_TITLE',
  DUPLICATE_UNIT = 'DUPLICATE_UNIT',
  REMOVE_UNIT = 'REMOVE_UNIT',
}

export enum UnitKind {
  CONTENT = 'ContentUnit',
  PDF = 'PDFUnit',
  VIDEO = 'VideoUnit',
  QUIZ = 'QuizUnit',
  SURVEY = 'SurveyUnit',
}
