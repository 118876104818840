import { ProfileOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Layout, Menu } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useCallback, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { Content } from '../../../shared/layout/Layout.style'
import { CertificatesOverview } from '../certificates-overview/CertificatesOverview'

export const Certificates = () => {
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  const { section: defaultSection } = useParams()
  const [section, setSection] = useState<string | undefined>(
    defaultSection || 'general'
  )

  const loadSection = useCallback(
    (section: string) => {
      navigate(`/certificates/${section}${window.location.search}`)
      setSection(section)
    },
    [navigate, setSection]
  )

  const items: ItemType[] = [
    {
      label: t({
        id: 'certificates.general',
        message: 'Attesten',
      }),
      key: 'general',
      icon: <ProfileOutlined />,
      disabled: !ability.can(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_USER
      ),
    },
  ]

  return (
    <>
      <Layout hasSider>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth={0}
          theme="light"
          width={300}
          style={{
            background: 'var(--ant-primary-1)',
            padding: '32px 0 32px 32px',
          }}
        >
          <Menu
            className="branch-menu"
            selectedKeys={section ? [section] : []}
            onClick={(item) => loadSection(item.key)}
            style={{ background: 'none', fontSize: 18, fontWeight: 700 }}
            mode="inline"
            items={items.filter((i) =>
              i && 'disabled' in i ? !i.disabled : true
            )}
          />
        </Layout.Sider>
        <Layout>
          <Content
            style={{
              display: 'block',
              background: '#FFF',
              padding:
                section === 'report' ? 8 : section?.startsWith('x_') ? 0 : 32,
              minHeight: 'calc(100vh - 72px)',
            }}
          >
            <Can I={PermissionAction.READ} a={PermissionObjectType.OWN_REPORT}>
              {section === 'general' && <CertificatesOverview />}
            </Can>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
