import { Trans, t } from '@lingui/macro'
import { Button, Modal } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

import {
  getCalendarLinks,
  openOrDownloadCalendar,
} from '@lms-shared-patterns/utils'

import DatePicker from '../../../shared/components/date-picker/DatePicker'

import { ButtonContainer } from './AddToCalendarModal.style'

interface AddToCalendarModalProps {
  onClose: () => void
  open: boolean
  deadline?: Date
  description?: string | null
  name: string
  duration?: number | null
  url: string
}

export const AddToCalendarModal = ({
  onClose,
  open,
  deadline,
  description,
  name,
  duration,
  url,
}: AddToCalendarModalProps) => {
  const [date, setDate] = useState<null | Dayjs>(null)

  const calendarDescription = `${t({
    id: 'modal.add_to_calendar.template.link',
    message: 'Link',
  })}: ${url}

${
  description
    ? `${t({
        id: 'modal.add_to_calendar.template.description',
        message: 'Beschrijving',
      })}: ${description}`
    : ''
}`

  const title = `${name}${
    deadline
      ? ` - ${t({
          id: 'modal.add_to_calendar.template.deadline',
          message: 'Deadline',
        })}: ${dayjs(deadline).tz('Europe/Brussels').format('DD/MM/YYYY')}`
      : ''
  }`

  const links = getCalendarLinks({
    title,
    duration: Math.min(duration || 60, 240),
    start: date?.toDate(),
    description: calendarDescription || '',
    url,
  })

  const disabledDate: (current: Dayjs) => boolean = (current) => {
    return current && current < dayjs().startOf('day')
  }

  return (
    <Modal
      forceRender
      title={t({
        id: 'modal.add_to_calendar.heading',
        message: 'Opleiding in je agenda inplannen',
      })}
      open={open}
      footer={null}
      onCancel={onClose}
    >
      {/* The <div> element has a child <button> element that allows keyboard interaction */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div onClick={(e) => e.stopPropagation()}>
        <DatePicker
          showNow={false}
          minuteStep={5}
          style={{ width: '100%' }}
          allowClear={false}
          value={date}
          showTime
          onChange={(e) => setDate(e)}
          format="DD/MM/YYYY HH:mm"
          disabledDate={disabledDate}
        />

        <ButtonContainer gutter={0}>
          {date &&
            Object.keys(links).map((key) => {
              const link = links[key as keyof typeof links]

              return (
                <Button
                  onClick={() => {
                    openOrDownloadCalendar(link, title + '.ics')
                  }}
                  key={key}
                >
                  {key}
                </Button>
              )
            })}
        </ButtonContainer>

        {date && (
          <Button onClick={onClose} type="primary">
            <Trans id="action.ready">Klaar</Trans>
          </Button>
        )}
      </div>
    </Modal>
  )
}
