import { Button, Collapse } from 'antd'
import styled, { AnyStyledComponent } from 'styled-components'

export const Wrapper = styled.div``

export const Section = styled.div`
  padding: 1.75rem 1.5rem;

  &:not(:first-child) {
    border-top: 1px solid #e5e5e5;
  }

  .ant-checkbox-group.vertical .ant-checkbox-group-item {
    display: flex;
    margin-bottom: 4px;
    margin-top: 4px;
  }
`

export const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
`

export const StyledCollapse = styled(Collapse as unknown as AnyStyledComponent)`
  & > .ant-collapse-item > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 1.5rem 0;
    font-size: 1.125rem;
  }

  & > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-bottom: 24px;
  }
`

export const ResetButton = styled((props) => <Button {...props} />)`
  margin-top: -12px;
  margin-bottom: -12px;
`
