import { Trans } from '@lingui/macro'
import { Button, Col, Space } from 'antd'
import { ReactNode, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router-dom'

import { ContentUnit } from 'apps/lms-front/src/generated/graphql'
import { logActivity } from 'apps/lms-front/src/modules/shared/helpers/log-activity'

import { PageHeader } from '../UnitViewer.style'

interface Props {
  unit: ContentUnit
  handleUnitCompletion: () => void
}

export const ContentUnitViewer = ({ unit, handleUnitCompletion }: Props) => {
  const { event_id } = useParams()
  const [loading, setLoading] = useState(false)

  return (
    <Col md={20} flex={1}>
      <PageHeader
        title={unit.name}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      />
      <Space direction="vertical">
        <article>{ReactHtmlParser(unit.content) as ReactNode}</article>
        {unit.next ? (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true)
              logActivity({ unit_id: unit._id, event_id, completed: true })
                .then(() => handleUnitCompletion())
                .finally(() => setLoading(false))
            }}
          >
            <Trans id="unit.viewer.action.complete_and_continue">
              Voltooien en doorgaan
            </Trans>
          </Button>
        ) : (
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true)
              logActivity({ unit_id: unit._id, completed: true })
                .then(() => handleUnitCompletion())
                .finally(() => setLoading(false))
            }}
          >
            <Trans id="unit.viewer.action.complete_course">
              Opleiding voltooien
            </Trans>
          </Button>
        )}
      </Space>
    </Col>
  )
}
