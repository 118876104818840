/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DownloadOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Uppy } from '@uppy/core'
import { Dashboard } from '@uppy/react'
import Tus from '@uppy/tus'
import {
  Avatar,
  Button,
  Card,
  Form,
  List as AntList,
  Input,
  Space,
  Tooltip,
  Modal,
  message,
} from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import Meta from 'antd/lib/card/Meta'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  CloudflareVideo,
  CloudflareVideosQuery,
  SelfStudyStep,
} from 'apps/lms-front/src/generated/graphql'
import { Can } from 'apps/lms-front/src/modules/auth/components/Can'
import CLOUDFLARE_VIDEOS_QUERY from 'apps/lms-front/src/modules/units/queries/cloudflare-videos.graphql'

import { useBranch } from '../../auth/hooks/use-branch'
import { LoadSection } from '../../core/components/LoadScreen'
import { InputSearch } from '../../shared/components/input-search/InputSearch'

const StListItem = styled((props) => <AntList.Item {...props} />)`
  padding-left: 1rem;
  border-radius: 0.5rem;
  &:hover {
    background-color: #f0f0f0;
  }
`

export const StepEditVideoUnit = ({
  step,
  form,
}: {
  step: SelfStudyStep
  form: FormInstance
}) => {
  const [videoPickerVisible, setVideoPickerVisible] = useState(false)
  const [videoUploaderVisible, setVideoUploaderVisible] = useState(false)
  const [libraryVideo, setLibraryVideo] = useState<
    CloudflareVideo | undefined
  >()
  const [uppyClient, setUppyClient] = useState<Uppy>()
  const [query, setQuery] = useState<string | undefined>()

  const branch = useBranch()

  const [
    loadCloudflareVideos,
    { data: cloudflareVideos, loading: fetchingVideos },
  ] = useLazyQuery<CloudflareVideosQuery>(CLOUDFLARE_VIDEOS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      search: query,
    },
  })

  useEffect(() => {
    if (step?.custom_video_unit?.cf_stream?.uid)
      setLibraryVideo({
        uid: step.custom_video_unit.cf_stream.uid,
        thumbnail: step.custom_video_unit.cf_stream.thumbnail,
        signedBucketURL: step.custom_video_unit.cf_stream.signedBucketURL,
        duration: step.custom_video_unit.cf_stream.duration,
        meta: { name: step.custom_video_unit.cf_stream.meta.name },
      } as CloudflareVideo)

    form.setFieldValue(
      ['custom_video_unit', 'cf_stream_id'],
      step?.custom_video_unit?.cf_stream?.uid
    )
  }, [step, loadCloudflareVideos, form])

  const handleVideoSelected = (video: CloudflareVideo) => {
    form.setFieldValue(['custom_video_unit', 'cf_stream_id'], video.uid)
    setLibraryVideo(video)
    setVideoPickerVisible(false)
    form.setFieldValue('linked_unit_duration', video.duration)
  }

  useEffect(() => {
    const getUppyClient = async () => {
      return new Uppy({ debug: true, autoProceed: true }).use(Tus, {
        endpoint: `${
          import.meta.env.NX_BACKEND_URL
        }/api/files/generateCloudflareTusURL`,
        chunkSize: 150 * 1024 * 1024,
        async onBeforeRequest(req) {
          if (req.getURL().includes('generateCloudflareTusURL'))
            req.setHeader('Upload-Creator', branch?._id)
        },
        async onAfterResponse(_, res) {
          if (res.getHeader('stream-media-id')) {
            const cf_stream_id = res.getHeader('stream-media-id')
            form.setFieldValue(
              ['custom_video_unit', 'cf_stream_id'],
              cf_stream_id
            )
            setLibraryVideo(
              (v) =>
                ({
                  ...v,
                  uid: cf_stream_id,
                }) as CloudflareVideo
            )
          }
        },
      })
    }
    getUppyClient().then((client) => {
      client.on('file-added', (file) => {
        setLibraryVideo({
          meta: { name: file.name },
        } as CloudflareVideo)
      }),
        client.on('upload-success', () => {
          loadCloudflareVideos()
          setVideoUploaderVisible(false)
          message.info(
            t({
              id: 'course.unit.form.upload.video.success',
              message:
                'De upload was succesvol. Het kan enkele minuten duren voor de video in jouw bibliotheek beschikbaar is.',
            })
          )
        })
      setUppyClient(client)
    })
    return () => uppyClient?.close({ reason: 'unmount' })
  }, [])

  return (
    <>
      <Form.Item
        hidden={!!libraryVideo}
        label={t({
          id: 'course.unit.form.label.external_video_url',
          message: 'Video URL',
        })}
        name={['custom_video_unit', 'external_url']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        label={t({
          id: 'course.unit.form.label.cf_stream_id',
          message: 'Cloudflare Stream ID',
        })}
        name={['custom_video_unit', 'cf_stream_id']}
      >
        <Input />
      </Form.Item>
      <Form.Item name="linked_unit_duration" hidden>
        <input />
      </Form.Item>
      <Form.Item>
        {libraryVideo && (
          <Card style={{ marginBottom: 24 }}>
            <Meta
              avatar={
                <Avatar
                  shape="square"
                  src={`${libraryVideo.thumbnail}?time=5s`}
                  style={{ boxShadow: '5px 5px 40px rgba(0,0,0,0.12)' }}
                />
              }
              title={
                <Space>
                  <span>{libraryVideo.meta.name}</span>
                  <Can
                    I={PermissionAction.DOWNLOAD}
                    a={PermissionObjectType.VIDEO}
                  >
                    {libraryVideo.signedBucketURL && (
                      <Button
                        type={'text'}
                        onClick={() => {
                          window.open(
                            `${libraryVideo.signedBucketURL!}&response-content-disposition=attachment`,
                            '_blank'
                          )
                        }}
                      >
                        <Tooltip
                          title={t({
                            id: 'action.download',
                            message: 'Downloaden',
                          })}
                        >
                          <DownloadOutlined />
                        </Tooltip>
                      </Button>
                    )}
                  </Can>
                </Space>
              }
              description={
                libraryVideo.duration
                  ? dayjs.duration(libraryVideo.duration, 'seconds').humanize()
                  : undefined
              }
            />
          </Card>
        )}
        <Space>
          <Button
            onClick={() => {
              setVideoPickerVisible(true)
              loadCloudflareVideos()
            }}
          >
            <Trans id="course.unit.form.action.select_video">
              Video uit bibliotheek kiezen
            </Trans>
          </Button>
          <Button
            onClick={() => {
              setVideoUploaderVisible(true)
            }}
          >
            <Trans id="course.unit.form.action.upload_video">
              Uploaden naar de bibliotheek
            </Trans>
          </Button>
        </Space>
        {libraryVideo && (
          <Button
            type={'text'}
            onClick={() => {
              form.resetFields(['custom_video_unit', 'cf_stream_id'])
              setLibraryVideo(undefined)
            }}
          >
            <Trans id="course.unit.form.action.enter_video_url">
              Externe link invoeren
            </Trans>
          </Button>
        )}
      </Form.Item>
      <Modal
        open={videoUploaderVisible}
        maskClosable={false}
        closable={false}
        okText={t({
          id: 'action.upload',
          message: 'Upload',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ hidden: true }}
        onCancel={() => setVideoUploaderVisible(false)}
      >
        {uppyClient && <Dashboard uppy={uppyClient} />}
      </Modal>
      <Modal
        title={t({
          id: 'course.unit.action.select_video',
          message: 'Selecteer een video',
        })}
        open={videoPickerVisible}
        okText={t({
          id: 'action.add',
          message: 'Toevoegen',
        })}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okButtonProps={{ hidden: true }}
        onCancel={() => setVideoPickerVisible(false)}
      >
        <InputSearch
          style={{ marginBottom: 24 }}
          placeholder={t({
            id: 'course.unit.form.placeholder.video_search',
            message: 'Zoeken...',
          })}
          onSearch={(val: string) =>
            val && val.length > 0 ? setQuery(val) : setQuery(undefined)
          }
        />

        {fetchingVideos && <LoadSection />}
        {!fetchingVideos && cloudflareVideos?.fetchCloudflareVideos && (
          <>
            <AntList
              dataSource={cloudflareVideos.fetchCloudflareVideos}
              renderItem={(item) => (
                <StListItem
                  key={item.uid}
                  onClick={() => handleVideoSelected(item as CloudflareVideo)}
                  style={{ cursor: 'pointer' }}
                >
                  <AntList.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        src={`${item.thumbnail}?time=5s`}
                        style={{ boxShadow: '5px 5px 40px rgba(0,0,0,0.12)' }}
                      />
                    }
                    title={item.meta.name}
                    description={dayjs
                      .duration(item.duration, 'seconds')
                      .humanize()}
                  />
                </StListItem>
              )}
            />
          </>
        )}
      </Modal>
    </>
  )
}
