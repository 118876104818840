/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const purgeProperties = (object: any, properties: string[]) => {
  if (Array.isArray(object)) {
    object.forEach((element) => purgeProperties(element, properties))
  } else if (typeof object !== 'string') {
    if (!object) return
    const keys = Object.keys(object)
    keys.forEach((key) => {
      if (properties.includes(key)) {
        const descriptor = Object.getOwnPropertyDescriptor(object, key)
        if (descriptor?.configurable) {
          delete object[key]
        } else {
          console.error(
            `Cannot delete '${key}'-property, which might introduce other problems.`
          )
        }
      } else {
        purgeProperties(object[key], properties)
      }
    })
  }
}
