import styled from 'styled-components'

import { Container as AntContainer } from '../../../shared/layout/Layout.style'

export const Container = styled(AntContainer)`
  margin-top: 1rem;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.125rem;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

export const Intro = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.35rem;
  }
`

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & + p,
  & + ul,
  & + ol {
    margin-top: -1rem;
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  li > & {
    margin-bottom: 1.25rem;
  }
`

export const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`
