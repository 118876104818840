import { useMutation, useQuery } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Modal, Select } from 'antd'
import { test } from 'fuzzyjs'
import { useState } from 'react'

import {
  BranchAccessibleCoursesQuery,
  ImportCourseAsModuleMutation,
} from 'apps/lms-front/src/generated/graphql'
import BRANCH_COURSES from 'apps/lms-front/src/modules/branch/queries/branch-accessible-courses.graphql'

import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import IMPORT_COURSE_AS_MODULE_MUTATION from '../mutations/import-course-as-module.graphql'

export const ImportModuleModal = ({
  path,
  open,
  onClose,
}: {
  path: string
  open: boolean
  onClose: () => void
}) => {
  const [course, setCourse] = useState<string | null>()
  const { data: courses, loading: coursesLoading } =
    useQuery<BranchAccessibleCoursesQuery>(BRANCH_COURSES, {
      fetchPolicy: 'cache-and-network',
    })

  const [importCourse, { loading: importing }] =
    useMutation<ImportCourseAsModuleMutation>(
      IMPORT_COURSE_AS_MODULE_MUTATION,
      {
        variables: {
          path_id: path,
          course_id: course,
        },
        refetchQueries: ['path'],
      }
    )

  return (
    <Modal
      title={
        <Trans id="paths.import.title">Opleiding als module importeren</Trans>
      }
      open={open}
      onCancel={() => {
        onClose()
      }}
      okButtonProps={{
        disabled: !course,
        loading: importing,
      }}
      okText={t({
        id: 'action.import',
        message: 'Importeren',
      })}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      onOk={() => {
        importCourse()
          .then(() => {
            onClose()
          })
          .catch(errorNotifierFn)
      }}
    >
      <Select
        disabled={coursesLoading}
        style={{ width: '100%' }}
        placeholder={t({
          id: 'step.linked_unit.field.placeholder.course',
          message: 'Selecteer een opleiding',
        })}
        options={
          courses?.fetchBranchAccessibleCourses.results.map((course) => ({
            label: course.translation.name,
            value: course._id,
          })) || []
        }
        filterOption={(inputValue: string, option) =>
          test(inputValue, option?.label || '', {
            caseSensitive: false,
          })
        }
        showSearch
        value={course}
        onChange={(value) => {
          setCourse(value)
        }}
      />
    </Modal>
  )
}
