import { Trans } from '@lingui/macro'

export const CharacterLimitHelper = (props: {
  content: string | undefined
  max: number
  errorOnly?: boolean
  infoOnly?: boolean
  html?: boolean
}) => {
  let length = props.content?.length || 0

  if (props.html && props.content) {
    length = props.content.replaceAll(/<[^>]+>/g, '').length || 0
  }

  const remaining = props.max - length

  if (remaining / props.max > 0.25) return

  if (remaining >= 0) {
    if (!props.errorOnly) {
      return (
        <small>
          <Trans id="validation.limit.warning">
            Nog {remaining} karakters resterend.
          </Trans>
        </small>
      )
    }
  } else {
    if (!props.infoOnly) {
      return (
        <small className="ant-form-item-explain-error">
          <Trans id="validation.limit.reached">
            Limiet overschreden met {Math.abs(remaining)} karakters
          </Trans>
        </small>
      )
    }
  }
  return undefined
}
