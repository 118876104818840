import { t, Trans } from '@lingui/macro'
import { LiveKitRoom } from '@livekit/components-react'
import { Button, Col, Form, Input, Layout, notification, Row } from 'antd'
import { Room, RoomOptions, VideoPresets } from 'livekit-client'
import { useEffect, useMemo } from 'react'

import { FetchMeetingDetailsQuery } from 'apps/lms-front/src/generated/graphql'

import { useAuth } from '../../../auth/hooks/use-auth'
import { ChatParticipant, useStream } from '../../contexts/StreamContext'

import { VideoConference } from './components/video/VideoConference'

interface JoinStreamContentProps {
  data: FetchMeetingDetailsQuery
  refetch?: () => void
}

export function JoinStreamContent({ data }: JoinStreamContentProps) {
  const { user } = useAuth()
  const {
    room,
    setRoom,
    token,
    setToken,
    isRoomActive,
    setIsRoomActive,
    isMeetingHost,
    setIsMeetingHost,
    participantName,
    setParticipantName,
    emit,
    connected,
    getToken,
    setChatParticipants,
    setCallId,
  } = useStream()

  useEffect(() => {
    if (data?.fetchLiveEventByCallId.stream_details?.is_active) {
      setIsRoomActive(true)
    }
  }, [data, setIsRoomActive])

  const roomOptions: RoomOptions = {
    adaptiveStream: true,
    dynacast: true,
    publishDefaults: {
      simulcast: true,
    },
    videoCaptureDefaults: {
      resolution: VideoPresets.h720.resolution,
    },
    audioCaptureDefaults: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
  }

  const isMeetingHostMemo = useMemo(
    () =>
      data?.fetchLiveEventByCallId?.stream_details?.host_ids.includes(
        user?._id
      ) ?? false,
    [data, user]
  )

  useEffect(() => {
    setIsMeetingHost(isMeetingHostMemo)
  }, [setIsMeetingHost, isMeetingHostMemo])

  useEffect(() => {
    if (data?.fetchLiveEventByCallId.stream_details) {
      setIsRoomActive(data?.fetchLiveEventByCallId.stream_details.is_active)
    }
  }, [data, setIsRoomActive])

  useEffect(() => {
    if (user) {
      setParticipantName(`${user.firstName} ${user.lastName}`)
    }
  }, [user, setParticipantName])

  useEffect(() => {
    if (connected && room) {
      setCallId(data?.fetchLiveEventByCallId.call_id)
      emit('joinStream', {
        call_id: data?.fetchLiveEventByCallId.call_id,
      })
    }
  }, [
    connected,
    room,
    data?.fetchLiveEventByCallId._id,
    isMeetingHost,
    participantName,
    emit,
  ])

  const joinRoom = async () => {
    const room = new Room()
    setRoom(room)

    try {
      const token = await getToken(data?.fetchLiveEventByCallId.call_id)
      await room.connect(import.meta.env.NX_LIVEKIT_URL, token)

      if (isMeetingHost) {
        emit('activateMeetingRoom', {
          call_id: data?.fetchLiveEventByCallId.call_id,
        })
        setIsRoomActive(true)
      }
      setToken(token)
    } catch (error) {
      notification.error({
        message: t({
          id: 'stream.join.error',
          message: `There was an error connecting to the room: ${
            (error as Error).message
          }`,
        }),
      })
      setRoom(undefined)
    }
  }

  useEffect(() => {
    if (data?.fetchLiveEventByCallId?.participants) {
      setChatParticipants(
        data.fetchLiveEventByCallId.participants as unknown as ChatParticipant[]
      )
    }
  }, [data?.fetchLiveEventByCallId?.participants, setChatParticipants])

  const isUserAllowed = useMemo(() => {
    if (
      data?.fetchLiveEventByCallId.participants &&
      data?.fetchLiveEventByCallId?.stream_details
    ) {
      const isParticipant = data?.fetchLiveEventByCallId?.participants.find(
        (p) => p._id === user?._id
      )
      const isHost = data?.fetchLiveEventByCallId?.stream_details.host_ids.find(
        (h) => h === user?._id
      )
      return isParticipant || isHost
    }
    return false
  }, [data, user])

  if (!isUserAllowed) {
    return (
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col span={8}>
          <div
            style={{
              textAlign: 'center',
              background: '#fff',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Trans id="stream.join.not_allowed">
              Je hebt niet de juiste rechten om deel te nemen aan deze meeting.
            </Trans>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <div className="app">
      {token ? (
        isMeetingHost || isRoomActive ? (
          <LiveKitRoom
            token={token}
            serverUrl={import.meta.env.NX_LIVEKIT_URL}
            connect={true}
            options={roomOptions}
            audio={isMeetingHost}
            video={isMeetingHost}
          >
            <Layout>
              <Row
                justify="center"
                style={{ marginBottom: '20px', padding: '20px' }}
              >
                <Col span={24}>
                  <div className="livekit-container">
                    <VideoConference
                      isHostUser={!!isMeetingHost}
                      roomData={data}
                    />
                  </div>
                </Col>
              </Row>
            </Layout>
          </LiveKitRoom>
        ) : (
          <Row justify="center" style={{ marginTop: '50px' }}>
            <Col span={8}>
              <div
                style={{
                  textAlign: 'center',
                  background: '#fff',
                  padding: '24px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Trans id="stream.join.not_started">
                  Deze meeting is nog niet gestart.
                </Trans>
              </div>
            </Col>
          </Row>
        )
      ) : (
        <Row justify="center" style={{ marginTop: '50px' }}>
          <Col span={8}>
            <div id="join">
              <Form
                layout="vertical"
                onFinish={joinRoom}
                style={{
                  background: '#fff',
                  padding: '24px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Form.Item
                  label={t({ id: 'stream.join.name', message: 'Naam' })}
                  required
                >
                  <Input disabled value={participantName} />
                </Form.Item>

                <Form.Item
                  label={t({ id: 'stream.join.room', message: 'Meeting ID' })}
                  required
                >
                  <Input disabled value={data.fetchLiveEventByCallId.call_id} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    <Trans id="stream.join.join">Nu deelnemen</Trans>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}
