import { useNavigate, useParams } from 'react-router-dom'

import { PageProps } from '../../../shared/interfaces/page.interface'
import { CourseEditContentUnit } from '../edit-unit/content/CourseEditContentUnit'
import { CourseEditPDFUnit } from '../edit-unit/pdf/CourseEditPdfUnit'
import { CourseEditQuizUnit } from '../edit-unit/quiz/CourseEditQuizUnit'
import { CourseEditSurveyUnit } from '../edit-unit/survey/CourseEditSurveyUnit'
import { CourseEditVideoUnit } from '../edit-unit/video/CourseEditVideoUnit'

export const CourseAddUnit = (props: PageProps) => {
  const navigate = useNavigate()
  const { id } = useParams()

  switch (props.route.path.split('add/')[1]) {
    case 'pdf': {
      return <CourseEditPDFUnit {...props} />
    }
    case 'video': {
      return <CourseEditVideoUnit {...props} />
    }
    case 'content': {
      return <CourseEditContentUnit {...props} />
    }
    case 'quiz': {
      return <CourseEditQuizUnit {...props} />
    }
    case 'survey': {
      return <CourseEditSurveyUnit {...props} />
    }
    default: {
      navigate(`/courses/${id}/edit-content`)
      break
    }
  }

  return null
}
