import { Button, Dropdown, MenuProps } from 'antd'
import { ButtonProps } from 'antd/es/button'

type Props = {
  children: React.ReactNode
  menu?: MenuProps
}

export const DropdownButton = ({
  children,
  icon,
  ...props
}: Props & ButtonProps) => {
  return props.menu && !props.menu.hidden ? (
    <Dropdown.Button {...props} icon={icon}>
      {children}
    </Dropdown.Button>
  ) : (
    <Button {...props}>{children}</Button>
  )
}
