import { Col } from 'antd'
import styled from 'styled-components'

import { breakpoints } from '../../../shared/layout/breakpoints'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 24px;
`

export const Course = styled.div`
  box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.08);
  height: calc(100% - 1rem);
  margin-bottom: 1rem;
  transition: box-shadow 200ms ease;
  &:hover {
    box-shadow: 5px 10px 50px rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }
`

export const CoursesStickyCol = styled((props) => <Col {...props} />)`
  @media (min-width: 576px) {
    position: sticky;
    top: 24px;
    height: calc(100vh - 45px);
  }
`

export const CoursesWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  @media ${breakpoints.sm} {
    height: calc(100vh - 45px);
  }

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const CoursesPaginationWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`
