import { useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { TreeSelectProps } from 'antd'
import { useContext, useMemo } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  BranchUsersGroupsQuery,
  BranchUsersKeyValuesQuery,
  HierarchyQuery,
} from 'apps/lms-front/src/generated/graphql'

import { AbilityContext } from '../../../auth/components/Can'
import {
  prepSectionsForTreeSelect,
  prepUsersForTreeSelect,
  TreeSelectNodeProps,
} from '../../../branch/hooks/use-hierarchy-tree'
import { TreeSelect } from '../../components/tree-select/TreeSelect'

import HIERARCHY_QUERY from './../../../branch/queries/hierarchy.graphql'
import BRANCH_USERS_GROUPS from './../../../courses/queries/branch-users-groups.graphql'
import BRANCH_USERS_KEY_VALUES from './../../../courses/queries/branch-users-key-values.graphql'

export const UserTreeSelect = (
  props: TreeSelectProps & { hidden?: boolean }
) => {
  const ability = useContext(AbilityContext)

  const { data: groupsData } = useQuery<BranchUsersGroupsQuery>(
    BRANCH_USERS_GROUPS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: ability.cannot(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_USER_GROUP
      ),
    }
  )

  const { data: usersData } = useQuery<BranchUsersKeyValuesQuery>(
    BRANCH_USERS_KEY_VALUES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )
  const { data: hierarchy } = useQuery<HierarchyQuery>(HIERARCHY_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const treeData = useMemo(() => {
    const hierarchyNodes: TreeSelectNodeProps[] = hierarchy?.fetchHierarchy
      ? prepSectionsForTreeSelect(hierarchy?.fetchHierarchy, ability, {
          action: PermissionAction.CREATE,
          object: PermissionObjectType.ASSIGNMENT,
        })
          .map((node) => {
            return node.pId
              ? node
              : {
                  ...node,
                  disabled: false,
                  selectable: !node.disabled,
                }
          })
          .filter((node) => !node.disabled)
          .filter((node) => node.pId || node.selectable)
      : []

    const userNodes: TreeSelectNodeProps[] = usersData?.fetchBranchUsers
      ? prepUsersForTreeSelect(usersData.fetchBranchUsers, ability, {
          action: PermissionAction.CREATE,
          object: PermissionObjectType.ASSIGNMENT,
        }).filter((node) => !node.disabled)
      : []
    if (userNodes.length > 0)
      userNodes.unshift({
        id: 'users',
        selectable: false,
        value: 'users',
        title: t({
          id: 'user-tree-select.form.users.users',
          message: 'Gebruikers',
        }),
        label: t({
          id: 'user-tree-select.form.users.users',
          message: 'Gebruikers',
        }),
        level: 0,
      })

    const groupNodes: TreeSelectNodeProps[] =
      groupsData?.fetchBranchGroups.map((group) => ({
        id: group._id,
        pId: 'groups',
        value: group._id,
        title: group.name,
        label: group.name,
        level: 1,
      })) || []
    if (groupNodes.length > 0)
      groupNodes.unshift({
        id: 'groups',
        selectable: false,
        value: 'groups',
        title: t({
          id: 'user-tree-select.form.users.groups',
          message: 'Groepen',
        }),
        label: t({
          id: 'user-tree-select.form.users.groups',
          message: 'Groepen',
        }),
        level: 0,
      })

    return [...hierarchyNodes, ...userNodes, ...groupNodes]
  }, [usersData, groupsData, hierarchy])

  return (
    <TreeSelect
      multiple
      treeLine={true}
      showSearch
      treeDataSimpleMode
      loading={treeData.length === 0}
      allowClear
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      filterTreeNode={(input, option) =>
        (option.title as string)?.toLowerCase().includes(input.toLowerCase())
      }
      treeNodeLabelProp="label"
      style={{ width: '100%' }}
      placeholder={t({
        id: 'user-tree-select.form.placeholder.users',
        message: 'Selecteer gebruikers of groepen',
      })}
      treeData={treeData}
      {...props}
    />
  )
}
