import { useNavigate } from 'react-router-dom'

import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

export const Logout = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()

  logout(() => {
    navigate('/login')
  })

  return null
}
