import { Link } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'

export const Table = styled.div`
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  width: calc(100% - 5rem);
  box-sizing: content-box;
`

export const TBody = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  user-select: none;
`

export const TR = styled.div`
  height: 45px;
  line-height: 45px;
  display: flex;
  flex-direction: row;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  align-items: stretch;
`

export const TD = styled.div`
  &:first-child {
    flex: 1;
    min-width: 200px;
  }
  padding: 0 0.5rem;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`

export const CenteredTD = styled(TD)`
  text-align: center;
  min-width: 45px;
  width: 45px;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
`

export const THead = styled.div`
  height: 140px;
  line-height: 140px;
  user-select: none;
  display: flex;
  flex-direction: row;
`

export const RotatedTH = styled.div`
  padding: 4px 8px;
  height: 140px;
  white-space: nowrap;
  min-width: 45px;
  width: 45px;
  border: none;

  & > div {
    transform: translate(19px, 39px) rotate(315deg);
    width: 45px;
  }

  & > div > span {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 4px 0 8px;
    transform: translateY(-2px);
  }
`

export const TableCellLink = styled(Link as unknown as AnyStyledComponent)`
  text-decoration: none !important;
  color: var(--text-color);

  &:hover {
    color: var(--ant-primary-color);
  }
`

export const Done = styled.div`
  position: relative;
  z-index: 10;
  cursor: pointer;
  background-color: var(--ant-success-color-outline);
  color: var(--ant-success-color);
`

export const InProgress = styled.div`
  position: relative;
  z-index: 10;
  cursor: pointer;
  background-color: var(--ant-warning-color-outline);
  color: var(--ant-warning-color);
`
