export const startRecordingApiCall = async (call_id: string | undefined) => {
  if (!call_id) {
    throw new Error('Meeting ID is required to start recording')
  }
  const backendUrl = import.meta.env.NX_BACKEND_URL
  if (!backendUrl) {
    throw new Error('Backend URL is not configured')
  }
  const response = await fetch(`${backendUrl}/api/meetings/recordings/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
    },
    body: JSON.stringify({ call_id }),
  })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(
      `Failed to start recording: ${error?.error || response.statusText}`
    )
  }

  return await response.json()
}

export const stopRecordingApiCall = async (call_id: string | undefined) => {
  const backendUrl = import.meta.env.NX_BACKEND_URL
  if (!backendUrl) {
    throw new Error('Backend URL is not configured')
  }
  const response = await fetch(`${backendUrl}/api/meetings/recordings/stop`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
    },
    body: JSON.stringify({ call_id }),
  })

  if (!response.ok) {
    const error = await response.json()
    throw new Error(
      `Failed to stop recording: ${error?.error || response.statusText}`
    )
  }

  return await response.json()
}
