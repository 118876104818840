import { t } from '@lingui/macro'
import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn(
        t({
          id: 'error.clipboard_not_supported',
          message: 'Klembord niet ondersteund.',
        })
      )
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      console.warn(
        t({
          id: 'error.copy_failed',
          message: 'Kopiëren naar klembord gefaald.',
        }),
        error
      )
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}
