import { i18n } from '@lingui/core'
import { type MediaStorage } from '@vidstack/react'

export class MediaDatabaseStorage implements MediaStorage {
  private time: number | undefined

  constructor(time?: number) {
    if (time) this.time = time
  }

  async getVolume() {
    const volume = localStorage.getItem('aa_volume')
    if (volume) return Number.parseFloat(volume)

    return null
  }
  async setVolume(volume: number) {
    localStorage.setItem('aa_volume', String(volume))
  }

  async getMuted() {
    return localStorage.getItem('aa_muted') === 'true'
  }
  async setMuted(isMuted: boolean) {
    localStorage.setItem('aa_muted', String(isMuted))
  }

  async getTime() {
    if (this.time) return this.time

    return null
  }
  async setTime() {
    // do nothing
  }

  async getLang() {
    return localStorage.getItem('aa_lang') || i18n.locale
  }
  async setLang(lang: string | null) {
    if (lang === i18n.locale) localStorage.removeItem('aa_lang')
    else localStorage.setItem('aa_lang', lang || i18n.locale)
  }

  async getCaptions() {
    return (
      localStorage.getItem('aa_captions') === 'true' || i18n.locale === 'fr'
    )
  }
  async setCaptions(isOn: boolean) {
    localStorage.setItem('aa_captions', String(isOn))
  }

  async getPlaybackRate(): Promise<number | null> {
    const playbackRate = localStorage.getItem('aa_playback_rate')
    if (playbackRate) return Number.parseFloat(playbackRate)

    return null
  }
  async setPlaybackRate(playbackRate: number) {
    localStorage.setItem('aa_playback_rate', String(playbackRate))
  }
  async getVideoQuality(): Promise<null> {
    return null
  }
  async setVideoQuality() {
    // do nothing
  }

  async getAudioGain(): Promise<number | null> {
    return localStorage.getItem('aa_audio_gain')
      ? Number(localStorage.getItem('aa_audio_gain'))
      : 1
  }
  async setAudioGain(gain: number) {
    localStorage.setItem('aa_audio_gain', String(gain))
  }
}
