import {
  AudioMutedOutlined,
  AudioOutlined,
  DesktopOutlined,
  MessageOutlined,
  PoweroffOutlined,
  TrademarkCircleOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLocalParticipant, useRoomContext } from '@livekit/components-react'
import '@livekit/components-styles' // Add default styles for LiveKit components
import { Badge } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStream } from '../../../../contexts/StreamContext'
import {
  startRecordingApiCall,
  stopRecordingApiCall,
} from '../utils/recording-handler'
import { ControlButton, ControlPanelContainer } from './ControlPanel.style'

export const ControlPanel = ({
  isHostUser,
  onToggleChat,
  isChatVisible,
  unreadChatCount,
}: {
  isHostUser: boolean
  onToggleChat: () => void
  isChatVisible: boolean
  unreadChatCount
}) => {
  const { localParticipant } = useLocalParticipant()
  const { call_id } = useParams()
  const { leaveRoom, emit, canEnableCamera, canEnableMicrophone } = useStream()
  const room = useRoomContext()
  const [isRecording, setIsRecording] = useState(false)

  const toggleAudio = async () => {
    if (canEnableMicrophone) {
      await localParticipant.setMicrophoneEnabled(
        !localParticipant.isMicrophoneEnabled
      )
    }
  }

  const toggleVideo = async () => {
    if (canEnableCamera) {
      await localParticipant.setCameraEnabled(!localParticipant.isCameraEnabled)
    }
  }

  const toggleScreenShare = async () => {
    await (localParticipant.isScreenShareEnabled
      ? localParticipant.setScreenShareEnabled(false)
      : localParticipant.setScreenShareEnabled(true))
  }

  const startRecording = async () => {
    try {
      await startRecordingApiCall(call_id)
      setIsRecording(true)
    } catch (error) {
      console.error('Failed to start recording', error)
    }
  }

  const stopRecording = async () => {
    try {
      await stopRecordingApiCall(call_id)
      setIsRecording(false)
    } catch (error) {
      console.error('Failed to stop recording', error)
    }
  }

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  return (
    <ControlPanelContainer>
      <>
        {canEnableCamera && canEnableMicrophone && (
          <>
            <ControlButton
              onClick={toggleAudio}
              disabled={!canEnableMicrophone}
            >
              {localParticipant.isMicrophoneEnabled ? (
                <AudioOutlined />
              ) : (
                <AudioMutedOutlined />
              )}
              <span>
                {localParticipant.isMicrophoneEnabled
                  ? t({
                      id: 'stream.control_panel.action.mute',
                      message: 'Geluid dempen',
                    })
                  : t({
                      id: 'stream.control_panel.action.unmute',
                      message: 'Geluid hervatten',
                    })}
              </span>
            </ControlButton>
            <ControlButton onClick={toggleVideo} disabled={!canEnableCamera}>
              {localParticipant.isCameraEnabled ? (
                <VideoCameraOutlined />
              ) : (
                <VideoCameraAddOutlined />
              )}
              <span>
                {localParticipant.isCameraEnabled
                  ? t({
                      id: 'stream.control_panel.action.stop_video',
                      message: 'Video stoppen',
                    })
                  : t({
                      id: 'stream.control_panel.action.start_video',
                      message: 'Video starten',
                    })}
              </span>
            </ControlButton>
          </>
        )}
        {isHostUser && (
          <ControlButton onClick={toggleScreenShare}>
            <DesktopOutlined />
            <span>
              {localParticipant.isScreenShareEnabled
                ? t({
                    id: 'stream.control_panel.action.stop_sharing_screen',
                    message: 'Scherm delen stoppen',
                  })
                : t({
                    id: 'stream.control_panel.action.share_screen',
                    message: 'Scherm delen',
                  })}
            </span>
          </ControlButton>
        )}
      </>
      <ControlButton
        onClick={() => {
          leaveRoom()
          emit('leaveStream', { call_id })
          room?.disconnect()
        }}
      >
        <PoweroffOutlined style={{ color: 'red' }} />
        <span>
          {t({ id: 'stream.control_panel.action.leave', message: 'Verlaten' })}
        </span>
      </ControlButton>
      <ControlButton onClick={onToggleChat}>
        <Badge dot={unreadChatCount > 0} offset={[-5, 5]}>
          <MessageOutlined
            style={{ color: isChatVisible ? '#1890ff' : 'white' }}
          />
        </Badge>
        <span>
          {isChatVisible
            ? t({
                id: 'stream.control_panel.action.hide_chat',
                message: 'Chat verbergen',
              })
            : t({
                id: 'stream.control_panel.action.show_chat',
                message: 'Chat tonen',
              })}
        </span>
      </ControlButton>
      {isHostUser && (
        <ControlButton onClick={toggleRecording}>
          <TrademarkCircleOutlined
            style={{ color: isRecording ? 'red' : 'inherit' }}
          />
          <span>
            {isRecording
              ? t({
                  id: 'stream.control_panel.action.stop_recording',
                  message: 'Opname stoppen',
                })
              : t({
                  id: 'stream.control_panel.action.record',
                  message: 'Opname starten',
                })}
          </span>
        </ControlButton>
      )}
    </ControlPanelContainer>
  )
}
