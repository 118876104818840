import {
  AlertOutlined,
  CheckOutlined,
  CloseOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
  MoreOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  notification,
  Space,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

import { EventLocationType } from '@lms-shared-patterns/enums/event.enums'
import {
  ApproveLiveEventRegistrationMutation,
  LiveEventsQuery,
  Participant,
  RegisterParticipantForLiveEventMutation,
} from 'apps/lms-front/src/generated/graphql'

import { useEventStatus } from '../hooks/use-event-status.hook'
import { useUserEventRegistrationStatus } from '../hooks/use-user-event-registration-status.hook'
import APPROVE_LIVE_EVENT_REGISTRATION_MUTATION from '../mutations/approve-live-event-registration.graphql'
import DENY_LIVE_EVENT_REGISTRATION_MUTATION from '../mutations/deny-live-event-registration.graphql'
import REGISTER_PARTICIPANT_FOR_LIVE_EVENT_MUTATION from '../mutations/register-participant-for-live-event.graphql'
import LIVE_EVENT_PARTICIPANTS_QUERY from '../queries/live-event-participants.graphql'

import {
  LargeTag,
  ParticipantDetails,
  ParticipantListItemWrapper,
  ParticipantName,
  ParticipantRegistrationDetails,
} from './ParticipantListItem.style'

export const ParticipantListItem = ({
  participant,
  event,
}: {
  participant: Participant
  event: LiveEventsQuery['fetchLiveEvents']['results'][0]
}) => {
  const { hasReachedCapacity, onlyOnline, onlyPhysical, ended } =
    useEventStatus(event)
  const {
    canRegisterSubject,
    canApprove,
    canDeny,
    registrationCancelled,
    eventCancelled,
    waiting,
    registered,
    selfRegistered,
    approved,
    denied,
    selfDenied,
  } = useUserEventRegistrationStatus(
    { ...event, my_registration: participant.registration },
    participant._id
  )

  const [denyForm] = Form.useForm()
  const [denyModalVisible, setDenyModalVisible] = useState<boolean>(false)

  const [register] = useMutation<RegisterParticipantForLiveEventMutation>(
    REGISTER_PARTICIPANT_FOR_LIVE_EVENT_MUTATION,
    {
      variables: {
        event_id: event._id,
        user_id: participant._id,
      },
      refetchQueries: [
        {
          query: LIVE_EVENT_PARTICIPANTS_QUERY,
          variables: {
            id: event._id,
          },
        },
      ],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.registered',
            message: 'Deelnemer ingeschreven',
          }),
        })
      },
    }
  )

  const [approve] = useMutation<ApproveLiveEventRegistrationMutation>(
    APPROVE_LIVE_EVENT_REGISTRATION_MUTATION,
    {
      variables: {
        event_id: event._id,
        user_id: participant._id,
      },
      refetchQueries: [
        {
          query: LIVE_EVENT_PARTICIPANTS_QUERY,
          variables: {
            id: event._id,
          },
        },
      ],
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'events.participants.confirmation.approved',
            message: 'Deelnemer goedgekeurd',
          }),
        })
      },
    }
  )

  const [deny, { loading: denying }] =
    useMutation<ApproveLiveEventRegistrationMutation>(
      DENY_LIVE_EVENT_REGISTRATION_MUTATION,
      {
        variables: {
          event_id: event._id,
          user_id: participant._id,
        },
        refetchQueries: [
          {
            query: LIVE_EVENT_PARTICIPANTS_QUERY,
            variables: {
              id: event._id,
            },
          },
        ],
        onCompleted: () => {
          setDenyModalVisible(false)
          denyForm.resetFields()
          notification.success({
            message: t({
              id: 'events.participants.confirmation.denied',
              message: 'Deelnemer afgewezen',
            }),
          })
        },
      }
    )

  const dropDownMenuItems = [
    canRegisterSubject && {
      key: 'undo',
      icon: <UndoOutlined />,
      label: (
        <Trans id="events.participants.reregister">Terug inschrijven</Trans>
      ),
      onClick: () =>
        register({
          variables: {
            type: participant.registration.type,
          },
        }),
    },
    participant.registration.type === EventLocationType.Physical &&
      !onlyPhysical &&
      !registrationCancelled &&
      !ended && {
        key: 'switch-location-to-online',
        icon: <GlobalOutlined />,
        label: (
          <Trans id="events.participants.switch-location-to-online">
            Verander naar &quot;online&quot;
          </Trans>
        ),
        onClick: () =>
          register({
            variables: {
              type: EventLocationType.Online,
            },
          }),
      },
    participant.registration.type === EventLocationType.Online &&
      !onlyOnline &&
      !registrationCancelled &&
      !ended &&
      !hasReachedCapacity && {
        key: 'switch-location-to-physical',
        icon: <FundProjectionScreenOutlined />,
        label: (
          <Trans id="events.participants.switch-location-to-physical">
            Verander naar &quot;fysiek&quot;
          </Trans>
        ),
        onClick: () =>
          register({
            variables: {
              type: EventLocationType.Physical,
            },
          }),
      },
    canApprove && {
      disabled:
        hasReachedCapacity &&
        participant.registration.type === EventLocationType.Physical,
      key: 'approve',
      icon: <CheckOutlined />,
      label: <Trans id="events.participants.approve">Goedkeuren</Trans>,
      onClick: () => approve(),
    },
    canDeny && {
      key: 'deny',
      icon: <CloseOutlined />,
      label: <Trans id="events.participants.deny">Afwijzen</Trans>,
      onClick: () => setDenyModalVisible(true),
    },
  ].filter(Boolean)

  const showOnlineIcon =
    participant.registration.type === EventLocationType.Online && !onlyOnline
  const showPhysicalIcon =
    event.location_type.length > 1 &&
    participant.registration.type === EventLocationType.Physical

  return (
    <ParticipantListItemWrapper>
      <div>
        <Avatar
          src={participant.picture?.url}
          icon={participant.firstName.slice(0, 1).toUpperCase()}
        />
      </div>
      <ParticipantDetails>
        <div>
          <ParticipantName
            style={{
              textDecoration: participant.registration.denied
                ? 'line-through'
                : 'none',
            }}
          >
            <Space>
              <span>
                {participant.firstName} {participant.lastName}
              </span>
              {showOnlineIcon && (
                <Tooltip title="Online">
                  <GlobalOutlined />
                </Tooltip>
              )}
              {showPhysicalIcon && (
                <Tooltip title="Fysiek">
                  <FundProjectionScreenOutlined />
                </Tooltip>
              )}
            </Space>
          </ParticipantName>
          <ParticipantRegistrationDetails>
            {registrationCancelled && (
              <Trans id="events.participants.cancelled_details">
                Uitgeschreven op
                {' ' +
                  `${dayjs(participant.registration.cancelled).format(
                    'DD/MM/YYYY'
                  )} `}
              </Trans>
            )}
            {registered && (
              <>
                <Trans id="events.participants.registration_details">
                  Ingeschreven op
                  {' ' +
                    `${dayjs(participant.registration.created).format(
                      'DD/MM/YYYY'
                    )} `}
                </Trans>{' '}
                {selfRegistered ? null : (
                  <Trans id="events.participants.registration_details.by">
                    door
                    {' ' +
                      `${participant.registration.created_by.firstName} ${participant.registration.created_by.lastName}`}
                  </Trans>
                )}
              </>
            )}
            {denied ? (
              <>
                <Trans id="events.participants.denied_details">
                  Afgewezen op
                </Trans>
                {' ' +
                  `${dayjs(participant.registration.denied).format(
                    'DD/MM/YYYY'
                  )} `}
                {selfDenied ? null : (
                  <Trans id="events.participants.denied_details.by">
                    door
                    {' ' +
                      `${participant.registration.denied_by?.firstName} ${participant.registration.denied_by?.lastName}`}
                  </Trans>
                )}
              </>
            ) : null}
          </ParticipantRegistrationDetails>
        </div>
        <div style={{ display: 'flex' }}>
          <Space>
            {registrationCancelled && (
              <Tooltip title={participant.registration.cancellation_reason}>
                <LargeTag>
                  <Trans id="events.participants.unregistered">
                    <CloseOutlined />
                    Uitgeschreven
                  </Trans>
                </LargeTag>
              </Tooltip>
            )}
            {approved && (
              <LargeTag>
                <Trans id="events.participants.approved">
                  <CheckOutlined />
                  Goedgekeurd
                </Trans>
              </LargeTag>
            )}
            {denied && (
              <Tooltip title={participant.registration.denial_reason}>
                <LargeTag>
                  <Trans id="events.participants.denied">
                    <CloseOutlined />
                    Afgewezen
                  </Trans>
                </LargeTag>
              </Tooltip>
            )}
            {waiting && (
              <Space>
                <LargeTag>
                  <Trans id="events.participants.waiting">
                    <AlertOutlined />
                    Wacht op goedkeuring
                  </Trans>
                </LargeTag>
                {canApprove && (
                  <Tooltip
                    title={
                      <Trans id="events.participants.approve">Goedkeuren</Trans>
                    }
                  >
                    <Button
                      shape="circle"
                      icon={<CheckOutlined />}
                      size="small"
                      disabled={
                        hasReachedCapacity &&
                        participant.registration.type ===
                          EventLocationType.Physical
                      }
                      onClick={() => approve()}
                    />
                  </Tooltip>
                )}
                {canDeny && (
                  <Tooltip
                    title={
                      <Trans id="events.participants.deny">Afwijzen</Trans>
                    }
                  >
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => setDenyModalVisible(true)}
                    />
                  </Tooltip>
                )}
              </Space>
            )}
            {!eventCancelled && dropDownMenuItems.length > 0 && (
              <Dropdown
                menu={{ items: dropDownMenuItems as MenuProps['items'] }}
              >
                <Button type={'text'} icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
          </Space>
        </div>
      </ParticipantDetails>
      <Modal
        open={denyModalVisible}
        onCancel={() => setDenyModalVisible(false)}
        closable={false}
        maskClosable={false}
        centered={true}
        bodyStyle={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
        onOk={() => denyForm.submit()}
        okText={<Trans id="action.deny">Afwijzen</Trans>}
        okButtonProps={{ loading: denying }}
      >
        <Form
          form={denyForm}
          onFinish={({ reason }) =>
            deny({
              variables: { reason },
            })
          }
        >
          <Form.Item
            label={
              <Trans id={'events.participants.deny_reason'}>
                Reden voor afkeuring
              </Trans>
            }
            name="reason"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </ParticipantListItemWrapper>
  )
}
