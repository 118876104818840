import {
  BookOutlined,
  CalendarOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  MoreOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { t, Trans } from '@lingui/macro'
import { Button, Dropdown, notification, Popconfirm, Space, Tag } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useCopyToClipboard } from 'usehooks-ts'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  PathQuery,
  StepCategory,
  StepUnitKind,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../auth/components/Can'
import { errorNotifierFn } from '../../shared/helpers/error-notifier'
import { usePath } from '../contexts/PathContext'
import { useClipboardCheck } from '../hooks/use-clipboard-check.hook'
import { useClipboardPaste } from '../hooks/use-clipboard-paste.hook'
import DELETE_PATH_STEP from '../mutations/delete-path-step.graphql'
import {
  Block,
  BlockDetails,
  BlockSubtitle,
  BlockTitle,
} from '../pages/LearningPathTimeline.style'

const OptionalTag = () => (
  <Tag>
    <Trans id="paths.detail.timeline.optional">Optioneel</Trans>
  </Tag>
)

const getLinkUrl = (
  step: NonNullable<
    PathQuery['fetchLearningPathById']['modules']
  >[0]['steps'][0]
) => {
  return step.linked_unit_id
    ? `./unit/${step.linked_unit_id}`
    : 'linked_event_id' in step
    ? step.linked_event_id
      ? `/calendar?event=${step.linked_event_id}&date=${step.linked_event?.start}`
      : step.custom_live_event
      ? `/calendar?event=${step.custom_live_event}&date=${step.custom_event?.start}`
      : ''
    : ''
}

export const StepCard = ({
  step,
  ghost,
  sortMode,
  onEdit,
}: {
  step: NonNullable<
    PathQuery['fetchLearningPathById']['modules']
  >[0]['steps'][0]
  ghost?: boolean
  sortMode?: boolean
  onEdit?: () => void
}) => {
  const { isTodayBeforeAvailabilityDate } = usePath()
  const navigate = useNavigate()
  const [, copy] = useCopyToClipboard()
  const link = getLinkUrl(step)

  const { canPaste, checkClipboard } = useClipboardCheck()

  const handlePaste = useClipboardPaste(step.module_id, ['path'], step._id)

  const handleCopy = (step) => {
    copy(
      JSON.stringify({
        id: step._id,
        type: 'PathStep',
      })
    ).then(() => {
      checkClipboard()
    })
  }

  const [deleteStep] = useMutation(DELETE_PATH_STEP, {
    refetchQueries: ['path'],
  })

  const handleDelete = async (step) => {
    if (step?._id) {
      await deleteStep({
        variables: { step_id: step._id },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'step.event.delete.success',
              message: 'Groepssessie succesvol verwijderd',
            }),
          })
        })
        .catch(errorNotifierFn)
    }
  }

  return (
    <Block
      style={{
        ...(ghost ? { opacity: 0.4, border: '2px dashed #ccc' } : {}),
        ...(link &&
        !sortMode &&
        !isTodayBeforeAvailabilityDate(step?.available_from)
          ? { cursor: 'pointer' }
          : {}),
      }}
      onClick={
        link &&
        !sortMode &&
        !isTodayBeforeAvailabilityDate(step?.available_from)
          ? () => navigate(link)
          : undefined
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <BlockSubtitle>
            <Space>
              {renderStepCategory(step.category)}
              {renderStepUnitKind(step.unit_kind)}
            </Space>
          </BlockSubtitle>
          <BlockTitle>
            <Space>
              {step.name}
              {step.optional && <OptionalTag />}
            </Space>
          </BlockTitle>
          {(step.deadline || step.duration || step.available_from) &&
            !sortMode && (
              <BlockDetails>
                {!!step.duration && (
                  <span>
                    {step.duration}{' '}
                    {t({
                      id: 'time.minutes',
                      message: 'minuten',
                    })}
                  </span>
                )}
                {!!step.available_from && (
                  <span>
                    <Trans id="paths.detail.timeline.available_from">
                      Beschikbaar vanaf
                    </Trans>
                    {`: ${dayjs(step.available_from).format('DD MMM YYYY')}`}
                  </span>
                )}
                {!!step.deadline && (
                  <span>
                    <Trans id="paths.detail.timeline.deadline">Deadline</Trans>
                    {`: ${dayjs(step.deadline).format('DD MMM YYYY')}`}
                  </span>
                )}
              </BlockDetails>
            )}
        </div>
        {!sortMode && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={(e) => e.stopPropagation()}>
            <Can
              I={PermissionAction.UPDATE}
              a={PermissionObjectType.BRANCH_LEARNING_PATH}
            >
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => {
                  onEdit?.()
                }}
              />
              <Popconfirm
                key="delete"
                title={
                  <Trans id="path.step.delete.confirm">
                    Weet je zeker dat je deze stap wilt verwijderen? Deze actie
                    kan niet ongedaan worden gemaakt.
                  </Trans>
                }
                okText={<Trans id="action.yes">Ja</Trans>}
                cancelText={<Trans id="action.no">Nee</Trans>}
                onConfirm={() => handleDelete(step)}
                placement="topRight"
              >
                <Button type="text" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Dropdown
                onOpenChange={() => checkClipboard()}
                menu={{
                  items: [
                    {
                      key: 'copy',
                      label: (
                        <Trans id="paths.detail.timeline.copy">Kopiëren</Trans>
                      ),
                      icon: <CopyOutlined />,
                      onClick: () => handleCopy(step),
                    },
                    {
                      key: 'paste',
                      disabled: !canPaste,
                      label: (
                        <Trans id="paths.detail.timeline.paste">Plakken</Trans>
                      ),
                      icon: <SnippetsOutlined />,
                      onClick: handlePaste,
                    },
                  ],
                }}
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </Can>
          </div>
        )}
      </div>
    </Block>
  )
}

const renderStepCategory = (category: StepCategory | undefined) => {
  switch (category) {
    case StepCategory.SelfStudyStep: {
      return (
        <Space size="small">
          <BookOutlined />
          <Trans id="paths.detail.timeline.self_study">Zelfstudie</Trans>
        </Space>
      )
    }
    case StepCategory.AssignmentStep: {
      return (
        <Space size="small">
          <FormOutlined />
          <Trans id="paths.detail.timeline.assignment">Opdracht</Trans>
        </Space>
      )
    }
    case StepCategory.EventStep: {
      return (
        <Space size="small">
          <CalendarOutlined />
          <Trans id="paths.detail.timeline.event">Groepssessie</Trans>
        </Space>
      )
    }
    default: {
      return category
    }
  }
}

const renderStepUnitKind = (unit_kind: StepUnitKind | undefined | null) => {
  if (!unit_kind) return null
  switch (unit_kind) {
    case 'ContentUnit': {
      return (
        <Space size="small">
          &bull;
          <Trans id="paths.detail.timeline.content_unit">Tekst</Trans>
        </Space>
      )
    }
    case 'PdfUnit': {
      return (
        <Space size="small">
          &bull;
          <Trans id="paths.detail.timeline.pdf_unit">PDF</Trans>
        </Space>
      )
    }
    case 'VideoUnit': {
      return (
        <Space size="small">
          &bull;
          <Trans id="paths.detail.timeline.video_unit">Video</Trans>
        </Space>
      )
    }
    case 'QuizUnit': {
      return (
        <Space size="small">
          &bull;
          <Trans id="paths.detail.timeline.quiz_unit">Toets</Trans>
        </Space>
      )
    }
    case 'SurveyUnit': {
      return (
        <Space size="small">
          &bull;
          <Trans id="paths.detail.timeline.survey_unit">Vragenlijst</Trans>
        </Space>
      )
    }
    default: {
      return null
    }
  }
}
