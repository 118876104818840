import { useMutation, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import { Form, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { memo } from 'react'

import {
  FieldParent,
  FieldsQuery,
  HierarchySectionQuery,
  UpdateHierarchySectionMutation,
} from 'apps/lms-front/src/generated/graphql'

import { CustomFieldsFormSection } from '../../../shared/components/custom-fields-form-section/CustomFieldsFormSection'

import FIELDS_QUERY from './../../../settings/queries/fields.graphql'
import UPDATE_HIERARCHY_SECTION_MUTATION from './../../mutations/update-hierarchy-section.graphql'
import HIERARCHY_SECTION_QUERY from './../../queries/hierarchy-section.graphql'
const UpdateModal = ({
  section_id,
  onCancel,
  onUpdateComplete,
}: {
  section_id: string
  onCancel: () => void
  onUpdateComplete: () => void
}) => {
  const [form] = useForm()

  const { data: fields } = useQuery<FieldsQuery>(FIELDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: section } = useQuery<HierarchySectionQuery>(
    HIERARCHY_SECTION_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: section_id,
      },
      onCompleted: () => form.resetFields(),
    }
  )

  const [update, { loading }] = useMutation<UpdateHierarchySectionMutation>(
    UPDATE_HIERARCHY_SECTION_MUTATION,
    {
      onCompleted: () => {
        notification.success({
          message: t({
            id: 'modal.edit_hierarchy_section.success',
            message: 'Details succesvol opgeslagen.',
          }),
        })
        onCancel()
      },
      refetchQueries: ['hierarchySection'],
      variables: {
        id: section_id,
      },
    }
  )

  return (
    <Modal
      open={!!section_id}
      title={t({
        id: 'modal.edit_hierarchy_section.title',
        message: `${
          section?.fetchHierarchySection?.name
            ? `"${section?.fetchHierarchySection?.name}"`
            : 'Sectie'
        } bewerken`,
      })}
      centered={true}
      destroyOnClose={true}
      maskClosable={false}
      okText={t({
        id: 'action.save',
        message: 'Opslaan',
      })}
      cancelText={t({
        id: 'action.cancel',
        message: 'Annuleren',
      })}
      onCancel={onCancel}
      onOk={() => form.submit()}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        onFinish={(values) =>
          update({
            variables: {
              data: {
                ...values,
              },
            },
          }).then(() => onUpdateComplete())
        }
      >
        <CustomFieldsFormSection
          parent={FieldParent.HierarchySection}
          admin={true}
          initialValues={section?.fetchHierarchySection?.meta}
          fields={fields?.fetchFields}
        />
      </Form>
    </Modal>
  )
}

export const UpdateHierarchySectionModal = memo(UpdateModal)
