import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styled from 'styled-components'

interface Props {
  id: string
  children: React.ReactNode
  className?: string
}

function Sortable(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className={props.className}
      style={style}
      {...attributes}
      {...listeners}
    >
      {props.children}
    </div>
  )
}

export const SortableSectionItem = styled(Sortable)`
  display: block;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  cursor: move; /* fallback if grab cursor is unsupported */
`

export const SortableTimelineItem = styled(Sortable)`
  display: block;
  padding: 0.5rem 0.5rem;
  cursor: move; /* fallback if grab cursor is unsupported */
`

export const PresentationalSortableTimelineItem = styled.div`
  display: block;
  padding: 0.5rem 0.5rem;
  cursor: move; /* fallback if grab cursor is unsupported */
`
