import {
  UserOutlined,
  PlaySquareOutlined,
  UnlockOutlined,
  ApartmentOutlined,
  TeamOutlined,
  SafetyCertificateOutlined,
  TagsOutlined,
  ApiOutlined,
  ControlOutlined,
  ImportOutlined,
  BulbOutlined,
  LaptopOutlined,
} from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Layout, Menu } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ObjectId } from 'bson'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { BranchNavItemExtension } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { AbilityContext, Can } from '../../../auth/components/Can'
import { Frame } from '../../../extensions/components/frame/Frame'
import { BranchReport } from '../../../reports/pages/branch/Branch'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { BranchCategories } from '../categories/BranchCategories'
import { BranchCertificationTypes } from '../certification-types/BranchCertificationTypes'
import { BranchCourses } from '../courses/BranchCourses'
import { BranchGroups } from '../groups/BranchGroups'
import { BranchHierarchy } from '../hierarchy/Hierarchy'
import { ImportFlow } from '../import/Import'
import { Jobs } from '../jobs/Jobs'
import { Organisation } from '../organisation/Organisation'
import { BranchRoles } from '../roles/BranchRoles'
import { BranchRules } from '../rules/BranchRules'
import { ConfigureSoftware } from '../software/ConfigureSoftware'
import { BranchUsers } from '../users/BranchUsers'

export const Branch = ({ setPageTitle }: PageProps) => {
  const { section: defaultSection } = useParams()
  const [section, setSection] = useState<string | undefined>(defaultSection)
  const { user } = useAuth()
  const branch = useBranch()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  const extensions = useMemo(() => {
    return (
      branch?.extensions?.filter((extension) => {
        return (
          extension.roles.length === 0 ||
          extension.roles.some(
            (role_id) =>
              user?.roles.some((r) => new ObjectId(r.role_id).equals(role_id))
          )
        )
      }) || []
    )
  }, [branch, user?.roles])

  const loadSection = useCallback(
    (section: string) => {
      navigate('/branch/' + section)
      setSection(section)
    },
    [navigate, setSection]
  )

  /**
   * Redirect to the right page where you have access for
   */
  useEffect(() => {
    if (!section) {
      if (
        ability.can(PermissionAction.READ, PermissionObjectType.BRANCH_USER)
      ) {
        return loadSection('users')
      } else if (
        ability.can(
          PermissionAction.UPDATE,
          PermissionObjectType.BRANCH_USER_GROUP
        )
      ) {
        return loadSection('groups')
      } else if (
        ability.can(
          PermissionAction.READ,
          PermissionObjectType.BRANCH_COURSE_ATTRIBUTES
        )
      ) {
        return loadSection('categories')
      } else if (
        ability.can(
          PermissionAction.UPDATE,
          PermissionObjectType.BRANCH_COURSE_CATEGORY
        )
      ) {
        return loadSection('categories')
      } else if (
        ability.can(PermissionAction.UPDATE, PermissionObjectType.BRANCH_ROLE)
      ) {
        return loadSection('roles')
      } else if (
        ability.can(
          PermissionAction.UPDATE,
          PermissionObjectType.BRANCH_CERTIFICATION_TYPE
        )
      ) {
        return loadSection('certification-types')
      } else if (
        ability.can(
          PermissionAction.IMPORT,
          PermissionObjectType.BRANCH_HIERARCHY
        )
      ) {
        return loadSection('import')
      } else if (
        ability.can(
          PermissionAction.READ,
          PermissionObjectType.BRANCH_JOB_OPPORTUNITY
        )
      ) {
        return loadSection('jobs')
      }
    }
  }, [section, loadSection, ability])

  const items: ItemType[] = [
    {
      label: t({
        id: 'branch.users.heading',
        message: 'Gebruikers',
      }),
      key: 'users',
      icon: <UserOutlined />,
      disabled: !ability.can(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_USER
      ),
    },
    {
      label: t({
        id: 'branch.organisation.heading',
        message: 'Organisatie',
      }),
      key: 'organisation',
      icon: <ApartmentOutlined />,
      disabled: ability.cannot(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_HIERARCHY
      ),
    },
    {
      label: t({
        id: 'branch.groups.heading',
        message: 'Groepen',
      }),
      key: 'groups',
      icon: <TeamOutlined />,
      disabled: !ability.can(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_USER_GROUP
      ),
    },
    {
      label: t({
        id: 'branch.roles.heading',
        message: 'Gebruikersrollen',
      }),
      key: 'roles',
      icon: <UnlockOutlined />,
      disabled: ability.cannot(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_ROLE
      ),
    },
    {
      label: t({
        id: 'branch.certification_types.heading',
        message: 'Certificeringstypes',
      }),
      key: 'certification-types',
      icon: <SafetyCertificateOutlined />,
      disabled: ability.cannot(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_CERTIFICATION_TYPE
      ),
    },
    {
      label: t({
        id: 'branch.courses.heading',
        message: 'Opleidingen',
      }),
      key: 'courses',
      icon: <PlaySquareOutlined />,
      disabled: !ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_COURSE_ATTRIBUTES
      ),
    },
    {
      label: t({
        id: 'branch.categories.heading',
        message: 'Categorieën',
      }),
      key: 'categories',
      icon: <TagsOutlined />,
      disabled: !ability.can(
        PermissionAction.UPDATE,
        PermissionObjectType.BRANCH_COURSE_CATEGORY
      ),
    },
    {
      label: t({
        id: 'branch.rules.heading',
        message: 'Regels',
      }),
      key: 'rules',
      icon: <ControlOutlined />,
      disabled: !ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_RULE
      ),
    },
    {
      label: t({
        id: 'branch.jobs.heading',
        message: 'Vacatures',
      }),
      key: 'jobs',
      icon: <BulbOutlined />,
      disabled: !ability.can(
        PermissionAction.READ,
        PermissionObjectType.BRANCH_JOB_OPPORTUNITY
      ),
    },
    {
      label: t({
        id: 'branch.software.heading',
        message: 'Software & more',
      }),
      key: 'software',
      icon: <LaptopOutlined />,
      disabled: !ability.can(
        PermissionAction.CONFIGURE,
        PermissionObjectType.BRANCH_SOFTWARE
      ),
    },
    {
      label: t({
        id: 'branch.import.heading',
        message: 'Importeren',
      }),
      key: 'import',
      icon: <ImportOutlined />,
      disabled: !ability.can(
        PermissionAction.IMPORT,
        PermissionObjectType.BRANCH_HIERARCHY
      ),
    },
  ]

  extensions.forEach((extension) => {
    if (extension.__typename === 'BranchNavItemExtension') {
      items.push({
        label: extension.label,
        key: `x_${extension.key}`,
        icon: <ApiOutlined />,
      })
    }
  })

  return (
    <Layout hasSider>
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth={0}
        theme="light"
        width={300}
        style={{
          background: 'var(--ant-primary-1)',
          padding: '32px 0 32px 32px',
        }}
      >
        <Menu
          className="branch-menu"
          selectedKeys={section ? [section] : []}
          onClick={(item) => loadSection(item.key)}
          style={{ background: 'none', fontSize: 18, fontWeight: 700 }}
          mode="inline"
          items={items.filter((i) =>
            i && 'disabled' in i ? !i.disabled : true
          )}
        />
      </Layout.Sider>
      <Layout>
        <Content
          style={{
            display: 'block',
            background: '#FFF',
            padding:
              section === 'report'
                ? 8
                : section?.startsWith('x_') ||
                  section === 'hierarchy' ||
                  section === 'organisation'
                ? 0
                : 32,
            minHeight: 'calc(100vh - 72px)',
          }}
        >
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.BRANCH_USER}>
            {section === 'users' && <BranchUsers />}
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_USER_GROUP}
          >
            {section === 'groups' && <BranchGroups />}
          </Can>
          <Can
            I={PermissionAction.READ}
            a={PermissionObjectType.BRANCH_COURSE_ATTRIBUTES}
          >
            {section === 'courses' && <BranchCourses />}
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_COURSE_CATEGORY}
          >
            {section === 'categories' && <BranchCategories />}
          </Can>
          <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH_REPORT}>
            {section === 'report' && <BranchReport />}
          </Can>
          <Can
            I={PermissionAction.READ}
            a={PermissionObjectType.BRANCH_HIERARCHY}
          >
            {section === 'hierarchy' && (
              <BranchHierarchy
                onNavigate={(section: string) => setSection(section)}
              />
            )}
          </Can>
          <Can
            I={PermissionAction.READ}
            a={PermissionObjectType.BRANCH_HIERARCHY}
          >
            {section === 'organisation' && (
              <Organisation
                onNavigate={(section: string) => setSection(section)}
              />
            )}
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.BRANCH_ROLE}>
            {section === 'roles' && <BranchRoles />}
          </Can>
          <Can
            I={PermissionAction.UPDATE}
            a={PermissionObjectType.BRANCH_CERTIFICATION_TYPE}
          >
            {section === 'certification-types' && <BranchCertificationTypes />}
          </Can>
          <Can I={PermissionAction.READ} a={PermissionObjectType.BRANCH_RULE}>
            {section === 'rules' && <BranchRules />}
          </Can>
          <Can
            I={PermissionAction.IMPORT}
            a={PermissionObjectType.BRANCH_HIERARCHY}
          >
            {section === 'import' && <ImportFlow />}
          </Can>
          <Can
            I={PermissionAction.READ}
            a={PermissionObjectType.BRANCH_JOB_OPPORTUNITY}
          >
            {section === 'jobs' && <Jobs />}
          </Can>
          <Can
            I={PermissionAction.CONFIGURE}
            a={PermissionObjectType.BRANCH_SOFTWARE}
          >
            {section === 'software' && <ConfigureSoftware />}
          </Can>
          {extensions.map((extension) => {
            if (extension.__typename === 'BranchNavItemExtension') {
              return (
                section === `x_${extension.key}` && (
                  <Frame
                    key={extension.key}
                    extension={extension as BranchNavItemExtension}
                    setPageTitle={setPageTitle}
                  />
                )
              )
            }
            return null
          })}
        </Content>
      </Layout>
    </Layout>
  )
}
