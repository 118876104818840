import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import {
  NavItemExtension,
  BranchNavItemExtension,
} from 'apps/lms-front/src/generated/graphql'

interface FrameProps {
  extension: NavItemExtension | BranchNavItemExtension
  setPageTitle?: (title: string) => void
  path?: string
}

export const Frame = ({ extension, setPageTitle, path }: FrameProps) => {
  const navigate = useNavigate()
  const frame = useRef<HTMLIFrameElement | null>(null)

  const host = extension.url.match(
    /^((https?|ftp):\/)?\/?([^\s/:]+)((\/\w+)*\/)([\w.-]+[^\s#?]+)(.*)?(#[\w-]+)?$/
  )?.[3]

  useEffect(() => {
    const iframe = frame.current

    const handleFrameLoad = () => {
      if (extension && 'label' in extension) {
        setPageTitle?.(extension.label)
      }
    }

    iframe?.addEventListener('load', handleFrameLoad)

    return () => iframe?.removeEventListener('load', handleFrameLoad)
  }, [extension, setPageTitle, frame])

  useEffect(() => {
    const handleFrameMessage = (message: MessageEvent) => {
      if (message.data?.includes?.('navigate:')) {
        navigate(
          message.data.replace('navigate:', '').replace('X_SLUG', extension.key)
        )
      }
    }

    window?.addEventListener('message', handleFrameMessage)

    return () => window.removeEventListener('message', handleFrameMessage)
  }, [navigate])

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={
            host
              ? `frame-src ${host} https://www.youtube.com https://www.youtube-nocookie.com https://player.vimeo.com http://localhost:8000;`
              : 'frame-src https://www.youtube.com https://www.youtube-nocookie.com https://player.vimeo.com http://localhost:8000;'
          }
        />
      </Helmet>
      <iframe
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        /* eslint-disable-line react/no-unknown-property */ allowtransparency="true"
        ref={frame}
        allow=""
        referrerPolicy="origin"
        frameBorder="0"
        title={extension.label}
        src={`${extension.url}${path || ''}${
          extension.authenticated
            ? `?token=${encodeURIComponent(
                localStorage.getItem('aa_lms_at') || ''
              )}&host=${encodeURIComponent(
                window.location.host
              )}&hostname=${encodeURIComponent(window.location.hostname)}`
            : ``
        }`}
        style={{
          display: 'block',
          height: 'calc(100vh - 72px)',
          width: '100%',
        }}
      ></iframe>
    </>
  )
}
