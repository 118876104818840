/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-useless-undefined */
import { DeleteOutlined, EditOutlined, DownOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  ChannelsQuery,
  CreateChannelMutation,
  DeleteChannelMutation,
  UpdateChannelMutation,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { dateSort, defaultSort } from '../../../shared/utils/sort'
import CREATE_CHANNEL_MUTATION from '../../mutations/create-channel.graphql'
import DELETE_CHANNEL_MUTATION from '../../mutations/delete-channel.graphql'
import UPDATE_CHANNEL_MUTATION from '../../mutations/update-channel.graphql'
import CHANNELS_QUERY from '../../queries/channels.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface ChannelRow {
  key: string
  name: string
  created?: Date
}

export const Channels = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [channelModalVisible, setChannelModalVisible] = useState(false)
  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<ChannelsQuery['fetchChannels'][0]>()
  const [form] = useForm()

  const { data, loading } = useQuery<ChannelsQuery>(CHANNELS_QUERY)

  const [createChannel, { loading: creating }] =
    useMutation<CreateChannelMutation>(CREATE_CHANNEL_MUTATION)

  const [updateChannel, { loading: updating }] =
    useMutation<UpdateChannelMutation>(UPDATE_CHANNEL_MUTATION)

  const [deleteChannel, { loading: deleting }] =
    useMutation<DeleteChannelMutation>(DELETE_CHANNEL_MUTATION)

  useEffect(() => {
    if (updateSubject) {
      setChannelModalVisible(true)
    }
  }, [updateSubject])

  useEffect(() => {
    if (channelModalVisible) form.resetFields()
  }, [channelModalVisible, form])

  const channels = useMemo(() => {
    const fuse = new Fuse(data?.fetchChannels || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchChannels || []
    ).map<ChannelRow>((item: ChannelsQuery['fetchChannels'][0]) => ({
      key: item._id,
      name: item.name,
      created: item.created,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<ChannelRow> = [
    {
      title: t({
        id: 'settings.channels.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.channels.table.created',
        message: 'Aangemaakt',
      }),
      dataIndex: 'created',
      key: 'created',
      sorter: dateSort('created') as CompareFn<unknown>,
      render: (text: string) => dayjs(text).tz('Europe/Brussels').fromNow(),
    },
    {
      title: t({
        id: 'settings.channels.table.actions',
        message: 'Acties',
      }),
      key: 'operation',
      fixed: 'right',
      width: 110,
      render: (_, record: ChannelRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.CHANNEL}>
            <Tooltip title="Bewerken">
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchChannels.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.CHANNEL}>
            <Tooltip title={'Verwijderen'}>
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.channels.action.delete.confirm',
                  message:
                    'Ben je zeker dat je deze kennisbron wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'action.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'action.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteChannel({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['channels'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.channels.action.delete.success',
                          message: 'Kennisbron succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createChannel({
        variables: {
          ...fields,
        },
        refetchQueries: ['channels'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.channels.action.create.success',
              message: 'Kennisbron succesvol aangemaakt',
            }),
          })
          setChannelModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateChannel({
        variables: {
          id: updateSubject?._id,
          ...fields,
        },
        refetchQueries: ['channels'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.channels.action.update.success',
              message: 'Kennisbron succesvol gewijzigd',
            }),
          })
          setChannelModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.channels.title',
          message: 'Kennisbronnen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.channels.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.CHANNEL}
          >
            <DropdownButton
              icon={<DownOutlined />}
              onClick={() => setChannelModalVisible(true)}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-channels',
                    label: t({
                      id: 'settings.channels.action.import',
                      message: 'Kennisbronnen importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="settings.channels.action.create">
                Kennisbron aanmaken
              </Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.channels.table.empty',
            message: 'Geen kennisbronnen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),
          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={channels}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.channels.action.update.title',
                message: 'Kennisbron wijzigen',
              })
            : t({
                id: 'settings.channels.action.create.title',
                message: 'Kennisbron aanmaken',
              })
        }
        open={channelModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setChannelModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.channels.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.channels.form.validation.name',
                  message: 'Gelieve een naam in te vullen.',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="action.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
    </>
  )
}
