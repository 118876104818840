import { TourProvider as ReacTourProvider, StepType } from '@reactour/tour'

import { useTourSteps } from './use-tour-steps'

export const TourProvider = ({ children }: { children: React.ReactNode }) => {
  const steps = useTourSteps()

  return (
    <ReacTourProvider
      steps={steps.filter(Boolean) as StepType[]}
      onClickMask={() => false}
      styles={{
        popover: (base) => ({
          ...base,
          padding: 25,
          borderRadius: 5,
        }),
        close: (base) => ({
          ...base,
          top: 16,
          right: 16,
        }),
        badge: (base) => ({
          ...base,
          display: 'none',
        }),
        dot: (base) => ({
          ...base,
          backgroundColor: 'var(--primary-color)',
        }),
      }}
    >
      {children}
    </ReacTourProvider>
  )
}
