import { useMutation } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Checkbox as AntCheckbox, Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { AcceptTermsMutation } from 'apps/lms-front/src/generated/graphql'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'

import ACCEPT_TERMS_MUTATION from './../mutations/accept-terms.graphql'

export const TermsConditions = () => {
  const user = useAuth()
  const { i18n } = useLingui()

  const [accept, { loading }] = useMutation<AcceptTermsMutation>(
    ACCEPT_TERMS_MUTATION
  )
  const [form] = useForm()

  return (
    <Modal
      key={i18n.locale}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      title={
        <>
          {t({
            id: 'modal.terms_conditions.title',
            message: 'Accepteer onze voorwaarden om verder te gaan',
          })}
        </>
      }
      open={
        !!user.user &&
        // (
        !user.user.acceptedTerms
        /*||
          dayjs(user.user.acceptedTerms).isBefore(
            dayjs('21-01-2024', 'DD-MM-YYYY')
          ))
        )*/
      }
      closable={false}
      maskClosable={false}
      okText={t({
        id: 'action.accept',
        message: 'Accepteer',
      })}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      cancelButtonProps={{ hidden: true }}
      width={640}
    >
      <Form
        form={form}
        onFinish={async () => {
          try {
            const fields = await form.validateFields()
            if (fields.agree) {
              accept()
            } else {
              form.setFieldValue('agree', null)
              await form.validateFields()
            }
          } catch (error) {
            console.error(error)
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          name="agree"
          valuePropName="checked"
          style={{ marginBottom: 48 }}
          rules={[
            {
              required: true,
              message: t({
                id: 'modal.terms_conditions.error',
                message:
                  'Gelieve de voorwaarden te accepteren door de checkbox aan te vinken.',
              }),
            },
          ]}
        >
          <AntCheckbox className="terms-conditions-checkbox">
            <Trans id="modal.terms_conditions.permission">
              Ik verklaar mij akkoord met de{' '}
              <a
                href="https://accountantsacademy.be/algemene-voorwaarden"
                target="_blank"
                rel="noreferrer"
              >
                Algemene Voorwaarden
              </a>{' '}
              van deze e-learning tool.
            </Trans>
          </AntCheckbox>
        </Form.Item>
      </Form>
      <p>
        <Trans id="modal.terms_conditions.instructions">
          Vink de checkbox aan en klik op de knop &quot;
          <strong>Accepteer</strong>&quot; om verder te gaan.
        </Trans>{' '}
        <Trans id="modal.terms_conditions.dpo">
          Bij vragen kan u terecht bij de externe gegevensverantwoordelijke:
        </Trans>{' '}
        {import.meta.env.NX_DPO_NAME || 'Cédric Vercamer'} (
        <a
          href={`mailto:${
            import.meta.env.NX_DPO_EMAIL ||
            import.meta.env.NX_ADMIN_EMAIL ||
            'cedric@accountantsacademy.be'
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {import.meta.env.NX_DPO_EMAIL ||
            import.meta.env.NX_ADMIN_EMAIL ||
            'cedric@accountantsacademy.be'}
        </a>
        )
      </p>
    </Modal>
  )
}
