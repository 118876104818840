import { t } from '@lingui/macro'
import { ReactNode, createContext, useState } from 'react'

export const TitleContext = createContext({
  title: '',
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-empty-function
  setTitle: (_: string) => {},
})

export const TitleProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState(
    t({ id: 'action.loading', message: 'Aan het laden' })
  )

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  )
}
