import { CheckOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Col, Menu, Row } from 'antd'
import { ObjectId } from 'bson'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { UnitKind } from '@lms-shared-patterns/models'
import {
  ContentUnit,
  MyCourseActivityQuery,
  PdfUnit,
  PublicUnitQuery,
  Unit,
} from 'apps/lms-front/src/generated/graphql'
import { Container } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

import { LoadScreen, LoadSection } from '../../../core/components/LoadScreen'
import { getParentRoute } from '../../../core/routes/router'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { logActivity } from '../../../shared/helpers/log-activity'
import { PageProps } from '../../../shared/interfaces/page.interface'
import { Header } from '../../components/header'

import MY_ACTIVITY_QUERY from './../../../user/queries/my-course-activity.graphql'
import PUBLIC_UNIT_QUERY from './../../queries/public-unit.graphql'
import { ContentUnitViewer } from './content-unit-viewer/ContentUnitViewer'
import { PDFUnitViewer } from './pdf-unit-viewer/PdfUnitViewer'
import {
  QuizRemoteUnitViewer,
  QuizUnitViewer,
} from './quiz-unit-viewer/QuizUnitViewer'
import { CourseCompletionModal } from './shared-modals/CourseCompletionModal'
import {
  SurveyRemoteUnitViewer,
  SurveyUnitViewer,
} from './survey-unit-viewer/SurveyUnitViewer'
import { Content, SideMenu, UnitFocusLayout } from './UnitViewer.style'
import { VideoUnitViewer } from './video-unit-viewer/VideoUnitViewer'

export const UnitViewer = ({ route }: PageProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const { unit: id, certification: certification_id, event_id } = params
  const parent = getParentRoute(route, params, certification_id ? 1 : 2)

  const canNavigateToUnit = (
    sequentiality = false,
    target: Unit | undefined,
    next?: string
  ) => {
    if (!sequentiality || event_id) return true
    const isNextUnit = target?._id
      ? new ObjectId(target._id as string).equals(next || '')
      : target?._id === next
    const targetCompleted = !!target?.my_activity?.completed
    return isNextUnit || targetCompleted
  }

  const [loading, setLoading] = useState(false)

  const { data, error, refetch } = useQuery<PublicUnitQuery>(
    PUBLIC_UNIT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { id, event_id },
    }
  )

  const unit = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return data?.fetchUnitById as PublicUnitQuery['fetchUnitById'] & {
      __typename:
        | 'ContentUnit'
        | 'VideoUnit'
        | 'PDFUnit'
        | 'QuizUnit'
        | 'SurveyUnit'
    }
  }, [data])

  const course = useMemo(() => {
    return unit?.course
  }, [unit])

  const menuItems = useMemo(() => {
    if (!course) return []
    return [...(course?.translation.contents || [])]
      .sort((a, b) => a.order - b.order)
      .map((section) => {
        return {
          key: section._id,
          label: section.title,
          children: [...section.units]
            .sort((a, b) =>
              a.__typename !== 'Unit' && b.__typename !== 'Unit'
                ? a.order - b.order
                : 0
            )
            .map((u) => {
              if (u.__typename === 'ContentUnit')
                return {
                  ...u,
                  translation: u.contentUnitTranslation,
                }

              if (u.__typename === 'PDFUnit')
                return {
                  ...u,
                  translation: u.pdfUnitTranslation,
                }

              if (u.__typename === 'VideoUnit')
                return {
                  ...u,
                  translation: u.videoUnitTranslation,
                }

              if (u.__typename === 'QuizUnit')
                return {
                  ...u,
                  translation: u.quizUnitTranslation,
                }

              if (u.__typename === 'SurveyUnit')
                return {
                  ...u,
                  translation: u.surveyUnitTranslation,
                }

              return u
            })
            .map((u) => {
              const disabled = !canNavigateToUnit(
                course?.my_activity?.certification_type?.sequentiality || false,
                u as Unit,
                course?.firstUnit || undefined
              )
              if (u.__typename === 'Unit') {
                return undefined
              }
              return {
                disabled,
                key: u._id,
                label: u.translation?.name || u.name,
                icon: u.my_activity?.completed ? <CheckOutlined /> : undefined,
              }
            })
            .filter(Boolean),
        }
      })
  }, [course])

  const openMenuSections = useMemo(() => {
    return menuItems
      ?.filter((section) => section.children.find((u) => u?.key === unit?._id))
      .map((i) => i.key)
  }, [menuItems, unit])

  const navigateToUnit = useCallback(
    (id: string) => {
      setLoading(true)
      refetch({ id })
        .then(() => {
          setLoading(false)
          navigate(`./../${id}`)
        })
        .finally(() => setLoading(false))
    },
    [navigate, refetch]
  )

  const course_id =
    data?.fetchUnitById && 'course_id' in data.fetchUnitById
      ? data?.fetchUnitById.course_id
      : undefined

  const { refetch: refetchMyActivity } = useQuery<MyCourseActivityQuery>(
    MY_ACTIVITY_QUERY,
    {
      variables: { course_id },
      fetchPolicy: 'network-only',
      skip: !course_id,
    }
  )

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleUnitCompletion = useCallback(async () => {
    try {
      setLoading(true)
      const { data: latestData } = await refetchMyActivity()
      const allUnitsCompletedDate =
        latestData?.fetchMyCourseActivity?.allUnitsCompleted

      // there is a next unit and not all completed
      if (unit?.next && !allUnitsCompletedDate) {
        navigateToUnit(unit.next)
      } else {
        if (allUnitsCompletedDate) {
          const now = dayjs()
          const time = dayjs(allUnitsCompletedDate)

          // completed within 15 minutes and not in localstorage --> show modal
          if (
            now.diff(time, 'minute') <= 15 &&
            !(
              sessionStorage.getItem('aa_course_completed') ===
              unit?.course?.slug
            )
          ) {
            setSessionStorageItem(
              'aa_course_completed',
              unit?.course?.slug ?? ''
            )
            setIsModalVisible(true)
          } else {
            // completed more than 15 minutes ago --> navigate to next unit
            // or if latest page --> navigate to course
            if (unit?.next) {
              navigateToUnit(unit.next)
            } else {
              navigate(
                `${parent}/${encodeURIComponent(unit?.course?.slug || '')}`
              )
            }
          }
        } else {
          console.info('Course not yet completed')
          navigate(`${parent}/${encodeURIComponent(unit.course?.slug || '')}`)
        }
      }
    } catch (error) {
      console.error('Error handling unit completion:', error)
      // Optionally show an error message to the user
    } finally {
      setLoading(false)
    }
  }, [
    navigate,
    navigateToUnit,
    parent,
    refetchMyActivity,
    unit?.course?.slug,
    unit?.next,
  ])

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleGoToCertificates = useCallback(() => {
    setIsModalVisible(false)
    navigate(`/certificates?id=${unit?.course_id}`)
  }, [navigate, unit])

  const renderedUnit = useMemo(() => {
    if (!unit) return null

    switch (unit.__typename) {
      case UnitKind.CONTENT: {
        return (
          <ContentUnitViewer
            unit={unit as ContentUnit}
            handleUnitCompletion={handleUnitCompletion}
          />
        )
      }
      case UnitKind.VIDEO: {
        return (
          <VideoUnitViewer
            unit={unit}
            allowChangingPlaybackRate={
              !(
                course?.my_activity?.certification_type?.disable_playbackrate ||
                false
              ) || !!unit.my_activity?.completed
            }
            refetchUnit={async () => await refetch()}
            handleUnitCompletion={handleUnitCompletion}
          />
        )
      }
      case UnitKind.PDF: {
        return (
          <PDFUnitViewer
            unit={unit as PdfUnit}
            handleUnitCompletion={handleUnitCompletion}
          />
        )
      }
      case UnitKind.QUIZ: {
        return event_id ? (
          <QuizRemoteUnitViewer
            unit={unit}
            event_id={event_id}
            handleUnitCompletion={handleUnitCompletion}
          />
        ) : (
          <QuizUnitViewer
            unit={unit}
            refetchUnit={async () => await refetch()}
            handleUnitCompletion={handleUnitCompletion}
          />
        )
      }
      case UnitKind.SURVEY: {
        return event_id ? (
          <SurveyRemoteUnitViewer
            unit={unit}
            event_id={event_id}
            handleUnitCompletion={handleUnitCompletion}
          />
        ) : (
          <SurveyUnitViewer
            unit={unit}
            refetchUnit={async () => await refetch()}
            handleUnitCompletion={handleUnitCompletion}
          />
        )
      }
      default: {
        return null
      }
    }
  }, [unit, navigate, navigateToUnit])

  useEffect(() => {
    if (
      !canNavigateToUnit(
        course?.my_activity?.certification_type?.sequentiality || false,
        unit as unknown as Unit,
        course?.firstUnit || undefined
      )
    ) {
      if (course?.firstUnit) navigateToUnit(course?.firstUnit)
      else navigate('/')
    } else if (unit)
      logActivity({ unit_id: unit?._id, certification_id, event_id }).then(
        () => {
          if (certification_id) {
            navigate(parent)
          } else {
            refetch()
          }
        }
      )
  }, [
    course,
    unit,
    certification_id,
    parent,
    navigateToUnit,
    navigate,
    refetch,
    canNavigateToUnit,
    event_id,
  ])

  if (error) return <Navigate to={parent} />
  if (!unit) return <LoadScreen />

  return (
    <>
      <Helmet>
        <title>{course?.translation.name}</title>
      </Helmet>
      <Header
        title={course?.translation.name}
        onBack={() =>
          event_id
            ? navigate(`/vod-events/${encodeURIComponent(event_id)}`)
            : navigate(`${parent}/${encodeURIComponent(course?.slug || '')}`)
        }
      />
      <Container>
        <UnitFocusLayout>
          <Content style={{ flex: 1, backgroundColor: '#FFF' }}>
            <Row justify="center" style={{ flex: 1 }} gutter={24}>
              {loading ? (
                <Col md={20} flex={1}>
                  <>
                    <LoadSection />
                  </>
                </Col>
              ) : (
                <>
                  {renderedUnit}
                  <CourseCompletionModal
                    isModalVisible={isModalVisible}
                    handleModalClose={handleModalClose}
                    handleGoToCertificates={handleGoToCertificates}
                    isGroupSession={!!event_id}
                  />
                </>
              )}
            </Row>
          </Content>
          <SideMenu width="25%">
            {unit && (
              <Menu
                key={unit._id}
                mode="inline"
                defaultOpenKeys={openMenuSections}
                selectedKeys={[unit._id]}
                style={{ height: '100%' }}
                items={menuItems}
                onClick={({ key }) => navigateToUnit(key)}
              />
            )}
          </SideMenu>
        </UnitFocusLayout>
      </Container>
    </>
  )
}
