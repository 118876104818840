import { Layout, PageHeader as PH } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import styled, { AnyStyledComponent } from 'styled-components'

import { Content as LayoutContent } from 'apps/lms-front/src/modules/shared/layout/Layout.style'

export const SideMenu = styled(Sider as unknown as AnyStyledComponent)`
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const UnitFocusLayout = styled(Layout as unknown as AnyStyledComponent)`
  margin-top: 2rem;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`
export const Content = styled(LayoutContent)`
  @media screen and (min-width: 768px) {
    width: 75%;
  }
`

export const PageHeader = styled(PH as unknown as AnyStyledComponent)`
  .ant-page-header-heading {
    justify-content: center;
  }

  .ant-page-header-heading-title {
    white-space: normal;
    line-height: 150%;

    @media (max-width: 767px) {
      font-size: 1rem;
    }

    @media (min-width: 767px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1200px) {
      font-size: 1.75rem;
    }
  }
`
