/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default */
import {
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react'
import { Integration } from '@sentry/types'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { message, notification } from 'antd'
import axios from 'axios'
import 'overlayscrollbars/styles/overlayscrollbars.css'
import { posthog } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import 'slick-carousel/slick/slick.css'

import './main.css'
import { Root } from './root'

posthog.init(import.meta.env.NX_POSTHOG_TOKEN, {
  api_host: import.meta.env.NX_POSTHOG_PROXY_HOST,
  ui_host: import.meta.env.NX_POSTHOG_HOST,
})
posthog.register_for_session({
  frontend_version: APP_VERSION,
})

initSentry({
  dsn: import.meta.env.NX_SENTRY_DSN,
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    new posthog.SentryIntegration(
      posthog,
      import.meta.env.NX_SENTRY_ORG,
      import.meta.env.NX_SENTRY_DSN
        ? Number.parseInt(
            (import.meta.env.NX_SENTRY_DSN as string).split('sentry.io/')[1]
          )
        : undefined
    ) as Integration,
  ],
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  release: APP_VERSION,
  environment: APP_ENV,
  beforeSend(event) {
    return import.meta.env.DEV ? null : event
  },
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      document.dispatchEvent(new Event('token_expired'))
    }
    return error
  }
)

message.config({
  top: window.outerHeight / 2 - 100,
  duration: 2,
  maxCount: 1,
})

notification.config({
  placement: 'bottomRight',
  duration: 2,
})

const bootstrap = async () => {
  createRoot(document.querySelector('#root') as HTMLElement).render(
    <PostHogProvider client={posthog}>
      <Root />
    </PostHogProvider>
  )
}
bootstrap()
