import { AxiosRequestConfig } from 'axios'
import download from 'js-file-download'
import { WorkBook, utils, write } from 'xlsx'

import { axiosClient } from '../../shared/hooks/use-axios'

export const exporter = async ({
  url,
  params = {},
  type = 'json',
  name,
  dates,
  total,
}: {
  url: string
  params?: AxiosRequestConfig<unknown>['params']
  type: string
  name: string
  dates?: string[]
  total?: unknown[]
}): Promise<void> => {
  if (type === 'zip') {
    // Download files instead of reporting
    const { data: zip, headers: zipHeaders } = await axiosClient.get(
      `${url}/download`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
        },
        params,
        responseType: 'blob',
      }
    )

    // Get original filename from headers
    const contentDisposition = zipHeaders['content-disposition']
    const matches = /filename="([^"]+)"/.exec(contentDisposition)
    const filename = matches && matches[1] ? matches[1] : 'certificates.zip'

    // Prepare download
    const downloadUrl = URL.createObjectURL(new Blob([zip]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', filename)
    document.body.append(link)

    // Start download
    link.click()

    link.remove()
    return
  }

  const { data: parsed } = await axiosClient.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('aa_lms_at')}`,
    },
    params,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = parsed.map((item: any) => {
    for (const key of dates || []) {
      if (item[key] && !Number.isNaN(new Date(item[key])))
        item[key] = new Date(item[key])
    }
    return item
  })

  if (type === 'json') {
    return download(JSON.stringify(data), name + '.json', 'application/json')
  }

  const worksheet = utils.json_to_sheet(data)
  const workbook: WorkBook = {
    Sheets: total
      ? {
          [name]: worksheet,
          Overview: utils.json_to_sheet(total),
        }
      : {
          [name]: worksheet,
        },
    SheetNames: total ? [name, 'Overview'] : [name],
  }
  if (type === 'csv') {
    return download(utils.sheet_to_csv(worksheet), name + '.csv', 'text/csv')
  } else if (type === 'xlsx') {
    return download(
      write(workbook, { bookType: 'xlsx', type: 'array', cellDates: true }),
      name + '.xlsx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  }
}
