import { DownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import { Col, Empty, Row, Space } from 'antd'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { ArticlesQuery, Image } from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { LoadScreen } from '../../../core/components/LoadScreen'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { ArticleCard } from '../../components/article-card/ArticleCard'
import ARTICLES_QUERY from '../../queries/articles.graphql'

export const ArticleOverview = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { data } = useQuery<ArticlesQuery>(ARTICLES_QUERY, {
    variables: { filter: { query: searchTerm, limit: 9999 } },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const articles = data?.fetchArticles

  return (
    <>
      <Row>
        <div style={{ marginLeft: 'auto', marginBottom: 24 }}>
          <Space>
            <InputSearch
              key="1"
              placeholder={t({
                id: 'articles.search',
                message: 'Zoeken',
              })}
              onSearch={(value) => {
                setSearchTerm(value)
              }}
              style={{ width: 200 }}
            />
            <Can
              I={PermissionAction.CREATE}
              a={PermissionObjectType.ARTICLE}
              key="2"
            >
              <DropdownButton
                icon={<DownOutlined />}
                type="primary"
                menu={{
                  onClick: ({ key }) => navigate(key),
                  items: [
                    {
                      disabled: true,
                      key: 'import',
                      label: t({
                        id: 'articles.overview.action.import',
                        message: 'Berichten importeren',
                      }),
                    },
                  ],
                }}
              >
                <Link className={'btn-link'} to={'/articles/new'}>
                  <Trans id="articles.overview.action.create">
                    Bericht aanmaken
                  </Trans>
                </Link>
              </DropdownButton>
            </Can>
          </Space>
        </div>
      </Row>
      <Row align={'stretch'} style={{ margin: -8 }}>
        {!data && (
          <Col span={24}>
            <LoadScreen />
          </Col>
        )}
        {articles?.results.length === 0 && (
          <Col span={24}>
            <Empty
              style={{ paddingTop: 24 }}
              description={t({
                id: 'articles.empty',
                message: 'Geen artikels gevonden.',
              })}
            />
          </Col>
        )}
        {articles?.results.map((article) => (
          <Col
            key={article._id}
            span={24}
            sm={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ padding: 8 }}
          >
            <ArticleCard
              title={article.translation.title || undefined}
              date={article.published}
              time={article.readingTime}
              user={
                article.author
                  ? {
                      name: `${article.author.firstName} ${article.author.lastName}`,
                      avatar: article.author.picture?.url,
                    }
                  : { name: article.externalAuthor?.name || '' }
              }
              image={article.thumbnail as Image}
              url={`/articles/${encodeURIComponent(article.slug)}`}
            >
              {article.intro}
            </ArticleCard>
          </Col>
        ))}
      </Row>
    </>
  )
}
