import { t } from '@lingui/macro'
import { Col, PageHeader, Row, Statistic } from 'antd'
import dayjs from 'dayjs'
import quarterPlugin from 'dayjs/plugin/quarterOfYear'
import { StringParam, useQueryParam } from 'use-query-params'

import { formatDuration } from '@lms-shared-patterns/utils'
import { useAuth } from 'apps/lms-front/src/modules/auth/hooks/use-auth'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import DatePicker from '../../../shared/components/date-picker/DatePicker'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { UserCoursesReport } from '../../components/UserCoursesReport'
dayjs.extend(quarterPlugin)

export const PersonalReport = () => {
  const [start, setStart] = useQueryParam('start', StringParam)
  const [end, setEnd] = useQueryParam('end', StringParam)

  const branch = useBranch()
  const { user } = useAuth()

  const [{ data, loading }] = useAxios({
    url: `/api/activity/users/${user?._id}/general`,
    params:
      start && end
        ? {
            start,
            end,
          }
        : undefined,
  })

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.personal.title',
          message: 'Persoonlijk rapport',
        })}
        extra={
          <DatePicker.RangePicker
            ranges={{
              [t({
                id: 'datepicker.preset.last_7_days',
                message: 'Laatste 7 dagen',
              })]: [dayjs().add(-7, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.last_30_days',
                message: 'Laatste 30 dagen',
              })]: [dayjs().add(-30, 'd'), dayjs()],
              [t({
                id: 'datepicker.preset.this_week',
                message: 'Deze week',
              })]: [dayjs().startOf('week'), dayjs().endOf('week')],
              [t({
                id: 'datepicker.preset.this_month',
                message: 'Deze maand',
              })]: [dayjs().startOf('month'), dayjs().endOf('month')],
              [t({
                id: 'datepicker.preset.this_quarter',
                message: 'Dit kwartaal',
              })]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
              [t({
                id: 'datepicker.preset.this_year',
                message: 'Dit jaar',
              })]: [dayjs().startOf('year'), dayjs().endOf('year')],
            }}
            allowClear={true}
            allowEmpty={[false, false]}
            defaultValue={[
              start ? dayjs(start) : null,
              end ? dayjs(end) : null,
            ]}
            disabledDate={(current) => {
              return current && current > dayjs().endOf('day')
            }}
            onChange={(dates) => {
              if (dates) {
                setStart(dates[0]?.toISOString())
                setEnd(dates[1]?.toISOString())
              } else {
                setStart(undefined)
                setEnd(undefined)
              }
            }}
            format="DD/MM/YYYY"
          />
        }
      ></PageHeader>
      <Content>
        <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.personal.in_progress',
                message: 'Actieve opleidingen',
              })}
              value={data?.in_progress || 0}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.personal.completed',
                message: 'Voltooide opleidingen',
              })}
              value={data?.completed || 0}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.personal.total',
                message: 'Totaal aantal opleidingen',
              })}
              value={data?.total || 0}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.personal.total_training_duration',
                message: 'Totale trainingstijd',
              })}
              value={
                data?.total_duration ? formatDuration(data?.total_duration) : 0
              }
              loading={loading}
            />
          </Col>
        </Row>
        {user && (
          <UserCoursesReport
            userId={user._id}
            branchId={branch?._id}
            exportable={'/api/export/personal'}
            start={start || undefined}
            end={end || undefined}
          />
        )}
      </Content>
    </Content>
  )
}
