import { SearchOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Alert,
  AutoComplete,
  Badge,
  Checkbox,
  Col,
  Empty,
  Radio,
  Row,
  Select,
  Slider,
  Space,
} from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  NumberParam,
  NumericObjectParam,
  ObjectParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'

import {
  JobAccessibilityOptions,
  JobBenefits,
  JobContractType,
  JobEducationLevel,
  JobOtherCriteria,
  JobSchedule,
  JobType,
  StudentJobSchedule,
} from '@lms-shared-patterns/models/job-type.model'

import { LoadScreen } from '../../../core/components/LoadScreen'
import { Filter } from '../../../courses/components/courses-filters/CoursesFilters'
import { ResetButton } from '../../../courses/components/courses-filters/CoursesFilters.style'
import {
  Content,
  CoursesStickyCol,
  CoursesWrapper,
} from '../../../courses/pages/courses/Courses.style'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import useThrottledFunction from '../../../shared/hooks/use-throttled-function'
import { JobCard } from '../../components/JobCard'
import { renderJobType } from '../../utils/render-job-type'

import JOB_CITIES from './../../queries/job-cities.graphql'
import JOB_OPPORTUNITIES from './../../queries/job-opportunities.graphql'
import { Section, SectionTitle } from './JobOverview.style'

import type {
  JobCitiesQuery,
  JobOpportunitiesQuery,
} from 'apps/lms-front/src/generated/graphql'

export const JobOverview = () => {
  const [anonymized, setAnonymized] = useState<boolean>(
    localStorage.getItem('aa_jobs_anon') === 'true'
  )
  const contentRowRef = useRef<HTMLDivElement>(null)
  const filterRowRef = useRef<HTMLDivElement>(null)
  const { search } = useLocation()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [wage, setWage] = useQueryParam('wage', NumericObjectParam)
  const [placeId, setPlaceId] = useQueryParam('placeId', StringParam)
  const [place, setPlace] = useQueryParam('place', ObjectParam)
  const [radius, setRadius] = useQueryParam('radius', NumberParam)
  const [suggestions, setSuggestions] = useState<
    JobCitiesQuery['fetchCitySuggestions']
  >([])

  const wageMin = wage?.min || 0
  const wageMax = wage?.max || 5000

  useEffect(() => {
    localStorage.setItem('aa_jobs_anon', anonymized.toString())
  }, [anonymized])

  useEffect(() => {
    const handleAnonymizedChanged = (e: KeyboardEvent) => {
      if (e.key === 'o' && (e.metaKey || e.ctrlKey)) setAnonymized((v) => !v)
      if (e.key === 'Escape') setAnonymized(false)
    }
    document.addEventListener('keydown', handleAnonymizedChanged)

    return () =>
      document.removeEventListener('keydown', handleAnonymizedChanged)
  }, [])

  const [getCitySuggestions] = useLazyQuery<JobCitiesQuery>(JOB_CITIES)

  const fetchSuggestions = async (input) => {
    if (input.length > 2) {
      const response = await getCitySuggestions({
        variables: {
          query: input,
        },
      })

      setSuggestions(response.data?.fetchCitySuggestions || [])
    } else {
      setSuggestions([])
    }
    if (!radius) setRadius(20)
  }

  const {
    throttledFn: throttledSuggestionFetch,
    cancel: cancelThrottledSuggestionFetch,
  } = useThrottledFunction({
    callbackFn: fetchSuggestions,
    throttleMs: 500,
  })

  const [filter, setQueryFilter] = useQueryParam('filter', ObjectParam)

  useEffect(() => {
    sessionStorage.setItem('aa_job_filters', search)
  }, [search])

  useEffect(() => {
    if (place?.name) setSearchTerm(place.name)
  }, [place])

  const setFilter = useCallback(
    (action) => {
      if (!action) setQueryFilter({})

      setQueryFilter((current) => {
        const update = {}

        for (const key in action) {
          if (action[key]?.length) {
            update[key] = action[key]
          } else if (current?.[key]) {
            delete current[key]
          }
        }
        return { ...current, ...update }
      })
    },
    [setQueryFilter]
  )

  useEffect(() => {
    if (!filter?.type) setFilter({ type: JobType.All })
  }, [filter, setFilter])

  const { data } = useQuery<JobOpportunitiesQuery>(JOB_OPPORTUNITIES, {
    variables: {
      filter: {
        place: placeId,
        radius,
        educationLevel: filter?.education?.split(','),
        title: filter?.type?.split(','),
        contract: filter?.contract?.split(','),
        schedule: filter?.schedule?.split(','),
        other: filter?.other?.split(','),
        accessibility: filter?.accessibility?.split(','),
        benefits: filter?.benefits?.split(','),
        wage: [wage?.min || 0, wage?.max || 5000],
      },
    },
  })

  return (
    <>
      <Content>
        <Row gutter={[16, 16]} ref={contentRowRef}>
          <CoursesStickyCol
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <CoursesWrapper ref={filterRowRef}>
              <Section>
                <SectionTitle>
                  <Trans id="jobs.search.title">Vacatures zoeken</Trans>
                </SectionTitle>

                <AutoComplete
                  value={searchTerm}
                  dropdownMatchSelectWidth={252}
                  style={{ width: '100%' }}
                  options={suggestions.map((suggestion) => ({
                    label: suggestion.name,
                    value: suggestion.name,
                  }))}
                  onSelect={(value) => {
                    setPlace(suggestions.find((s) => s.name === value))
                    setPlaceId(suggestions.find((s) => s.name === value)?.id)
                    setSuggestions([])
                  }}
                  onSearch={(query) => fetchSuggestions(query)}
                >
                  <InputSearch
                    placeholder={t({
                      id: 'jobs.search.placeholder',
                      message: 'Locatie',
                    })}
                    allowClear={true}
                    onChange={(e) => {
                      setSearchTerm(e.target.value)
                      if (e.target.value === '') {
                        setPlace(null)
                        setPlaceId(null)
                        setSearchTerm('')
                        setSuggestions([])
                      } else {
                        cancelThrottledSuggestionFetch()
                        throttledSuggestionFetch(e.target.value)
                      }
                    }}
                    value={searchTerm}
                    style={{ width: '100%' }}
                  />
                </AutoComplete>
                {!!place && (
                  <Space style={{ marginTop: 15 }}>
                    <div>in een straal van</div>
                    <Select
                      style={{ width: 100 }}
                      value={radius}
                      onChange={(value) => setRadius(value)}
                      options={[
                        {
                          value: 5,
                          label: '5 km',
                        },
                        {
                          value: 10,
                          label: '10 km',
                        },
                        {
                          value: 15,
                          label: '15 km',
                        },
                        {
                          value: 20,
                          label: '20 km',
                        },
                        {
                          value: 25,
                          label: '25 km',
                        },
                        {
                          value: 30,
                          label: '30 km',
                        },
                        {
                          value: 40,
                          label: '40 km',
                        },
                        {
                          value: 50,
                          label: '50 km',
                        },
                        {
                          value: 60,
                          label: '60 km',
                        },
                        {
                          value: 75,
                          label: '75 km',
                        },
                        {
                          value: 100,
                          label: '100 km',
                        },
                      ]}
                    />
                  </Space>
                )}
              </Section>
              <Filter
                heading={
                  <Space>
                    <Trans id="jobs.filters.type">Type</Trans>
                  </Space>
                }
                expand
              >
                <Radio.Group
                  className="vertical"
                  options={[
                    {
                      label: renderJobType(JobType.All),
                      value: JobType.All,
                    },
                    {
                      label: renderJobType(JobType.FirstJob),
                      value: JobType.FirstJob,
                    },
                    {
                      label: renderJobType(JobType.Internship),
                      value: JobType.Internship,
                    },
                    {
                      label: renderJobType(JobType.StudentJob),
                      value: JobType.StudentJob,
                    },
                  ]}
                  value={filter?.type}
                  onChange={(e) => {
                    setFilter({ type: e.target.value })
                    setFilter({ schedule: '' })
                    setFilter({ contract: '' })
                    setFilter({ benefits: '' })
                    setWage({ min: 0, max: 5000 })
                  }}
                />
              </Filter>
              {filter?.type === JobType.FirstJob && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.contract">Contract</Trans>
                      <Badge
                        count={filter?.contract?.split(',').length}
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Checkbox.Group
                    className="vertical"
                    options={[
                      {
                        label: 'Vast contract',
                        value: JobContractType.Permanent,
                      },
                      {
                        label: 'Tijdelijk contract',
                        value: JobContractType.Temporary,
                      },
                    ]}
                    value={filter?.contract?.split(',')}
                    onChange={(value) => {
                      setFilter({ contract: value.join(',') })
                    }}
                  />
                </Filter>
              )}
              {filter?.type === JobType.FirstJob && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.schedule">Werkschema</Trans>
                      <Badge
                        count={filter?.schedule?.split(',').length}
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Checkbox.Group
                    className="vertical"
                    options={[
                      {
                        label: 'Voltijds',
                        value: JobSchedule.FullTime,
                      },
                      {
                        label: 'Part-time',
                        value: JobSchedule.PartTime,
                      },
                    ]}
                    value={filter?.schedule?.split(',')}
                    onChange={(value) => {
                      setFilter({ schedule: value.join(',') })
                    }}
                  />
                </Filter>
              )}
              {(filter?.type === JobType.FirstJob ||
                filter?.type === JobType.Internship) && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.education_level">
                        Opleidingsrichting
                      </Trans>
                      <Badge
                        count={filter?.education?.split(',').length}
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Checkbox.Group
                    className="vertical"
                    options={[
                      {
                        label: 'Bachelor',
                        value: JobEducationLevel.Bachelor,
                      },
                      {
                        label: 'Master',
                        value: JobEducationLevel.Master,
                      },
                      {
                        label: 'Graduaat',
                        value: JobEducationLevel.Graduate,
                      },
                      ...(filter?.type === JobType.FirstJob
                        ? [
                            {
                              label: 'Geen diploma vereist',
                              value: JobEducationLevel.None,
                            },
                          ]
                        : []),
                      ...(filter?.type === JobType.Internship
                        ? [
                            {
                              label: 'Geen voorkeur',
                              value: JobEducationLevel.Either,
                            },
                          ]
                        : []),
                    ]}
                    value={filter?.education?.split(',')}
                    onChange={(value) => {
                      setFilter({ education: value.join(',') })
                    }}
                  />
                </Filter>
              )}
              {filter?.type === JobType.FirstJob && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.wage">Loonvork</Trans>
                      <Badge
                        count={
                          (wage?.min || wage?.max) &&
                          (wage?.min !== 0 || wage?.max !== 5000)
                            ? 1
                            : 0
                        }
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Slider
                    tooltip={{
                      formatter: (value) => `€ ${value}`,
                    }}
                    range={{
                      draggableTrack: true,
                    }}
                    onChange={(value) => {
                      setWage({
                        min: value[0],
                        max: value[1],
                      })
                    }}
                    value={[wageMin, wageMax]}
                    min={0}
                    max={5000}
                    step={250}
                    marks={{
                      0: '€ 0',
                      1000: '1000',
                      2000: '2000',
                      3000: '3000',
                      4000: '4000',
                      5000: '5000',
                    }}
                  />
                </Filter>
              )}
              {filter?.type === JobType.FirstJob && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.benefits">
                        Extralegale voordelen
                      </Trans>
                      <Badge
                        count={filter?.benefits?.split(',').length}
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Checkbox.Group
                    className="vertical"
                    options={[
                      {
                        label: 'Bedrijfswagen',
                        value: JobBenefits.CompanyCar,
                      },
                      {
                        label: 'Smartphone',
                        value: JobBenefits.Smartphone,
                      },
                      {
                        label: 'Laptop',
                        value: JobBenefits.Laptop,
                      },
                      {
                        label: 'Maaltijdcheques',
                        value: JobBenefits.MealVouchers,
                      },
                      {
                        label: 'Cafetariaplan',
                        value: JobBenefits.CafetariaPlan,
                      },
                      {
                        label: 'Hospitalisatie- en groepsverzekering',
                        value: JobBenefits.HealthInsurance,
                      },
                      {
                        label: 'Mobiliteitsbudget',
                        value: JobBenefits.MobilityBudget,
                      },
                      {
                        label: 'Bonus',
                        value: JobBenefits.Bonus,
                      },
                    ]}
                    value={filter?.benefits?.split(',')}
                    onChange={(value) => {
                      setFilter({ benefits: value.join(',') })
                    }}
                  />
                </Filter>
              )}
              {filter?.type === JobType.StudentJob && (
                <Filter
                  heading={
                    <Space>
                      <Trans id="jobs.filters.schedule">Werkschema</Trans>
                      <Badge
                        count={filter?.schedule?.split(',').length}
                        dot={false}
                      />
                    </Space>
                  }
                  expand
                >
                  <Checkbox.Group
                    className="vertical"
                    options={[
                      {
                        label: 'Vaste dag(en) per week',
                        value: StudentJobSchedule.FixedDays,
                      },
                      {
                        label: 'Schoolvakanties',
                        value: StudentJobSchedule.Vacations,
                      },
                    ]}
                    value={filter?.schedule?.split(',')}
                    onChange={(value) => {
                      setFilter({ schedule: value.join(',') })
                    }}
                  />
                </Filter>
              )}
              <Filter
                heading={
                  <Space>
                    <Trans id="jobs.filters.accessibility">
                      Bereikbaarheid
                    </Trans>
                    <Badge
                      count={filter?.accessibility?.split(',').length}
                      dot={false}
                    />
                  </Space>
                }
                expand
              >
                <Checkbox.Group
                  className="vertical"
                  options={[
                    {
                      label: 'Parking aanwezig',
                      value: JobAccessibilityOptions.Parking,
                    },
                    {
                      label: 'Makkelijk bereikbaar via het openbaar vervoer',
                      value: JobAccessibilityOptions.PublicTransport,
                    },
                  ]}
                  value={filter?.accessibility?.split(',')}
                  onChange={(value) => {
                    setFilter({ accessibility: value.join(',') })
                  }}
                />
              </Filter>
              <Filter
                heading={
                  <Space>
                    <Trans id="jobs.filters.other">Overige</Trans>
                    <Badge
                      count={filter?.other?.split(',').length}
                      dot={false}
                    />
                  </Space>
                }
                expand
              >
                <Checkbox.Group
                  className="vertical"
                  options={[
                    {
                      label: (
                        <Trans id="job.remote.flexible_hours">
                          Flexibele werkuren
                        </Trans>
                      ),
                      value: JobOtherCriteria.FlexibleHours,
                    },
                    {
                      label: (
                        <Trans id="job.remote.label">
                          Mogelijkheid tot thuiswerk
                        </Trans>
                      ),
                      value: JobOtherCriteria.RemoteWork,
                    },
                    ...(filter?.type === JobType.FirstJob
                      ? [
                          {
                            label: (
                              <Trans id="job.remote.itaa_internship">
                                Mogelijkheid tot ITAA-stage
                              </Trans>
                            ),
                            value: JobOtherCriteria.ItaaInternship,
                          },
                        ]
                      : []),
                  ]}
                  value={filter?.other?.split(',')}
                  onChange={(value) => {
                    setFilter({ other: value.join(',') })
                  }}
                />
              </Filter>
              <Section>
                <ResetButton
                  type="primary"
                  disabled={Object.keys(filter || {}).length === 0}
                  onClick={() => {
                    setSearchTerm('')
                    setFilter(null)
                  }}
                >
                  <Trans id="courses.filter.reset">Reset alle filters</Trans>
                </ResetButton>
              </Section>
            </CoursesWrapper>
          </CoursesStickyCol>
          <Col
            sm={{ span: 12 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
            style={{ marginBottom: -30 }}
          >
            <Row gutter={16}>
              {data?.fetchJobOpportunities ? (
                data?.fetchJobOpportunities.count === 0 ||
                data?.fetchJobOpportunities.relaxed ? (
                  <>
                    <Col xs={24}>
                      {data.fetchJobOpportunities.count > 0 && (
                        <Alert
                          type="warning"
                          icon={<SearchOutlined style={{ marginRight: 16 }} />}
                          showIcon={true}
                          message={
                            <>
                              <h3 style={{ margin: 0 }}>
                                {t({
                                  id: 'jobs.empty',
                                  message:
                                    'Je zoekopdracht leverde geen resultaten op.',
                                })}
                              </h3>
                              <span>
                                {t({
                                  id: 'jobs.empty.relax',
                                  message:
                                    'Misschien zijn volgende vacatures iets voor jou:',
                                })}
                              </span>
                            </>
                          }
                          style={{ marginBottom: 16 }}
                        />
                      )}
                      {data.fetchJobOpportunities.count === 0 && (
                        <Empty
                          style={{ paddingTop: 24 }}
                          description={t({
                            id: 'jobs.empty',
                            message:
                              'Je zoekopdracht leverde geen resultaten op.',
                          })}
                        />
                      )}
                    </Col>
                    {data.fetchJobOpportunities.results.map((job) => (
                      <JobCard
                        key={job._id}
                        job={job}
                        anonymized={anonymized}
                      />
                    ))}
                  </>
                ) : (
                  data.fetchJobOpportunities.results.map((job) => (
                    <JobCard key={job._id} job={job} anonymized={anonymized} />
                  ))
                )
              ) : (
                <Col xs={24}>
                  <LoadScreen />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </>
  )
}
