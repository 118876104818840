import styled from 'styled-components'

import { Arrow } from '../../courses/components/arrow/Arrow'

export const StyledArrow = styled(Arrow)`
  position: absolute;
  right: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const Dot = styled.div`
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
`

export const EventCardWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.15);
  }
`

export const EventCardContents = styled.div`
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
`

export const EventModalWrapper = styled.div`
  padding: 1.25rem 1.5rem 1.25rem 1.5rem;

  .event-description-wysiwyg {
    p:last-child {
      margin-bottom: 0;
    }
  }
`

export const TitleTagWrapper = styled.span`
  margin-left: 0.5rem;
  vertical-align: baseline;
  position: relative;
  top: -2px;
`

export const Ruler = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  width: 100%;
`
