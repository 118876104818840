import { useCallback } from 'react'
import { Handle, HandleProps, useNodeId, useStore } from 'reactflow'

export const SingleConnectionHandle = (props: HandleProps) => {
  const id = useNodeId()

  // This selector checks the current edges and returns true if the current node is not a target in any edge
  const canConnect = useStore(
    useCallback(
      (store) => !store.edges.some((edge) => edge.target === id),
      [id]
    )
  )

  return <Handle {...props} isConnectable={canConnect} />
}
