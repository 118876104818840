/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sonarjs/no-identical-functions */
import { Trans, t } from '@lingui/macro'
import { Col, PageHeader, Row, Statistic } from 'antd'
import { useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

import { useHierarchyTree } from '../../../branch/hooks/use-hierarchy-tree'
import { setSessionStorageItem } from '../../../core/utils/session-storage'
import { TreeSelect } from '../../../shared/components/tree-select/TreeSelect'
import { useAxios } from '../../../shared/hooks/use-axios'
import { Content } from '../../../shared/layout/Layout.style'
import { Link } from '../../components/Link'
import { Table } from '../../components/Table'

export const BranchReport = () => {
  const branch = useBranch()

  const [activityPerCoursePage, setActivityPerCoursePage] = useState<number>(1)
  const [activityPerUserPage, setActivityPerUserPage] = useState<number>(1)

  const [hierarchyFilter, setHierarchyFilter] = useQueryParam(
    'section',
    StringParam
  )
  const { data: treeData, loading: treeLoading } = useHierarchyTree({
    filterByPermission: {
      action: PermissionAction.READ,
      object: PermissionObjectType.BRANCH_REPORT,
    },
    noBranches: true,
  })

  const [{ data, loading }] = useAxios({
    url: `/api/activity/branch`,
    params: {
      section_id: hierarchyFilter,
    },
  })

  const [{ data: activityPerCourse, loading: loadingActivityPerCourse }] =
    useAxios({
      url: `/api/activity/branch/activity-per-course`,
      params: {
        page: activityPerCoursePage,
        section_id: hierarchyFilter,
      },
    })

  const [{ data: activityPerUser, loading: loadingActivityPerUser }] = useAxios(
    {
      url: `/api/activity/branch/activity-per-user`,
      params: {
        page: activityPerUserPage,
        section_id: hierarchyFilter,
      },
    }
  )

  return (
    <Content style={{ backgroundColor: '#FFF' }}>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'reports.branch.title',
          message: `Rapport voor ${branch?.name}`,
        })}
        extra={
          treeData.length > 0 && (
            <TreeSelect
              placeholder={t({
                id: 'reports.filter.hierarchy',
                message: 'Filter op afdeling',
              })}
              treeDefaultExpandAll={true}
              treeLine={true}
              showSearch
              treeDataSimpleMode
              style={{ width: 250 }}
              dropdownMatchSelectWidth={false}
              filterTreeNode={(input, option) =>
                (option.title as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
              }}
              treeData={treeData}
              loading={treeLoading}
              allowClear={true}
              treeNodeLabelProp="label"
              value={hierarchyFilter}
              onChange={(value) => {
                setSessionStorageItem('aa_report_filter', value)
                setHierarchyFilter(value)
              }}
            />
          )
        }
      ></PageHeader>
      <Content>
        <Row gutter={16} style={{ textAlign: 'center', marginBottom: 16 }}>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.branch.active_users',
                message: 'Actieve gebruikers',
              })}
              value={data?.active_users}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.branch.total_users',
                message: 'Totaal aantal gebruikers',
              })}
              value={data?.total_users}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.branch.active_courses',
                message: 'Actieve opleidingen',
              })}
              value={data?.active_courses}
              loading={loading}
            />
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} style={{ marginBottom: 24 }}>
            <Statistic
              title={t({
                id: 'reports.branch.total_courses',
                message: 'Totaal aantal opleidingen',
              })}
              value={data?.total_courses}
              valueStyle={{ textAlign: 'center' }}
              loading={loading}
            />
          </Col>
        </Row>
        {
          <>
            <h3>
              <Trans id="reports.branch.activity_per_course">
                Meest gevolgde opleidingen
              </Trans>
            </h3>
            <Table
              bordered
              size="small"
              dataSource={activityPerCourse?.data.map(
                (activity: {
                  name: string
                  users: number
                  active_users: number
                  completed_users: number
                }) => {
                  activity.active_users =
                    activity.users - activity.completed_users
                  return activity
                }
              )}
              loading={loadingActivityPerCourse}
              rowKey="_id"
              pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                onChange: (page: number) => setActivityPerCoursePage(page),
                showSizeChanger: false,
                total: activityPerCourse?.total,
              }}
              columns={[
                {
                  title: t({
                    id: 'reports.branch.activity_per_course.table.name',
                    message: 'Opleiding',
                  }),
                  dataIndex: 'name',
                  key: 'name',
                  render: (name: string, record) => (
                    <Link
                      to={`/reports/courses?query=${encodeURIComponent(
                        name
                      )}&expand=${encodeURIComponent(
                        (record as { _id: string })._id
                      )}`}
                    >
                      {name}
                    </Link>
                  ),
                },
                {
                  title: t({
                    id: 'reports.branch.activity_per_course.table.users',
                    message: 'Gebruikers',
                  }),
                  children: [
                    {
                      title: t({
                        id: 'reports.branch.activity_per_course.table.users.active',
                        message: 'In uitvoering',
                      }),
                      dataIndex: 'active_users',
                      key: 'active_users',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.branch.activity_per_course.table.users.completed',
                        message: 'Voltooid',
                      }),
                      dataIndex: 'completed_users',
                      key: 'completed_users',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.branch.activity_per_course.table.users.total',
                        message: 'Totaal',
                      }),
                      dataIndex: 'users',
                      key: 'users',
                      align: 'center' as const,
                    },
                  ],
                },
              ]}
            />
          </>
        }
        {
          <>
            <h3>
              <Trans id="reports.branch.activity_per_user">
                Meest actieve gebruikers
              </Trans>
            </h3>
            <Table
              bordered
              size="small"
              loading={loadingActivityPerUser}
              dataSource={activityPerUser?.data.map(
                (activity: {
                  name: string
                  first_name: string
                  last_name: string
                  courses: number
                  active_courses: number
                  completed_courses: number
                }) => {
                  activity.name = `${activity.first_name} ${activity.last_name}`
                  activity.active_courses =
                    activity.courses - activity.completed_courses
                  return activity
                }
              )}
              rowKey="_id"
              pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                onChange: (page: number) => setActivityPerUserPage(page),
                showSizeChanger: false,
                total: activityPerUser?.total,
              }}
              columns={[
                {
                  title: t({
                    id: 'reports.branch.activity_per_user.table.user',
                    message: 'Gebruiker',
                  }),
                  dataIndex: 'name',
                  key: 'name',
                  render: (name: string, record) => (
                    <Link
                      to={`/reports/users?query=${encodeURIComponent(
                        name
                      )}&expand=${encodeURIComponent(
                        (record as { _id: string })._id
                      )}`}
                    >
                      {name}
                    </Link>
                  ),
                },
                {
                  title: t({
                    id: 'reports.branch.activity_per_user.table.courses',
                    message: 'Opleidingen',
                  }),
                  children: [
                    {
                      title: t({
                        id: 'reports.branch.activity_per_user.table.courses.active',
                        message: 'In uitvoering',
                      }),
                      dataIndex: 'active_courses',
                      key: 'active_courses',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.branch.activity_per_user.table.courses.completed',
                        message: 'Voltooid',
                      }),
                      dataIndex: 'completed_courses',
                      key: 'completed_courses',
                      align: 'center' as const,
                    },
                    {
                      title: t({
                        id: 'reports.branch.activity_per_user.table.courses.total',
                        message: 'Totaal',
                      }),
                      dataIndex: 'courses',
                      key: 'courses',
                      align: 'center' as const,
                    },
                  ],
                },
              ]}
            />
          </>
        }
      </Content>
    </Content>
  )
}
