import { RcFile } from 'antd/lib/upload'

export const getBase64 = (
  img: Blob | RcFile | undefined,
  callback: (result: string | ArrayBuffer | null) => void
) => {
  if (!img) return
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
