/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'
import { makeUseAxios } from 'axios-hooks'

export const axiosClient = axios.create({
  baseURL: `${import.meta.env.NX_BACKEND_URL}`,
  headers: {
    'x-academy-host': window.location.hostname,
  },
})

const useAxiosHook = makeUseAxios({
  axios: axios.create({
    baseURL: `${import.meta.env.NX_BACKEND_URL}`,
    headers: {
      'x-academy-host': window.location.hostname,
    },
  }),
})

export const useAxios = <T = any>(config: AxiosRequestConfig) => {
  const token = localStorage.getItem('aa_lms_at')
  return useAxiosHook<T>({
    ...config,
    headers: {
      ...config.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  })
}
