import json2mq from 'json2mq'
import { useMediaQuery } from 'usehooks-ts'

import type { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray'

export const breakpoints = {
  xs: json2mq({ minWidth: 0 }),

  sm: json2mq({ minWidth: 576 }),
  below_sm: json2mq({ maxWidth: 575 }),

  md: json2mq({ minWidth: 768 }),
  below_md: json2mq({ maxWidth: 767 }),

  lg: json2mq({ minWidth: 1024 }),
  below_lg: json2mq({ maxWidth: 1023 }),

  xl: json2mq({ minWidth: 1280 }),
  below_xl: json2mq({ maxWidth: 1279 }),

  '2xl': json2mq({ minWidth: 1536 }),
  below_2xl: json2mq({ maxWidth: 1535 }),
}

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export const useBreakpoint = (bps: NonEmptyArray<Breakpoint>): Breakpoint => {
  const dxl: boolean = useMediaQuery(breakpoints['2xl'])
  const xl: boolean = useMediaQuery(breakpoints.xl)
  const lg: boolean = useMediaQuery(breakpoints.lg)
  const md: boolean = useMediaQuery(breakpoints.md)
  const sm: boolean = useMediaQuery(breakpoints.sm)
  if (dxl && bps.includes('2xl')) return '2xl'
  if (xl && bps.includes('xl')) return 'xl'
  if (lg && bps.includes('lg')) return 'lg'
  if (md && bps.includes('md')) return 'md'
  if (sm && bps.includes('sm')) return 'sm'
  return 'xs'
}
