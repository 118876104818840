/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unicorn/no-useless-undefined */
import {
  DeleteOutlined,
  EditOutlined,
  ControlOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Trans, t } from '@lingui/macro'
import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Radio,
  Space,
  Table,
  Tooltip,
  Transfer,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { CompareFn } from 'antd/lib/table/interface'
import { ObjectId } from 'bson'
import { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Fuse from 'fuse.js'
import { test } from 'fuzzyjs'
import { useEffect, useMemo, useState } from 'react'

import {
  LimitAccessType,
  PermissionAction,
  PermissionObjectType,
} from '@lms-shared-patterns/models'
import {
  GroupsQuery,
  CreateGroupMutation,
  DeleteGroupMutation,
  UpdateGroupMutation,
  UsersQuery,
  BranchesQuery,
  BranchAccessibleCoursesQuery,
} from 'apps/lms-front/src/generated/graphql'

import { Can } from '../../../auth/components/Can'
import { ActionButtonWrapper } from '../../../shared/components/action-button-wrapper/ActionButtonWrapper'
import { DropdownButton } from '../../../shared/components/dynamic-dropdown-button/DropdownButton'
import { InputSearch } from '../../../shared/components/input-search/InputSearch'
import { errorNotifierFn } from '../../../shared/helpers/error-notifier'
import { defaultSort } from '../../../shared/utils/sort'

import BRANCH_ACCESSIBLE_COURSES_QUERY from './../../../branch/queries/branch-accessible-courses.graphql'
import CREATE_GROUP_MUTATION from './../../mutations/create-group.graphql'
import DELETE_GROUP_MUTATION from './../../mutations/delete-group.graphql'
import UPDATE_GROUP_MUTATION from './../../mutations/update-group.graphql'
import BRANCHES_QUERY from './../../queries/branches.graphql'
import GROUPS_QUERY from './../../queries/groups.graphql'
import USERS_QUERY from './../../queries/users.graphql'

extend(utc)
extend(timezone)
extend(relativeTime)

interface GroupRow {
  key: string
  name: string
  created?: Date
}

type BranchTransferItem = BranchesQuery['fetchBranches'][0]

export const Groups = () => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const [groupModalVisible, setGroupModalVisible] = useState(false)
  const [groupAccessModalVisible, setGroupAccessModalVisible] = useState(false)

  const [deletePopConfirmIndex, setDeletePopConfirmIndex] = useState<string>()
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false)
  const [updateSubject, setUpdateSubject] =
    useState<GroupsQuery['fetchGroups'][0]>()
  const [updateAccessSubject, setUpdateAccessSubject] =
    useState<GroupsQuery['fetchGroups'][0]>()

  const [form] = useForm()
  const [rulesForm] = useForm()

  const [limitAccessType, setLimitAccessType] = useState<LimitAccessType>(
    LimitAccessType.NONE
  )

  const { data, loading } = useQuery<GroupsQuery>(GROUPS_QUERY)

  const { data: courses, loading: coursesLoading } =
    useQuery<BranchAccessibleCoursesQuery>(BRANCH_ACCESSIBLE_COURSES_QUERY, {
      variables: {
        branch_id: updateAccessSubject?.branch_id,
      },
    })

  const [createGroup, { loading: creating }] = useMutation<CreateGroupMutation>(
    CREATE_GROUP_MUTATION
  )

  const [updateGroup, { loading: updating }] = useMutation<UpdateGroupMutation>(
    UPDATE_GROUP_MUTATION
  )

  const [deleteGroup, { loading: deleting }] = useMutation<DeleteGroupMutation>(
    DELETE_GROUP_MUTATION
  )

  const { data: userData } = useQuery<UsersQuery>(USERS_QUERY)
  const { data: branchData } = useQuery<BranchesQuery>(BRANCHES_QUERY)

  const users = useMemo(() => {
    return userData?.fetchUsers.results
      .filter(
        (user) =>
          !updateSubject?.branch_id ||
          user.branches?.some((branch) =>
            new ObjectId(branch.branch_id).equals(updateSubject.branch_id || '')
          )
      )
      .map((user) => ({
        _id: user._id,
        name: `${user.firstName} ${user.lastName} (${user.email})`,
      }))
  }, [userData, updateSubject])

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedBranches, setSelectedBranches] = useState<string[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])

  const filterOption = (
    inputValue: string,
    option: { _id: string; name: string }
  ) =>
    test(inputValue, option.name, {
      caseSensitive: false,
    })

  const branchFilterOption = (inputValue: string, option: BranchTransferItem) =>
    test(inputValue, `${option.name})`)

  useEffect(() => {
    if (updateSubject) {
      setGroupModalVisible(true)
      if (updateSubject.users) setSelectedUsers(updateSubject.users)
      setSelectedBranches(updateSubject.branches)
    }
  }, [updateSubject])

  useEffect(() => {
    if (updateAccessSubject) {
      setGroupAccessModalVisible(true)
      setLimitAccessType(
        updateAccessSubject.limit_access_type as unknown as LimitAccessType
      )
      setSelectedCourses(updateAccessSubject.selected_courses)
    }
  }, [updateAccessSubject, rulesForm])

  useEffect(() => {
    if (groupModalVisible) form.resetFields()
  }, [groupModalVisible, form])

  const groups = useMemo(() => {
    const fuse = new Fuse(data?.fetchGroups || [], {
      keys: ['name'],
      findAllMatches: true,
    })

    const result = fuse.search(searchTerm)

    return (
      searchTerm.length > 1
        ? result.map((result) => result.item)
        : data?.fetchGroups || []
    ).map<GroupRow>((item: GroupsQuery['fetchGroups'][0]) => ({
      key: item._id,
      name: item.name,
      users: item.users,
      created: item.created,
      branch_id: item.branch_id,
    }))
  }, [data, searchTerm])

  const columns: ColumnsType<GroupRow> = [
    {
      title: t({
        id: 'settings.groups.table.name',
        message: 'Naam',
      }),
      dataIndex: 'name',
      key: 'name',
      sorter: defaultSort('name') as CompareFn<unknown>,
      fixed: 'left',
    },
    {
      title: t({
        id: 'settings.groups.table.users',
        message: 'Gebruikers',
      }),
      dataIndex: 'users',
      key: 'users',
      render: (users: string[]) => users.length,
    },
    {
      title: t({
        id: 'settings.groups.table.branch',
        message: 'Afdeling',
      }),
      dataIndex: 'branch_id',
      key: 'branch_id',
      render: (branch_id: string) =>
        branchData?.fetchBranches.find((branch) =>
          new ObjectId(branch._id).equals(branch_id)
        )?.name,
    },
    {
      title: t({
        id: 'settings.groups.table.actions',
        message: 'Acties',
      }),
      align: 'center',
      key: 'operation',
      fixed: 'right',
      width: 160,
      render: (_, record: GroupRow) => (
        <ActionButtonWrapper>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.USER_GROUP}>
            <Tooltip
              title={t({
                id: 'actions.edit',
                message: 'Bewerken',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateSubject(
                    data?.fetchGroups.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.UPDATE} a={PermissionObjectType.USER_GROUP}>
            <Tooltip
              title={t({
                id: 'settings.groups.table.actions.manage_access',
                message: 'Opleidingen verbergen',
              })}
            >
              <Button
                onClick={() => {
                  setUpdateAccessSubject(
                    data?.fetchGroups.find((branch) =>
                      new ObjectId(branch._id).equals(record.key)
                    )
                  )
                }}
                shape="circle"
                icon={<ControlOutlined />}
              />
            </Tooltip>
          </Can>
          <Can I={PermissionAction.DELETE} a={PermissionObjectType.USER_GROUP}>
            <Tooltip
              title={t({
                id: 'actions.delete',
                message: 'Verwijderen',
              })}
            >
              <Popconfirm
                placement={'left'}
                title={t({
                  id: 'settings.groups.table.delete.confirmation',
                  message: 'Ben je zeker dat je deze groep wil verwijderen?',
                })}
                open={
                  deletePopConfirmIndex === record.key &&
                  deletePopConfirmVisible
                }
                okType="danger"
                okText={t({
                  id: 'actions.delete',
                  message: 'Verwijderen',
                })}
                cancelText={t({
                  id: 'actions.cancel',
                  message: 'Annuleren',
                })}
                okButtonProps={{ loading: deleting }}
                onConfirm={() => {
                  deleteGroup({
                    variables: {
                      id: record.key,
                    },
                    refetchQueries: ['groups'],
                  })
                    .then(() => {
                      notification.success({
                        message: t({
                          id: 'settings.groups.delete.success',
                          message: 'Groep succesvol verwijderd',
                        }),
                      })
                    })
                    .catch(errorNotifierFn)
                    .finally(() => setDeletePopConfirmVisible(false))
                }}
                onCancel={() => setDeletePopConfirmVisible(false)}
              >
                <Button
                  onClick={() => {
                    setDeletePopConfirmIndex(record.key)
                    setDeletePopConfirmVisible(true)
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Can>
        </ActionButtonWrapper>
      ),
    },
  ]

  const handleChange = (newUsers: string[]) => {
    setSelectedUsers(newUsers)
  }

  const handleBranchChange = (newBranches: string[]) => {
    setSelectedBranches(newBranches)
  }

  const handleCreation = async () => {
    try {
      const fields = await form.validateFields()
      createGroup({
        variables: {
          ...fields,
          users: selectedUsers,
          branches: selectedBranches,
        },
        refetchQueries: ['groups'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.groups.create.success',
              message: 'Groep succesvol aangemaakt',
            }),
          })
          setGroupModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleUpdate = async () => {
    try {
      const fields = await form.validateFields()
      updateGroup({
        variables: {
          id: updateSubject?._id,
          ...fields,
          users: selectedUsers,
          branches: selectedBranches,
        },
        refetchQueries: ['groups'],
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.groups.update.success',
              message: 'Groep succesvol gewijzigd',
            }),
          })
          setGroupModalVisible(false)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  const handleRulesUpdate = async () => {
    try {
      const fields = await rulesForm.validateFields()
      updateGroup({
        variables: {
          id: updateAccessSubject?._id,
          ...fields,
          limit_access_type: limitAccessType,
          selected_courses:
            limitAccessType === LimitAccessType.ALL_BUT_SELECTED ||
            limitAccessType === LimitAccessType.SELECTED
              ? selectedCourses
              : [],
        },
      })
        .then(() => {
          notification.success({
            message: t({
              id: 'settings.groups.rules_update.success',
              message: 'Succesvol toegepast',
            }),
          })
          setGroupAccessModalVisible(false)
          setUpdateAccessSubject(undefined)
        })
        .catch(errorNotifierFn)
    } catch {
      return
    }
  }

  return (
    <>
      <PageHeader
        ghost={false}
        className="site-page-header"
        title={t({
          id: 'settings.groups.title',
          message: 'Groepen',
        })}
        style={{ backgroundColor: '#FFF' }}
        extra={[
          <InputSearch
            key="1"
            placeholder={t({
              id: 'settings.groups.search',
              message: 'Zoeken op naam',
            })}
            onSearch={(value) => {
              setSearchTerm(value)
              setPage(1)
            }}
            style={{ width: 200 }}
          />,
          <Can
            key="2"
            I={PermissionAction.CREATE}
            a={PermissionObjectType.USER_GROUP}
          >
            <DropdownButton
              onClick={() => {
                form.resetFields()
                setUpdateSubject(undefined)
                setSelectedBranches([])
                setSelectedUsers([])
                setGroupModalVisible(true)
              }}
              type="primary"
              menu={{
                hidden: true,
                items: [
                  {
                    key: 'import-groups',
                    label: t({
                      id: 'settings.groups.import',
                      message: 'Groepen importeren',
                    }),
                  },
                ],
              }}
            >
              <Trans id="settings.groups.create">Groep aanmaken</Trans>
            </DropdownButton>
          </Can>,
        ]}
      />
      <Table
        locale={{
          emptyText: t({
            id: 'settings.groups.table.empty',
            message: 'Geen groepen gevonden.',
          }),
          cancelSort: t({
            id: 'table.sort.cancel',
            message: 'Klik om niet langer te sorteren.',
          }),
          triggerAsc: t({
            id: 'table.sort.asc',
            message: 'Klik om oplopend te sorteren.',
          }),

          triggerDesc: t({
            id: 'table.sort.desc',
            message: 'Klik om aflopend te sorteren.',
          }),
        }}
        scroll={{ x: 400 }}
        dataSource={groups}
        loading={loading}
        columns={columns}
        showSorterTooltip={false}
        pagination={{
          current: page,
          onChange: (page: number) => setPage(page),
        }}
      />
      <Modal
        forceRender
        title={
          updateSubject
            ? t({
                id: 'settings.groups.update.title',
                message: 'Groep wijzigen',
              })
            : t({
                id: 'settings.groups.create.title',
                message: 'Nieuwe groep aanmaken',
              })
        }
        open={groupModalVisible}
        onOk={updateSubject ? handleUpdate : handleCreation}
        confirmLoading={creating || updating}
        onCancel={() => {
          setGroupModalVisible(false)
        }}
        afterClose={() => setUpdateSubject(undefined)}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={
          updateSubject
            ? t({
                id: 'action.update',
                message: 'Wijzigen',
              })
            : t({
                id: 'action.create',
                message: 'Aanmaken',
              })
        }
        width={640}
      >
        <Form
          key={updateSubject?._id}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={updateSubject ? handleUpdate : handleCreation}
          initialValues={updateSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.groups.form.label.name',
              message: 'Naam',
            })}
            name="name"
            rules={[
              {
                required: true,
                message: t({
                  id: 'settings.groups.form.validation.name',
                  message: 'Gelieve de naam van de groep in te vullen',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Space direction="vertical">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Transfer
                operationStyle={{ animation: 'none' }}
                style={{ height: 300 }}
                listStyle={{ width: 275, height: 300 }}
                showSearch
                // @ts-ignore
                dataSource={users}
                // @ts-ignore
                filterOption={filterOption}
                targetKeys={selectedUsers}
                // @ts-ignore
                render={(record: (typeof users)[0]) => record.name}
                onChange={handleChange}
                // @ts-ignore
                rowKey={(record: (typeof users)[0]) => record._id}
                titles={[
                  t({
                    id: 'settings.groups.form.transfer.users',
                    message: 'Alle gebruikers',
                  }),
                  t({
                    id: 'settings.groups.form.transfer.group',
                    message: 'Groep',
                  }),
                ]}
                pagination={{
                  pageSize: 100,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Transfer
                operationStyle={{ animation: 'none' }}
                style={{ height: 300 }}
                listStyle={{ width: 275, height: 300 }}
                showSearch
                // @ts-ignore
                dataSource={branchData?.fetchBranches}
                // @ts-ignore
                filterOption={branchFilterOption}
                targetKeys={selectedBranches}
                // @ts-ignore
                render={(record: BranchTransferItem) => `${record.name}`}
                onChange={handleBranchChange}
                // @ts-ignore
                rowKey={(record: BranchTransferItem) => record._id}
                titles={[
                  t({
                    id: 'settings.groups.form.transfer.branches',
                    message: 'Alle afdelingen',
                  }),
                  t({
                    id: 'settings.groups.form.transfer.group',
                    message: 'Groep',
                  }),
                ]}
                pagination={{
                  pageSize: 100,
                }}
              />
            </div>
          </Space>
          <Button hidden disabled={creating} type="primary" htmlType={'submit'}>
            <Trans id="actions.save">Opslaan</Trans>
          </Button>
        </Form>
      </Modal>
      <Modal
        forceRender
        title={t({
          id: 'settings.groups.manage_access.title',
          message: 'Opleidingen verbergen voor groep',
        })}
        open={groupAccessModalVisible}
        onOk={handleRulesUpdate}
        confirmLoading={updating}
        onCancel={() => {
          setGroupAccessModalVisible(false)
          setUpdateAccessSubject(undefined)
        }}
        cancelText={t({
          id: 'action.cancel',
          message: 'Annuleren',
        })}
        okText={t({
          id: 'action.apply',
          message: 'Toepassen',
        })}
        width={800}
      >
        <Form
          key={`${updateAccessSubject?._id}-access`}
          form={rulesForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleRulesUpdate}
          initialValues={updateAccessSubject || undefined}
          autoComplete="off"
        >
          <Form.Item
            label={t({
              id: 'settings.groups.manage_access.form.label.mode',
              message: 'Welke opleidingen wil je voor deze groep verbergen?',
            })}
            labelCol={{ span: 24 }}
            required={true}
          >
            <Radio.Group
              value={limitAccessType}
              onChange={(e) => {
                setLimitAccessType(e.target.value)
                setSelectedCourses([])
              }}
            >
              <Space direction="vertical">
                <Radio value={LimitAccessType.ALL_BUT_SELECTED}>
                  <Trans id="settings.groups.manage_access.form.mode.all_but_selected">
                    Alle opleidingen behalve...
                  </Trans>
                </Radio>
                <Radio value={LimitAccessType.SELECTED}>
                  <Trans id="settings.groups.manage_access.form.mode.selected">
                    Geselecteerde opleidingen
                  </Trans>
                </Radio>
                <Radio value={LimitAccessType.NONE}>
                  <Trans id="settings.groups.manage_access.form.mode.none">
                    Geen opleidingen verbergen
                  </Trans>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {limitAccessType === LimitAccessType.ALL_BUT_SELECTED ||
          limitAccessType === LimitAccessType.SELECTED ? (
            <Transfer
              disabled={coursesLoading}
              style={{ height: 300 }}
              listStyle={{ width: 400, height: 300 }}
              // @ts-ignore
              filterOption={filterOption}
              showSearch
              dataSource={courses?.fetchBranchAccessibleCourses.results}
              rowKey={(record) => record._id}
              titles={[
                t({
                  id: 'settings.groups.manage_access.form.transfer.courses',
                  message: 'Opleidingen',
                }),
                t({
                  id: 'settings.groups.manage_access.form.transfer.selected',
                  message: 'Geselecteerd',
                }),
              ]}
              locale={{
                itemUnit: t({
                  id: 'settings.groups.manage_access.form.transfer.item_unit',
                  message: 'opleiding',
                }),
                itemsUnit: t({
                  id: 'settings.groups.manage_access.form.transfer.items_unit',
                  message: 'opleidingen',
                }),
              }}
              targetKeys={selectedCourses}
              onChange={(newSelectedCourses) =>
                setSelectedCourses(newSelectedCourses)
              }
              render={(item) => item.name}
            />
          ) : null}
        </Form>
      </Modal>
    </>
  )
}
