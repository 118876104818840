import { Trans } from '@lingui/macro'
import { Content } from 'antd/lib/layout/layout'
import Color from 'color'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import styled, { AnyStyledComponent } from 'styled-components'

import { useBranch } from 'apps/lms-front/src/modules/auth/hooks/use-branch'

const WelcomeImage = styled.img`
  position: absolute;
  right: -1.5rem;
  pointer-events: none;
  z-index: 0;
  max-height: 95vh;
`

export const Container = styled(Content as unknown as AnyStyledComponent)<{
  image?: string
}>`
  min-height: 100vh;
  width: 100%;
  ${(props) => (props.image ? `max-width: 80rem;` : `text-align: center;`)}
  padding: 0 1.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media screen and (min-width: 105rem) {
    max-width: 96rem;
  }
`

export const H1 = styled.h1`
  color: #fff;
  font-size: 5vh;
  font-weight: 300;

  @media screen and (min-width: 992px) {
    font-size: 7vh;
  }
`

export const Welcome = () => {
  const branch = useBranch()
  const navigate = useNavigate()

  const welcome = useMemo(() => branch?.landingImage?.url, [branch])

  const contrast =
    (branch?.theme.secondaryColor &&
      branch?.theme.primaryColor &&
      Math.abs(
        Color(branch?.theme.secondaryColor).luminosity() -
          Color(branch?.theme.primaryColor).luminosity()
      )) ||
    1

  const buttonColor =
    branch?.theme.secondaryColor && contrast > 0.2
      ? branch.theme.secondaryColor
      : branch?.theme.headerColor

  return (
    <>
      <Helmet>
        <title>{branch?.name}</title>
      </Helmet>
      <div
        className="welcome-background"
        style={{
          background: branch?.disableLandingGradient
            ? branch?.theme.headerColor
            : `linear-gradient(145deg, ${branch?.theme.headerColor}, ${branch?.theme.primaryColor} 125%)`,
        }}
      >
        <Container image={welcome}>
          {branch?.logo?.url && (
            <img
              src={branch?.logo?.url}
              width={branch?.logo?.width}
              height={branch?.logo?.height}
              alt=""
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: '30vh',
                marginBottom: '10vh',
                alignSelf: welcome ? undefined : 'center',
              }}
            />
          )}
          <H1>
            <Trans id="welcome.heading">
              Welkom op het leerplatform van
              <br />
              <strong style={{ fontWeight: 700 }}>{branch?.name}</strong>
            </Trans>
          </H1>
          <span>
            <button
              onClick={() => navigate('/login')}
              style={{
                position: 'relative',
                zIndex: '2',
                marginTop: '7.5vh',
                marginBottom: '2.5vh',

                padding: '0.5rem 1.5rem',
                background: branch?.theme.primaryColor,
                border: 'none',
                fontSize: '2.5vh',
                fontWeight: 700,
                color: buttonColor,
                boxShadow: 'none',
                cursor: 'pointer',
              }}
            >
              <Trans id="welcome.login">Inloggen</Trans>
            </button>
          </span>
          {branch?.enableRegistration && (
            <p style={{ color: '#FFF' }}>
              <Trans id="welcome.contact_for_account">
                Nog geen account? Neem{' '}
                <a
                  href="https://accountantsacademy.be/contact/"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact
                </a>{' '}
                op.
              </Trans>
            </p>
          )}
          {welcome && <WelcomeImage src={welcome} alt="welcome" />}
        </Container>
      </div>
    </>
  )
}
