import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const List = styled.ul`
  list-style: none;
  text-align: center;
`

const ListItem = styled.li`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;

  a {
    text-decoration: none !important;
    transition: border-bottom 0.2s ease;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid;
    }
  }
`

export const LanguageSelector = () => {
  return (
    <Wrapper>
      <List>
        <ListItem>
          <Link to={'/login'} onClick={() => window.LMS.setLanguage('nl-BE')}>
            Doorgaan in het <strong>Nederlands</strong>
          </Link>
        </ListItem>
        <ListItem>
          <Link to={'/login'} onClick={() => window.LMS.setLanguage('fr')}>
            Continuer en <strong>Français</strong>
          </Link>
        </ListItem>
        <ListItem>
          <Link to={'/login'} onClick={() => window.LMS.setLanguage('en')}>
            Continue in <strong>English</strong>
          </Link>
        </ListItem>
      </List>
    </Wrapper>
  )
}
