import styled from 'styled-components'

export const EventBarWrapper = styled.div`
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--event-bar-text-color);
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`

export const EventBarTitle = styled.h2`
  margin: 0;
  font-size: 1.15rem;
  font-weight: 700;
  color: var(--btn-text-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const EventBarSubtitle = styled.p`
  font-size: 0.875rem;
  margin: 0;
  color: var(--btn-text-color);
  overflow: hidden;
`

export const EventBarImage = styled.img`
  max-width: 4rem;
  width: auto;
  height: auto;
  margin-right: 1rem;
`

export const EventBarContent = styled.div`
  flex: 1;
  overflow: hidden;
  padding-right: 1rem;
`

export const EventBarCloseIcon = styled.div`
  color: var(--btn-text-color);
  font-size: 1.15rem;
  position: absolute;
  right: 0;
  top: 0.1rem;
  padding: 1rem;
`
